const enDictionary = {
 "commons": {},
 "customer_report": {
  "customer": "Customer Name",
  "customer_report_date_range": "Support maximum inquiry duration within %{days} days(e.g. 2022/07/01-2022/07/30), and inquiry start date should be less than or equal to end date.",
  "report": "Report List",
  "report_exclusive": "Report Exclusive",
  "country": "Country of Destination",
  "tooltip_import_date": "Import Date:Entry Date",
  "option_label_import_date": "Import Date",
  "error_exceed_row_limit": "Over %{row_limit} Records, please input any milestone to limit the data scope.",
  "error_invalid_filter": "You have specified filters that this report is not supported. Please try to remove them."
 },
 "utilities_mgmt": {
  "long_polling_status_success": "Success",
  "long_polling_status_failed": "Get resource failed, please try again later",
  "long_polling_status_running": "Getting resource, please wait...",
  "no_match_data": "No matched data",
  "long_live_task_status_success": "Success",
  "long_live_task_status_failed": "Get resource failed, please try again later",
  "long_live_task_status_running": "Getting resource, please wait...",
  "long_live_task_upload_process_result_title": "Upload Process Result",
  "long_live_task_upload_process_result_total": "Total",
  "long_live_task_upload_process_result_complete": "Complete",
  "long_live_task_upload_process_result_in_complete": "In-complete",
  "long_live_task_upload_process_result_error": "error",
  "undefined": ""
 },
 "cfs": {
  "acting_station": "Acting Station",
  "actual_pickup": "Actual Pickup Date",
  "actual_pickup_at": "Actual Pickup Time",
  "add_one_row": "Add one row",
  "add_route": "Add Route",
  "additional_scan": "What the user scan on the package",
  "adjust_column": "Adjust Column",
  "agent": "Agent",
  "airline_name": "Airline Name",
  "alert_confirm_leave": "The information will not be saved. Are you sure to leave?",
  "alert_save_confirm": "Are you sure to save?",
  "alert_save_success": "Successfully updated.",
  "already_checked": "This was already checked!",
  "back": "Back",
  "before_a_week": "One week before",
  "before_today": "Before today",
  "booking_at": "HAWB Booking Time",
  "browse": "Preview",
  "call_printer": "Call Printer",
  "call_printer_success": "Call Printer Successfully",
  "Can not export over 300 row": "Can not export over 300 row",
  "can_not_export_over_x_row": "Can not export over %{number} row",
  "Cannot Export": "Cannot Export",
  "cannot_be_blank": "Cannot be blank",
  "cannot_export": "Cannot Export",
  "caution": "Caution",
  "check_failed_hint": "NG! Please scan again!",
  "check_ok_hint": "Check OK! Next!",
  "check_result": "Check Result",
  "choose_input_mode": "Choose Input Mode",
  "column_adjustment": "Column Adjustment",
  "column_with_error": "Column with Error",
  "compress_img_before_upload": "Compress image before uploading",
  "consignee_name": "Consignee",
  "create_date": "Create Date",
  "csv_upload": "CSV Upload",
  "customer_name": "Customer Name",
  "data_in": "EDI Info",
  "data_input": "Data Input",
  "data_uploaded_failed": "Note! There are errors found in the file. Please correct the data and upload again.",
  "data_uploaded_successfully": "Data uploaded successfully",
  "delete_confirm": "Are you sure to delete?",
  "delete_success": "Delete Success",
  "delivery_package_number": "Pallet ID",
  "details": "Details",
  "digits": "digits",
  "dn": "DN",
  "download_template": "Download Template",
  "download_the_csv_template": "Download the CSV template",
  "edi_recieve_at": "EDI Receive Time",
  "equals": "equals",
  "error_message_is_already_exist": "%{column} already exists.",
  "estimated_pickup": "Estimated Pickup Date",
  "estimated_pickup_at": "Estimated Pickup Time",
  "eta": "ETA",
  "etd": "ETD",
  "export": "Export",
  "extra_route": "For extra route",
  "failed_check": "Failed Check",
  "fast_label_check_finish": "Stop",
  "fast_label_check_hold_scan": "Keep scanning as you can!",
  "fast_label_check_wrong_label": "You have NOT finished!",
  "fill_in_the_house_airway_bill_no": "Fill in the House Airway Bill No.",
  "fill_in_the_master_airway_bill_no": "Fill in the Master Airway Bill No.",
  "fill_in_the_pieces_of_hawb": "Fill in the pieces of HAWB",
  "fill_in_the_pieces_of_mawb": "Fill in the pieces of MAWB",
  "fill_in_the_short_message_for_note": "Fill in the message to note",
  "fill_in_the_station_code": "Fill in the station code, such as TPE, HKG, PVG…etc",
  "finish_time": "Last Scan Time",
  "flight_number": "Flight",
  "generate_pdf": "Generate PDF",
  "group_box": "Group Box",
  "group_id": "Group ID",
  "hawb": "HAWB",
  "hawb_create_date": "HAWB Info. Create Date",
  "hawb_dest": "HAWB Dest",
  "hawb_details": "HAWB Details",
  "hawb_done_by_fast_checking": "HAWB with many cartons/bags",
  "hawb_not_completed": "HAWB not completed",
  "hawb_orig": "HAWB Orig",
  "hawb_pallet_count_loose_carton_hint": "Normally HAWB pcs is the sum of pallet count and loose carton. Check and modify the HAWB pcs and package information if necessary.",
  "hawb_pcs": "HAWB Pcs",
  "hawb_gw": "HAWB GW",
  "hawb_validation_error_message": "Incorrect HAWB format",
  "hold_on_scan_ref_no_on_package": "Scan %{ref_no_name} on package!",
  "how_to_fill_in_the_template": "How to fill in the template",
  "include_in_the_list": "Include in the list",
  "incorrect_qr_code": "Incorrect QR Code",
  "information_input": "Information Input",
  "information_search": "Information Search",
  "input": "Input",
  "input_hint": "Input Hint",
  "inv_no": "INV No.",
  "INVOICE_NUMBER": "INV No",
  "is_additional_scan": "",
  "is_additional_scan_alert": "Note: Among the labels you just print, some are required to carefully check with the barcode on the cargo.",
  "it_is_ok_to_leave_it_blank": "It is OK to leave it blank.",
  "Label print record is over 5000,please contact your account manager!": "Label print record is over 5000,please contact your account manager!",
  "Label Quantity per Pallet": "Label Quantity per Pallet",
  "label_check_cannnot_scan_hint": "*Note: If you cannot scan the barcode, please key in %{place} %{number} digits of %{ref_no_name}.",
  "label_check_rule": "Label Check Rule",
  "label_check_scan_barcode_hint": "Scan %{ref_no_name} on the package",
  "label_check_scan_hint": "Scan QR Code on MEC label",
  "label_quantity_per_pallet": "Label Quantity per Pallet",
  "label_size": "Label Size",
  "last_three_days": "The past 3 days",
  "last_three_days_to_a_week": "The past 4 to 7 days",
  "last_update": "Last Update: %{time}",
  "latest_update_by": "Latest Update By",
  "less_than_3_days_before_etd": "Within 3 days of ETD",
  "location_setting": "Location Setting",
  "loose_carton": "Loose Carton",
  "loose_cartons": "Loose Cartons",
  "mapping_info_not_in_database": "%{column}: Not in the database",
  "mapping_info_upload_failed": "Upload Failed",
  "mapping_information_upload": "Mapping Information Upload",
  "mapping_method": "Mapping method",
  "mapping_target": "What to map on QR code",
  "master_number": "MAWB",
  "master_pallet": "MAWB Plt #",
  "master_pieces": "MAWB Pcs",
  "mawb_dest": "MAWB Dest",
  "mawb_orig": "MAWB Orig",
  "move_to_urgent_confirm": "Are you sure to revise it in Urgent Input?",
  "necessary_to_fill_in": "Necessary to fill in?",
  "not_same_label_size": "Not Same Label Size",
  "not_scanned": "Not Scanned",
  "not_the_same_shipment": "Fast check must to be same shipment",
  "number": "No",
  "ok": "OK!",
  "option_milstone_actual_pickup": "Actual Pickup Date",
  "option_milstone_estimated_pickup": "Estimated Pickup Date",
  "option_milstone_eta": "ETA",
  "option_milstone_etd": "ETD",
  "ordinary_label_print": "Ordinary Label Print",
  "other": "Other",
  "overwrite": "Overwrite",
  "package_checked": "Package Checked",
  "package_checked_successfully": "Packages checked successfully",
  "package_detail": "HAWB Pkg Detail",
  "package_failed_checked": "Package Failed Checking",
  "package_failed_checking": "Package failed the checking",
  "package": "Package",
  "package_id": "Package ID",
  "package_information": "Package Information",
  "package_not_checked_in_this_hawb": "Package not checked",
  "package_not_checked_ok": "Package not Checked OK",
  "package_not_checked_ok_yet": "Package not checked OK yet",
  "package_qty": "Package Qty",
  "pallets": "Pallets",
  "pallet_counts": "Pallet Counts",
  "pallet_is_not_in_task": "The label is NOT to be checked.",
  "pallet_number": "Pallet Number",
  "part_no": "Part No.",
  "PART_NUMBER": "Part No",
  "Please check row first": "Please check row first",
  "please_refresh_task_first": "Please click Refresh button first",
  "please_set_hawb_pcs": "Please set HAWB pcs.",
  "POSTFIX": "Postfix",
  "PREFIX": "Prefix",
  "print_at": "Print Time",
  "print_status": "Print Status",
  "print_status_not_printed": "Not Printed",
  "print_status_printed": "Printed",
  "REF": "Ref No",
  "ref_cartons": "Cartons in Ref#",
  "ref_no": "Reference No",
  "ref_no_name": "Reference No. Name",
  "ref_number": "Ref#",
  "refresh_task": "Refresh Data",
  "refresh_task_done": "Refresh Done",
  "refresh_task_failed": "Refresh Failed",
  "refreshing": "Refreshing...",
  "remark": "Remark",
  "reprint_label": "Reprint Label",
  "result": "Result",
  "routes": "Routes",
  "routing": "Routing",
  "routing_leg": "Routing Leg",
  "routing_all_legs": "All Legs",
  "routing_first_leg": "First Leg",
  "routing_others": "2nd Leg and others",
  "routing_number": "Routing Number",
  "row_no": "Row No",
  "rule_book": "Rule Book",
  "rules": "Rules",
  "scan": "Scan",
  "scenario": "Scenario",
  "search": "Search",
  "shipment_number": "HAWB",
  "shipper_name": "Shipper",
  "single_input": "Single Input",
  "skipped_pcs": "Unscanned Pcs",
  "sscc_code": "SSCC Code",
  "SSCC_CODE": "SSCC Code",
  "staff_belonged_station": "Staff's belonged station",
  "step": "Step",
  "summary": "Summary",
  "task_summary": "Task Summary",
  "The Export Send Your Email!": "The Export Send Your Email!",
  "today": "Today",
  "todo_item_hawb_details": "To-do Items of Today - HAWB Details",
  "todo_item_summary": "To-do Items of Today - Summary",
  "total_cartons": "Total Cartons",
  "total_for_today": "Total for Today",
  "trigger_condition": "Trigger Condition",
  "trigger_condition_package_id": "Customer + Package ID",
  "trigger_condition_place_first": "First",
  "trigger_condition_place_last": "Last",
  "trigger_condition_vip_name": "Customer",
  "trigger_condition_word_is": "word Is",
  "trigger_condition_word_isnot": "word Is Not",
  "turnaround_station": "Turnaround Station",
  "type": "Type",
  "update": "Update",
  "update_hawb_pkg_data": "Update HAWB Pkg Data",
  "update_user_station_success": "User's station updated successfully!",
  "upload_date": "Upload Date",
  "upload_failed": "Uplaod Failed",
  "upload_step_by_step": "Upload step by step",
  "upload_task_count": "Upload Task Count: %{number}",
  "upload_the_csv_file": "Upload the CSV file",
  "urgent_input": "Urgent Input",
  "urgent_input_error_msg_airline_not_found": "%{column}: Airline name not found",
  "urgent_input_error_msg_cannot_be_zero": "%{column}: Total pcs cannnot be zero",
  "urgent_input_error_msg_customer_not_found": "%{column}: Customer information not found",
  "urgent_input_error_msg_incorrect_hawb": "%{column}: Incorrect HAWB format",
  "urgent_input_error_msg_incorrect_scenario": "%{column}: Incorrect scenario",
  "urgent_input_error_msg_incorrect_station": "%{column}: Incorrect station name",
  "urgent_input_error_msg_negative": "%{column}: Not positive integer",
  "urgent_input_error_msg_no_data": "%{column}: No Data",
  "urgent_input_error_msg_incorrect_shipment_type": "%{column}: Incorrect shipment type",
  "validation_failed_message": "%{type} : %{msg}",
  "vip_name": "Customer",
  "watch_out": "Caution",
  "we_have": "we have:",
  "worksheet": "Worksheet",
  "you_can_only_fill_u_e_or_a": "You can only fill in U, E, or A.",
  "no_label_print": "No Label Print",
  "no_mapping_info": "No Mapping info",
  "create_failed": "Create Failed",
  "no_presigned_url": "No presigned url",
  "upload_s3_failure": "Upload S3 Failure",
  "no_download_url": "No Download url",
  "site": "Site",
  "series_type": "Series Type",
  "series_number": "Series Number",
  "reserve": "Reserve",
  "storage_location": "Storage Location",
  "reference_number": "Reference Number",
  "damaged": "Damaged",
  "record_cargo_information": "Record cargo information",
  "temporary_series_number": "Temporary Series Number",
  "receive": "Receive",
  "reject": "Reject",
  "received_quantity": "Received Qty",
  "last_received_date": "Last Receive Date",
  "confirm_receiving": "Confirm receiving?",
  "confirm_receiving_message": "Shall we stop receiving cargoes for this HAWB? (HAWB pcs would be updated with received Qty.)",
  "click_here_to_print_the_storage_remark": "Click here to print the storage remark",
  "confirm_delete": "Confirm to delete?",
  "do_you_want_to_remove_selected_photos": "Do you want to remove selected photo?",
  "over_max_file_count %{max_length}": "Exceed document Qty limit: ${max_length}",
  "call_printer_success_task": "Call printer successfully.",
  "call_printer_in_progess": "Printing in progess",
  "selected_label": "Label to process",
  "completed": "Completed",
  "create_temporary_series": "Create temporary series",
  "more_information": "More information",
  "whether_to_show_on_the_label": "Whether to show on the label",
  "display_mawb_pcs": "Show MAWB Pcs",
  "display_hawb_pcs": "Show HAWB Pcs",
  "do_you_want_to_save_the_changed_information  ": "Do you want to save the changed information?",
  "actually_shown_on_the_label": "Actually shown on the label:",
  "security_declaration": "Security Declaration",
  "what_to_print_out": "What to print out",
  "label": "Label",
  "shipping_remark": "Shipping Remark",
  "display_mawb": "Display MAWB",
  "display_hawb": "Display HAWB",
  "shipment_type_hint": "You can only fill in H or D",
  "shipment_type": "Shipment Type",
  "revise_the_major_information_on_the_label": "Revise the major information on the label",
  "revise_the_destination_airport": "Revise the destination airport",
  "revise_reference_information_for_this_shipment": "Revise reference information for this shipment",
  "revise_the_package_details_for_reference": "Revise the package details (for reference)",
  "revise_the_remark_information_on_the_label": "Revise the Remark information on the label",
  "directly_find_the_package_id_to_revise_the_remark": "Directly find the Package ID to revise the Remark.",
  "select_the_package_id_above_and_input_the_revised_remark_information_then_click_overwrite_button": "Select the Package ID above, and input the revised Remark information. Then click Overwrite button.",
  "it_is_required_to_revise_in_urgent_input_tab_if_needed_to_change_the_package_pieces_of_apple_gar_or_garmin_or_ask_customer_to_resending_the_edi_continue": "It is required to revise in urgent input tab if needed to change the package pieces of APPLE_GAR or GARMIN. Or ask customer to resending the EDI.\\nContinue?",
  "whe_cannot_you_find_the_labels": "Why cannot you find the labels?",
  "URGENT_USE": "Missing EDI",
  "paste_the_label_information": "Paste the label information",
  "multiple_input": "Multiple input",
  "key_points": "Key points",
  "only_mawb_pcs_customer_and_remark_are_not_required_columns": "Only MAWB Pcs, Customer and Remark are NOT required columns.",
  "customer_is_required_to_fill_in_while_the_scenario_is_urgent_use": "Customer is required to fill in while the scenario is \"Urgent Use\".",
  "not_necessary_to_input_capitalized_letters": "Not necessary to input capitalized letters.",
  "options_for_scenario_and_label_size_are_fixed": "Options for Scenario and Label Size are fixed.",
  "system_will_inspect_whether_you_input_the_information_correctly_or_not": "System will inspect whether you input the information correctly or not.",
  "information_will_not_be_saved_until_all_columns_are_correct": "Information will NOT be saved until all columns are correct.",
  "urgent_use": "Missing EDI",
  "click_here_to_update_package_id": "Click here to update package ID",
  "notes_of_revising_hawb_pcs": "*Note: If you revise the HAWB Pcs, remember to click the %{logo} logo.",
  "urgent_input_instruction_1": "Only MAWB Pcs, Customer and Remark are NOT required columns.",
  "urgent_input_instruction_2": "Default scenario is \"Missing EDI\". If you want to choose OTHERS scenario, clear the cell.",
  "urgent_input_instruction_3": "Under scenario \"Missing EDI\", label size is fixed as 4x6.",
  "urgent_input_instruction_4": "If the scenario is not \"Missing EDI\", you can choose the label size at will.",
  "urgent_input_instruction_5": "For Label Size, you can only type in 4x6 or 4x3.",
  "urgent_input_instruction_6": "For Shipment Type, type in \"H\" for Hub; or type in \"D\" for Direct Ship.",
  "urgent_input_instruction_7": "Shipment Type is required, if Customer is Apple GAR.",
  "urgent_input_instruction_8": "Text will be automatically changed to capitalized letters.",
  "urgent_input_instruction_9": "System will inspect whether you input the information corrrectly or not. Information will NOT be saved until all columns are correct.",
  "multiple_sets_tooltip": "You can type in multiple sets of text, and system will search data accordingly.",
  "type_in_keyword_tooltip": "Type in keyword and select an hinted item",
  "select_the_scenario": "Select the scenario",
  "input_the_conditions": "Input the search conditions",
  "option_milstone_created_at_utc": "Created At",
  "necessary_to_input": "Necessary to input",
  "the_text_will_be_shown_on_all_of_the_labels": "The text will be shown on all of the labels",
  "necessary_to_input_only_if_scenario_is_urgent_use": "Necessary to input only if scenario is Missing EDI",
  "supplement_label": "Supplement Label",
  "saved_items": "Saved Items",
  "select_all": "Select All",
  "cancel": "Cancel",
  "cancel_all": "Cancel All",
  "printing_quantity": "Printing Quantity",
  "why_cannot_you_find_the_labels": "Why cannot you find the labels?",
  "error_code_request_item_is_invalid": "%{item} is invalid",
  "error_code_search_not_found": "Cannot find the data",
  "error_code_print_size_not_same": "You cannot process different label size at the same time.",
  "main_page": "Main Page",
  "package_level": "Package level",
  "delivery_level": "Delivery level",
  "photo_management": "Photo management",
  "select_the_milestone_to_check": "Select the milestone",
  "enter_the_hawb_or_package_id": "Enter the HAWB or Package ID",
  "enter_hawb_or_dn_or_package_id": "Enter the HAWB, DN or Package ID",
  "enter": "Enter",
  "export_receiving": "Export - Receiving",
  "fuzzy_tooltip": "Fuzzy search: Just type in part of the texts",
  "supplement_column_tooltip": "Upload photos to create 4x3 size label.",
  "choose_the_scenario": "Choose the scenario",
  "fill_in_the_label_information": "Fill in the label information",
  "display_airline_logo": "Display airlines logo",
  "hawb_postfix": "Add suffix in HAWB",
  "urgent_input_error_msg_incorrect_label_size": "%{column}: Incorrect label size",
  "urgent_input_error_msg_incorrect_mawb": "%{column}: Incorrect MAWB format",
  "urgent_input_error_msg_cannot_type_in_numbers": "%{column}: Cannot type in numbers",
  "urgent_input_error_msg_exceed_4_characters": "%{column}: Cannot exceed 4 characters",
  "hawb_suffix": "HAWB suffix",
  "add_suffix_in_hawb": "Add suffix in HAWB",
  "notice_multiple_shipment_selected": "Notice! Multiple shipments selected",
  "photo": "Photo",
  "text": "Text",
  "mnl_hongkong_airline_name_cargo": "Change Airline Name",
  "mawb": "MAWB",
  "simple_mode": "Simple Mode",
  "here_you_can_only_search_the_data_that_booking_is_made_within_30_days": "Here you can only search the data that is set up within 30 days",
  "select_the_information_level": "Select the information level",
  "photo_taking": "Photo Taking",
  "take_photo_now": "Take photo now",
  "check_all_photo": "Check all photos",
  "pouch_label": "Pouch label",
  "please_input_valid_shipment_number_or_package_number": "Please input valid shipment number or package ID",
  "select_the_photos_you_surely_want_to_upload": "Select the photos you surely want to upload\n",
  "check": "Check",
  "error_label_size_not_set": "Label template of this size is not set up.",
  "error_source_folder_not_set": "The printer routing (folder) is not set up.",
  "change_label_size": "Change Label Size",
  "choose_what_to_upload": "Choose what to upload",
  "you_cannot_select_both_hawb_and_package_id": "You cannot select both HAWB and Package ID",
  "partial_receive": "Partial Receive",
  "handled_today": "Handled Today",
  "label_quantity": "Label Quantity",
  "print_label": "Print Label",
  "you_should_select_at_least_one_hawb": "You should select at least one HAWB",
  "receive_by_package": "Receive By Package",
  "receiving": "Receiving",
  "please_scan_the_qrcode": "Please scan the QR Code",
  "just_scanned_in": "Just Scanned In",
  "status": "Status",
  "customer": "Customer",
  "package_to_receive": "Package To Receive",
  "repeat_scan": "Repeat Scan",
  "received": "Received",
  "error_shipment_can_not_split_combine": "Shipment can not split & combine",
  "carton_qty": "Carton Qty",
  "document_type": "Doc Type",
  "printer_name": "Printer Name",
  "now_you_are_at": "Now you are at",
  "choose_a_printer": "Choose a printer",
  "select_the_default_printer": "Select the default printer",
  "fix_the_printer_setting": "Fix the printer setting",
  "please_select_printer_for_each_label_size": "Please select printer for each label size",
  "photo_upload": "Take Photos",
  "pallet_split_and_combine": "Pallet Split & Combine",
  "scan_the_qrcode_on_the_label": "Scan the QRcode on the label",
  "pcs": "Pcs",
  "urgent": "Urgent ",
  "dn_to_reallocate": "DN's to re-allocate",
  "dn_no": "DN No.",
  "print_out": "Print Out",
  "reallocate_the_dn": "Re-allocate the DN's",
  "qty": "Qty",
  "edit": "Edit",
  "save": "Save",
  "selected_qty": "Selected Qty",
  "combine_with": "Combine With",
  "splitted_packages": "Resulting Packages",
  "choose_a_pallet_to_put_these_cartons": "Choose a pallet to put these cartons",
  "consignee": "Consignee",
  "brief_address": "Brief Address",
  "type_in_the_carton_qty_and_click_the_button_below": "Type in carton Qty and click the button below",
  "split_out": "Split out",
  "please_type_in_qty": "You must type in some numbers here.",
  "please_input_correct_number": "Please input correct number",
  "exceed_carton_qty": "Exceed Carton Qty",
  "please_allocate_all_delivery": "Please allocate all delivery",
  "already_pick_up_ofd": "Aleady Pickup (OFD)",
  "not_received": "Not Received",
  "already_put_away": "Already Put Away",
  "not_put_away_yet": "Not Put Away Yet",
  "no": "No.",
  "to_do_list": "To-do List",
  "group_put_away": "Group Put-away",
  "refresh_data": "Refresh Data",
  "open_pcs": "Open Pcs",
  "scanned": "Scanned",
  "do_you_want_to_clear_all_shipments": "Do you want to clear all shipments",
  "put_away": "Put Away",
  "error_code_cannot_find_the_location": "Cannot find this location code",
  "error_code_resource_not_found": "%{resource} is not found",
  "error_delivery_package_can_not_put_away": "%{delivery_package_number} can not put away",
  "stored_package_qty": "Stored Package Qty",
  "delivery_no": "Delivery No.",
  "package_checker": "Package Checker",
  "consignee_address": "Consignee Address",
  "current_milestone": "Current Milestone",
  "next_milestone": "Next Milestone",
  "action_required": "Action Required",
  "latest_updated_by": "Latest Updated By",
  "latest_updated_at": "Latest Update Time",
  "put_away_by": "Put away by",
  "put_away_time": "Put away time",
  "put_away_combo_mode_data_dialog_message": "To keep data clean, we will delete the scanned data. Are you sure to switch the mode?",
  "carton_sorting": "Carton Sorting",
  "result_summary": "Result Summary",
  "sorting_info_upload": "Sorting Info Upload",
  "start": "Start",
  "stop": "Stop",
  "scan_the_barcode": "Scan the barcode",
  "repeated_scan": "Repeated scan",
  "wrong_barcode": "Wrong barcode",
  "scan_result": "Scan result",
  "package_number": "Package number",
  "get_report": "Get report",
  "linked_with_hawb": "Linked with HAWB",
  "sorting_input_date": "Sorting input date",
  "start_date": "Start date",
  "end_date": "End date",
  "date_range_should_be_within_n_days": "Date range should be within %{days} days",
  "start_date_should_be_before_or_eqaul_to_end_date": "Start date should be before or equal to end date",
  "date": "Date",
  "packslip_number": "Packslip No.",
  "import_receive": "Import Receive",
  "remember_to_get_pod_sign_off": "Remember to get POD sign-off",
  "check_if_you_get_the_pod_copy": "Check if you get the POD copy",
  "package_checker_milestone_import_cfs_not_arrived": "Not arrived yet",
  "package_checker_milestone_truck_arrived": "Truck arrived",
  "package_checker_milestone_import_cfs_received": "Received",
  "package_checker_milestone_import_cfs_reweigh": "Reweigh & Take photos",
  "package_checker_milestone_import_cfs_sorting": "Sorting",
  "package_checker_milestone_available_for_delivery": "Put Away",
  "package_checker_milestone_import_cfs_pickup": "Picking",
  "package_checker_milestone_ofd": "Out for delivery",
  "package_checker_milestone_actual_pod": "POD",
  "cannot_find_this_delivery_package": "Cannot find it in the database",
  "latest_updated_at_utc": "Latest Updated at (UTC)",
  "field_is_required": "%{field} is required",
  "field_is_duplicate": "%{field} is duplicate",
  "error_delivery_package_can_not_pick_up": "%{delivery_package_number} can not pick up",
  "picking_entry": "Picking-Entry",
  "scan_the_qrcode_on_the_picking_order": "Scan the QRcode on the Dispatch Manifest",
  "dispatch_no": "Dispatch No",
  "truck_pickup_time": "Truck Pickup Time",
  "bol_no": "BOL No",
  "pallet_is_not_in_dispatch": "The pallet is NOT in this Dispatch.",
  "package_ref_info": "Package Ref. Info.",
  "flight_status": "Flight Status",
  "on_way": "On way",
  "landed": "Landed",
  "is_checked": "Checked by scanning",
  "shipment_information": "Shipment Information",
  "lot_no": "Lot No.",
  "bag_id": "Bag ID",
  "quantity": "Qty",
  "scanned_quantity": "Scanned Qty",
  "Bag Checking": "Bag Checking",
  "input_the_mawb": "Input the MAWB",
  "scan_the_qr_code_on_the_package": "Scan the QR code on the package",
  "there_is_still_information_not_uploaded": "There is still information not uploaded",
  "confirm_upload": "Do you confirm to upload?",
  "bag_id_is_not_belong_to_master_number": "%{bag_id} does NOT belong to %{master_number}",
  "weight": "Weight",
  "get_weight": "Get Weight",
  "reweigh": "Reweigh",
  "record_the_weight_result": "Record the weight result",
  "reweigh_summary": "Reweigh Summary",
  "select": "Select",
  "link_the_scale": "Link the scale",
  "reweigh_pcs": "Reweigh Pcs",
  "reweigh_sum": "Reweigh Sum",
  "package_to_be_reweighted": "Package to be reweighed",
  "are_you_sure_to_submit_the_scanned_information": "Are you sure to submit the scanned information ?",
  "destination": "Destination",
  "error_has_already_received": "%{number} - Already received!",
  "error_has_been_already_ofd": "Already out for delivery.",
  "error_has_not_received_yet": "%{number} - NOT received yet!",
  "error_has_not_received_all_shipment_packages_yet": "Pallets are not fully received",
  "error_must_reweigh_and_take_pictures": "%{number} - Not reweighed! Photo NOT taken yet!",
  "error_remember_reweigh_and_take_pictures": "Remember to reweigh and take pictures!",
  "error_no_need_to_split_combine": "%{number} - Not breakable!",
  "error_has_already_pick_up": "Already picked.",
  "error_has_not_put_away_yet": "This pallet is not put away",
  "error_has_not_split_and_combine_yet": "Have not done split & combine!",
  "error_freight_is_damaged": "%{number} - The freight is damaged!",
  "error_delivery_can_not_recreate": "Delivery Number has been bound to other sheet.",
  "field_must_be_positive_integer": "%{field} must be positive integer",
  "error_cant_upload_after_bag_checking": "Can't upload shipment manifest after bag check",
  "bag_checking": "Bag Checking",
  "error_cannot_update_import_received_date": "Cannot Update Import Receivee Date",
  "sheet_status": "Sheet Status",
  "sheet_number": "Sheet No",
  "destination_mec_station": "Destination (MEC Station)",
  "delivery_number": "DN",
  "estimated_delivery_date_sla": "SLA",
  "origin_station": "Origin",
  "destination_station": "Destination",
  "target_mec_station": "Targeted MEC Station",
  "estimated_truck_pickup_at": "Estimated Pickup Date",
  "estimated_truck_pickup_at_time": "Estimated Pickup Time",
  "actual_truck_pickup_at": "Actual Truck Pickup Date",
  "estimated_delivery_at": "Estimated Delivery Date",
  "estimated_delivery_at_time": "Estimated Delivery Time",
  "delivery_created_at_utc": "DN Created Date (UTC)",
  "sheet_created_at": "Sheet Created Date",
  "is_delivery_put_on_bol": "DN put on BOL?",
  "is_delivery_readiness": "DN Readiness",
  "split": "Split",
  "created_at_utc": "Created At (UTC)",
  "ready_for_delivery": "Ready for delivery",
  "not_ready": "Not Ready",
  "at_least_one_row": "Please select at least one item.",
  "at_least_two_row": "Please select at least two items.",
  "at_least_one_transfer_manifest": "Please select at least one Transfer Manifest",
  "at_least_one_need_transfer_delivery": "Please select at least one DN",
  "cannot_combine_transfer_manifests": "You cannot combine multiple Transfer Manifests.",
  "cannot_split_transfer_manifest": "You cannot split this Transfer Manifest.",
  "only_can_print_transfer_manifests": "You cannot print a item not on the Transfer Manifest.",
  "cannot_create_more_transfer_manifest": "One DN can only be loaded on one Tansfer Manifest.",
  "cannot_revise_picked_data": "The cargoes were picked up. Cannot change it.",
  "sheet_information_cannot_be_revised": "Sheet information cannot be revised.",
  "are_you_sure_to_create_the_sheet": "Are you sure to create a %{type}?",
  "are_you_sure_to_update_the_sheet": "Are you sure to update the sheet information?",
  "are_you_sure_to_cancel_the_sheet": "Are you sure to cancel the %{type}?",
  "are_you_sure_to_combine_the_sheet": "Are you sure to combine the %{type} with new DN's?",
  "are_you_sure_to_split_the_sheet": "Are you sure to split the DN out?",
  "carrier_code": "Carrier Code",
  "is_package_damaged": "Package Damaged",
  "select_ble_device": "Select your Bluetooth device",
  "disconnect": "Disconnect",
  "parse_message_failed": "Fail to fetch weight data.",
  "sync_message_failed": "Fail to connect with the device.",
  "additional_milestone": "--Additional--",
  "latest_printed_label_qty": "Latest Printed Qty",
  "hawb_prefix": "HAWB Prefix",
  "label_shipment_number_postfix": "Suffix",
  "reweigh_is_number": "You must type in numbers here.",
  "import_receive_multibox_step1": "Step 1",
  "import_receive_multibox_step1_text": "Put the cargoes in order, and start counting the quantity of box/carton/bag.",
  "import_receive_multibox_step2": "Step 2",
  "import_receive_multibox_step2_text": "Type in the actual quantity of box/carton/bag.",
  "import_receive_multibox_step3": "Step 3",
  "import_receive_multibox_step3_text": "How many pallets are they loaded on?",
  "import_receive_multibox_confirm_text": "Note that from now on, this shipment will be handled in the form of ",
  "import_receive_multibox_confirm_warning": "You CANNOT change the package type afterwards.",
  "import_receive_multibox_confirm_print": "Do you want to print out new labels now?",
  "import_receive_multibox_check_text": "According to the package quantity and unit booked in the system, this shipment is NOT supposed to change the package type.",
  "import_receive_multibox_check_warning": "Do you still want to proceed?",
  "import_receive_multibox_leave_confirm": "Are you sure to leave this page?",
  "import_receive_multibox_leave_info": "Continue to finish the scanning.",
  "photo_of_the_scale": "Photo of the scale",
  "cargo_outlook": "Cargo Outlook",
  "error_received_when_have_inventory_manifest": "Please put away the cargoes",
  "is_damaged": "Is Damaged",
  "missing_dispatch_info": "Missing Dispatch Info.",
  "you_should_scan_delivery_packages_first": "Scan the QR code first.",
  "error_package_type_transformation": "Pallet type freight cannot be transformed.",
  "combo_mode_name": "Combo Mode",
  "ship_to_name": "Ship-to Name",
  "ship_to_city": "Ship-to City",
  "error_has_been_already_ofd_by_tms": "%{number} - Done (Already OFD via TMS)",
  "please_select_at_least_one_photo": "You must select at least one photo.",
  "handover": "Handover",
  "mawb_arrived": "%{mawb} arrived",
  "mawb_incorrect": "Incorrect MAWB",
  "sheet_number_incorrect": "Does not exist in the system",
  "check_mawb": "Check MAWB",
  "new_package_id": "New Package ID",
  "old_package_id": "Old Package ID",
  "the_pallet_is_not_for_this_truck": "The pallet is NOT for this truck!",
  "location": "Location",
  "urgent_label": "Urgent Label?",
  "picking": "Picking",
  "label_print": "Label Printed?",
  "label_type": "Label Type",
  "ofd": "OFD",
  "error_received_must_split_combine": "Must split & combine!!",
  "select_source_of_photo": "Choose how to get the photos",
  "photo_source_camera": "Take photos",
  "photo_source_device": "Upload from device",
  "station": "Station",
  "inventory_management_notice": "Here we simply look at the freights that are not Out for Delivery (OFD).",
  "photo_selection_info": "Photos: %{number} / %{total}",
  "photo_move": "Move Photo",
  "photo_move_title": "Move this photo to:",
  "photo_move_target_information_level": "Information Level",
  "photo_move_target_milestone": "Milestone",
  "photo_move_target_target_number": "Target Number",
  "no_printer_set_up_in_this_site": "Have not set up printer information in the system",
  "must_split_combine_modal_message": "Note: You must use Pallet Split & Combine to generate new labels before put-away.",
  "timestamp": "Show timestamp",
  "handling_record": "Handling Record",
  "delivery_id": "Delivery ID",
  "partial_receive_confirmation": "Are you sure to partially receive current freights?",
  "error_sync_from_prolink_failed": "Data-sync failed",
  "sync_from_prolink": "Data-sync in progress",
  "error_need_reprint_label": "Due to Customer Code error, you must re-print the label!",
  "error_vip_name_need_checked_and_revised": "Customer Code was mistakenly changed. Call origin station to correct it!",
  "split_and_combine": "Split & Combine",
  "single_package": "Single Package",
  "scan_qrcode_or_enter_hawb_below": "Scan QR Code or enter HAWB No below.",
  "break_pallet": "Break Pallet",
  "record": "Record",
  "unfold": "Unfold",
  "pod": "POD",
  "truck_arrives": "Truck arrives",
  "ata": "ATA",
  "footprints": "Footprints",
  "double_click_to_select_the_package_id": "Double-click to select the Package ID",
  "fold": "Fold",
  "delivery_number_more": "...more",
  "cannot_create_different_delivery_method": "You are NOT allowed to select the items with different Delivery Methods at the same time.",
  "cannot_combine_different_delivery_method": "The items you select have a different delivery method.",
  "sheet_type": "Sheet Type",
  "service_type": "Service Type",
  "transfer_manifest": "Transfer Manifest",
  "customer_pickup": "Customer Pickup",
  "inventory_adjustment_create_error": "You are NOT allowed to select the items with different Delivery Methods at the same time.",
  "inventory_adjustment_combine_error": "The items you select belong to different delivery methods.",
  "error_cannot_delete_no945_shipment": "Cannot delete the data created by Missing EDI scenario.",
  "pickup_some_not_picked_up": "Please keep scanning the rest of packages",
  "pickup_all_picked_up": "Remember to sign up Customer Pickup Form and take pictures on it",
  "warning_cargo_need_to_break_down": "This pallet needs to break down!",
  "error_shipment_not_exists": "",
  "warning_delivery_package_already_rebuild": "Some of the data have been rebuilt before.<br/>Are you sure to proceed?<br/><br/>%{shipments}",
  "pallets_to_break_down": "Pallets to break down",
  "choose_the_method_to_reallocate_the_dn": "Choose the method to re-allocate the DN's",
  "manual_way": "Manual Way",
  "automatic_way": "Automatic Way",
  "system_suggestion": "System Suggestion",
  "update_pcs": "Updated Pcs",
  "save_and_reprint_labels": "Save and Reprint Labels",
  "split_qty": "Split Qty",
  "please_select_one_package_to_combine": "Please select one package to combine",
  "need_to_reprint_labels": "Need to reprint labels!",
  "you_have_not_reprinted_the_labels": "You have not reprinted the labels.",
  "do_you_want_to_reprint_labels_now": "Do you want to reprint labels now?",
  "just_leave_anyway": "No, just leave anyway.",
  "let_me_do_it_now": "Yes, let me do it now.",
  "last_received_time": "Last Receive Time",
  "inbound_checking": "Inbound Checking",
  "error_pick_up_cancelled_dispatch": "This Dispatch No has been cancelled.",
  "error_pick_up_dispatch_with_all_splittable_packages": "Do Pallet Split & Combine first.",
  "error_pick_up_dispatch_with_all_urgent_created_packages": "All are Urgent Labels. Do receiving and sorting first.",
  "info_not_show_goods_on_table": "*This table does not show %{brand} freights.",
  "input_location_code": "Input the location code",
  "data_rebuild": "Data Rebuild",
  "data_rebuild_input_hawb": "Input the HAWB",
  "data_rebuild_button_hint": "Click the button to rebuild data",
  "data_rebuild_rebuild_button": "Rebuild Package Data",
  "data_rebuild_result_success": "Successfully rebuilt<br/><br/>%{shipments}",
  "data_rebuild_result_some_failed": "Successfully rebuilt<br/>%{successes}<br/><br/>But some just failed<br/>%{failures}",
  "data_rebuild_result_error": "Failed to rebuild<br/><br/>%{shipments}",
  "urgent_multiple_input_instruction_1": "Only MAWB Pcs and Remark are NOT required columns.",
  "urgent_multiple_input_instruction_2": "For Label Size, you can only type in 4x6, 4x5, or 4x3.",
  "urgent_multiple_input_instruction_3": "Text will be automatically changed to capitalized letters.",
  "urgent_multiple_input_instruction_4": "System will inspect whether you input the information corrrectly or not. Information will NOT be saved until all columns are correct.",
  "label_configuration": "Label Configuration",
  "click_to_partially_print_the_label": "Click to partially print the label.",
  "missing_record_alert": "Note: The label information you search is not all available.",
  "check_details": "Check Details",
  "data_availability": "Data Availability",
  "available_or_not": "Available?",
  "get_label_information": "Get Label Information",
  "get_label_information_notice": "*If the customer is APPLE_GAR, the system will create Urgent-Input type of label.",
  "advanced_search": "Advanced Search",
  "fuzzy_search": "Fuzzy Search",
  "edi945_in": "EDI945 In",
  "latest_print_time": "Latest Print Time",
  "first_print_time": "1st Print Time",
  "edi_receive_time": "EDI Receive Time",
  "revise_airport_code": "Revise airport code",
  "notes_of_revising_package_details": "*Note: Normally HAWB pcs should be the sum of pallet count and loose carton. Modify the HAWB pcs if necessary.",
  "revise_the_package_information_on_the_label": "Revise the package information on the label",
  "reset_with_edi945": "Reset with EDI945",
  "cannot_input_mawb_and_hawb_at_the_same_time": "Cannot input MAWB and HAWB at the same time.",
  "notice_of_revising_package_of_apple_gar_or_garmin": "It is required to revise in urgent input tab if needed to change the package pieces of APPLE_GAR or GARMIN. Or ask customer to resending the EDI.",
  "sort_date": "Sort date",
  "update_date": "Update Date",
  "must_larger_than_hawb_pcs": "MAWB pcs cannot be less than HAWB pcs.",
  "package_count": "Package Count",
  "atd": "ATD",
  "need_breakdown": "Need Breakdown",
  "missing_milestone": "Missing Milestone",
  "no_truck_arrival_time": "No truck arrival time",
  "not_broken_down": "Not broken down",
  "not_picked": "Not picked",
  "carton_sorting_incorrect_field": "Incorrect %{field}",
  "check_consignee_for_packslip": "Please check the consignee for the following Packslip No.",
  "scan_record": "Scan Record:",
  "type_in": "Type In",
  "type_in_the_packslip_no": "Type in the Packslip No",
  "manual_input": "Manual Input",
  "time": "Time",
  "adjust_quantity": "Adjust Qty",
  "total_quantity": "Total Qty",
  "carton_sorting_error_msg_incorrect_shipper": "Incorrect Shipper",
  "carton_sorting_error_msg_incorrect_consignee": "Incorrect Consignee",
  "identify": "Identify",
  "re_select_photo": "Please re-select photo range",
  "airline_code": "Airline Code",
  "airline_code_max_length": "Code max length is %{length}",
  "airline_modal_create_title": "Create Airline",
  "airline_modal_edit_title": "Edit Airline",
  "error_code_others": "Caused by uncertain reason. Please contact IT engineer.",
  "cant_sync_received_shipment": "Data is limited to refresh data if freights have been received.",
  "direct_upload": "Direct Upload",
  "label_printing": "Label Printing",
  "specific_list": "Specific List",
  "default_setting_for_the_station": "Default setting for the station",
  "add_site": "Add Site",
  "1_printers": "1. Printers",
  "2_labels": "2. Labels",
  "delete_station": "Delete Station",
  "template_name": "Template Name",
  "list_name": "List Name",
  "location_code": "Location Code",
  "tpe_cs_list": "TPE CS List",
  "employee_id": "Employee ID",
  "chinese_name": "Chinese Name",
  "phone_number": "Phone Number",
  "close": "Close",
  "only_handle_ptt": "Here we only handle PTT",
  "directly_put_away_with_ia": "Directly put away the freights",
  "notify_to_do_inbound_check": "Please do Inbound Checking",
  "can_not_less_than_scanned_qty": "can’t less than scanned qty",
  "abnormal_receive": "Record the abnormality",
  "1_type_in_hawb": "1. Type in the HAWB",
  "2_select_issue_on_label": "2. Select the issue type on the label",
  "3_reprint_labels": "3. Reprint the labels",
  "4_record_situation": "4. Record the situation",
  "import_receive_issue_bad_printing_quality": "Bad printing quality",
  "import_receive_issue_damaged_label": "Label is damaged",
  "import_receive_issue_different_package_ids_on_pallet": "Different Package ID's on one pallet",
  "import_receive_issue_others": "Others",
  "volume_download": "Volume Download",
  "notify_the_result_of_uploading": "Notify the result of uploading",
  "you_have_to_select_photos_first": "You have to select photos first",
  "undefined": ""
 },
 "billing": {
  "charge_amount": "Amount",
  "allocation_edit_header_new": "Create PO Request",
  "allocation_edit_header_edit": "Edit PO Request:",
  "allocation_edit_header_view": "View PO Request:",
  "allocation_sum": "Check Sum",
  "credit_to_id": "Vendor",
  "issued_by_station": "Station",
  "payment_method": "Payment Method",
  "origin_currency": "Currency",
  "origin_amount": "Amount",
  "station": "Station",
  "department": "Department",
  "charge_code": "Charge Code",
  "quantity": "QTY",
  "origin_charge_rate": "Rate",
  "unit": "Unit",
  "calculate_by": "Calculate By",
  "allocation_method": "Allocation Method",
  "confirm": "Confirm",
  "allocation_history_header": "Allocation History",
  "po_request_id": "PO Request ID",
  "created_date": "Created Date",
  "prolink_id": "Prolink ID",
  "view": "View",
  "edit": "Edit",
  "sum_error": "Sum doesn't Match!",
  "add_new_allocation": "Add New",
  "calculate": "Calculate",
  "allocation_new_dispatch_must_has_allocation": "New Dispatch must has Allocation",
  "allocation_sum_check_not_match": "Allocation check sum not match",
  "ttl_amount": "Amount",
  "ref_sharp": "REF#",
  "dept": "Dept",
  "reference_number": "Reference No",
  "vip_name": "Customer",
  "bill_to_id": "Debit To/Vendor",
  "category": "Category",
  "billing_type": "Billing Type",
  "invoice_type": "Invoice Type",
  "invoice_voucher_number": "Invoice/ Voucher No",
  "shipment_number": "Shipment No",
  "print_at_from": "Printed Date From",
  "print_at_to": "Printed Date To",
  "void": "Void",
  "client_id": "Client ID",
  "sales_number": "Sales Code",
  "ap_status": "AP Status",
  "customer": "Customer",
  "printed": "Printed",
  "request": "Request",
  "delete": "Delete",
  "amount": "Amount",
  "currency": "Currency",
  "bcr_category_id": "Major Category",
  "bcr_reason_code": "Detail Reason",
  "voucher_history_header": "PO Info (From Prolink/ CW1)",
  "void_confirm_signature": "Please confirm with accounting team for this Void operation of this Invoice/Voucher#",
  "invouce_voucher_number": "Invoice / Voucher No",
  "reference_no": "Reference No",
  "billing_correction_request_bcr": "Billing Correction Request - BCR",
  "trade_type": "Transaction",
  "transaction": "Transaction",
  "tips": "Tips",
  "mass_upload_tips_detail": "If choose \"General\", 1 uploaded record will generate 1 PO/ Invoice. \nIf choose \"3rd Party Station\", 1 uploaded record will generate 2 Invoices + 1 PO.",
  "mass_upload_tips_detail_step2": "The listed results will be uploaded to Prolink/ CW1 to print PO/ Invoice.",
  "created_by": "Created By",
  "charge_category": "Charge Category",
  "project_id": "Project ID",
  "fd_no": "FD No",
  "pr_no": "PR No",
  "request_type": "Request Type",
  "request_status": "Request Status",
  "is_late": "Late Billing",
  "mawb_mbl": "MAWB/ MBL",
  "create_date_utc": "Created Date (UTC)",
  "print_at": "Printed Date",
  "voided_date": "Void Date",
  "voucher_type_invoice": "Invoice",
  "voucher_type_voucher": "Voucher",
  "trade_type_trade": "Trade",
  "trade_type_non_trade": "Non Trade",
  "ap_status_c": "C",
  "request_type_displayed_create": "Create",
  "request_type_displayed_void": "Void",
  "request_status_in_progress": "In Progress",
  "request_status_failure": "Failure",
  "billing_tab_posted_tab": "Printed/ Voided",
  "billing_tab_requested_tab": "Request",
  "bill_credit_to_id": "Debit To/ Vendor",
  "bill_credit_to": "Debit To/ Vendor",
  "charge_line_origin_currency": "Rate Currency",
  "charge_line_origin_amount_in_usd": "Amount (USD)",
  "allocated_origin_allocation_amount": "Allocated Amount",
  "allocated_origin_allocation_amount_in_usd": "Allocated Amount (USD)",
  "request_serial_number": "Request ID",
  "charge_line_description": "Charge Description",
  "invoice_amount": "Invoice Amount",
  "invoice_amount_in_usd": "Invoice Amount (USD)",
  "invoice_currency": "Invoice Currency",
  "nvat_amount": "Pre-Tax Amount",
  "vat_amount": "Vat Amount",
  "ap_amount": "AP Amount",
  "cant_void_void_request_is_processing": "Can't void, the void request is processing",
  "amount_usd": "Amount (USD)",
  "allocated_amount_usd": "Allocated Amount (USD)",
  "failure_reason": "Failure Reason",
  "type": "Type",
  "billing_type_main": "MAIN",
  "billing_type_credit_note": "CREDIT",
  "billing_type_debit_note": "DEBIT",
  "ap_status_complete": "Complete",
  "ap_status_delete": "Delete",
  "mass_upload": "Mass Upload",
  "billing_mass_upload_type_general": "General",
  "billing_mass_upload_type_third_station": "3rd Station Upload",
  "mass_upload_step1_title_general_upload": "Step 1: General Upload",
  "mass_upload_step2_title_check_result": "Step 2: Check Result",
  "mass_upload_step2_no_data_info": "Please make sure all data is correct first and click \"Check All & Transfer\".",
  "mass_upload_column_key_check_result": "Check Result",
  "mass_upload_column_key_upload_type": "Upload Type",
  "mass_upload_column_key_station": "Station",
  "mass_upload_column_key_dept": "Dept",
  "mass_upload_column_key_customer": "Customer",
  "mass_upload_column_key_debit_to_vendor": "Debit to/ Vendor",
  "mass_upload_column_key_payment_term": "Payment Term",
  "mass_upload_column_key_sales_code": "Sales Code",
  "mass_upload_column_key_reference_no": "Reference No",
  "mass_upload_column_key_charge_code": "Charge Code",
  "mass_upload_column_key_qty": "Qty",
  "mass_upload_column_key_unit": "Unit",
  "mass_upload_column_key_rate": "Rate",
  "mass_upload_column_key_rate_currency": "Rate Currency",
  "mass_upload_column_key_rate_amount": "Rate Amount",
  "mass_upload_column_key_charge_description": "Charge Description",
  "mass_upload_column_key_3rd_station": "3rd Station",
  "mass_upload_column_key_3rd_dept": "3rd Dept",
  "mass_upload_column_key_3rd_sales_code": "3rd Sales Code",
  "mass_upload_column_error_key_value_can_not_be_blank": "Can not be blank",
  "mass_upload_column_error_key_invalid_upload_type_value": "Invalid Upload Type",
  "mass_upload_column_error_key_invalid_station_value": "Invalid Station",
  "mass_upload_column_error_key_invalid_dept_value": "Invalid Dept",
  "mass_upload_column_error_key_invalid_customer_value": "Invalid Customer",
  "mass_upload_column_error_key_invalid_debit_to_vendor_value": "Invalid Debit to/Vendor",
  "mass_upload_column_error_key_invalid_payment_term_value": "Invalid Payment Term",
  "mass_upload_column_error_key_invalid_sales_code_value": "Invalid Sales Code",
  "mass_upload_column_error_key_invalid_reference_no_value": "Invalid Reference No",
  "mass_upload_column_error_key_invalid_charge_code_value": "Invalid Charge Code",
  "mass_upload_column_error_key_invalid_unit_value": "Invalid Unit",
  "mass_upload_column_error_key_invalid_rate_currency_value": "Invalid Rate Currency",
  "mass_upload_column_error_key_invalid_rate_amount_value": "Invalid Rate Amount",
  "mass_upload_column_error_key_invalid_3rd_sales_code_value": "Invalid 3rd Party Sale Code",
  "download_template": "Download Template",
  "upload_file": "Upload File",
  "or_paste_data": "Or Paste Data",
  "warning_data_over_write_after_upload": "(All input data will be overwritten if choosing new file)",
  "file": "File",
  "total_records": "Total Records",
  "total_amount": "Total Amount",
  "error": "Error",
  "checked": "Checked",
  "check_and_transfer": "Check & Transfer",
  "mass_upload_post_all_to_prolink": "Post All To Prolink",
  "mass_upload_post_all_to_prolink_success": "Success! Do you want to go to query result page?",
  "check_first_memo": "Please click \"Check\" first.",
  "warning_change_type_clear_data": "Change Type Will Clear All Data",
  "warning_would_you_like_to_send_to_prolink": "Sure to Send to Prolink?",
  "check_confirm_to_send_to_prolink": "Confirm",
  "voucher_no": "Voucher No",
  "invoice_voucher_date": "Invoice/ Voucher Date",
  "acting_station": "Acting Station",
  "warning_code_billing_mass_upload_duplicate_row_in_input_data": "duplicate of row %{row_id}(Debit to/vendor, Reference No.), please confirm before uploading.",
  "warning_code_billing_mass_upload_duplicate_row_in_db": "duplicate INV/PO (Debit to/vendor, Reference No.) in the system (shipment num:%{reference_no}), please confirm before uploading.",
  "value_can_not_be_blank": "Can not be blank",
  "invalid_upload_type_value": "Invalid Upload Type",
  "invalid_station_value": "Invalid Station",
  "invalid_dept_value": "Invalid Dept",
  "invalid_customer_value": "Invalid Customer",
  "invalid_debit_to_vendor_value": "Invalid Debit to/Vendor",
  "invalid_payment_term_value": "Invalid Payment Term",
  "invalid_sales_code_value": "Invalid Sales Code",
  "invalid_reference_no_value": "Invalid Reference No",
  "invalid_charge_code_value": "Invalid Charge Code",
  "invalid_unit_value": "Invalid Unit",
  "invalid_rate_currency_value": "Invalid Rate Currency",
  "invalid_rate_amount_value": "Invalid Rate Amount",
  "invalid_3rd_sales_code_value": "Invalid 3rd Party Sale Code",
  "cant_void_voided_voucher": "Can't void voided Voucher",
  "cant_void_voided_invoice": "Can't void voided Invoice",
  "cant_void_ap_completed_voucher": "Can't void AP completed Voucher",
  "cant_void_ap_completed_invoice": "Can't void AP completed Invoice",
  "cant_void_voiding_voucher": "Can't void the voiding Voucher",
  "cant_void_voiding_invoice": "Can't void the voiding invoice",
  "ap_inv_no": "CW1 AP Inv No",
  "cw1_chg_code": "CW1 Charge Code",
  "voucher_source": "Source",
  "source_tx_pk": "Source Reference ID",
  "source_tx_creator": "Creator",
  "package": "PKG",
  "rate_per_package": "Rate per Package",
  "rate_per_kg": "Rate per KG",
  "missing_required": "Missing Required Field",
  "status": "Status",
  "action": "Action",
  "allocated_amount": "Allocated Amount",
  "undefined": ""
 },
 "tms": {
  "alert_at_least_bol": "At least one BOL for Dispatch",
  "alert_at_least_one_bol": "Select at least one BOL",
  "alert_at_least_one_shipment": "Add at least one shipment to BOL.",
  "alert_at_least_one_shipment_per_bol": "Select at least one shipment per BOL",
  "alert_bol_and_dispatch_is_different_carrier_code": "You can't add BOL into Dispatch that carrier is different, please check and select again.",
  "alert_cancel_edit_dispatch": "Discard your change ?",
  "alert_confirm_cancel": "Continue to cancel ?",
  "alert_confirm_delete_dispatch": "Are you sure you want to delete the Dispatch?",
  "alert_confirm_edit_dispatch": "Are you sure you want to edit the Dispatch?",
  "alert_confirm_save_dispatch": "Are you sure you want to save the Dispatch?",
  "alert_different_carrier_code": "Have the different preassigned carrier code.",
  "alert_different_consignee_address": "Have the different consignee address.",
  "alert_different_consignee_id": "Have the different consignee id.",
  "alert_different_delivery_method": "Have the different delivery method.",
  "alert_different_payer": "Have the different payer.",
  "alert_different_payer_account": "Have the different payer account.",
  "alert_different_payer_name": "Have the different payer name.",
  "alert_different_quotation_level": "Have the different Quotation Level.",
  "alert_have_different_bol_carrier_confirm_go_on": "Have Different BOL Carrier! Would u like to continue?",
  "alert_no_dispatch": "No Dispatch!",
  "alert_no_dispath_bols": "No BOLs!",
  "alert_required_quotation_fields": "Missing required quoation fields, pls check.",
  "accept_quotation_tooltip": "Accept quotation, quotation status will be changed to Accepted.",
  "actual_carrier": "Actual Carrier",
  "actual_driver_arrival_time": "Actual Driver Arrival Time",
  "actual_out_for_delivery_date": "OFD Date",
  "actual_out_for_delivery_time": "OFD Time",
  "add_quotation": "Add Quotation",
  "add_quotation_tooltip": "Add a new quotation",
  "advanced_search": "More Options",
  "agent_role": "H Agent",
  "alert_accept_quotation_fail_do_retry_or_manual_or_return": "Failed to accept quotation, do you want to Retry, Submit Manually, or Back?  ",
  "alert_accept_quotation_manual": "The quotation is successfully accepted in Morrison System.  Next please continue to finish booking with carrier.",
  "alert_are_you_sure_to_cancel_quotation": "Do you want to cancel quotation?",
  "alert_are_you_sure_to_submit_quotation": "Do you want to submit quotation request?",
  "alert_assigned_to_driver": "Dispatch status is changed to \"15-Assigend To Driver\".",
  "alert_cancel_quotaion_confirm": "Do you want to cancel quotation?",
  "alert_cancel_quotation_fail_do_retry_or_manual_or_return": "Failed to cancel quotation, do you want to Retry, Manually Cancel, or Back?  ",
  "alert_cancel_quotation_manual": "The quotation is successfully canceled in Morrison system. Next please continue to cancel quotation with carrier.",
  "alert_confirm_leave": "Unsaved changes will be lost, are you sure?",
  "alert_confirm_not_mobile_save": "Driver Phone No is not a mobile number,  do you still want to save?",
  "alert_confirm_send_sms": "If you click \"ok\", we will send SMS text to driver phone No %{number} .",
  "alert_create_bol_fail": "Failed to create BOL/ DN, please try again.",
  "alert_custom_value_have_multiple_currency_and_go_on": "Unable to sum up \"Custom value\" due to different currency.",
  "alert_document_ready_is_not_ready": "\"Document Ready\" is not \"Y\", please check first.",
  "alert_fast_create_remove_bol_in_bol_step_warning": "Are you sure to remove this BOL/ DN? This cannot be undone.",
  "alert_have_different_bol_carrier": "Shipments have different \"Carriers\" in BOL/DN, and cannot be added into same Dispatch order.",
  "alert_have_different_bid_required": "Shipments have different \"Bid Required\" values in BOL/DN, and cannot be added into same Dispatch order.",
  "alert_have_different_bol_payment_to_carrier_by_and_go_on": "Shipments have different \"Payment to Carrier By\" values, do you still want to add these shipments into same Dispatch Order? ",
  "alert_have_different_bol_station": "Shipments have different \"BOL/DN Station\", and cannot be added into same Dispatch order.",
  "alert_have_different_delivery_method": "Shipments have different \"Delivery Method\" values in BOL/DN, and cannot be added into same Dispatch order.",
  "alert_have_different_ship_mode": "Shipments have different \"Ship Mode\", and cannot be added into same Dispatch.",
  "alert_no_quotation": "Quotation is not ready, please complete quotation first.",
  "alert_out_for_delivery": "Dispatch %{dispatchNumber} status is \"20 - Out For Delivery\".",
  "alert_planned_out_for_delivery_at": "Planned OFD Time",
  "alert_print_label_confirm": "Do you want to print the carrier label?",
  "alert_required_all_fields": "There are items that require your attention. Please correct them and submit the form again. ",
  "alert_save_success": "Successfully updated.",
  "alert_selected_shipments_need_add_to_bol": "Some shipments selected are not added to any BOL/DN. Please make sure all shipments are added into BOL/DN first.",
  "alert_send_sms": "SMS sent successfully.",
  "alert_submit_quotation_fail_do_retry_or_manual_or_return": "Failed to submit quotation request, do you want to retry, submit manually, or back ?  ",
  "alert_submit_quotation_manual": "Please remember to submit booking request offline to carrier.",
  "alert_are_you_sure_to_accept_quotation": "Do you want to accept the quotation?",
  "already_has_quotation_cannot_edit_address": "Unable to edit shipping address due to it has been delivered or it has quotation",
  "already_has_quotation_cannot_edit_shipment": "Unable to adjust shipment/delivery due to it has been delivered or it has quotation",
  "actual_appointment_date": "Actual Appt Date",
  "appointment_confirmed_date": "Appt Confirm Date",
  "appointment_made_date": "Appt Made Date",
  "are_you_sure_to_change_bid_required": "This change will cancel all quotations in this Dispatch order. Are you sure to continue?",
  "are_you_sure_to_change_delivery_method": "This change will cancel all quotations in this Dispatch order. Are you sure to continue?",
  "are_you_sure_to_change_carrier_code": "This change will take impact on all BOL/ DN. BOL/ DN and Dispatch will need to be confirmed again, quotation and PO request will be canceled if any; Do you really want to change Carrier Code?",
  "assistant_driver_1_name": "Assistant Driver 1",
  "assistant_driver_2_name": "Assistant Driver 2",
  "bid_required": "Bid Required",
  "bid_required_tooltip": "Bidding before shipping out",
  "bol": "BOL",
  "bol_booking_status_is_completed_please_cancel_booking": "The BOL’s quotation status is Completed, please cancel quotation before cancel BOL. ",
  "bol_carrier": "BOL Carrier",
  "bol_delivery_method": "Delivery Method",
  "bol_details": "BOL Details",
  "bol_dn_carrier": "BOL Carrier",
  "bol_dn_delivery_date": "BOL Delivery Date",
  "bol_dn_delivery_from": "Delivery From",
  "bol_dn_form": "BOL Form",
  "bol_dn_no": "BOL No",
  "bol_dn_pod_from": "POD From",
  "bol_dn_shipment_mode": "Shipment Mode",
  "bol_dn_shipto_state": "To State",
  "bol_dn_station": "Station",
  "bol_dn_type": "BOL Type",
  "bol_edit": "BOL Edit",
  "bol_grouping_rule": "BOL Grouping Rule",
  "bol_indicator": "BOL Indicator",
  "bol_mgmt": "BOL Mgmt",
  "bol_new": "BOL New",
  "bol_no": "BOL No",
  "bol_status": "BOL Status",
  "bol_status_update": "BOL Status Update",
  "booking_date": "Booked",
  "booking_status": "Quotation Status",
  "cancel_dispatch": "Remove from Dispatch",
  "cancel_quotation_and_continue_to_cancel_this_bill_of_lading": "This change will cancel all quotations in BOL %{bol_number} if have accepted quotations. Are you sure to continue?",
  "cancel_quotation_and_delete_dispatch_dispatchnumber": "This change will cancel all quotations in Dispatch %{dispatchNumber} . Are you sure to continue?",
  "cancel_quotation_tooltip": "Cancel quotation which quotation status is Accepted",
  "carrier": "Carrier",
  "carrier_service_level": "Carrier Svc Lvl",
  "column_adjustment": "Column Adjustment",
  "comment_category": "Comment Category",
  "complete_time": "Complete Time",
  "confirm_complete": "Confirm Complete",
  "cannot_be_empty": "Cannot be empty",
  "consol_closed_at": "Console Close",
  "creation_date": "Creation Date",
  "delivery_date": "POD Time",
  "delivery_method": "Delivery Method",
  "delivery_method_tooltip": "Express service is quoted on BOL/ DN, LTL/FTL service is quoted on Dispatch",
  "delivery_no": "Delivery No",
  "destination_doc_status": "Dest Doc Status",
  "dispatch_delivery_method": "Dispatch Delivery Method",
  "dispatch_header": "Dispatch Header",
  "dispatch_no": "Dispatch No",
  "dispatch_station": " Station",
  "dispatch_type": "Dispatch Type",
  "document_ready_tooltip": "Pls make sure the shipping documents is ready before shipping out.",
  "error_you_have_different_dispatch_no": "You selected shipments which already have more than one Dispatch, pls select again.",
  "exception": "Exception",
  "exception_message": "Exception Message",
  "exception_number": "Exception Qty",
  "fcr": "FCR",
  "fcr_c": "Combine",
  "fcr_n": "No",
  "fcr_y": "Yes",
  "forwarder_cargo_receipt": "Forwarder Cargo Receipt",
  "grouping_rule_a": "Rule A: Group deliveries and assign BOL if same consignee address",
  "grouping_rule_b": "Rule B: Group deliveries and assign BOL if same consignee code and consignee address",
  "grouping_rule_c": "Rule C: Group deliveries and assign BOL if same shipper code and consignee code",
  "grouping_rule_d": "Rule D: Group deliveries and assign BOL if same shipper name, consignee name and consignee address",
  "grouping_rule_e": "Rule E: Assign individual BOL",
  "gw": "GW",
  "hawb_no": "HAWB / HBL",
  "input_error_code": "Input Error Code",
  "instruction": "Special Instruction",
  "logistic_handling": "Logistic Handling",
  "logistic_number": "LOG No",
  "logno": "LOG No",
  "manual": "Manual",
  "master_agent_role": "Consol Agent",
  "misc_docs": "Misc. Docs",
  "ofd_time": "OFD Time",
  "off_load_at": "Offload",
  "open_delivery_status_update": "Update Delivery Status",
  "open_handover_edit": "Handover ",
  "out_for_delivery_date": "Out For Delivery",
  "pallet_status": "Import Pallet Status",
  "payment_method": "Payment Method",
  "pickup_date_over_arrival_date": "Planned Driver Arrival Time MUST be earlier than the BOL Target Delivery Date. ",
  "pkg_qty": "PKG Qty",
  "pkg_unit": "PKG Unit",
  "planned_driver_arrival_time": "Planned Driver Arrival Time",
  "planned_driver_time_tooltip": "The time that driver is expected to arrive at warehouse.",
  "planned_out_for_delivery_time": "Planned OFD Time",
  "pod": "POD File",
  "pod_checked": "POD Checked",
  "pod_date": "POD Date",
  "pod_file": "POD File",
  "preassigned_carrier_updated_at": "Assign Carrier Date",
  "preassigned_carrier_updated_by": "Assign Carrier By",
  "primary_driver_license_no": "Primary Driver License No",
  "primary_driver_license_number": "Primary Driver License No",
  "primary_driver_name": "Primary Driver Name",
  "primary_driver_phone_no": "Primary Driver Phone No",
  "primary_driver_phone_number": "Primary Driver Phone No",
  "print": "Print",
  "print_bol": "Print BOL",
  "print_label_tooltip": "Print label after accept quotation",
  "print_manifest": "Print Manifest",
  "quotation": "Quotation ID",
  "quotation_is_accepted": "Quotation accepted",
  "quotation_is_submitted": "Quotation submitted",
  "quotation_status": "Quotation Status",
  "reference_files": "Reference Files",
  "retry": "Retry",
  "rule_a": "Rule A",
  "rule_b": "Rule B",
  "rule_c": "Rule C",
  "rule_d": "Rule D",
  "rule_e": "Rule E",
  "sharp_bols": "#BOLs",
  "sharp_deliveries": "#Deliveries",
  "sharp_gross_weight": "#Gross Weight",
  "sharp_gw": "#GW",
  "sharp_loading_meter": "#LDM",
  "sharp_packages": "#Packages",
  "sharp_pkgs": "#PKGs",
  "sharp_shipments": "#Shipments",
  "sharp_shpts": "#SHPTs",
  "sharp_chargeable_weight": "#CW",
  "ship_from": "From",
  "ship_to_city": "To City",
  "ship_to_country": "To Country/Area",
  "ship_to_name": "To Name",
  "ship_to_state": "To State",
  "shipment_customer": "Customer",
  "shipment_mode": "Shipment Mode",
  "shipment_no": "HAWB / HBL",
  "special_instruction_max_length_tooltip": "Maximize 150 characters ",
  "station": "Station",
  "status": "Status",
  "submit_quotation_tooltip": "Submit quotation request to carrier",
  "tag_api_manual": "Manual",
  "tag_api_manual_tooltip": "Manual quotation ",
  "tag_quotation_accepted": "Ready",
  "tag_quotation_accepted_tooltip": "Quotation status is ready",
  "tag_quotation_not_accepted": "Not Ready",
  "tag_quotation_not_accepted_tooltip": "Quotation status is not ready yet",
  "to_address": "To Address",
  "to_city": "To City",
  "to_country": "To Country/Area",
  "to_name": "To Name",
  "to_state": "To State",
  "tracking_device": "Tracking Device No",
  "tracking_device_no": "Tracking Device No",
  "tracking_device_number": "Tracking Device No",
  "tracking_no": "Tracking No",
  "trailer_load_by": "Trailer Loaded By",
  "trailer_no": "Trailer Plate No",
  "truck": "Truck",
  "truck_no": "Truck No",
  "truck_type": "Truck Type",
  "type": "Type",
  "unlink_bol": "Are you sure you want to remove shipment from Dispatch ?",
  "urgent_delivery": "Urgent Delivery",
  "urgent_delivery_tooltip": "Urgent delivery",
  "vip_name": "Customer",
  "verification_code": "Verification Code",
  "alert_check_customer_pickup_shipments": "This selection including %{shipmentCounts} shipments with “To port” service type. (ATA or DTA)\b\nIncluding HAWB: %{shipmentNumbers}.\nPlease change service type to “To Door” type (ATD or DTD) before entering dispatch creation.",
  "alert_check_customer_pickup_before_check_in_counter": "This selection including %{shipmentCounts} shipment(s) with “To port”  service type ( ATA or DTA or PTP or DTP or ATP or PTA) .\nIncluding HAWB: %{shipmentNumbers}.\nPlease change service type to “To Door” type (ATD or DTD or PTD) before Assign Carrier.",
  "alert_invalide_customer_shipments": "Apple GAR, Garmin, Gap cannot mix with other customer in a disaptch.",
  "warning_has_dispatch_shipments": "Warning:\n\nThis selection including %{shipmentCounts} shipments have already had OFD/POD.\nSystem will update OFD & POD timestamps and POD files on these shipments based on TMS and ePOD.\nIncluding HAWB: %{shipmentNumbers}.\n\nDo you want to proceed BOL & Dispatch creation?",
  "warning": "Warning",
  "warning_unable_to_change_appt": "Unable to change below Delivery's Actual Appt Date and/or EDD. Please click to go to TRCK to change Actual Appt Date and/or EDD.",
  "service_type_change_to": "Service Type Change To",
  "delivery_method_change_to": "Delivery Method Change To",
  "change_service_type": "Change Service Type",
  "original_service_type": "Original\nService Type",
  "new_service_type": "New\nService Type",
  "original_delivery_method": "Original\nDelivery Method",
  "new_delivery_method": "New\nDelivery Method",
  "error_add_fast_create_dispatch": "You cannot fast create with different dispath no",
  "allocation_sum_check_not_match": "Allocation Sum Not Match",
  "allocation_not_save": "Allocation Not Save",
  "allocation_has_posted_allocation": "There are POs created in Prolink/CW1, please make sure to void previous POs and add new request with new shipments.",
  "allocation_cant_remove_delivery_with_posted_allocation": "There are POs created in Prolink/CW1, please make sure to void previous POs.",
  "change_delivery_package_data": "Change Delivery Package Data",
  "third_party": "Third Party",
  "new_delivery_need_new_allocation": "Need Allocation when Add New Delivery",
  "confirm_is_delete_delivery_allocation": "All PO requests will be recalculated by GW. You can modify in dispatch detail if needed. ",
  "cancel_allocation_failed": "Cancel Allocation Failed",
  "reference_number": "Ref",
  "estimated_delivery_date": "EDD",
  "customs_cleared_at": "Customs Cleared (Export)",
  "split_delivery_tooltip": "Split Delivery",
  "split_delivery_title": "Split: HAWB#:%{shipment_number} Delivery#:%{delivery_number}",
  "selected_packages": "Selected Packages",
  "estimated_gw": "Estimated GW",
  "estimated_cw": "Estimated CW",
  "selected_pallets_packages_will_be_split_to_a_new_delivery": "Selected Pallets/Packages will be split to a new Delivery#.",
  "remaining_packages": "Remaining Packages",
  "remaining_gw": "Remaining GW",
  "remaining_cw": "Remaining CW",
  "split_pkg_qty": "Split Package Qty",
  "split_pkg_unit": "Split Package Unit",
  "split_gw": "Split GW",
  "split_cw": "Split CW",
  "selected_package_unit_and_weight_will_be_split_to_a_new_delivery": "Selected Package, Unit and weight will be split to a new Delivery#.",
  "location": "Location",
  "pallet_id_package_id": "Pallet ID/Package ID",
  "hawb_allocated_amount": "HAWB Allocated Amount",
  "hawb_allocated_chargeable_weight_rate": "HAWB Allocated CW Rate",
  "upload_proof_of_handover": "Proof of Handover",
  "deliveries_have_been_assigned_to_BOL": "%{assignedDeliveries}/%{totalDeliveries} DNs have been assigned to BOL",
  "deliveries_have_not_been_assigned": "%{notAssignedDeliveries} DNs have not been assigned",
  "different_consignee_address": "%{deliveryNumber} has the different consignee address.",
  "alert_update_pod_time_and_pod_file": "Please update POD time and upload POD file for the new added BOL in Delivery Status Update .",
  "managed_in_cfs_tooltip": "Managed in CFS",
  "appointment_confirmed_number": "Appt Confirm No",
  "original_actual_appointment_date": "Original Actual Appt",
  "new_actual_appointment_date": "New Actual Appt",
  "original_estimated_delivery_date": "Original EDD",
  "new_estimated_delivery_date": "New EDD",
  "estimated_delivery_date_sla": "SLA",
  "print_pallet_label": "Print CNEE Pallet Label",
  "print_pallet_label_failed": "Print CNEE Pallet Label Failed",
  "department_of_transportation_number": "US DOT No",
  "motor_carrier_number": "US MC No",
  "go_to_trck": "Go to TRCK",
  "billing_country_code": "Country Code",
  "billing_postal_code": "Zip Code",
  "bol_and_manifest": "BOL and Manifest",
  "cnee_pallet_label": "CNEE Pallet Label",
  "should_print_pallet_label": "Shipment %{shipmentList} is required to print consignee pallet label, please print consignee pallet label before handover shipments to driver.",
  "notify_id": "Notify Party Code",
  "notify_name": "Notify Party Name",
  "notify_address": "Notify Party Address",
  "notify_city_name": "Notify Party City",
  "notify_state": "Notify Party State",
  "notify_country": "Notify Party Country",
  "tracking_status_p44_inactive": "P44-Inactive",
  "tracking_status_p44_active": "P44-Active",
  "tracking_status_p44_completed": "P44-Completed",
  "tracking_status_p44_invalid_data": "P44-InvalidData",
  "tracking_status_p44_request_processing": "P44-Processing",
  "tooltip_tracking_status_p44_inactive": "Tracking will be starting.",
  "tooltip_tracking_status_p44_active": "Tracking has already started.",
  "tooltip_tracking_status_p44_completed": "Tracking is finished.",
  "tooltip_tracking_status_p44_invalid_data": "Tracking data is invalid.",
  "tooltip_tl_type": "TL (also called FTL) refers to a full truckload, it take up the space or weight limit of an entire trailer. LTL stands for “less than truckload”  that does not require the entire space of a truck. ",
  "tooltip_license_plate_no": "License Plate No(origin Truck No) is the official set of numbers and letters shown on the front and back of a road vehicle.",
  "tooltip_fleet_vehicle_id": "Fleet vehicle ID is a asset number or ID assigned to truck by carrier. It is painted on the outside of the tractor.",
  "truck_info": "Truck Info",
  "license_plate_no": "License Plate No",
  "tl_type": "TL Type",
  "tracking_status": "Tracking Status",
  "dispatch_status": "Dispatch Status",
  "fleet_vehicle_no": "Fleet Vehicle No",
  "alert_fleet_vehicle_required": "This is a P44-enabled truck vendor, but there is no Fleet Vehicle No provided. Do you want to proceed?",
  "alert_address_not_valid": "Some shipping addresses may not be valid, do you want to continue?",
  "bol_status_passed": "Passed!",
  "bol_status_address_error": "Address validation fail - %{errorReason} Please check and modify on \"Address\", \"City\", \"State\", and \"Zipcode\" if needed.",
  "truck_tracking_processing_reason": "P44 Tracking establishment is still in progress, please refresh again later.",
  "truck_tracking_success": "P44 Tracking established successfully, P44 Tracking Status is Active",
  "modal_truck_tracking_error_reason": "P44 Tracking is still %{status} - %{reason}. Please check and revise data.",
  "truck_tracking_error_reason": "Tracking Error Reason",
  "truck_tracking_error_suggestion": "Contact station power user if you want to proceed printing PDF without P44 Tracking.",
  "truck_tracking_reminder_message": "Please make sure Tracking Device is activated and placed on a pallet.",
  "tracking_p44_dispatch_required_message": "Either SCAC, DOT No or MC No field is required.",
  "error_required_fleet_vehicle_number": "Please check with trucker and input Fleet Vehicle No for P44 Tracking",
  "tracking_p44_bol_delivery_date_required": "Checked Fail: Target Delivery Date is mandatory",
  "tracking_p44_bol_delivery_date_not_match_planned": "Checked Fail: Invalid Target Delivery Date, should be later than Planned Driver Arrival Time",
  "tracking_p44_bol_pdat_must_earlier_than_tdt": "Check Fail: Planned Driver Arrival Time must be earlier than Target Delivery Date.",
  "tracking_p44_bol_delivery_date_not_from_future": "Checked Fail: Invalid Target Delivery Date, should be a future time",
  "tracking_p44_bol_delivery_date_passed": "Checked Pass: Valid Target Delivery Date",
  "tracking_p44_bol_delivery_all_passed": "Pass checks.",
  "tracking_p44_bol_delivery_date_invalid_range": "Checked Fail: Invalid Target Delivery Date Range",
  "tracking_p44_bol_delivery_time_earlier_than_current": "Target Delivery Date is earlier than the current time, please double check and input.",
  "alert_bol_has_error": "BOL data have issues, please revise.",
  "alert_bol_has_warning": "BOL data have issues, do you want to continue?",
  "address_floor": "Floor/Suite/Unit",
  "p44_permission_confirm": "I confirm to skip P44 Tracking, and proceed to generate Dispatch PDF",
  "fleet_vehicle_number_placeholder": "Input Vehicle No for P44 Tracking...",
  "part_number_list": "Part Number",
  "product_description_list": "Product Description",
  "freight_charge_terms": "Freight Charge Terms",
  "freight_charges_collect": "Collect",
  "freight_charges_prepaid": "Prepaid",
  "freight_charges_third_party": "3rd Party",
  "freight_counted_by": "Freight Counted By",
  "freight_counted_driver_pallets": "Driver/pallets said to contain",
  "freight_counted_driver_pieces": "Driver/Pieces",
  "freight_counted_shipper": "Shipper",
  "freight_term": "Freight Term",
  "from": "From",
  "ftl": "FTL",
  "preassigned_carrier": "Preassigned Carrier",
  "date_range_error": "Date Range Error",
  "must_be_before_the_end_time": "Must be before the end time",
  "must_be_after_the_start_time": "Must be after the start time.",
  "planned_driver_arrival_time_is_earlier_than_current": "Planned Driver Arrival Time is earlier than the current time, please double check",
  "delivery_date_range_error": "Target Delivery Date should be later than Planned Driver Arrival Time",
  "fast_dispatch_bol_close_error_message": "There are fields that require your attention. Please correct them.",
  "recalculate_po_request_with_allocation_method": "After this action, previously created PO Request(s) of this Dispatch will be auto re-calculated by previous Allocation Method -",
  "recalculate_po_request_with_no_allocation_method": "After this action, previously created PO Request(s) of this Dispatch will be auto re-calculated by GW. ",
  "are_you_sure_to_change_vendor_code": "The vendor code is not the same as the Dispatch Carrier, are you sure to continue?",
  "please_double_confirm": "Please double confirm the amount allocation after this action.",
  "recalculate_po_request_with_allocation_method_period": ".",
  "new_delivery_has_zero_value": "Allocated Amount = 0, are you sure to save ?",
  "new_delivery_has_zero_value_tooltip_text": "Please make sure if allocated amount is 0",
  "po_request_status_pending": "PENDING",
  "po_request_status_processing": "PROCESSING",
  "po_request_status_error": "ERROR",
  "po_request_status_request_to_pl_po_ok": "REQUEST_TO_PL_PO_OK",
  "po_request_status_request_to_cw1_po_ok": "REQUEST_TO_CW1_PO_OK",
  "po_request_status_cw1_ap_completed": "CW1_AP_COMPLETED",
  "po_request_status_pending_explaination": "PO request will be sent to Prolink/CW1 after the dispatch is delivered(30-Delivered).",
  "po_request_status_processing_explaination": "PO request is sent to Prolink/CW1, should be completed in 2 hrs, contact mec_onehelp@morrisonexpress.com if any abnormal",
  "po_request_status_error_explaination": "PO Request has error，contact mec_onehelp@morrisonexpress.com",
  "po_request_status_request_to_pl_po_ok_explaination": "PO is created in Prolink",
  "po_request_status_request_to_cw1_po_ok_explaination": "PO is created in CW1",
  "po_request_status_cw1_ap_completed_explaination": "PO is converted to AP in CW1",
  "delivery_allocated_amount_tips": "TMS trucking cost request allocated amount to delivery, it will not be impacted by any change in Prolink or CW1",
  "delivery_allocated_amount": "Delivery Allocated Amount",
  "actual_pickup_at": "Picked Up",
  "eta": "Last ETA",
  "etd": "First ETD",
  "atd": "ATD",
  "ata": "ATA",
  "doc_released_at": "Document Released",
  "customs_released_at": "Customs Released",
  "arrived_cfs_at": "Arrived at CFS",
  "actual_delivery_date": "Actual Delivery Date",
  "country_code_exceed_limitation": "Two-letter country code",
  "invalid_carrier_code": "SMS message for ePOD login will be not sent to driver due to invalid carrier code. Driver could scan the QRcode on the Dispatch documents to login ePOD. ",
  "carrier_sms_disabled": "SMS message for ePOD login will be not sent to driver due to SMS message is disabled for this carrier. Driver could scan the QRcode on the Dispatch documents to login ePOD. ",
  "refresh_data": "Refresh Data",
  "sync_from_prolink": "Data-sync in progress",
  "generate_dn": "Generate DN",
  "generate_dn_max_error": "Can not exceed %{max} item to generate.",
  "generate_dn_warning": "Selected HAWB is Taobao shipment. Do you want to create DN by Taobao consignee?",
  "generate_dn_leave_message": "尚有正在編輯的DN，確定要離開？",
  "generate_dn_upload_modal_title": "Upload Bag ID",
  "generate_dn_upload_modal_tips": "Please copy excel data and paste on sheet below.",
  "generate_dn_upload_modal_consignee": "Consignee",
  "generate_dn_upload_modal_bag_id": "Bag ID",
  "generate_dn_upload_success": "Upload successfully",
  "generate_dn_upload_fail": "上傳失敗",
  "import_pallet_status": "Import Pallet Status",
  "planned_driver_arrival_date": "Planned Driver Arrival Date",
  "delivery_not_found": "Missing delievery data",
  "vip_name_is_not_top_partner_express": "Invalid Customer included: TOP_PARTNER_EXPRESS only",
  "delivery_status_is_not_unassigned": "Invalid status included: 00-Initial only",
  "exceed_the_number_of_deliveries_limit": "Can not exceed 10 item to generate",
  "please_select_at_least_one_consignee": "Please select at least one consignee",
  "available_for_delivery": "Available For Delivery",
  "please_update_ofd_above_before_updating_pod_time": "Please update the OFD Time above before updating POD Time",
  "please_go_to_handover_to_input_the_drivers_infomation_before_updating_ofd": "Please go to Handover to input the drivers information before updating OFD Time",
  "error_pod_datetime_not_future_time": "POD Time exceed the current time, please double check and input.",
  "error_pod_datetime_earlier_than_ofd_time": "POD Time is earlier than the OFD time, please double check and input.",
  "error_ofd_datetime_not_future_time": "error_ofd_datetime_not_future_time",
  "handover_submit_error_ofd_earlier_than_adat": "OFD Time is earlier than Actual Driver Arrival Time, please double check and input.",
  "handover_submit_error_ofd_later_than_current": "OFD Time exceed the current time, please double check and input",
  "handover_submit_error_ofd_later_than_pod": "POD Time is earlier than the OFD time, please double check and input",
  "handover_submit_error_pod_later_than_current": "POD Time exceed the current time, please double check and input",
  "undefined": ""
 },
 "bk": {
  "query_failed": "Please refresh and try again. If you still cannot quering this page - Please contact mec_onehelp@morrisonexpress.com",
  "create_csv_failed": "Export failed, please refresh and try again. If you still get this message - Please contact mec_onehelp@morrisonexpress.com",
  "data_not_found": "Unexpected process error (Data not found) - Please contact mec_onehelp@morrisonexpress.com",
  "company_id_error": "Company ID error",
  "stage_error": "Your action is not allowed at this stage",
  "cancel_reason_error": "Cancel Error",
  "query_parameter_error": "Query parameter error",
  "ods_api_failed": "ODS api failed",
  "field_verify_error": "Shipper information is not yet confirmed, please click on the pencil icon in the address section then uncheck the \"will be provided later\".",
  "pre_assign_hawb_num_can_not_be_used": "The pre-assign HAWB/HBL you typed is used, please choose a new one.",
  "booking_request_submit_error": "Booking request submit error",
  "cs_not_found": "We will provide an Online Booking service for the route you choose as soon as possible. Until then, please choose another origin port or contact your sales directly.",
  "hash_error": "Hash code does not match",
  "booking_request_can_not_find_customer_code": "The corresponding customer code for this booking request is not exists. Please contact mec_onehelp@morrisonexpress.com",
  "status_is_completed": "The status of this booking request was completed",
  "status_is_canceled": "The status of this booking request was cancelled. You can not doing this action. ",
  "task_api_failed": "Please refresh and try again (Task API failed). If you still get same message - Please contact mec_onehelp@morrisonexpress.com",
  "common_api_failed": "Please refresh and try again (Common API failed). If you still get same message - Please contact mec_onehelp@morrisonexpress.com",
  "not_allowed_to_modify_after_submission": "Origin port and Service type is not allowed to modify after submitted, Please cancel this booking request first then create a new one.",
  "sec_agent_not_existed": "Secure agent is not existed, Please contact sales to check quotation content.",
  "secure_station_not_mapped": "Secure station is not existed, Please contact sales to check quotation content.",
  "duplicate_reference_value": "Shipment reference value is dupliate, please check your refernece value.",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "Unsaved changes will be lost, are you sure you want to leave?",
  "draft_save_success": "Save draft success",
  "booking_request_submit_success": "Submit success",
  "booking_request_confirm_success": "Create success, ",
  "booking_request_cancel_success": "Cancel successs",
  "address": "Address",
  "approved": "Approved",
  "billing_info": "Billing Information",
  "bill_of_lading_type": "House B/L Assignment",
  "booking": "Booking",
  "booking_number_name": "Booking Number",
  "booking_request_no": "Booking Request No.",
  "booking_request_number": "Request No.",
  "booking_status_canceled": "Canceled",
  "booking_status_completed": "Completed",
  "booking_status_draft": "Draft",
  "booking_status_in_progress": "In Progress",
  "booking_status_submitted": "Submitted",
  "booking_status_booking_in_progress": "Booking In Progress",
  "brokerag_and_cargo": "Add on Service",
  "calculated_by": "Calculated By ",
  "calculated_by_total": "Total Shipment",
  "calculated_by_unit": "Each Unit",
  "cancel_at_utc": "Booking Cancel Date (UTC)",
  "cancel_by": "Booking Request Cancel by",
  "cancel_check": "Are you sure want to cancel booking request %{booking_id}? ",
  "cancel_notify_check": "Are you sure you want to cancel %{booking_id} ? Morrison is processing your shipment and may incur some charges for cancellation, we will contact you as soon as possible to confirm the charges, until then your order status will remain in booking in progress / in progress.",
  "cancel_notify_cs": "We already notified customer service and will contact you soon.",
  "cargo_insurance": "Cargo Insurance",
  "cargo_ready_date": "Cargo Ready Date",
  "city": "City",
  "client_id": "Customer Code",
  "client_name": "Customer Name",
  "clone": "Clone",
  "code": "Code",
  "commercial_invoice": "Commercial Invoice",
  "comments": "Comments",
  "commodity": "Commodity",
  "commodity_code": "Commodity Code",
  "commodity_desc": "Commodity Description",
  "confirm_at_utc": "Booking Request Confirm Date (UTC)",
  "confirm_by": "Booking Request Confirm by",
  "consignee": "Consignee",
  "consignee_code": "Consignee Code",
  "consignee_info": "Cnee Addr.",
  "consignee_name": "Consignee Name",
  "contact_name": "Contact Name",
  "container_type": "Container Type",
  "country": "Country/Area",
  "create_booking": "Create Booking",
  "created_at_utc": "Create Date (UTC)",
  "created_by": "Booking Request Create By",
  "custom_brokerage": "Custom Brokerage",
  "customer_code": "Customer Code",
  "customer_code_name": "Customer Code/Name",
  "customer_expected_pickup_at": "Expected Pickup Date",
  "customer_expected_at": "Expected Delivery Date",
  "customer_name": "Customer Name",
  "dangerous_goods_declaration": "Material Safety Data Sheet (MSDS)",
  "delivery_port": "Delivery Port",
  "department": "Department",
  "dest": "Destination Port",
  "destination": "Destination",
  "destination_port_country": "Dest Country/Area",
  "destination_port_full": "Destination Port",
  "details": "Details",
  "diff_dest_area_notify": "The %{area} country/area you choose is different from the destination",
  "diff_origin_area_notify": "The %{area} country/area you choose is different from the origin port ",
  "direct_shipment": "Direct Shipment",
  "direct_shipment_desc": "If you choose Direct Shipment, Morrison will provide the MAWB/MBL number via booking confirmation. Until then, you can use the booking request number to track the progress of the process.",
  "discharge": "Discharge Port",
  "documents": "Documents",
  "effective_from": "Effective From",
  "effective_to": "Effective To",
  "expected_eta": "Target Delivery Date",
  "expected_eta_note": "Morrison will do its best to meet your expectations, but there is no guarantee of arrival date.",
  "expected_pickup_date": "Expected Pickup Date",
  "expected_pickup_date_note": "The expected pick-up date must be equal to or later than cargo ready date, and earlier then expected delivery date.",
  "fcl": "FCL",
  "fcr_shipment": "FCR Shipment",
  "filed": "Filed",
  "freight_payment_term": "Freight Payment Term",
  "freight_term": "Freight Term",
  "general": "General",
  "general_shipment_desc": "Goods Description",
  "goods_description": "Goods Description",
  "goods_info": "Goods Information",
  "goods_type": "Goods Type",
  "gross_weight": "Gross Weight",
  "gross_weight_total": "Total Gross Weight",
  "incoterm": "Incoterm",
  "insurance_value": "Cargo Value",
  "insurance_value_currency": "Cargo Value Currency",
  "is_custom_service": "Custom Service ",
  "dangerous_goods": "Dangerous Good",
  "is_doc_ready": "Doc. Ready",
  "is_effective": "Is Effective",
  "is_insurance": "Insurance",
  "is_origin_cert": "Certificate of Origin",
  "lcl": "LCL",
  "load": "Load Port",
  "loads": "Loads",
  "loose_goods": "Loose Goods",
  "method": "Ship Method",
  "mode": "Ship Mode",
  "name": "Name",
  "need_carge": "Do you need cargo insurance add on? ",
  "need_carge_no": "NO - I've either got insurance or take full responsibility for any lost or damaged cargo.",
  "need_carge_yes": "Yes - I need cargo insurance. My cargo value is:",
  "not_sure_right_now": "Will be provided later",
  "notify": "Notify Party",
  "notify_info": "Notify Party Addr.",
  "notify_name": "Notify Party Name",
  "origin": "Origin",
  "origin_cert_memo": "Certificate of origin memo",
  "origin_port": "Origin Port",
  "origin_port_country": "Origin Country/Area",
  "other_reference_file": "Other Reference File",
  "others_payment_term": "Others Payment Term",
  "others_term": "Others Payment",
  "overwrite_confirm_title": "Are you sure you want to chang shipper/consignee code?",
  "overwrite_confirm_content": "After changing the shipper/consignee code, the quotation will be re-queried. Please check the correctness of the quotation again or select the correct quotation manually.",
  "overwrite_contact_code_title": "Are you sure you want to chang quotation?",
  "overwrite_contact_code_content": "After changing quotation, the shipper/consignee code may be overwritten. Please check the correct shipper/consignee code or select the correct code manually.",
  "overwrite_quotation_confirm_title": "Are you sure you want to chang shipper/consignee code?",
  "overwrite_quotation_confirm_content": "After changing the shipper/consignee code, the quotation will be re-queried. Please check the correctness of the quotation again or select the correct quotation manually.",
  "packing_list": "Packing List",
  "part_description": "Part Description",
  "part_number": "Part No",
  "parts": "Parts",
  "per_height": "Height (CM)",
  "per_length": "Length (CM)",
  "per_weight": "Width (CM)",
  "pending": "Pending",
  "pickup": "Pickup Information",
  "pickup_info": "Pickup Addr.",
  "pickup_name": "Pickup Name",
  "po_item_no": "PO Item No",
  "po_number": "PO No",
  "port_of_delivery": "Port of Delivery",
  "port_of_discharge": "Port of Discharge",
  "port_of_landing": "Port of Loading",
  "port_of_receipt": "Port of Receipt",
  "pre_assign": "Pre-assign HAWB/HBL Number",
  "pre_assign_number": "Pre-assign HAWB/HBL Number",
  "pre_assign_desc": "Please obtain a pre-assigned number from MEC first and do not use the same number repeatedly.",
  "qty_ea": "Qty(EA)",
  "qty_of_outers": "Qty of Packages",
  "quantity": "Quantity",
  "quotation": "Quotation",
  "quotation_number": "Quotation Number",
  "quotation_status": "Quotation Status",
  "quotation_type": "Quotation Type",
  "range_in": "Range in %{start}~%{end}",
  "receipt_port": "Receipt Port",
  "reference": "Reference No",
  "reference_number": "Reference No",
  "request_brokerage": "Do you need customs brokerage arrangement from MEC? ",
  "request_brokerage_no": "No - I have a customs broker.",
  "request_brokerage_yes": "Yes - I need customs brokerage.",
  "route": "Route",
  "sales_name": "Sales Name",
  "same_as_consignee": "Same As Consignee",
  "same_as_consignee_check_label": "Same As Consignee",
  "same_as_notify": "Same As Consignee",
  "same_as_notify_check_label": "I have notify party information.",
  "same_as_shipper": "Same As Shipper",
  "same_as_shipper_check_label": "Same As Shipper",
  "save_to_address": "Save to my address book",
  "secure_sales_no": "Secure Sales No.",
  "secure_station": "Secure Station",
  "select_customer_code": "Select Customer Code",
  "select_quotation_number": "Select Quotation Number",
  "service": "Service",
  "service_level": "Service Level",
  "service_type": "Service Type",
  "ship_type": "Ship Type",
  "shipment_by_to": "Customer Code",
  "shipment_mode_air": "Air",
  "shipment_mode_sea": "Sea",
  "shipment_number": "HAWB / HBL",
  "shipment_reference_no": "Reference",
  "shipment_require": "House B/L Assignment",
  "shipment_require_option_1": "Consol Shipment (HAWB/HBL)",
  "shipment_require_option_2": "Direct Shipment (MAWB/MBL)",
  "shipment_require_option_3": "Pre-assign HAWB/HBL provide by Morrison",
  "shipment_to": "Delivery Information",
  "shipment_to_info": "Delivery Addr.",
  "shipment_to_name": "Delivery Name",
  "shipper": "Shipper",
  "shipper_code": "Shipper Code",
  "shipper_info": "Shipper Addr.",
  "shipper_name": "Shipper Name",
  "shipping_marks": "Shipping Marks",
  "shipping_mode": "Ship Mode",
  "system_quotation": "System Quotation",
  "special_instruction": "Special Instruction",
  "state": "State",
  "status": "Status",
  "step_1_Service": "STEP 1: Service",
  "step_2_Address": "STEP 2: Address",
  "step_3_Detail": "STEP 3: Detail",
  "step_4_Doc": "STEP 4: Document",
  "submit_at": "Submitted At (UTC)",
  "submit_by": "Submitted By",
  "submit_question_title": "Are you sure you want to create booking?",
  "submit_question_content": "You can only change it in Prolink/CargoWise after sending, please confirm the shipper/consignee/quotation number is correct.",
  "submit_question": "Are you sure you want to create booking?",
  "submitted": "Submitted",
  "submitted_at": "Submit At (UTC)",
  "submitted_at_utc": "Request Submit At (UTC)",
  "submitted_by": "Booking Request Submit By",
  "target_delivery_date": "Target Delivery Date",
  "terms_confirm": "I confirm that this is a commercial shipment and all details are correct. I accept the %{a1}   terms of the booking %{a2}",
  "terms_confirm_check": "Please confirm booking detail and Morrison's terms of booking before you submit booking request.",
  "terms_confirm_check_title": "Cannot submit booking request.",
  "terms_confirm_check_content": "Please confirm booking detail and Morrison's terms of booking before you submit booking request.",
  "total_of_units": "Total of Packages",
  "transporation": "Transportation",
  "transporation_mode": "Transporation Mode",
  "type": "Type",
  "unit_weight": "Gross Weight (Per Each Outer)",
  "upload_doc": "Shipping Documents",
  "value": "Value",
  "volume_weight": "Volume",
  "volume_weight_total": "Total Volume",
  "zip_code": "Zip Code",
  "i_will_provide_later": "Will be provided later",
  "shipper_instruction_letter": "Shipper's Letter of Instruction ",
  "shipment_type": "Shipment Type",
  "shipment_desc": "Shipment Desc",
  "service_level_desc": "Shipment Desc",
  "source_db": "Source DB",
  "is_trade_billing": "Is Trade Billing",
  "origin_port_code": "Origin Port Code",
  "destination_port": "Destination Port",
  "destination_port_code": "Destination Port Code",
  "shipment": "Shipment Detail",
  "rejected": "Rejected",
  "i_am_not_sure_right_now_tip": "If you don't know the shipper's name and address, please check this box and provide the shipper's contact information to proceed with the booking request.",
  "i_will_provide_later_tip": "Please provide this type of documentation before shipping to ensure your shipment arrives on time. If you do not have such a document at the time of booking, please check this checkbox to proceed with your booking request.",
  "status_hint_for_doc": "Please upload required documents.",
  "status_hint_for_address": "Please confirm shipper's information.",
  "notify_section_titile": "Notify Party Information",
  "address_checked_hint": "The corresponding code has been selected.",
  "address_warning_hint": "Multiple corresponding codes exist, please select from the list or click on the search icon to view details.",
  "address_error_hint": "No master code found. Please apply a new CCC code or click the search icon to modify the criteria.",
  "quotation_warning_hint": "Multiple corresponding quotations exist, please select from the list or click on the search icon to view details",
  "quotation_checked_hint": "The corresponding quotation has been selected.",
  "quotation_error_hint": "No quotation found. Please click the search icon to modify the criteria.",
  "active": "Active",
  "draft": "Draft",
  "booking_in_progress": "Booking In Progress",
  "in_progress": "In Progress",
  "completed": "Completed",
  "canceled": "Canceled",
  "notify_only_allow_upload_once": "The file can only be uploaded once. If you need to modify the file after uploading, please leave a comment below and provide the file.",
  "query_customer_code": "Customer Code/Name",
  "reference_type_value_di_values": "DI Reference",
  "reference_type_value_in_values": "IN Reference",
  "reference_type_value_po_values": "PO Reference",
  "reference_type_value_sr_values": "SR Reference",
  "reference_type_value_other_values": "Other Reference",
  "cargo_ready_date_note": "Cargo ready date must be earlier then target delivery date",
  "cargo_ready_date_at": "Cargo Ready Date",
  "is_dangerous_goods": "Dangerous Good",
  "booking_date": "Booking Date",
  "actual_pickup_at": "Actual Pickup At",
  "etd": "ETD",
  "atd": "ATD",
  "eta": "ETA",
  "ata": "ATA",
  "service_type_dtd": "DTD",
  "service_type_dta": "DTA",
  "service_type_dtp": "DTP",
  "service_type_ptp": "PTP",
  "service_type_ptd": "PTD",
  "service_type_ata": "ATA",
  "service_type_atd": "ATD",
  "out_for_delivery_date": "Out Of Delivery Date",
  "actual_delivery_date": "Actual Delivery Date",
  "customer_expected_eta_at": "Expected ETA",
  "only_allow_decimal": "只允許輸入小數%{num}位",
  "debit_to_code_is_inactive": "Debit to party %{debit_to_id} %{debit_to_name} in quotation is inactive. Please check with account owner on how to proceed.",
  "booking_source_list": "Booking Source",
  "mop": "Created in Morrison One",
  "prolink": "Created in Prolink/CW1",
  "customer_edi": "Customer EDI",
  "summary_search_icon_hint": "To search and select the valid code.",
  "summary_search_quotation_icon_hint": "1. Please select the quotation by search \n2. Please contact Process team if no query result",
  "summary_select_shipper_hint": "Please complete shipper's information.",
  "summary_select_consignee_hint": "Please complete consignee's information.",
  "summary_select_pickup_hint": "Please complete pickup information.",
  "summary_select_delivery_hint": "Please complete delivery’s information.",
  "summary_select_notify_hint": "Please complete notify party’s information.",
  "summary_select_quotation_hint": "Please confirm quotation number.",
  "summary_select_bottom_hint": "Or please leave the comment to creator or submitter when information is uncertain. ",
  "booking_no_incomplete": "The booking request no. %{number} information is incomplete. Please follow the steps to create booking.",
  "control_tower_submit_notify_title": "Online Booking hasn’t been set up PIC in %{origin_port} Station. Please follow the steps below to proceed the booking. ",
  "control_tower_submit_notify": "1. Contact %{origin_port} station and confirm PIC<br>\n2. Provide Booking Request No. link to PIC and instruct to confirm the booking<br>\n3. Provide PIC to Process Team %{email}<br>",
  "control_tower_submit_notify_sub": "You will receive an email contains above instruction."
 },
 "uld": {
  "info_existing_uld_can_append": "An un-POD ULD is existing",
  "warning_mapping_exist": "This data already exists",
  "error_master_not_exist": "MAWB does not exist",
  "error_uld_has_multiple_airline": "An un-POD ULD only belongs an airline",
  "error_duplicate_mapping_current_upload": "There is some dupulicate data in this upload",
  "error_violate_uld_naming": "ULD No format error, both characters and numbers are required",
  "error_mapping_lack_uld_no": "MAWB Build type=ULD, ULD No must have value",
  "error_loose_uld_no_number": "MAWB Build type=LOOSE, ULD No needn’t",
  "error_build_type_not_support": "MAWB Build Type only support ULD or LOOSE",
  "alert_receive_uld_list": "Prepare to Receive #%{count} ULD, list as below, please check.",
  "alert_remove_uld_from_request": "%{uld_no} will remove from list, and this ULD status will be back to Dest Received, please comfirm.",
  "alert_download_uld_return_request": "Saved completely. Do you want to download ULD Returning BOL?",
  "alert_cancel_return_request": "Related ULD status will change back to Dest Received and cancel this Returning No, please comfirm.",
  "alert_uld_return_handover": "Out For Return Date is current datetime, it could be revised. Are you sure to handover ULD and Returning BOL to truck driver?",
  "alert_validate_uld_return_request": "Select ULDs meet below situation, please select again or revise data.\n-Selected ULDs contain multiple ULD return No\n-Selected ULDs mapping multiple airline\n-Selected ULDs are returning or already retuened",
  "alert_relate_to_return_request": "Please remove from ULD Return No, then selected ULD could be deleted.",
  "uld_status_loading": "00 ULD Loading",
  "uld_status_dest_received": "10 ULD Received",
  "uld_status_dest_wait_for_return": "20 ULD Wait For Return​",
  "uld_status_dest_returning": "30 ULD Returning",
  "uld_status_dest_returned": "40 ULD Returned",
  "return_request_status_dest_returning_job_created": "00 ULD Returning Prepare​",
  "return_request_status_dest_out_for_return": "10 ULD Out For Return",
  "return_request_statuss_dest_returned": "20 ULD Returned",
  "return_request_status_cancelled": "90 Cancelled",
  "btn_upload_uld_mawb": "Upload ULD-MAWB",
  "btn_uld_receiving": "ULD Receiving",
  "btn_uld_returning": "ULD Returning",
  "btn_receive": "Receive",
  "tooltip_master_build_type": "only input ULD or LOOSE",
  "tooltip_uld_type": "input ULD type(optional), ex: Q5, Q7",
  "undefined": "",
  "alert_carrier_code_not_exist": "",
  "alert_master_not_exist": "MAWB does not exist",
  "alert_uld_in_another_return_request": "",
  "alert_airline_conflict": "ULD should share at most one airline",
  "alert_return_request_not_exist": "",
  "alert_return_request_is_cancelled": "Return request is cancelled",
  "alert_undefined_return_request_status": "",
  "alert_uld_conflict": "",
  "alert_cannot_add_uld_to_return_request": "",
  "master_origin_port": "MAWB Orig",
  "master_destination_port": "MAWB Dest",
  "master_number": "MAWB",
  "airline_name": "Airline",
  "uld_number": "ULD No",
  "uld_status": "ULD Status",
  "uld_return_number": "ULD Return No",
  "master_build_type": "MAWB Bulid Type",
  "note": "Note",
  "destination_uld_received_at": "Dest ULD Received Date",
  "is_uld_return_pod": "ULD Return POD",
  "destination_uld_returned_at": "Dest ULD Returned Date",
  "target_uld_returned_at": "Target ULD Returned Date",
  "master_eta_at": "MAWB ETA",
  "master_ata_at": "MAWB ATA",
  "master_atd_at": "MAWB ATD",
  "uld_received_by": "ULD Received By",
  "uld_received_truck_company": "ULD Received Truck Company",
  "uld_returned_truck_company": "ULD Returned Truck Company",
  "uld_remark": "ULD Remark",
  "uld_type": "ULD Type",
  "uld_bulk_upload": "ULD Upload",
  "flight_number": "Flight No",
  "delete_uld": "Do you want to delete this ULD?",
  "status_loading": "00 ULD Loading",
  "status_received": "10 ULD Received",
  "status_wait_for_return": "20 ULD Wait For Return​",
  "status_returning": "30 ULD Returning",
  "status_returned": "40 ULD Returned",
  "receiving_submission": "Submit Receving Request",
  "received_time": "Received Time",
  "received_carrier_code": "Received Truck Company",
  "returning_card_title": "ULD Returning",
  "returning_number": "ULD Returning No",
  "returning_status": "Status",
  "not_added": "Not Added",
  "added": "Added",
  "returning_delivery_details": "Delivery Details",
  "uld_number_count": "#ULD",
  "out_for_return_at": "Out For Return At",
  "out_for_return": "Out For Return",
  "remark": "Remark",
  "airline_received_by": "Airline Received By",
  "airline_received_datetime": "Airline Received Date",
  "attachments": "Attachments",
  "station": "Station",
  "airline": "Airline"
 },
 "ct": {
  "account_is_not_registered_with_morrison_one": "%{email} account is not registered with Morrison One",
  "actual_delivery_date": "Delivered",
  "actual_pickup_at": "Picked Up",
  "amount": "Spend",
  "arrived_cfs_at": "Arrived at Dest CFS",
  "ata": "Arrived (ATA)",
  "atd": "Departured (ATD)",
  "attachment": "Attachment",
  "booking_date": "Booked",
  "booking_number": "Booking No",
  "booking_request_number": "Request No.",
  "booking_request_no": "Booking Request No.",
  "booking_number_name": "Booking Number",
  "brokerag_and_cargo": "Add on Service",
  "booking_status_draft": "Draft",
  "booking_status_submitted": "Submitted",
  "booking_status_in_progress": "In progress",
  "booking_status_completed": "Completed",
  "booking_status_canceled": "Canceled",
  "billing_info": "Billing Information",
  "calculated_by": "Calculated By ",
  "calculated_by_unit": "Each Unit",
  "calculated_by_total": "Total Shipment",
  "cancel_at_utc": "Booking Cancel Date",
  "cancel_by": "Booking Req. Cancel by",
  "cancelled_at": "Cancelled",
  "cancel_check": "Are you sure you want to cancel booking request?",
  "cancel_notify_check": "Are you sure you want to cancel? Morrison is processing your shipment and may incur some charges for cancellation, we will contact you as soon as possible to confirm the charges, until then your order status will remain in booking in progress / in progress.",
  "cargo_ready_date": "Cargo Ready Date",
  "cargo_insurance": "Cargo Insurance",
  "charge_weight": "Charge Weight",
  "charge_weight_unit": "Charge Weight Unit",
  "chargeable_weight": "Charge Weight",
  "client_name": "Customer Name",
  "column_setting": "Column Setting",
  "commercial_invoice": "Commercial Invoice",
  "commercial_invoice_document": "Commercial Invoice Doc.",
  "commodity": "Commodity",
  "comments": "Comments",
  "complete_date": "Shipment Complete",
  "confirm_at_utc": "Booking Req. Confirm Date",
  "confirm_by": "Booking Req. Confirm by",
  "consignee": "Consignee",
  "consignee_address": "Consignee Address",
  "consignee_city": "Consignee City",
  "consignee_country": "Consignee Country/Area",
  "consignee_info": "Cnee Addr.",
  "consignee_name": "Consignee Name",
  "consolidation_document": "Consolidation Document",
  "container": "Container",
  "container_number": "Container No",
  "container_type": "Container Type",
  "count": "Count",
  "created_at_utc": "Booking Req. Create Date",
  "created_by": "Booking Req. Create by",
  "create_booking": "Create Booking",
  "cubic_meter": "Cubic Meter",
  "currency": "Currency",
  "custom_brokerage": "Custom Brokerage",
  "customer_code": "Customer Code",
  "customer_code_name": "Customer Code/Name",
  "customer_name": "Customer Name",
  "customer_expected_pickup_at_utc": "Expected Pickup Date",
  "customer_expected_pod_at_utc": "Expected Delivery Date",
  "customized_setting": "Customized Setting",
  "customized_table_header": "Customized Table Header",
  "customs_cleared_at": "Cleared Customs",
  "customs_released_at": "Customs Released",
  "dangerous_goods": "Dangerous goods",
  "dangerous_goods_declaration": "Dangerous Goods Declaration",
  "delivery_container_number": "Delivery / Container No",
  "dest": "Destination Port",
  "destination": "Destination",
  "destination_port": "Dest Port",
  "destination_port_full": "Destination Port",
  "destination_port_country": "Dest Country/Area",
  "details": "Details",
  "delivery": "Delivery",
  "delivery_port": "Delivery Port",
  "di_no": "Delivery #",
  "di_number": "Di No",
  "dimension": "Dimension",
  "do_number": "Document No",
  "doc_released_at": "Document Released",
  "document_slash_image": "Document / Image",
  "documents": "Documents",
  "download_qr_code": "Download QR Code",
  "booking": "Booking",
  "direct_shipment": "Direct Shipment",
  "direct_shipment_desc": "If you choose Direct Shipment, Morrison will provide the MAWB/MBL number via booking confirmation. Until then, you can use the booking request number to track the progress of the process.",
  "email": "Email",
  "enter_setting_name": "Enter Group Name",
  "estimated_delivery": "Estimated Delivery",
  "estimated_pickup_at": "Picked Up",
  "eta": "ETA",
  "etd": "ETD",
  "event_time_local": "Event time (Local)",
  "excepton": "Exception",
  "expected_eta_note": "MEC會盡力達到您的期望，但不保證一定可滿足",
  "expected_eta": "Target Delivery Date",
  "expected_pickup_date": "Expected Pickup Date",
  "export": "Export",
  "fcr_shipment": "FCR Shipment",
  "fcl": "FCL",
  "fd_number": "FD Number",
  "flight": "Flight",
  "flight_from_port": "Flight From Port",
  "flight_number": "Flight No",
  "flight_to_port": "Flight To Port",
  "freight_term": "Freight Term",
  "freight_payment_term": "Freight Payment Term",
  "from": "From",
  "general": "General",
  "goods_type": "Goods Type",
  "goods_info": "Goods information",
  "goods_description": "Goods Description",
  "gross_weight": "Gross Weight",
  "gross_weight_total": "Total Gross Weight",
  "gross_weight_unit": "Gross Weight Unit",
  "group": "Group",
  "group_by": "Group By",
  "handling_pieces": "Pieces",
  "hawb_doc": "HAWB Doc.",
  "height": "Height",
  "housebill_not_found": "The Housebill does not found",
  "housebill_number": "Housebill No",
  "id": "Id",
  "if_you_can_not_find_cutomer_name_in_list_just_choose_general": "If you can not find customer name in list , just choose 'General'",
  "incoterm": "Incoterm",
  "insurance_value": "Comercial invoice Value",
  "insurance_value_currency": "Comercial invoice currency",
  "invoice_number": "Invoice No",
  "is_custom_service": "Custom service ",
  "is_dangerous_goods": "Dangerous Good",
  "is_doc_ready": "Doc. Ready",
  "is_hazardous": "Dangerous Goods",
  "is_insurence": "Insurance",
  "is_origin_cert": "Certificate of Origin",
  "issued_date": "Issue Date",
  "last_status": "Last Status",
  "length": "Length",
  "local": "Local",
  "local_time": "Local Time",
  "logistic_handling": "Logistic Handling",
  "m": "M",
  "mawb": "MAWB",
  "mawb_doc": "MAWB Doc.",
  "mec_invoice_doc": "MEC Invoice",
  "method": "Ship Method",
  "milestone": "Milestone",
  "mode": "Ship Mode",
  "morrison_register_email_only": "Morrison One registered email only",
  "name": "Name",
  "no_download_url": "No DownloadUrl !",
  "no_shipment_detail": "No Shipment Detail !",
  "no_shipment_document": "No Shipment Document !",
  "no_shipment_tracking": "No Shipment Tracking !",
  "notification_name": "Notification Name",
  "notification_settings": "Notification Settings",
  "notification_settings_hint": "This setting will active notification for every new shipment",
  "notify_group": "Notify Group",
  "notify": "Notify",
  "notify_info": "Notify Addr.",
  "notify_name": "Notify Name",
  "notify_party": "Notify Party",
  "notify_when_shipment": "Notify When Shipment",
  "no_need_direct_shipment": "No, I am fine with HAWB/HBL Number",
  "number": "Number",
  "ofd": "OFD",
  "option_milstone_ata": "Actual Arrival",
  "option_milstone_booking_date": "Booking Created",
  "option_milstone_completed_at": "Shipment Completed",
  "option_milstone_etd": "Estimated Departure",
  "order": "Order",
  "origin": "Origin",
  "origin_cert_memo": "Certificate of origin memo",
  "origin_port": "Origin Port",
  "origin_port_country": "Origin Country/Area",
  "other_docs": "Other Docs.",
  "other_reference_file": "Other reference file",
  "others_payment_term": "Others Payment Term",
  "out_for_delivery_date": "Out For Delivery",
  "package_quantity": "Package Qty",
  "packing_list": "Packing List",
  "parent_id": "Parent Code",
  "parts": "Parts",
  "part_number": "Part No",
  "part_description": "Part Description",
  "pallet": "Pallet",
  "pick_up": "Pick Up",
  "pickup": "Pickup",
  "pickup_info": "Pickup Addr.",
  "pickup_name": "Pickup Name",
  "place_of_Receipt": "Place of Receipt",
  "po_no": "PO No",
  "po_number": "PO No",
  "po_item_no": "PO Item No",
  "pod": "POD Time",
  "pod_file": "POD File",
  "port_of_discharge": "Port of Discharge",
  "port_of_delivery": "Port of Delivery",
  "port_of_landing": "Port of Loading",
  "port_of_receipt": "Port of Receipt",
  "progress": "Progress",
  "project": "Project",
  "proof_of_delivery": "Proof of Delivery",
  "proof_of_delivery_other_docs": "Proof of Delivery Other Docs.",
  "qr_code_tracking": "QR Code Tracking",
  "quantity": "Quantity",
  "query": "Query",
  "quotation": "Quotation",
  "quotation_number": "Quotation Number",
  "qty_ea": "Qty(EA)",
  "range_in": "輸入範圍在 %{start}~%{end}",
  "receipt_port": "Receipt Port",
  "reference": "Reference",
  "reference_4color_bt": "FourColor - Booking Title",
  "reference_apple_dri": "APPLE DRI",
  "reference_apple_fdmg": "FD MANAGER",
  "reference_apple_fdno": "FD No",
  "reference_apple_prno": "APPLE PR No",
  "reference_book_title": "Book Title",
  "reference_box": "BOX No",
  "reference_cc": "Cost Centre Code",
  "reference_cost_cnt": "Cost Center Number",
  "reference_cra": "Cannister No",
  "reference_dc": "Declaration No for AI and AE",
  "reference_di": "Delivery ID",
  "reference_gl_accunt": "GL Account No",
  "reference_in": "Invoice No",
  "reference_it": "Item No",
  "reference_load_date": "Ex - Factory Date for Sonos Report",
  "reference_lu": "LU No",
  "reference_number": "Reference No",
  "reference_or": "Order No",
  "reference_os_ossg_mm": "UMC Department Code",
  "reference_ot": "Other",
  "reference_pj": "Project Name",
  "reference_pl": "P / L #",
  "reference_pn": "Part No",
  "reference_po": "Purchase Order No",
  "reference_rma": "Returned Material Authorization",
  "reference_s_n": "Serial No",
  "reference_s_o": "S / O No + Ship",
  "reference_sd": "SDAF No",
  "reference_sgce1": "UMC Department Code",
  "reference_sgcs3": "UMC Department Code",
  "reference_si": "SID No",
  "reference_smp_cntct": "Contact / Purchaser",
  "reference_smp_po": "PO No",
  "reference_smp_psp": "PSP - Element No",
  "reference_smp_purno": "Purchase Requisition No",
  "reference_sr": "ShipRef No",
  "reference_st": "Style No",
  "reference_type": "TYPE",
  "reference_umc": "UMC Ref Type",
  "requester": "Requester",
  "result": "Result",
  "route": "Route",
  "sales_name": "Sales Name",
  "same_as_shipper": "same as shipper",
  "same_as_consignee": "same as consignee",
  "same_as_shipper_check_label": "Pickup as same as Shipper",
  "same_as_consignee_check_label": "Delivery as same as Consignee",
  "same_as_notify_check_label": "Notify 3rd party when shipment arrival at destination",
  "send_mail_notification_to": "Send Notification to",
  "send_me_pod_file": "Send me POD file",
  "send_notification_from": "Send Notification From",
  "send_sms_notification_to": "Send SMS Notification to",
  "secure_station": "Secure Station",
  "sequence": "Seq",
  "service": "Service",
  "service_level": "Service Level",
  "service_info": "Service Info",
  "service_remark": "Service Remark",
  "service_type": "Service Type",
  "ship_to": "Ship To",
  "ship_type": "Ship Type",
  "shipment_delay": "Shipment Delay",
  "shipment_details": "Shipment Details",
  "shipment_mode": "Mode",
  "shipment_number": "HAWB / HBL",
  "shipment_to": "Shipment To",
  "shipment_to_info": "Shipto Addr.",
  "shipment_to_name": "Shipto Name",
  "shipment_require": "Bill Of Lading Type",
  "shipment_require_option_1": "General Shipment (HAWB/HBL)",
  "shipment_require_option_2": "Direct Shipment (MAWB/MBL)",
  "shipment_require_option_3": "Pre-assign HAWB/HBL provide by Morrison",
  "shipper": "Shipper",
  "shipper_address": "Shipper Address",
  "shipper_city": "Shipper City",
  "shipper_country": "Shipper Country/Area",
  "shipper_info": "Shipper Addr.",
  "shipper_name": "Shipper Name",
  "shipping_marks": "Shipping Marks",
  "shipping_instruction": "Shipping Instruction",
  "ship": "",
  "shipping_mode": "Shipp Mode",
  "shipment_reference_no": "Reference",
  "sign_by": "Sign by",
  "shipment_by_to": "Customer code",
  "special_instruction": "Special instruction",
  "status": "Status",
  "submitted_at_utc": "Booking Rea. Submit Date",
  "submitted_by": "Booking Req. Submit by",
  "submit_at": "Submit At",
  "submit_by": "Submit By",
  "shipment_mode_air": "Air",
  "shipment_mode_sea": "Sea",
  "step_1_Service": "STEP 1: Service",
  "step_2_Address": "STEP 2: Address",
  "step_3_Detail": "STEP 3: Detail",
  "step_4_Doc": "STEP 4: Document",
  "subtotal": "Subtotal",
  "target_delivery_date": "Target Delivery Date",
  "teu": "Teu",
  "to": "To",
  "total_packages": "Total Packages",
  "total_pallets": "Pallet Qty",
  "total_pieces": "Total Pieces",
  "total_spend": "Total Spend",
  "total_weight": "Total Weight",
  "total_weight_kg": "Total G.Weight (Kg)",
  "trace_other_number": "Tracking other Number",
  "truck_size": "Truck Size",
  "truck_type": "Truck Type",
  "terms_confirm": "I confirm that this is a commercial shipment and all details are correct. I accept the %{a1}   terms of the booking %{a2}",
  "terms_confirm_check": "Please check \"I confirm that this is a commercial shipment and all details are correct. I accept the terms of the booking.\" before you submit booking request.",
  "type": "Type",
  "uom": "UOM",
  "upload_by": "Upload By",
  "upload_date_time": "Upload Date / Time",
  "upload_doc": "Shipping Documents",
  "uppercase_all": "ALL",
  "uppercase_canceled": "CANCELED",
  "uppercase_completed": "COMPLETED",
  "uppercase_details": "DETAILS",
  "uppercase_documents": "DOCUMENTS",
  "uppercase_in_progress": "IN PROGRESS",
  "uppercase_morrison_one": "MORRISON ONE",
  "uppercase_track": "TRACK",
  "uppercase_tracking": "TRACKING",
  "unit_weight": "Gross Weight (Per Outer)",
  "value": "value",
  "vessel": "Vessel",
  "vessel_name": "Vessel Name",
  "vip_name": "Customer",
  "volume_weight": "Volume Weight",
  "volume_weight_total": "Total Volume",
  "volumetric_weight": "Volume Weight",
  "voyage": "Voyage",
  "weight_kg": "G.Weight (Kg)",
  "width": "Width",
  "yes_no_0": "N",
  "yes_no_1": "Y",
  "your_shipment_encounters": "Shipment Encounter",
  "transporation": "Transportation",
  "transporation_mode": "Transporation Mode",
  "load": "Load Port",
  "loads": "Load",
  "lcl": "LCL",
  "loose_goods": "Loose Goods",
  "discharge": "Discharge Port",
  "total_of_units": "Total of Outers",
  "qty_of_outers": "Uty of Outers",
  "per_length": "Length (CM)",
  "per_weight": "Width (CM)",
  "per_height": "Height (CM)",
  "pre_assign": "Pre-assign HAWB/HBL Number",
  "pre_assign_desc": "Please obtain a pre-assigned number from MEC first and do not use the same number repeatedly.",
  "pre_assign_fill": "I have Morrison provide.",
  "request_brokerage": "Do you need customs brokerage arrangement from MEC? ",
  "need_carge": "Do you need cargo insurance add on? ",
  "request_brokerage_yes": "Yes - I need customs brokerage.",
  "request_brokerage_no": "NO - I have a customs broker.",
  "need_carge_yes": "Yes - I need cargo insurance.",
  "need_carge_no": "NO - I've either got insurance or take full responsibility for any lost or damaged cargo.",
  "select_quotation_number": "Select Quotation Number",
  "shipper_code": "Shipper Code",
  "consignee_code": "Consignee Code",
  "quotation_type": "Quotation Type",
  "quotation_status": "Quotation Status",
  "is_effective": "Is Effective",
  "submitted_at": "Submit At",
  "commodity_code": "Commodity Code",
  "commodity_desc": "Commodity Description",
  "others_term": "Others Payment",
  "effective_from": "Effective From",
  "effective_to": "Effective To",
  "secure_sales_no": "Secure Sales No.",
  "department": "Department",
  "select_customer_code": "Select Customer Code",
  "address": "Address",
  "city": "City",
  "state": "State",
  "zip_code": "Zip Code",
  "country": "Country/Area",
  "contact_name": "Contact Name",
  "code": "Code",
  "overwrite_confirm": "Do you want to overwrite the shipper/consignee information?",
  "save_to_address": "Save to my address book",
  "not_sure_right_now": "I'm not sure right now",
  "submit_question": "Confirm if you want to send a booking request.",
  "diff_origin_area_notify": "The %{area} country you choose is different from the origin port ",
  "diff_dest_area_notify": "The %{area} country you choose is different from the destination",
  "pod_doc": "POD Doc",
  "biling_name": "Customer Name",
  "teu_column_tooltip": "TEU is an estimate value due to missing value on container type.",
  "shipment_query_limitation_info": "Query data is available for booking shipment after 1st June 2020",
  "milestone_status_prepare_for_depature": "Prepare for departure",
  "milestone_status_departed_from_port": "Departed from port",
  "milestone_status_arrived_at_port": "Arrived at port",
  "milestone_status_prepare_for_delivery": "Prepare for delivery",
  "milestone_status_out_for_delivery": "Out for delivery",
  "milestone_status_delivered": "Delivered",
  "milestone_status": "Milestone Status",
  "shipment_created_at_utc": "Created At (UTC)",
  "shipment_count": "Total Shipment",
  "shipment_count_descript": "Total Number of Shipment (HAWB)",
  "ready_for_departure_count": "Prepare for departure",
  "departed_at_port_count": "Departed at port",
  "arrived_at_port_count": "Arrived at port",
  "prepare_for_delivery_count": "Prepare for delivery",
  "out_for_delivery_count": "Out for delivery",
  "delivered_count": "Delivered",
  "total_chargeable_weight": "Total CW (KG)",
  "abbr_consignee_name": "Consignee Name",
  "billing_account_name": "Customer Name",
  "completed_shipment_count": "Total Completed Shipment",
  "completed_shipment_count_sea_tooltip": "Total count of Completed HBL",
  "completed_chargeable_weight": "Total Completed CW (KG)",
  "cancelled_shipment_count": "Total Cancelled Shipment",
  "cancelled_shipment_count_sea_tooltip": "Total count of Cancelled HBL",
  "cancelled_chargeable_weight": "Total Cancelled CW (KG)",
  "total_shipment_count": "Total Shipment",
  "total_shipment_count_sea_tooltip": "Total count of HBL",
  "total_delivery_count": "Total Delivery",
  "total_delivery_count_sea_tooltip": "Total count of Delivery / Container",
  "total_teu": "Total TEU",
  "total_cubic_meter": "Total CBM",
  "completed_delivery_count": "Total Completed Delivery",
  "completed_delivery_count_sea_tooltip": "Total count of completed Delivery / Container",
  "total_completed_teu": "Total Completed TEU",
  "total_completed_cubic_meter": "Total Completed CBM",
  "cancelled_delivery_count": "Total Cancelled Delivery",
  "cancelled_delivery_count_sea_tooltip": "Total count of Cancelled Delivery / Container",
  "total_cancelled_teu": "Total Cancelled TEU",
  "total_cancelled_cubic_meter": "Total Cancelled CBM",
  "available_for_shipment_within_six_month": "Available for Shipment Within Six Month",
  "week": "Week",
  "error_notification_already_exists": "Notification for shipment %{shipment_number} already exists, please refresh the page.",
  "client_id": "Client ID",
  "advanced_filter": "Advanced Filter",
  "co2_emission_estimation": "CO2 Emission Estimation",
  "co2_emission_summary": "Estimated Carbon Emission Summary",
  "co2_emission_warning": "Carbon emissions values on the Morrison One Platform are provided by EcoTransIT. EcoTransIT is a flexible tool for complex calculations that supports companies to calculate their emissions. The software is accredited by Smart Freight Centre and is in compliance with the GLEC Framework and also meets the requirements of EN 16258 and the GHG protocol (Corporate Standard).  Visit  https://www.ecotransit.org/  for more details.",
  "co2_emission_not_availabled": "Not Availabled",
  "co2_emission_transport_status": "Transport Status",
  "co2_emission_estimated_distance": "Estimated Distance (KM)",
  "co2_emission_estimated_co2_emission": "Estimated CO2 Emission (KG)",
  "co2_emission_well_to_tank": "Well-to-Tank (WTT)",
  "co2_emission_tank_to_wheel": "Tank-to-Wheel (TTW)",
  "co2_emission_well_to_wheel": "Well-to-Wheel  (WTW)",
  "co2_emission_pick_up_from_shipper": "Pick up from shipper",
  "co2_emission_terminal_to_terminal": "Terminal to terminal",
  "co2_emission_delivery_to_customer": "Delivery to customer",
  "co2_emission_total_summary": "Total Summary",
  "quick_track_wrong_format": "Format not allowed",
  "quick_track_submit_btn": "TRACK",
  "quick_track_input_placeholder": "Tracking with Housebill No.",
  "quick_track_footer": "Morrison One for further shipment details or reach your contact window to apply Morrison One account.",
  "quick_track_milestone_history": "Milestone History",
  "quick_track_current_milestone_status": "Current Milestone Status",
  "quick_track_404_title": "This housebill number can not be found",
  "quick_track_404_text": "Please try another housebill number",
  "quick_track_housebill_not_found": "This housebill does not found, please check again or input another housebill number",
  "milestone_status_cancelled": "Cancelled",
  "milestone_status_tooltip": "Prepare for departure: Booked/Picked Up/Cleared Customs\nDeparture from port: Departured(ATD)\nArrive at port: Arrived(ATA)\nPrepare for delivery:Document Released/Customs Released/Arrived at Dest CFS\nOut for delivery:Out for delivery\nDelivered:Delivered",
  "port_list_placeholder": "Enter Port Name ex: TPE",
  "country_code_list_placeholder": "Enter Country Code ex: TW",
  "undefined": ""
 },
 "dashboard": {
  "add_section": "Add Section",
  "add_widget": "Add Widget",
  "add_widget_row": "Add Widget Row",
  "all": "All",
  "component_option_customer_tracking_shipments_query": "Customer Tracking Shipments Query",
  "custom_day_range": "In %{daynumber} days",
  "custom_week_range": "In %{weeknumber} weeks",
  "in_past_n_weeks": "In past %{weeknumber} weeks",
  "life_time": "Life Time",
  "current_week": "Current Week",
  "last_week": "Last Week",
  "last_last_week": "Last Last Week",
  "nth_week_of_last_year": "%{weeknumber}th week last year",
  "nth_week_of_this_year": "%{weeknumber}th week this year",
  "add_nth_week_option": "Select week",
  "custom-widget-2d-table-column-key": "Column Key",
  "custom-widget-2d-table-column-key-order": "Column Order",
  "custom-widget-2d-table-custom-column-key": "Custom Column",
  "custom-widget-2d-table-has-filter": "Has Filter",
  "custom-widget-2d-table-column-selector-detail": "Column Selector Detail",
  "custom-widget-2d-table-key-agg": "Aggregate",
  "custom-widget-2d-table-row-key": "Row Key",
  "custom-widget-2d-table-row-key-order": "Row Order",
  "custom-widget-2d-table-nongroup-column-key": "NonGroup Column",
  "custom-widget-group-by": "Group By",
  "custom_widget_prepend_text": "Prepend Text",
  "dashboard": "Dashboard",
  "data_selector": "Date Range Filter",
  "edit_widget": "Edit Widget",
  "error_add_fast_create_dispatch": "You cannot fast create with different dispath no",
  "hello_word": "Hi, %{account}. Welcom to Morrison One Platform.",
  "last_update": "Last Update",
  "select_a_dashboard": "select a dashboard",
  "setting_column_aggregate_avg": "average",
  "setting_column_aggregate_count": "count",
  "setting_column_aggregate_max": "max",
  "setting_column_aggregate_min": "min",
  "setting_column_aggregate_sum": "sum",
  "total": "Total",
  "grand_total": "Grand Total",
  "user_picker": "User Picker",
  "filter_input_placeholder": "Seperate by ,",
  "undefined": "",
  "component_option_kpi_late_milestone_query": "KPI Weekly Detail Late Milestone",
  "component_option_kpi_summary_query": "KPI Summary Query",
  "default": "Default Dashboard",
  "set_as_default": "Make Default",
  "use_i18n": "Use i18n",
  "ct_dashbaord_prepend_text": "Please refer to Tracking/Shipment Query page for most updated shipment progress.",
  "ct_dashboard_prepend_default_range_notice": "Defaults within past 3 months shipment data in this dashbaord, you can use \"Date Range\" function to modify inquery duration up to past 12 month.",
  "ct_dashboard_teu_notice": "notice: TEU is an estimate value due to missing value on container type, information for reference only.",
  "has_advanced_search_date_range_limit": "Advanced Search Date Range Limit",
  "has_advanced_search": "Has Advanced Search",
  "prepend_text": "Prepent Text",
  "prepend_i18n_key": "Prepend I18n Key",
  "prepend_text_classname": "Prepend Text className",
  "quick_guide_privilege_id": "Quick Guide Privilege ID",
  "ct_advanced_search_within_days_next_not_allowed": "Future date is not allowed",
  "ct_advanced_search_within_days_min_error": "Minimum in 1 day",
  "ct_advanced_search_within_days_max_error": "Maximum  within 180 days",
  "ct_advanced_search": "Date Range",
  "use_custom_advanced_search_key": "Custom Advacned Search Button Text",
  "custom_advanced_search_i18n_key": "Custom Advanced Search Button I18n Key",
  "ct_advanced_search_within_days_min_variable_error": "Minimum in %{number} day",
  "ct_advanced_search_within_days_max_variable_error": "Maximum  within %{number} days",
  "ct_advanced_search_between_min_variable_days_error": "Available for Shipment Within %{number} Days"
 },
 "edi": {
  "undefined": "Updated Date",
  "hawb_number": "HAWB / HBL",
  "customer_code": "Customer Code",
  "customer": "VIP Name",
  "ediMappingStatusList": "EDI Process Status",
  "ediProcessStatus": "EDI Process Status",
  "edi_type": "EDI Type",
  "created": "Created",
  "batch_send_fail": "Batch Send Fail",
  "batch_send_success": "Batch Send Success",
  "canceled": "Canceled",
  "generate_success": "Generate Success",
  "send_ack_failed": "Send Ack Failed",
  "send_success": "Send Success",
  "send_wait_ack": "Send Wait Ack",
  "send_fail": "Send Fail",
  "validate_fail": "Validate Fail",
  "validate_retry": "Validate Retry",
  "bill_to_party": "Bill-to Party",
  "origin_port": "Orig",
  "destination_port": "Dest",
  "invoice_number": "Inv No",
  "trade_type": "Trade Type",
  "non_trade": "Non-Trade",
  "trade": "Trade",
  "index_properties": "Index Ref",
  "inv_amount": "Inv Amount",
  "inv_currency": "Inv Currency",
  "shipment_first_atd": "HAWB First ATD (Local Time)",
  "edi_sent_at": "EDI Sent Date (UTC) ",
  "process_message": "Message",
  "isa_number": "ISA No",
  "billing_issued_at": "Inv date",
  "print_at": "Print Date",
  "shipment_last_ata": "Last ATA",
  "last_atd_input_date": "Last ATA Input Date(UTC)",
  "shipment_actual_pod": "POD",
  "edi_status_hint": "Edi status ",
  "pod_input_date": "POD Input Date(UTC)"
 },
 "config": {
  "client_id": "Client ID",
  "vip_name": "Customer",
  "status_type": "Status Type",
  "edi_category": "EDI Category",
  "edi_event_code": "EDI Event Code",
  "edi_status_code": "EDI Status Code",
  "edi_type": "EDI Type",
  "event_description": "Event Description",
  "mode": "Mode",
  "mop_status_code": "Mop Status Code",
  "is_synced": "Is Synced",
  "edi_charge_code": "EDI Charge Code",
  "edi_charge_description": "EDI Charge Description",
  "prolink_charge_code": "Prolink Charge Code",
  "prolink_charge_description": "Prolink Charge Description",
  "confirm_delete": "Confirm Delete",
  "are_you_sure_to_delete_this_system_setting": "Are You Sure To Delete This System Setting？",
  "setting_name": "Setting Name",
  "setting_value": "Setting Value",
  "need_sync": "Need Sync",
  "are_you_sure_to_delete_this_mec_station": "Are You Sure To Delete This Mec Station？",
  "code": "Code",
  "source_db": "Source DB",
  "station": "Station",
  "role": "Role",
  "time_zone": "Time Zone",
  "name_1": "Name 1",
  "name_2": "Name 2",
  "address_1": "Address 1",
  "address_2": "Address 2",
  "address_3": "Address 3",
  "address_4": "Address 4",
  "telephone": "Telephone",
  "fax": "Fax",
  "zip_code": "Zip Code",
  "city_code": "City Code",
  "state_code": "State Code",
  "country_code": "Country Code",
  "edi_city_name": "Edi City Name",
  "contact_name": "Contact Name",
  "report_region": "Report Region",
  "prolink_remark": "Prolink Remark",
  "tms_ship_from_name": "TMS Ship From Name",
  "tms_ship_from_address": "TMS Ship From Address",
  "tms_ship_from_city": "TMS Ship From City",
  "tms_ship_from_country": "TMS Ship From Country",
  "tms_ship_from_contact": "TMS Ship From Contact",
  "tms_ship_from_state": "TMS Ship From State",
  "tms_ship_from_tel": "TMS Ship From Tel",
  "default_pdf_template": "Default PDF Template",
  "edi_code": "EDI Code",
  "edi_sender": "EDI Sender",
  "need_ack": "Need Ack",
  "need_confirm": "Need Confirm",
  "need_mdn": "Need Mdn",
  "shipper_id": "Shipper ID",
  "entity": "Entity",
  "entity_field": "Entity Field",
  "entity_for_timezone_reference": "Entity For Timezone Reference",
  "timezone_reference_field": "Timezone Reference Field",
  "timezone_reference_type": "Timezone Reference Type",
  "notify_people": "Changing this setting will notify: ",
  "setting_description": "Setting Description: ",
  "table_options": "Table Options"
 },
 "epod": {
  "can_not_upload_because_checked": "Cannot upload because POD Document used by delivery is already checked, please contact Morrison OP",
  "input_error_code": "Input Exception Code",
  "company_stamp": "Company Stamp",
  "same_as_consignee": "Same As Consignee",
  "error_ofd_datetime_not_future_time": "OFD Time exceed the current time, please double check and input.",
  "error_invalid_date_format": "Invalid Date Format",
  "error_pod_datetime_not_future_time": "POD Time exceed the current time, please double check and input.",
  "error_pod_datetime_earlier_than_ofd_time": "POD Time is earlier than the OFD time, please double check and input."
 },
 "shalog": {
  "truck_plate_no": "Truck Plate No.",
  "lock_info_due_to_template_setting": "The field was locked due to template settings",
  "template": "Template",
  "error_shipment_pcs_should_not_be_zero": "Shipment Pcs should be greater than 0",
  "total_pallets_or_loose_cartons_should_not_be_zero": "Total pellets or Loose cartons should be greater than 0",
  "all": "All",
  "shipment_template_id": "Shipment Template ID",
  "project": "Project",
  "handing_station": "Handing Station",
  "fd_no": "FD No.",
  "pr_no": "PR No.",
  "sales_owner": "Sales Code",
  "template_remark": "Template Remark",
  "customer": "Customer",
  "shipper": "Shipper",
  "consignee": "Consignee",
  "client_id": "Client ID",
  "effective_start": "Effective Start",
  "effective_end": "Effective End",
  "handling_station": "Handling Station",
  "billing_station": "Billing Station",
  "billing_station_conf": "Billing Station Conf",
  "logistic_handling": "Logistic Handling",
  "origin_station": "Origin Station",
  "destination_station": "Destination Station",
  "origin_station_conf": "Origin Statio Conf",
  "shipment_mode": "Shipment Mode",
  "shipment_mode_conf": "Shipment Mode Conf",
  "template_number": "Template No",
  "template_status": "Template Status",
  "active": "Active",
  "inactive": "Inactive",
  "activate": "Activate",
  "deactivate": "Deactivate",
  "shipment": "Shipment",
  "routing": "Routing",
  "milestones_and_document": "Milestones & Document",
  "voucher_and_invoice": "Voucher and Invoice",
  "shipment_info": "Shipment Info",
  "are_you_sure_you_want_to_activate_the_shipment_template": "Are you sure you want to activate the shipment template?",
  "are_you_sure_you_want_to_deactivate_the_shipment_template": "Are you sure you want to deactivate the shipment template?",
  "step_one_shipment": "STEP1: Shipment",
  "step_two_milestones": "STEP2: Milestones",
  "step_three_document": "STEP3: Document",
  "step_four_billing": "STEP4: Billing",
  "cargo_details": "Cargo Details",
  "shipment_template_remark": "Shipment Template Remark",
  "effective_from": "Effective From",
  "effective_to": "Effective To",
  "origin": "Origin",
  "destination": "Destination",
  "ship_mode": "Ship Mode",
  "description_of_goods": "Description of Goods",
  "reference_no": "Reference No.",
  "special_instruction": "Special Instruction",
  "dangerous_good": "Dangerous Good",
  "package": "Package",
  "pallet_qty": "Pallet Qty",
  "loose_ctn_qty": "Loose CTN Qty",
  "shipment_pcs": "Shipment PCS",
  "gw": "GW",
  "cw": "CW",
  "volume": "Volume",
  "volumetric_weight": "Volumetric Weight",
  "delivery": "Delivery",
  "pick_up_name": "Pick-Up Name",
  "pick_up_address": "Pick-Up Address",
  "city": "City",
  "state": "State",
  "zip_code": "ZIP Code",
  "country": "Country/Area",
  "contact_name": "Contact Name",
  "contact_email": "Contact Email",
  "phone_number": "Phone No",
  "fd_info": "FD Info",
  "bpo_no": "BPO No.",
  "search_fd_info": "Search FD Info",
  "search": "Search",
  "submit": "Submit",
  "back": "Back",
  "next": "Next",
  "add": "Add",
  "save": "Save",
  "fd_manager": "FD Manager",
  "choose_bpo": "Choose BPO",
  "apple_dri": "Apple DRI",
  "change_info": "Change Info",
  "please_choose_station_and_customer_first": "Please Choose Station and Customer First",
  "shipment_template": "Shipment Template",
  "billing": "Billing",
  "milestones": "Milestones",
  "document": "Document",
  "total_bpo_amount": "Total BPO Amount",
  "bpo_spent_balance_percentage": "BPO Spent Balance (%)",
  "total_internal_invoice_amount": "Total Internal Inv Amount (A)",
  "total_billed_amount_in_trck": "Total Billed Amount in Apple TRCK",
  "spent_balance": "Spent Balance (Total BPO Amount - A)",
  "total_bpo_billed_balance": "Total BPO Billed Balance",
  "service_type": "Service Type",
  "service_remark": "Service Remark",
  "truck_type": "Truck Type",
  "truck_size": "Truck Size",
  "flight_number": "Flight No",
  "from_port": "From Port",
  "to_port": "To Port",
  "add_service": "Add Service",
  "delete_service": "Delete Service",
  "service_info": "Service Info",
  "routing_info": "Routing Info",
  "from": "From",
  "to": "To",
  "stop": "Stop",
  "same_as_shipper": "Same as Shipper",
  "same_as_consignee": "Same as Consignee",
  "name": "Name",
  "address": "Address",
  "floor": "Floor/Suit/Unit",
  "port_slash_location": "Port / Location",
  "stop_remark": "Stop Remark",
  "location_name": "Location Name",
  "port_code": "Port Code",
  "add_stop": "Add Stop",
  "milestone_info": "Milestone Info",
  "timezone": "Timezone",
  "add_milestone": "Add Milestone",
  "description": "Description",
  "delete_this_stop": "Delete this Stop",
  "milestone": "Milestone",
  "port": "Port",
  "location": "Location",
  "vendor": "Vendor",
  "dept": "Dept",
  "payment_method": "Payment Method",
  "customer_name": "Customer Name",
  "charge_code": "Charge Code",
  "charge_item": "Charge Item",
  "charge_description": "Charge Description",
  "qty": "QTY",
  "required_voucher": "Required Voucher",
  "required_invoice": "Required Invoice",
  "add_voucher": "Add Voucher",
  "station": "Station",
  "debit_to": "DebIt To",
  "debit_to_name": "DebIt To Name",
  "delete": "Delete",
  "edit": "Edit",
  "unit": "Unit",
  "rate": "Rate",
  "charge_uvat_amount": "Charge uVat Amount",
  "charge_vat_amount": "Charge Vat Amount",
  "charge_amount": "Charge Amount",
  "rate_currency": "Rate Currency",
  "add_invoice": "Add Invoice",
  "sales_code": "Sales Code",
  "add_item": "Add Item",
  "edit_voucher": "Edit Voucher",
  "edit_invoice": "Edit Invoice",
  "document_type": "Document Type",
  "mandatory": "Mandatory",
  "document_setting": "Document Setting",
  "vendor_name": "Vendor Name",
  "complete": "Complete",
  "add_document_type": "Add Document Type",
  "to_be_created": "To be created",
  "printed": "Printed",
  "origin_nvat_amount": "Origin uVat Amount",
  "origin_vat_amount": "Origin Vat Amount",
  "origin_amount": "Origin Amount",
  "origin_nvat_amount_in_usd": "Origin uVat Amount in USD",
  "origin_vat_amount_in_usd": "Origin Vat Amount in USD",
  "origin_amount_in_usd": "Origin Amount in USD",
  "origin_currency": "Origin Currency",
  "updated_by": "Updated by",
  "updated_at_utc": "Updated At (UTC)",
  "void_voucher": "Void Voucher",
  "reqest_voucher_processing": "Request Voucher (Processing)",
  "reqest_invoice_processing": "Request Invoice (Processing)",
  "void": "Void",
  "created_invoice": "Created Invoice",
  "created_voucher": "Created Voucher",
  "invoice": "Invoice",
  "voucher": "Voucher",
  "show_voided": "Show voided",
  "voucher_currency": "Voucher Currency",
  "total_sales": "Total Sales",
  "total_costs": "Total Costs",
  "gross_margin": "Gross Margin",
  "origin_charge_rate": "Origin Charge Rate",
  "voucher_no": "Voucher No.",
  "action": "Request Type",
  "the_field_will_be_not_editable_in_the_shipment_data": "The Field will be <b>NOT editable</b> in the shipment data",
  "the_field_will_be_editable_in_the_shipment_data": "The Field will be <b>editable</b> in the shipment data",
  "please_select_a_fd_number_for_this_template": "Please select a FD No for this template",
  "please_note_that_this_bpo_only_has_spend_balance_budget_left": "Please note that this BPO only has %{spendBalance}% budget left",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "Unsaved changes will be lost, are you sure you want to leave?",
  "tips": "Tips",
  "customer_code_name": "Customer Code/Name",
  "payment_term": "Payment Term",
  "customer_code": "Customer Code",
  "once_completed_this_template_cannot_be_changed_are_you_sure_to_continue": "Once completed, this template cannot be changed. Are you sure to continue?",
  "vip_name": "VIP Name",
  "shipper_city": "Shipper City",
  "shipper_address": "Shipper Address",
  "consignee_city": "Consignee City",
  "consignee_address": "Consignee Address",
  "consignee_address_localized": "Consignee Address Localized",
  "shipper_address_and_city": "Shipper Address & City",
  "consignee_address_and_city": "Consignee Address & City",
  "create_from_utc": "Create from (UTC)",
  "create_to_utc": "Create to (UTC)",
  "created_at_utc": "Created At (UTC)",
  "dg_y_n": "DG (Y/N)",
  "project_manager": "Project Manager",
  "shipper_id": "Shipper ID",
  "consignee_id": "Consignee ID",
  "to_be_created_voucher": "To be created voucher",
  "processing_voucher": "Process voucher",
  "printed_voucher": "Printed voucher",
  "to_be_created_invoice": "To be created Invoice",
  "processing_invoice": "Processing Invoice",
  "printed_invoice": "Printed Invoice",
  "last_updated_utc": "Last Updated (UTC)",
  "calculating": "Calculating",
  "not_including_mec_internal_billing": "Including SHALOG Printed Voucher / Invoice Only",
  "clone": "Clone",
  "can_not_be_zero": "Can Not Be Zero",
  "addressbook": "Address book",
  "clear": "Clear",
  "voucher_date": "Voucher Date",
  "invoice_date": "Invoice Date",
  "request_id": "Request Id",
  "voucher_processing_and_printed": "Voucher (Processing & Printed)",
  "invoice_processing_and_printed": "Invoice (Processing & Printed)",
  "bill_to": "Bill to",
  "cancel": "Cancel",
  "invoice_no": "Invoice No",
  "undo": "Undo",
  "chargeable_weight": "Charge Weight",
  "gross_weight": "Gross Weight",
  "service_provider": "Service Provider %{index}",
  "po_ready": "PO Ready",
  "invoice_ready": "Invoice Ready",
  "bulk_update": "Bulk Update",
  "generate_shipper_letter": "Generate Shipper's letter",
  "invoice_ready_at_utc": "Invoice Ready At (UTC)",
  "po_ready_at_utc": "PO Ready At (UTC)",
  "invoice_ready_by": "Invoice Ready By",
  "po_ready_by": "PO Ready By",
  "missing_pod": "Missing POD",
  "missing_pick_up": "Missing Pickup",
  "missing_pod_file": "Missing POD File",
  "is_ready": "Is Ready",
  "total_shipments_selected": "Total %{count} Shipments Selected",
  "is_invoice_ready": "Invoice Ready",
  "is_po_ready": "PO Ready",
  "is_invoice_ready_true": "Yes",
  "is_invoice_ready_false": "No",
  "is_po_ready_true": "Yes",
  "is_po_ready_false": "No",
  "invoice_no_ready_count": "INV Not Ready",
  "po_no_ready_count": "PO Not Ready",
  "invoice_no_ready_percentage": "INV Not Ready (%)",
  "po_no_ready_percentage": "PO Not Ready (%)",
  "actual_delivery_date (in nth week)": "Actual Delivery Date (in nth week)",
  "created_at_utc (in nth week)": "Created at UTC (in nth week)",
  "number_of_clones": "Number of Clones",
  "number_of_clones_is_required": "Number of Clones is Required",
  "the_maximum_number_of_clone_is_50": "The maximum number of clone is 50",
  "shipment_query_table_total_revenue": "Total Revenue",
  "shipment_query_table_total_cost": "Total Cost",
  "shipment_query_table_total_gross_profit": "Gross Profit",
  "shipment_query_table_total_gross_margin": "Gross Margin",
  "shipment_query_table_customs_gross_margin": "Customs GM",
  "shipment_query_table_documentation_gross_margin": "Document GM",
  "shipment_query_table_domestic_gross_margin": "Domestic GM",
  "shipment_query_table_freight_gross_margin": "Freight GM",
  "shipment_query_table_handling_gross_margin": "Handling GM",
  "shipment_query_table_trucking_gross_margin": "Trucking GM",
  "shipment_query_table_warehouse_gross_margin": "Warehouse GM",
  "shipment_query_table_logistic_gross_margin": "Logistic GM",
  "hawb_hbl": "HAWB / HBL",
  "shipment_edit": "Shipment Edit",
  "fd_number": "FD No",
  "shipment_count": "Shipment Count",
  "invoice_amount": "Invoice Amount",
  "po_amount": "PO Amount",
  "gross_margin_in_usd": "Gross Margin",
  "gross_margin_percentage": "Gross Margin Percentage",
  "late_creation_count": "Late Creation Count",
  "late_creation": "Late Creation",
  "missing_milestone": "Missing Milestone",
  "missing_invoice": "Missing Invoice",
  "missing_voucher": "Missing PO",
  "missing_reference": "Missing Reference",
  "late_creation_percentage": "Late Creation Percentage",
  "missing_pick_up_count": "Missing Pickup",
  "missing_pod_count": "Missing POD",
  "missing_pod_file_count": "Missing POD File",
  "missing_pod_percentage": "Missing POD (%)",
  "missing_pick_up_percentage": "Missing Pickup (%)",
  "missing_pod_file_percentage": "Missing POD File (%)",
  "missing_milestone_percentage": "Missing Milestone Percentage",
  "missing_invoice_percentage": "Missing Invoice Percentage",
  "missing_voucher_percentage": "Missing PO Percentage",
  "please_choose_shipment_template": "Please Choose Shipment Template",
  "warning_bpo_percentage_too_low": "Please note that this BPO only has %{number}% left",
  "confirm_bpo_percentage_too_low": "Please note that this BPO spent balance is less than 0%",
  "logistic_handling_shgdt": "SHGDT",
  "mode_comprehensive": "Comprehensive",
  "table_header_service": "Service %{index}",
  "table_header_service_type": "Service Type %{index}",
  "table_header_service_remark": "Service Remark %{index}",
  "table_header_service_handling_station": "Service Handling Station %{index}",
  "table_header_service_truck_type": "Service Truck Type %{index}",
  "table_header_service_truck_size": "Service Truck Size %{index}",
  "table_header_service_flight": "Service Flight %{index}",
  "table_header_service_flight_to_port": "Service Truck To Port %{index}",
  "table_header_service_flight_from_port": "Service Truck From Port %{index}",
  "table_header_milestone": "Milestone %{index}",
  "table_header_milestone_sequence": "Milestone Sequence %{index}",
  "table_header_milestone_db_type": "Milestone DB Type %{index}",
  "table_header_milestone_description": "Milestone Description %{index}",
  "table_header_milestone_datetime": "Milestone Datetime %{index}",
  "table_header_milestone_tz": "Milestone Timezone %{index}",
  "table_header_document": "Document %{index}",
  "table_header_document_type": "Document Type %{index}",
  "table_header_document_name": "Document Name %{index}",
  "table_header_document_link": "Document Link %{index}",
  "are_you_sure_you_want_to_cancel_the_shipment": "Confirm delete shipment?",
  "shipment_number": "Shipment No",
  "origin_port": "Origin Station",
  "destination_port": "Destination Station",
  "draft": "Draft",
  "in_progress": "In Progress",
  "completed": "Completed",
  "cancelled": "Cancelled",
  "DRAFT": "Draft",
  "IN_PROGRESS": "In Progress",
  "COMPLETED": "Completed",
  "CANCELLED": "Cancelled",
  "is_dangerous": "Dangerous Goods",
  "created_at": "Created at",
  "bpo_number": "BPO No",
  "shipper_name": "Shipper Name",
  "shipper_state": "Shipper State",
  "shipper_country": "Shipper Country/Area",
  "consignee_name": "Consignee Name",
  "consignee_state": "Consignee State",
  "consignee_country": "Consignee Country/Area",
  "pickup_at": "Pickup Date",
  "pickup_at_tz": "Pickup Date Timezone",
  "pod_at": "Actual Delivery Date",
  "pod_at_tz": "Actual Delivery Date Timezone",
  "sla_at": "SLA",
  "sla_at_tz": "SLA Timezone",
  "delivery_number": "Delivery No",
  "are_you_sure_you_want_to_clone_the_shipment": "Confirm clone shipment?",
  "confirm_clone_shipment": "Confirm clone shipment?",
  "milestone_at": "Milestone Date",
  "status": "Status",
  "PACKING_LIST": "Packing list",
  "COMMERCIAL_INVOICE": "Commercial invoice",
  "PROOF_OF_DELIVERY": "Proof of delivery",
  "add_file": "Add File",
  "view_file": "View File",
  "upload_document": "Upload Document",
  "empty": "Empty",
  "gm_percentage": "GM %",
  "reference_number": "Reference No",
  "are_you_sure_you_want_to_delete_the_address_book": "Are you sure you want to delete the address ? ID: %{id}",
  "select_address_book": "Select address",
  "create_address_book": "Create address",
  "edit_address_book": "Edit address",
  "address_id": "Address ID",
  "missing_billing": "Missing Billing",
  "table_header_missing_billing": "Missing Invoice/PO",
  "larger_than_40_percentage": "More than 40%",
  "between_20_40_percentage": "20% ~ 40%",
  "between_0_20_percentage": "0% ~ 20%",
  "between_minus_20_0_percentage": "-20% ~ 0%",
  "between_minus_20_minus_40_percentage": "-40% ~ -20%",
  "less_than_minus_40_percentage": "Less than - 40%",
  "master_number": "MAWB No",
  "uom": "UOM",
  "factor": "Factor",
  "length": "Length",
  "width": "Width",
  "height": "Height",
  "cartons": "Cartons",
  "part_number": "Part No",
  "part_description": "Part Description",
  "quantity": "Quanity",
  "delivery_po_number": "Part PO No",
  "only_completed_shipment_can_be_clone": "Only completed shipment can be cloned",
  "shipment_number_display": "Shipment No: %{shipmentNumber}",
  "created_by": "Created By",
  "owner": "Owner",
  "please_enter_correct_shipment_number": "Please enter correct Shipment No",
  "delivery_method": "Delivey Method",
  "invalid_shipment_status": "Invalid Shipment Status: %{status}",
  "pod_signed_by": "POD Signed By",
  "pod_signed_by_copy_from": "Copy from assignee contact",
  "pickup_date_should_be_earlier_than_pod_date": "Pickup date should be earlier than actual delivery date",
  "pod_date_should_be_later_than_pickup_date": "Actual delivery date should be later than pickup date",
  "link_shipment": "Linked Shipment",
  "save_shipment_detail_and_change_milestone_warning": "Your address has been changed. This will also change the routing address and milestone time zone in “Milestone” Page. ",
  "foreign_shipment": "Foreign Shipment",
  "save_link_shipment_number_alarm_text": "%{shipmentNumber} will be automatically sync'd to the %{newLinkShipmentNo} every updates.",
  "update_link_shipment_number_alarm_text": "You have changed the linked shipment from %{linkedShipmentNumber} to %{newLinkShipmentNo}. %{shipmentNumber} will be automatically sync'd to the %{newLinkShipmentNo} every updates.",
  "error_code_bulk_update_cant_save": "Bulk Update Save Error",
  "error_code_fd_status_update_fail": "FD Status Update Fail",
  "total_pallets": "Total Pallets",
  "loose_cartons": "Loose Cartons",
  "chargeable_weight_unit": "Chargeable Weight Unit",
  "gross_weight_unit": "Gross Weight Unit",
  "chargable_weight": "Chargeable Weight",
  "description_of_good": "Description of Goods",
  "fd_status": "FD No Status",
  "reference": "Reference",
  "reference_type": "Reference Type",
  "reference_value": "Reference Value",
  "reference_type_value_di_values": "DI Reference",
  "reference_type_value_in_values": "IN Reference",
  "reference_type_value_po_values": "PO Reference",
  "reference_type_value_sr_values": "SR Reference",
  "reference_type_value_undefined_values": "Undefined Reference",
  "reference_type_value_other_values": "Other Reference",
  "reference_type_value_whse_values": "Warehouse Reference",
  "reference_type_value_cargo_loc_values": "Cargo Location Reference",
  "reference_type_value_carrier_values": "Carrier Reference",
  "reference_type_value_mawb_values": "HAWB / MBL Reference",
  "reference_type_value_hawb_values": "HAWB / HBL Reference",
  "reference_type_value_vsl_name_values": "Vessel Name Reference",
  "reference_type_value_asn_values": "ASN Reference",
  "reference_type_value_bol_ref_values": "BOL Reference",
  "reference_type_value_cntr_no_values": "Container No Reference",
  "reference_type_value_cntr_size_values": "Container Size Reference",
  "all_data": "All Data",
  "is_linked": "Is Linked?",
  "are_you_sure_you_want_to_activate_this_fd_no": "Are you sure you want to activate this FD No?",
  "are_you_sure_you_want_to_deactivate_this_fd_no": "Are you sure you want to deactivate this FD No?",
  "linked_shipmentment_number": "Linked Shipment No",
  "estimated_pickup_date": "Estimated Pickup Date",
  "stop_from_template": "Stop from template",
  "gross_margin_ready": "GM Ready",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "inactive_fd_no": "Inactive FD No: %{fd_number}",
  "copy_from_estimated_date": "Copy from estimated date",
  "the_shipment_is_invalid": "The shipment is invalid",
  "linked_shipment": "Linked Shipment",
  "late_creation_milestone_days": "Late creation is set to be %{milestone} + %{lateCreationDays} days",
  "you_should_keyin_missing_reference": "You should keyin missing reference, missing reference: [ %{missedReference} ]",
  "shipper_address_code": "Shipper Address Code",
  "consignee_address_code": "Consignee Address Code",
  "link_shipment_bulk_edit": "Link Shipment",
  "link_shipment_no_is_not_existed": "Linked Shipment No is not existed",
  "error_code_shipment_id_is_not_exist": "Shipment No Not Found",
  "error_code_shipment_template_id_is_not_exist": "Template No Not Found",
  "link_shipment_no_checked_is_duplicate": "%{shipment_number} is duplicated",
  "milestone_at_cannot_be_cleared": "Milestone Date cannot be cleared",
  "excel_template_file_is_not_found": "",
  "you_cannot_cancel_the_shipment_without_a_comment": "You cannot cancel the shipment without a comment",
  "cancel_shipment": "Cancel Shipment",
  "bol_number_list": "BOL No",
  "generate_bol_or_sli": "Generate BOL/SLI",
  "you_can_only_select_less_than_100_shipments_at_once": "You can only select less than 100 shipments at once",
  "bol_per_shipment": "BOL per shipment",
  "create_bol_per_shipment": "Create BOL per Shipment",
  "consolidated_bol": "Consolidated BOL",
  "create_consolidated_bol": "Create Consolidated BOL",
  "sli_per_shipment": "SLI per shipment",
  "actual_appointment_date": "Actual Appointment Date",
  "actual_appointment_date_tz": "Actual Appointment Date Timezone",
  "error_code_bol_number_is_not_exist": "BOL No Not Found",
  "error_code_cancel_bol_number_failed": "Cancel BOL failed",
  "choose_the_reference_to_show_on_the_document": "Choose the reference to show on the document",
  "shipment_udpate": "Shipment Udpate",
  "shipment_update": "Shipment Update",
  "delivery_instruction": "Delivery Instruction",
  "pickup_instruction": "Pickup Instruction",
  "invalid_service_provider_code": "Invalid Service Provider Code",
  "invalid_escort_value": "Invalid Escort Value",
  "invalid_security_level": "Invalid Security level",
  "invalid_service_level": "Invalid Service level",
  "invalid_delivery_method_value": "Invalid Delivery Method Value",
  "invalid_cod_amount_fee_terms_value": "Invalid COD Amount Fee Terms Value",
  "invalid_customer_check_acceptable_value": "Invalid Customer Check Acceptable Value",
  "invalid_freight_charge_terms_value": "Invalid Freight Charge Terms Value",
  "invalid_bol_type_value": "Invalid BOL Type Value",
  "required_pickup_date": "Required Pickup Date",
  "required_pickup_date_start": "Required Pickup Date Start",
  "required_pickup_date_end": "Required Pickup Date End",
  "required_pickup_must_be_a_range": "Required Pickup must be a range",
  "required_delivery_date": "Required Delivery Date",
  "required_delivery_date_start": "Required Delivery Date Start",
  "required_delivery_date_end": "Required Delivery Date End",
  "required_delivery_must_be_a_range": "Required Delivery must be a range",
  "carrier_is_not_approved": "Carrier is not approved",
  "bol_service_provider": "Service Provider",
  "generate_files": "Generate Files(%{file_length})",
  "check_and_generate_files": "Check and Generate Files(%{file_length})",
  "are_you_sure_you_want_to_cancel_the_bol": "Confirm cancel %{bol_number}?",
  "error_code_bol_generate_failed": "BOL Generate Failed : %{bol_number}",
  "error_code_bol_validate_failed": "BOL Validate Failed : %{bol_number}",
  "error_code_consoildated_bol_validate_consignee_id": "Different consignee address cannot be consolidated",
  "error_code_consoildated_bol_validate_shipper_id": "Different Shipper address cannot be consolidated",
  "carrier_pro_number": "Pro No",
  "error_code_bol_no_truck_serivce_type": "No truck service can be used for generating BOL.",
  "un_no_for_dg": "UN No for DG",
  "shipment_special_instruction": "Shipment special instruction",
  "per_shipment": "BOL per shipment",
  "address_and_city": "Address & City",
  "actual_delivery_date": "Actual Delivery Date",
  "escort": "Escort",
  "tracking_device_number": "Tracking Device No",
  "change_you_make_here_will_also_apply_to_all_the_shipments_in_this_bol_no": "Change you make here will also apply to all the shipments in this BOL No",
  "missing_project_manager": "Missing Project Manager",
  "missing_pr_number": "Missing PR No.",
  "missing_fd_manager": "Missing FD Manager",
  "missing_fd_number": "Missing FD No.",
  "timezone_not_found_please_select_a_timezone": "Timezone not found. Please select a timezone",
  "missing_ref": "Missing Ref.",
  "missing_inv": "Missing Inv.",
  "dg": "DG",
  "error_code_bol_shipment_status_already_completed": "Shipment No below have delivered already. Remove the shipment from current BOL No before generating a new BOL No.",
  "error_code_bol_shipment_already_binded": "Shipment No below have BOL No already. Remove the shipment from current BOL No before generating a new BOL No.",
  "dispatch_number_list": "Dispatch No",
  "error_code_create_shipment_invalid_pr": "This PR No is invalid. The PR No has been inactivated by project manager. Please select another PR No or reach out to your project manager.",
  "cannot_proceed_the_bol_generation_shipment_below_are_still_in_draft_status": "Cannot proceed the bol generation. Shipment below are still in \"DRAFT\" status.",
  "error_code_bol_shipment_not_found_or_already_unbinded": "This shipment have already been removed from this BOL or no longer existed.",
  "edit_bol_per_shipment": "Edit BOL per Shipment",
  "edit_consolidated_bol": "Edit Consolidated BOL",
  "add_back": "Add Back",
  "shipment_dn_have_not_been_assgined": "",
  "cancel_bol_no": "Cancel Bol No %{bol_number}",
  "error_code_bol_already_unbinded_all_shipment": "This BOL No has been canceled. There are no shipments under this BOL No. Please refresh the page.",
  "removed_successfully": "Removed Successfully",
  "shipment_dns_have_not_been_assigned": "%{shipment_length} shipment/DNs have not been assigned",
  "you_are_about_to_remove_this_bol_no_do_you_want_to_proceed": "You are about to remove this BOL No, do you want to proceed?",
  "billing_reference_type": "Billing Ref Type",
  "invalid_required_pickup_date_range": "Invalid Required Pickup Date Range",
  "invalid_required_delivery_date_range": "Invalid Required Delivery Date Range",
  "invalid_date_range": "Invalid Date Range",
  "package_qty": "Package Qty",
  "bol_type": "BOL Type",
  "this_bol_no_has_delivered_already_do_you_want_to_proceed_the_change": "This BOL No has delivered already. Do you want to proceed the change?",
  "bol_shipment_packages": "(%{packages} Packages)",
  "bol_ship_from": "Ship From *",
  "bol_ship_to": "Ship To *",
  "handling_pieces": "Package Qty",
  "qty_should_not_be_zero": "QTY should be greater than 0",
  "rate_should_not_be_zero": "Rate should be greater than 0",
  "error_code_bol_voucher_not_void": "Shipment below already have its voucher. Void the voucher first to proceed.",
  "error_duplicate_charge_item": "Duplicated charge item is not allowed",
  "timezone_invalid": "Please correct the country and city information",
  "timezone_invalid_title": "The address is invalid",
  "product_line": "Product Line",
  "invalid_product_line": "Invalid Product Line",
  "cainiao_download": "Download",
  "cainiao_download_milestone": "Milestone & CCL.",
  "cainiao_download_epam": "e-Pam Customs",
  "cainiao_download_billing": "Billing Report",
  "cainiao_upload": "Upload",
  "cainiao_upload_milestone": "Milestone & CCL.",
  "please_try_again_later": "Please trry again later",
  "customs_clearance_status": "Customs Clear Status",
  "customs_clearance_status_tooltip": "The update will effect all the courier number of this shipment",
  "cainiao_upload_causes_of_incomplete": "causes of incomplete",
  "cainiao_upload_causes_detail_reason_1": "If there is any error in a single record, this recode will not be processed.",
  "cainiao_upload_causes_detail_reason_2": "MAWB No., Bag ID, CN No. is not existed.",
  "cainiao_upload_causes_detail_reason_3": "Invalid date format, should be YYYY/MM/DD hh:mm",
  "cainiao_upload_causes_detail_reason_4": "Wrong date sequence, early → late: customs clear start, customs cleared, OFD, POD",
  "cainiao_upload_error_column_key": "CN#"
 },
 "bk_tmp": {
  "actual_delivery_date": "Delivered",
  "actual_pickup_at": "Picked Up",
  "address": "Address",
  "amount": "Spend",
  "arrived_cfs_at": "Arrived at Dest CFS",
  "ata": "Arrived (ATA)",
  "atd": "Departured (ATD)",
  "attachment": "Attachment",
  "billing_info": "Billing Information",
  "booking": "Booking",
  "booking_date": "Booked",
  "booking_number": "Booking No",
  "booking_number_name": "Booking Number",
  "booking_request_no": "Booking Request No.",
  "booking_request_number": "Request No.",
  "booking_status_canceled": "Canceled",
  "booking_status_completed": "Completed",
  "booking_status_draft": "Draft",
  "booking_status_in_progress": "In progress",
  "booking_status_submitted": "Submitted",
  "brokerag_and_cargo": "Add on Service",
  "calculated_by": "Calculated By ",
  "calculated_by_total": "Total Shipment",
  "calculated_by_unit": "Each Unit",
  "cancel_at_utc": "Booking Cancel Date",
  "cancel_by": "Booking Req. Cancel by",
  "cancel_check": "Are you sure want to cancel booking request?",
  "cancel_notify_check": "Are you sure you want to cancel? Morrison is processing your shipment and may incur some charges for cancellation, we will contact you as soon as possible to confirm the charges, until then your order status will remain in booking in progress / in progress.",
  "cancelled_at": "Cancelled",
  "cargo_insurance": "Cargo Insurance",
  "cargo_ready_date": "Cargo Ready Date",
  "charge_weight": "Charge Weight",
  "charge_weight_unit": "Charge Weight Unit",
  "chargeable_weight": "Charge Weight",
  "city": "City",
  "client_name": "Customer Name",
  "code": "Code",
  "column_setting": "Column Setting",
  "comments": "Comments",
  "commercial_invoice": "Commercial Invoice",
  "commercial_invoice_document": "Commercial Invoice Doc.",
  "commodity": "Commodity",
  "commodity_code": "Commodity Code",
  "commodity_desc": "Commodity Description",
  "complete_date": "Shipment Complete",
  "confirm_at_utc": "Booking Req. Confirm Date",
  "confirm_by": "Booking Req. Confirm by",
  "consignee": "Consignee",
  "consignee_address": "Consignee Address",
  "consignee_city": "Consignee City",
  "consignee_code": "Consignee Code",
  "consignee_country": "Consignee Country/Area",
  "consignee_info": "Cnee Addr.",
  "consignee_name": "Consignee Name",
  "consolidation_document": "Consolidation Document",
  "contact_name": "Contact Name",
  "container": "Container",
  "container_number": "Container No",
  "container_type": "Container Type",
  "count": "Count",
  "country": "Country/Area",
  "create_booking": "Create Booking",
  "created_at_utc": "Booking Req. Create Date",
  "created_by": "Booking Req. Create by",
  "cubic_meter": "Cubic Meter",
  "currency": "Currency",
  "custom_brokerage": "Custom Brokerage",
  "customer_code": "Customer Code",
  "customer_code_name": "Customer Code/Name",
  "customer_expected_pickup_at_utc": "Expected Pickup Date",
  "customer_expected_pod_at_utc": "Expected Delivery Date",
  "customer_name": "Customer Name",
  "customized_setting": "Customized Setting",
  "customized_table_header": "Customized Table Header",
  "customs_cleared_at": "Cleared Customs",
  "customs_released_at": "Customs Released",
  "dangerous_goods": "Dangerous goods",
  "dangerous_goods_declaration": "Dangerous Goods Declaration",
  "delivery": "Delivery",
  "delivery_container_number": "Delivery / Container No",
  "delivery_port": "Delivery Port",
  "department": "Department",
  "dest": "Destination Port",
  "destination": "Destination",
  "destination_port": "Dest Port",
  "destination_port_country": "Dest Country/Area",
  "destination_port_full": "Destination Port",
  "details": "Details",
  "di_no": "Delivery #",
  "di_number": "Di No",
  "diff_dest_area_notify": "The %{area} country you choose is different from the destination",
  "diff_origin_area_notify": "The %{area} country you choose is different from the origin port ",
  "dimension": "Dimension",
  "direct_shipment": "Direct Shipment",
  "direct_shipment_desc": "Direct Shipment，Tracking Number (提單號碼) 會透過 Booking confirmation 回覆您，在此之前您可用 Booking 號碼追蹤處理進度",
  "discharge": "Discharge Port",
  "do_number": "Document No",
  "doc_released_at": "Document Released",
  "document_slash_image": "Document / Image",
  "documents": "Documents",
  "download_qr_code": "Download QR Code",
  "effective_from": "Effective From",
  "effective_to": "Effective To",
  "email": "Email",
  "enter_setting_name": "Enter Group Name",
  "estimated_delivery": "Estimated Delivery",
  "estimated_pickup_at": "Picked Up",
  "eta": "ETA",
  "etd": "ETD",
  "event_time_local": "Event time (Local)",
  "excepton": "Exception",
  "expected_eta": "Target Delivery Date",
  "expected_eta_note": "MEC會盡力達到您的期望，但不保證一定可滿足",
  "expected_pickup_date": "Expected Pickup Date",
  "export": "Export",
  "fcl": "FCL",
  "fcr_shipment": "FCR Shipment",
  "fd_number": "FD Number",
  "flight": "Flight",
  "flight_from_port": "Flight From Port",
  "flight_number": "Flight No",
  "flight_to_port": "Flight To Port",
  "freight_payment_term": "Freight Payment Term",
  "freight_term": "Freight Term",
  "from": "From",
  "general": "General",
  "goods_description": "Goods Description",
  "goods_info": "Goods information",
  "goods_type": "Goods Type",
  "gross_weight": "Gross Weight",
  "gross_weight_total": "Total Gross Weight",
  "gross_weight_unit": "Gross Weight Unit",
  "group": "Group",
  "group_by": "Group By",
  "handling_pieces": "Pieces",
  "hawb_doc": "HAWB Doc.",
  "height": "Height",
  "housebill_not_found": "The Housebill does not found",
  "housebill_number": "Housebill No",
  "id": "Id",
  "if_you_can_not_find_cutomer_name_in_list_just_choose_general": "If you can not find customer name in list , just choose 'General'",
  "incoterm": "Incoterm",
  "insurance_value": "Comercial invoice Value",
  "insurance_value_currency": "Comercial invoice currency",
  "invoice_number": "Invoice No",
  "is_custom_service": "Custom service ",
  "is_dangerous_goods": "Dangerous Good",
  "is_doc_ready": "Doc. Ready",
  "is_effective": "Is Effective",
  "is_hazardous": "DG",
  "is_insurence": "Insurance",
  "is_origin_cert": "Certificate of Origin",
  "issued_date": "Issue Date",
  "last_status": "Last Status",
  "lcl": "LCL",
  "length": "Length",
  "load": "Load Port",
  "loads": "Load",
  "local": "Local",
  "local_time": "Local Time",
  "logistic_handling": "Logistic Handling",
  "loose_goods": "Loose Goods",
  "m": "M",
  "mawb": "MAWB",
  "mawb_doc": "MAWB Doc.",
  "mec_invoice_doc": "MEC Invoice",
  "method": "Ship Method",
  "milestone": "Milestone",
  "mode": "Ship Mode",
  "morrison_register_email_only": "Morrison One registered email only",
  "name": "Name",
  "need_carge": "Do you need cargo insurance add on? ",
  "need_carge_no": "NO - I've either got insurance or take full responsibility for any lost or damaged cargo.",
  "need_carge_yes": "Yes - I need cargo insurance.",
  "no_download_url": "No DownloadUrl !",
  "no_need_direct_shipment": "No, I am fine with HAWB/HBL Number",
  "no_shipment_detail": "No Shipment Detail !",
  "no_shipment_document": "No Shipment Document !",
  "no_shipment_tracking": "No Shipment Tracking !",
  "not_sure_right_now": "I'm not sure right now",
  "notification_name": "Notification Name",
  "notification_settings": "Notification Settings",
  "notification_settings_hint": "This setting will active notification for every new shipment",
  "notify": "Notify",
  "notify_group": "Notify Group",
  "notify_info": "Notify Addr.",
  "notify_name": "Notify Name",
  "notify_party": "Notify Party",
  "notify_when_shipment": "Notify When Shipment",
  "number": "Number",
  "ofd": "OFD",
  "option_milstone_ata": "Actual Arrival",
  "option_milstone_booking_date": "Booking Created",
  "option_milstone_completed_at": "Shipment Completed",
  "option_milstone_etd": "Estimated Departure",
  "order": "Order",
  "origin": "Origin",
  "origin_cert_memo": "Certificate of origin memo",
  "origin_port": "Origin Port",
  "origin_port_country": "Origin Country/Area",
  "other_docs": "Other Docs.",
  "other_reference_file": "Other reference file",
  "others_payment_term": "Others Payment Term",
  "others_term": "Others Payment",
  "out_for_delivery_date": "Out For Delivery",
  "overwrite_confirm": "確認是否要覆蓋....... 待BA補字",
  "package_quantity": "Package Qty",
  "packing_list": "Packing List",
  "pallet": "Pallet",
  "parent_id": "Parent Code",
  "part_description": "Part Description",
  "part_number": "Part No",
  "parts": "Parts",
  "per_height": "Height (CM)",
  "per_length": "Length (CM)",
  "per_weight": "Width (CM)",
  "pick_up": "Pick Up",
  "pickup": "Pickup",
  "pickup_info": "Pickup Addr.",
  "pickup_name": "Pickup Name",
  "place_of_Receipt": "Place of Receipt",
  "po_item_no": "PO Item No",
  "po_no": "PO No",
  "po_number": "PO No",
  "pod": "POD Time",
  "pod_file": "POD File",
  "port_of_delivery": "Port of Delivery",
  "port_of_discharge": "Port of Discharge",
  "port_of_landing": "Port of Loading",
  "port_of_receipt": "Port of Receipt",
  "pre_assign": "Pre-assign HAWB/HBL Number",
  "pre_assign_desc": "Please obtain a pre-assigned number from MEC first and do not use the same number repeatedly.",
  "pre_assign_fill": "I have Morrison provide.",
  "progress": "Progress",
  "project": "Project",
  "proof_of_delivery": "Proof of Delivery",
  "proof_of_delivery_other_docs": "Proof of Delivery Other Docs.",
  "qr_code_tracking": "QR Code Tracking",
  "qty_ea": "Qty(EA)",
  "qty_of_outers": "Uty of Outers",
  "quantity": "Quantity",
  "query": "Query",
  "quotation": "Quotation",
  "quotation_number": "Quotation Number",
  "quotation_status": "Quotation Status",
  "quotation_type": "Quotation Type",
  "range_in": "輸入範圍在 %{start}~%{end}",
  "receipt_port": "Receipt Port",
  "reference": "Reference",
  "reference_4color_bt": "FourColor - Booking Title",
  "reference_apple_dri": "APPLE DRI",
  "reference_apple_fdmg": "FD MANAGER",
  "reference_apple_fdno": "FD No",
  "reference_apple_prno": "APPLE PR No",
  "reference_book_title": "Book Title",
  "reference_box": "BOX No",
  "reference_cc": "Cost Centre Code",
  "reference_cost_cnt": "Cost Center Number",
  "reference_cra": "Cannister No",
  "reference_dc": "Declaration No for AI and AE",
  "reference_di": "Delivery ID",
  "reference_gl_accunt": "GL Account No",
  "reference_in": "Invoice No",
  "reference_it": "Item No",
  "reference_load_date": "Ex - Factory Date for Sonos Report",
  "reference_lu": "LU No",
  "reference_number": "Reference No",
  "reference_or": "Order No",
  "reference_os_ossg_mm": "UMC Department Code",
  "reference_ot": "Other",
  "reference_pj": "Project Name",
  "reference_pl": "P / L #",
  "reference_pn": "Part No",
  "reference_po": "Purchase Order No",
  "reference_rma": "Returned Material Authorization",
  "reference_s_n": "Serial No",
  "reference_s_o": "S / O No + Ship",
  "reference_sd": "SDAF No",
  "reference_sgce1": "UMC Department Code",
  "reference_sgcs3": "UMC Department Code",
  "reference_si": "SID No",
  "reference_smp_cntct": "Contact / Purchaser",
  "reference_smp_po": "PO No",
  "reference_smp_psp": "PSP - Element No",
  "reference_smp_purno": "Purchase Requisition No",
  "reference_sr": "ShipRef No",
  "reference_st": "Style No",
  "reference_type": "TYPE",
  "reference_umc": "UMC Ref Type",
  "request_brokerage": "Do you need customs brokerage arrangement from MEC? ",
  "request_brokerage_no": "NO - I have a customs broker.",
  "request_brokerage_yes": "Yes - I need customs brokerage.",
  "requester": "Requester",
  "result": "Result",
  "route": "Route",
  "sales_name": "Sales Name",
  "same_as_consignee": "same as consignee",
  "same_as_consignee_check_label": "Delivery as same as Consignee",
  "same_as_notify": "same as notify",
  "same_as_notify_check_label": "Notify 3rd party when shipment arrival at destination",
  "same_as_shipper": "same as shipper",
  "same_as_shipper_check_label": "Pickup as same as Shipper",
  "save_to_address": "Save to my address book",
  "secure_sales_no": "Secure Sales No.",
  "secure_station": "Secure Station",
  "select_customer_code": "Select Customer Code",
  "select_quotation_number": "Select Quotation Number",
  "send_mail_notification_to": "Send Notification to",
  "send_me_pod_file": "Send me POD file",
  "send_notification_from": "Send Notification From",
  "send_sms_notification_to": "Send SMS Notification to",
  "sequence": "Seq",
  "service": "Service",
  "service_info": "Service Info",
  "service_level": "Service Level",
  "service_remark": "Service Remark",
  "service_type": "Service Type",
  "ship": "",
  "ship_to": "Ship To",
  "ship_type": "Ship Type",
  "shipment_by_to": "Customer code",
  "shipment_delay": "Shipment Delay",
  "shipment_details": "Shipment Details",
  "shipment_mode": "Mode",
  "shipment_mode_air": "Air",
  "shipment_mode_sea": "Sea",
  "shipment_number": "HAWB / HBL",
  "shipment_reference_no": "Reference",
  "shipment_require": "Bill Of Lading Type",
  "shipment_require_option_1": "General Shipment (HAWB/HBL)",
  "shipment_require_option_2": "Direct Shipment (MAWB/MBL)",
  "shipment_require_option_3": "Pre-assign HAWB/HBL provide by Morrison",
  "shipment_to": "Shipment To",
  "shipment_to_info": "Shipto Addr.",
  "shipment_to_name": "Shipto Name",
  "shipper": "Shipper",
  "shipper_address": "Shipper Address",
  "shipper_city": "Shipper City",
  "shipper_code": "Shipper Code",
  "shipper_country": "Shipper Country/Area",
  "shipper_info": "Shipper Addr.",
  "shipper_name": "Shipper Name",
  "shipping_instruction": "Shipping Instruction",
  "shipping_marks": "Shipping Marks",
  "shipping_mode": "Shipp Mode",
  "sign_by": "Sign by",
  "special_instruction": "Special instruction",
  "state": "State",
  "status": "Status",
  "step_1_Service": "STEP 1: Service",
  "step_2_Address": "STEP 2: Address",
  "step_3_Detail": "STEP 3: Detail",
  "step_4_Doc": "STEP 4: Document",
  "submit_at": "Submit At",
  "submit_by": "Submit By",
  "submit_question": "Confirm if you want to send a booking request.",
  "submitted_at": "Submit At",
  "submitted_at_utc": "Booking Rea. Submit Date",
  "submitted_by": "Booking Req. Submit by",
  "subtotal": "Subtotal",
  "target_delivery_date": "Target Delivery Date",
  "terms_confirm": "I confirm that this is a commercial shipment and all details are correct. I accept the %{a1}   terms of the booking %{a2}",
  "terms_confirm_check": "Please check \"I confirm that this is a commercial shipment and all details are correct. I accept the terms of the booking.\" before you submit booking request.",
  "teu": "Teu",
  "to": "To",
  "total_of_units": "Total of Outers",
  "total_packages": "Total Packages",
  "total_pallets": "Pallet Qty",
  "total_pieces": "Total Pieces",
  "total_spend": "Total Spend",
  "total_weight": "Total Weight",
  "total_weight_kg": "Total G.Weight (Kg)",
  "trace_other_number": "Tracking other Number",
  "transporation": "Transportation",
  "transporation_mode": "Transporation Mode",
  "truck_size": "Truck Size",
  "truck_type": "Truck Type",
  "type": "Type",
  "unit_weight": "Gross Weight (Per Outer)",
  "uom": "UOM",
  "upload_by": "Upload By",
  "upload_date_time": "Upload Date / Time",
  "upload_doc": "Shipping Documents",
  "uppercase_all": "ALL",
  "uppercase_canceled": "CANCELED",
  "uppercase_completed": "COMPLETED",
  "uppercase_details": "DETAILS",
  "uppercase_documents": "DOCUMENTS",
  "uppercase_in_progress": "IN PROGRESS",
  "uppercase_morrison_one": "MORRISON ONE",
  "uppercase_track": "TRACK",
  "uppercase_tracking": "TRACKING",
  "value": "value",
  "vessel": "Vessel",
  "vessel_name": "Vessel Name",
  "vip_name": "Customer",
  "volume_weight": "Volume Weight",
  "volume_weight_total": "Total Volume",
  "volumetric_weight": "Volume Weight",
  "voyage": "Voyage",
  "weight_kg": "G.Weight (Kg)",
  "width": "Width",
  "yes_no_0": "N",
  "yes_no_1": "Y",
  "your_shipment_encounters": "Shipment Encounter",
  "zip_code": "Zip Code",
  "undefined": "",
  "shipment_type": "Shipment Type",
  "shipment_desc": "Shipment Desc",
  "service_level_desc": "Service Level Desc",
  "source_db": "Source DB",
  "is_trade_billing": "Is Trade Billing",
  "origin_port_code": "Origin Port Code",
  "destination_port_code": "Destination Port Code",
  "shipment": "Shipment",
  "company_id_error": "Company ID error"
 },
 "packing_station": {
  "l1_creation": "L1 Creation",
  "create_l1": "Create L1",
  "create_l2": "Create L2",
  "create_l3": "Create L3",
  "create_spt": "Create SPT",
  "create_load": "Create Load",
  "l1": "L1",
  "qualified_order": "Qualified Order",
  "verification": "Verification",
  "csm_no": "CSM No",
  "location": "Location",
  "all_special_instruction": "All Special Instruction",
  "create_success": "Create Success",
  "ship_to": "Ship to",
  "ship_to_add": "Ship to add",
  "required": "Required",
  "something_wrong": "Something Wrong !",
  "site": "Site",
  "client": "Client",
  "pack_level": "Pack Level",
  "status": "Status",
  "reference": "Reference",
  "refresh": "Refresh",
  "new": "New",
  "check_your_rule": "Check Your Rule",
  "please_check_ship_to_code_and_pack_level_and_pack_code_have_not_the_same": "Please Check Ship to code、Pack level and Pack code have not the same",
  "status_have_to_confirmed_or_pack_level_have_to_csm": "Status have to Confirmed or Pack Level have to CSM",
  "enter_job_information": "Enter Job Information",
  "do_you_want_to_delete_the_jobs": "Do you want to delete the jobs ? ",
  "ok": "Ok",
  "cancel": "Cancel",
  "delete_success": "Delete Success",
  "do_you_want_to_confirm_the_jobs": "Do you want to confirm the Jobs ?",
  "confirm_success": "Confirm Success",
  "status_have_completed": "Status have Completed",
  "ship_to_code": "Ship To Code",
  "next_step": "Next Step",
  "print_success": "Print Success",
  "close": "Close",
  "job": "Job",
  "ad_hoc_charges": "Ad HOC Charges",
  "save_success": "Save Success",
  "charge_name_is_not_unique": "Charge Name is not unique",
  "name": "Name",
  "description": "Description",
  "remark": "Remark",
  "quantity": "Quantity",
  "cost": "Cost",
  "quotation": "Quotation",
  "create": "Create",
  "update": "Update",
  "do_you_want_to_delete_this_ad_hoc_charges": "Do you want to delete the Ad HOC Charges ?",
  "address": "Address",
  "carrier": "Carrier",
  "special_instruction": "Special Instruction",
  "attachment_list": "Attachment List",
  "update_success": "Update Success",
  "ship_to_info": "Ship To Info",
  "shipper_info": "Shipper Info",
  "ship_from_info": "Ship From Info",
  "attention": "Attention",
  "phone": "Phone",
  "address1": "Address1",
  "address2": "Address2",
  "address3": "Address3",
  "city": "City",
  "state": "State",
  "postal_code": "Postal Code",
  "country": "Country",
  "carrier_code": "Carrier Code",
  "service_code": "Service Code",
  "user_account": "User Account",
  "line_no": "Line No",
  "pack_no": "Pack No",
  "tracking_no": "Tracking No",
  "order_number": "Order Number",
  "pack_job": "Pack Job",
  "load_job": "Load Job",
  "batching_new": "Batching New",
  "job_number": "Job Number",
  "pack_rule": "Pack Rule",
  "load_number": "Load Number",
  "undefined": "",
  "configuration": "Configuration",
  "no": "No",
  "yes": "Yes",
  "consignment_number": "Consignment Number",
  "item_qty": "Item Qty",
  "default_carrier": "Default carrier",
  "empty": "Empty",
  "postcode": "Postcode",
  "sku": "SKU",
  "picking_sn": "Picking SN",
  "packing_sn": "Packing SN",
  "pick_qty": "Pick Qty",
  "total_qty": "Total Qty"
 },
 "devops": {
  "environment": "Environment",
  "branch": "Branch",
  "version": "Version",
  "commit_hash": "Commit Hash",
  "repository": "Repository",
  "created_at": "Created At",
  "created_by": "Created By",
  "ticket_list": "Ticket List",
  "issue_id": "Issue Id",
  "issue_key": "Issue Key",
  "issue_link": "Issue Link",
  "summary": "Summary"
 },
 "shpt": {
  "actual_appointment_date": "Actual Appt Date",
  "actual_arrive_first_us_ca_dest": "Actual Arrive First US/CA Dest",
  "actual_arrive_transshipment_port": "Actual Arrive Transshipment Port",
  "actual_delivery_date": "Actual Delivery Date",
  "actual_depart_port_at": "Actual Departed Port of Export Date",
  "actual_depart_transshipment_port": "Actual Depart Transshipment Port",
  "actual_departed_port_of_export": "Actual Departed Port Of Export",
  "actual_pickup_date": "Actual Pickup Up",
  "address": "Address",
  "address_in_local_language": "Address in local language",
  "address_line_one": "Address Line 1",
  "address_line_two": "Address Line 2",
  "address_will_be_updated_which_shipments_not_be_assigned_to_bol": "Address will be updated which shipments not be assigned to BOL",
  "adjust_column": "Adjust Column",
  "agent_id": "Agent ID",
  "agent_role": "H Agent",
  "air_ride": "Air Ride",
  "airport": "Airport",
  "alert": "Alert",
  "amat_edi_alert": "This HAWB has not yet linked to an AMAT ID %{id}. Please verify your AMAT ID and correct it.",
  "amat_id": "AMAT ID",
  "apply_to_all_shipments": "Apply to All Shipments",
  "appointment_confirmed_date": "Appt Confirmed Date",
  "appointment_confirmed_number": "Appt Confirmation No",
  "appointment_made_by": "Appt Made By",
  "appointment_made_date": "Appt Made Date",
  "appointment_made_method": "Appt Made Method",
  "appointment_made_with": "Appt Made With",
  "appointment_number": "Appt No",
  "arrival_city": "Arrival city",
  "arrival_terminal_at": "Arrival at Origin Terminal Date",
  "arrived_at_cfs": "Arrived at CFS",
  "arrived_ata": "Arrived (ATA)",
  "arrived_cfs": "Arrived at Destination CFS",
  "asn_number": "ASN Number",
  "at_risk": "At Risk",
  "ata": "ATA",
  "ata_first_amer": "Actual Arrive First US Destination Date",
  "ata_first_amer_port": "First US Destination",
  "ata_route_1_port": "Transshipment Port",
  "ata_to_port_arrival": "ATA to Port of Arrival",
  "atd": "Departured (ATD)",
  "attachment": "Attachment",
  "aw_gw": "AW / GW",
  "bol_group_rule": "BOL Grouping Rule",
  "bol_no": "BOL No",
  "booked": "Booked",
  "booking_date": "Booking Date",
  "booking_number": "Booking No",
  "brokerage": "Brokerage",
  "cancelled_at": "Cancelled",
  "cargo_receipt": "Cargo Receipt",
  "cargo_receipt_date": "Cargo Receipt Date",
  "carrier_released": "Carrier Released",
  "cartons": "Cartons",
  "category": "Category",
  "cbm": "CBM",
  "chargeable_weight": "Charge Weight",
  "checked_ata_and_eta": "Updated ATA：%{ata} is after ETA：%{eta}",
  "checked_atd_and_etd": "Updated ATD：%{atd} is after ETD：%{etd}",
  "checked_pod_and_edd": "Updated POD：%{pod} is after EDD：%{edd}",
  "city": "City",
  "client_id": "Client ID",
  "cod_amount": "COD Amount",
  "code": "Code",
  "comment": "Comment",
  "comment_here": "Comment Here",
  "commercial_Inv": "Commercial Inv #",
  "commercial_invoice_number": "Commercial Invoice#",
  "commercial_invoice_numbers": "Commercial Invoice No",
  "commercial_Invoice_value": "Commercial Invoice Value",
  "completed_date": "Completed Date",
  "confirm_destination_documents": "Confirm Destination Documents",
  "confirm_origin_documents": "Confirm Origin Documents",
  "confirmed": "Confirmed",
  "consignee": "Consignee",
  "consignee_address": "Consignee Address",
  "consignee_city": "Consignee City Code",
  "consignee_city_name": "Consignee City",
  "consignee_contact": "Consignee Contact",
  "consignee_country": "Consignee Country/Area",
  "consignee_email": "Consignee Email",
  "consignee_id": "Consignee ID",
  "consignee_info": "Consignee Info",
  "consignee_name": "Consignee Name",
  "consignee_state": "Consignee State",
  "consignee_tax_id": "Consignee's Tax ID",
  "consignee_telephone": "Consignee Telephone",
  "consignee_zipcode": "Consignee Zip",
  "consol_closed_at": "Console Close",
  "contact": "Contact",
  "container_loading_type": "Container Loading Type",
  "container_mode": "Container Mode",
  "container_seal_number": "Container Seal No",
  "container_type": "Container Type",
  "container_type_description": "Container Type Description",
  "copy_edd_from_export": "Copy EDD From Export",
  "country": "Country/Area",
  "country_of_origin": "Country of Origin",
  "create_date_utc": "Create Date (UTC)",
  "created_by": "Created By",
  "cubic_meter": "CBM",
  "currency": "Currency",
  "custom_cleared": "Custom Cleared",
  "customer": "VIP Name",
  "customer_code": "Customer Code",
  "customer_code_name": "Customer Code/Name",
  "customer_special_requirement_sla": "Customer Special Requirement / SLA",
  "customs_cleared": "Customs Cleared",
  "customs_released": "Customs Released",
  "customs_value": "Customs Value",
  "customized_shipment_data": "Customized shipment data",
  "customized_shipment_data_select_message": "Please select the report name.",
  "customized_shipment_data_select_hint": "If you couldn’t see the list, please contact Mec Onehelp %{mail} for further permission",
  "cw": "CW",
  "dangerous_good": "Dangerous Goods",
  "delete": "Delete",
  "delivery": "Delivery",
  "delivery_chargeable_weight": "Delivery Charge Weight",
  "delivery_cubic_meter": "Delivery CBM",
  "delivery_gross_weight": "Delivery Gross Weight",
  "delivery_loose_cartons": "Delivery Loose Carton Qty",
  "delivery_method": "Delivery Method",
  "delivery_no": "Delivery No",
  "delivery_package_quantity": "Delivery Package Qty",
  "delivery_port": "Port of Delivery",
  "delivery_port_city": "Port of Delivery City",
  "delivery_port_country": "Port of Delivery Country",
  "delivery_total_cartons": "Delivery Carton Qty",
  "delivery_total_pallets": "Delivery Pallet Qty",
  "delivery_total_pieces": "Delivery Piece Qty",
  "delivery_volumetric_weight": "Delivery Volume Weight",
  "dep": "Dep",
  "department": "Department",
  "department_number": "Department #",
  "departure_city": "Departure city",
  "departure_from_shipper_warehouse": "Departure from Shipper Warehouse",
  "description_of_goods": "Description of Goods",
  "dest_custom_released": "Dest Custom Released",
  "dest_customs_released": "Dest Customs Released",
  "destination": "Destination",
  "destination_city": "Destination city",
  "destination_customs_started_at": "Destination Customs Started Date",
  "destination_port": "Dest",
  "destination_port_city": "Dest Port City",
  "destination_port_country": "Dest Port Country",
  "destination_region": "Dest Region",
  "dim": "DIM",
  "dispatch_no": "Dispatch No",
  "dispatch_number": "Dispatch number",
  "do_you_want_to_raise_an_irregularity_to_alert_the_customer": "Do you want to raise an irregularity to alert the customer ?",
  "doc_received_at": "Doc Received Date",
  "doc_released": "Doc Released",
  "doc_submitted_at": "Doc Submitted Date",
  "doc_to_broker_at": "Doc To Broker Date",
  "document_released": "Document Released",
  "edi_send_date_time_utc": "EDI Sent Date & Time",
  "edi_status_code": "EDI Status Code",
  "estimated_delivery_date": "Estimated Delivery Date",
  "estimated_delivery_date_sla": "Estimated Delivery Date (SLA)",
  "estimated_pickup_at": "Estimated Pick-up Date",
  "eta": "Last ETA",
  "etd": "First ETD",
  "event_date_and_time_local": "Event Datetime (Local)",
  "event_description": "Event Description",
  "event_location": "Event Location",
  "event_routing": "Event & Routing",
  "exception": "Exception",
  "exceptions": "exceptions",
  "export": "Export",
  "export_milestones": "Export",
  "factor": "Factor",
  "fcr": "FCR",
  "fish_and_wildlife": "Fish & Wildlife",
  "freight_term": "Freight Term",
  "from": "From",
  "from_tms": "From TMS",
  "gross_weight": "Gross Weight",
  "handed_to_carrier": "Handed to Carrier",
  "handling_pieces": "HAWB Pcs",
  "hawb": "HAWB",
  "hawb_detail": "HAWB Detail",
  "hazardous": "Hazardous",
  "height": "Height",
  "hts_code": "HS Code",
  "id": "ID",
  "id_not_found": "ID not found",
  "import": "Import",
  "import_milestones": "Import",
  "incoterm": "Inco Term",
  "input_more_than_the_next_seven_days_is_not_allowed": "Input more than the next 7 days is not allowed.",
  "instruction": "Instruction",
  "internal_transaction_number": "ITN#",
  "international_duty_taxes": "International Duites & Taxes",
  "is_customs_clearance": "Customs clearance",
  "item": "Item #",
  "item_quantity": "Number of Items",
  "itn_mrn": "ITN # / MRN #",
  "last_consol_closed_at": "Last Consol Closed Date",
  "length": "Length",
  "line_item_class_number": "Class Number",
  "logistic_handling": "Logistic Handling",
  "logistic_number": "LOG No",
  "loose_carton_quantity": "Loose carton quantity",
  "loose_cartons": "Loose Ctn Qty",
  "lot_number": "Lot No",
  "master_agent_id": "Master Agent ID",
  "master_agent_role": "Consol Agent",
  "master_number": "MAWB No",
  "max_length_two": "Max Length Two",
  "milestone": "Milestone",
  "milestone_utc": "Milestone (UTC)",
  "milestone_status": "Milestone Status",
  "morrison_one_customer": "Morrison One Customer",
  "name": "Name",
  "need_resubmission": "Need Resubmission",
  "no": "No",
  "no_select_shipment_list": "No select shipment List !",
  "notify_party_address": "Notify Party Address",
  "notify_party_city": "Notify Party City Code",
  "notify_party_city_name": "Notify Party City",
  "notify_party_country": "Notify Party Country/Area",
  "notify_party_id": "Notify Party ID",
  "notify_party_name": "Notify Party Name",
  "notify_party_state": "Notify Party State",
  "notify_party_zipcode": "Notify Party Zipcode",
  "npi": "NPI",
  "number": "Number",
  "ofd_departed_from_cfs": "OFD (Departed from CFS)",
  "off_load_at": "Offload",
  "off_loaded_at": "Off Loaded Date",
  "office": "Office",
  "onboard_date": "Onboard Date",
  "order": "Order #",
  "order_number": "Order Number",
  "orig_port_of_loading": "Orig (Port of Loading)",
  "origin": "Origin",
  "origin_customs_broker": "Origin customs broker",
  "origin_customs_cleared": "Origin Customs Cleared",
  "origin_pickup_vechicle_registration": "Origin pickup vechicle registration",
  "origin_pickup_vehicle_driver": "Origin pickup vehicle driver",
  "hawb_hbl": "HAWB / HBL",
  "orig": "Orig",
  "dest": "Dest",
  "origin_status": "Origin Status",
  "mawb_mbl": "MAWB / MBL",
  "orig_mawb_mbl": "Orig (MAWB / MBL)",
  "dest_mawb_mbl": "Dest (MAWB / MBL)",
  "dest_status": "Dest Status",
  "dn_sid": "DN / SID",
  "doc_status": "Doc Status",
  "origin_pickup_vehicle_registration_plate": "Origin Pickup Vehicle Plate",
  "origin_port": "Origin",
  "origin_port_city": "Orig Port City",
  "origin_port_country": "Orig Port Country",
  "out_for_delivery_date": "Out for Delivery",
  "out_of_gauge": "Out of Gauge",
  "pack": "Pack #",
  "package": "Package",
  "package_detail": "Package Details",
  "package_quantity": "Package Qty",
  "packing_info": "Packing Info",
  "part_description": "Part Description",
  "part_number": "Part Number",
  "payment_method": "Payment Method",
  "payment_to_carrier_by": "Payment to Carrier By",
  "pick_up_date": "Actual Pick-up Date",
  "pickup_address": "Pickup address",
  "pickup_contactor": "Pickup contactor",
  "pickup_info": "Pickup Info",
  "pickup_telephone": "Pickup telphone",
  "pickup_up": "Picked up",
  "po_item_number": "PO Item No",
  "po_number": "PO No",
  "pod_delivery_date": "Actual Delivery Date",
  "pod_doc_checked": "POD Document Checked",
  "pod_doc_checked_messages": "You have reviewed the document. Our customer will be able to see the document.",
  "pod_doc_unchecked_messages": "You have unchecked the document. Our customer will not be able to see the document.",
  "pod_name": "Signed by",
  "pod_signed_by": "Signed by",
  "port_of_receipt": "Port of Receipt",
  "port_of_receipt_city": "Port of Receipt City",
  "port_of_receipt_country": "Port of Receipt Country",
  "preview": "Preview",
  "product_characteristic": "Product Characteristic",
  "product_type": "Product Type",
  "project_code": "Project Code",
  "prolink_export_created_by": "Export Created By",
  "prolink_import_created_by": "Import Created By",
  "proof_of_delivery_location": "POD Location",
  "quantity": "Quantity",
  "quotation_number": "Quotation No",
  "ready_for_pickup_at": "Ready For Pickup Date",
  "reason": "Reason",
  "received_from_airline": "Received from airline",
  "reference": "Reference",
  "reference_no": "Reference No",
  "reference_number": "AMAT Reference#",
  "reference_type": "Reference Type",
  "reference_value_di": "DI Reference",
  "reference_value_in": "IN Reference",
  "reference_value_po": "PO Reference",
  "reference_value_sr": "SR Reference",
  "reference_value_undefined": "Undefined Reference",
  "release_location": "Release Location",
  "release_to": "Release To",
  "release_type": "Release Type",
  "requested_sailing_date": "Requested Sailing Date",
  "required": "Required",
  "required_arrival_time": "Required arrival time",
  "required_pick_up_time": "Required pick up time",
  "routing_atd": "%{prefix} ATD",
  "routing_console_sequence": "%{prefix} Sequence",
  "routing_eta": "%{prefix} ETA",
  "routing_etd": "%{prefix} ETD",
  "routing_from_port": "%{prefix} From",
  "routing_master_number": "%{prefix} MAWB / MBL",
  "routing_number": "%{prefix} Flight / Vessel No",
  "routing_to_port": "%{prefix} To",
  "routing_vessel_name": "%{prefix} Vessel Name",
  "routing_voyage_name": "%{prefix} Voyage",
  "sea_destination_port": "Dest (Port of Discharge)",
  "security_level": "Security Level",
  "security_level_handling": "Security Level Handling",
  "select_hawbs_to_apply": "Select HAWBs to apply",
  "select_milestones_to_update": "Select Milestones to update",
  "sequence": "Seq",
  "service_level": "Svc Lvl",
  "service_type": "Svc Type",
  "ship_date": "Ship Date",
  "shipment_details": "Shipment Details",
  "shipment_mode": "Ship Mode",
  "shipment_number": "Shipment No",
  "shipment_status": "Shipment Status",
  "shipment_type": "Shipment Type",
  "shipper": "Shipper",
  "shipper_address": "Shipper Address",
  "shipper_city": "Shipper City Code",
  "shipper_city_name": "Shipper City",
  "shipper_contact": "Shipper Contact",
  "shipper_country": "Shipper Country/Area",
  "shipper_email": "Shipper Email",
  "shipper_id": "Shipper ID",
  "shipper_name": "Shipper Name",
  "shipper_state": "Shipper State",
  "shipper_tax_id": "Shipper's Tax ID",
  "shipper_telephone": "Shipper Telephone",
  "shipper_zipcode": "Shipper Zipcode",
  "shipping_instructions": "Shipping Instructions",
  "signed_by": "Signed By",
  "sla": "SLA",
  "something_wrong": "Something Wrong !",
  "special_delivery_instruction": "Special Delivery Instruction",
  "special_instruction": "Special Instruction",
  "split_shipment": "Split HAWB",
  "state": "State",
  "status": "Status",
  "submitted_to_customs": "Submitted to Customs",
  "tea": "TEA #",
  "tea_number": "TEA #",
  "tel": "Tel",
  "this_vendor_doesnt_have_amat_id": "This vendor doesn't have AMAT ID",
  "this_vendor_doesnt_have_it": "This vendor doesn't have it",
  "tianma_edi_alert": "This HAWB has not yet linked to an Tianma ID %{id}. Please verify your Tianma ID and correct it.",
  "tianma_id": "Tianma ID",
  "to": "To",
  "total_carton": "Total carton",
  "total_cartons": "Total Cartons",
  "total_gross_weight_kg": "Total gross weight(KG)",
  "total_net_weight_kg": "Total net weight(KG)",
  "total_packages": "Total Packages",
  "total_pallet": "Total pallet",
  "total_pallets": "Plt Qty",
  "total_pieces": "Total Pieces",
  "total_volume": "Total Volume",
  "transshipment_station": "Transshipment Station",
  "transshipment_type": "Transshipment Type",
  "type": "Type",
  "unit": "Unit",
  "unit_value": "Unit Value",
  "uom": "UOM",
  "update_to_address_book": "Update to Address Book",
  "updated_success_shipment_list": "Updated Success Shipment List",
  "upload_document": "Upload Document",
  "uppercase_all": "ALL",
  "uppercase_canceled": "CANCELED",
  "uppercase_completed": "COMPLETED",
  "uppercase_in_progress": "IN PROGRESS",
  "urgent": "Urgent",
  "urgent_delivery": "Urgent Delivery",
  "vessel_name": "Vessel Name",
  "volume": "Volume",
  "volumetric_factor": "Volume Factor",
  "volumetric_unit": "Unit of Volume",
  "volumetric_weight": "Volume Weight",
  "voyage": "Voyage",
  "vw": "VW",
  "weight_unit": "Weight Unit",
  "width": "Width",
  "yes": "Yes",
  "zipcode": "Zipcode",
  "required_edi": "Required EDI",
  "edi_invoice": "EDI Invoice",
  "edi_milestone": "EDI Milestone",
  "edi_inbound": "Customer EDI Inbound",
  "empty_container_to_shipper": "Empty Out Gate at Origin",
  "full_container_loaded_at_origin_port": "Full in Gate at Origin",
  "container_available_at_discharge_port": "Discharged From POD",
  "container_out_gate_at_discharge_port": "Full Outgate",
  "container_on_rail_at_discharge_port": "On Rail",
  "container_off_rail_at_discharge_port": "Off Rail",
  "container_out_gate_at_dest_rail_station": "Full Outgate Inland",
  "empty_container_to_carrier": "Empty Container Return ",
  "please_check": "Checked!",
  "container_available_notice": "Container Available Notice",
  "no_shipment_container_mode_warning": "The Shipment Container Mode is null, please check!",
  "document_no_or_commerce_invoice_no": "Document No / Commerce INV No",
  "routing_ata": "ATA",
  "should_be_identical_with_port_of_routing_number": "Should be identical with %{port} of %{routing_number}",
  "samsung_document_no": "Document No",
  "samsung_invoice_no": "Invoice No",
  "samsung_document_no_and_invoice_no": "Document No / Invoice No",
  "samsung_document_no_placeholder": "Add multiple “Document No” separated by comma (,)",
  "destination_pickup_vehicle_driver": "Dest Pickup Vehicle Driver",
  "destination_pickup_vehicle_registration_plate": "Dest Pickup Vehicle Registration Plate",
  "arrival_origin_at": "Arrival at Origin Port",
  "departure_from_discharging_port_at": "Departure from Discharging Port",
  "arrived_cfs_at": "Arrived at CFS",
  "departure_from_cfs_at": "OFD (Departure from CFS)",
  "samsung_edi_alert": "This HAWB has not yet linked to an Samsung Request %{id}. Please verify your Samsung Request and correct it.",
  "edi_message_departure_port": "Origin",
  "edi_message_destination_port": "Destination",
  "edi_message_document_date": "Document Date",
  "edi_message_ship_date": "Ship Date",
  "edi_message_transportation_type": "Transportation Type",
  "edi_message_order_type": "Order Type",
  "edi_message_payment_method": "Payment method",
  "edi_message_inco_term": "IncoTerm",
  "edi_message_business_type": "Business Type",
  "edi_message_reference_document_number": "Ref Document No",
  "edi_message_hsi_dentifier": "HSI",
  "edi_message_total_package": "Total Package",
  "edi_message_total_pieces": "Total Pieces",
  "edi_message_total_volume": "Total Volume",
  "edi_message_total_gross_weight": "Total Gross Weight",
  "edi_message_total_net_weight": "Total Net Weight",
  "edi_message_invoice_number": "Invoice No",
  "edi_message_invoice_amount": "Invoice Amount",
  "edi_message_freight_amount": "Freight Amount",
  "edi_message_shipper_or_sender": "Shipper/Sender",
  "edi_message_plant": "Plant",
  "edi_message_shipper_contact": "Shipper Contact",
  "edi_message_shipper_phone_or_email": "Shipper Phone/Email",
  "edi_message_shipper_address": "Shipper Address",
  "edi_message_consignee": "Consignee",
  "edi_message_consignee_contact": "Consignee Contact",
  "edi_message_consignee_phone_or_email": "Consignee Phone/Email",
  "edi_message_consignee_address": "Consignee Address",
  "edi_message_buyer": "Buyer",
  "edi_message_buyer_contact": "Buyer Contact",
  "edi_message_buyer_phone_or_email": "Buyer Phone/Email",
  "edi_message_buyer_address": "Buyer Address",
  "edi_message_packing_detail": "Packing Detail",
  "edi_message_part_number": "Part No.",
  "edi_message_part_description": "Description",
  "edi_message_packing_quantity": "Packing Qty.",
  "edi_message_pieces_quantity": "Pieces Qty.",
  "edi_message_gross_weight": "Gross Weight",
  "edi_message_net_weight": "Net Weight",
  "edi_message_volume": "Volume",
  "edi_message_unit_price_amount": "Unit Price Amount",
  "edi_message_other_charge_amount": "Other Charge Amount",
  "container": "Container",
  "add_container": "Add Container ",
  "booking_no": "Booking No",
  "po_no": "PO No",
  "bulk_update": "Bulk Update",
  "bulk_edit_shipment_reference_no": "Bulk Update Shipment References",
  "apple_fd_no": "FD No",
  "apple_pr_no": "APPLE PR NO",
  "apple_fd_manager": "FD MANAGER",
  "apple_dri": "APPLE DRI",
  "purchase_order_no": "PO No",
  "order_no": "OR No",
  "so_number_plus_ship": "S/O No",
  "do_no": "DO No",
  "delivery_id": "DI No",
  "invoice_no": "Invoice No",
  "shipment_request_number": "SR No",
  "packing_list_no": "Packing List No",
  "part_no": "Part No",
  "permit_reference": "Permit Reference",
  "item_no": "Item No",
  "umc_ref_type": "UMC Ref Type",
  "serial_no": "Serial No",
  "returned_material_authorization": "RMA",
  "sid_no": "SID No",
  "contact_purchase": "SMP/CNTCT",
  "other1_ot": "Other1",
  "other2_ot1": "Other2",
  "other3_ot2": "Other3",
  "other4_ot3": "Other4",
  "other5_ot4": "Other5",
  "other6_ot5": "Other6",
  "created_at_utc": "Created At (UTC)",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "actual_pickup_at": "Picked Up",
  "customs_cleared_at": "Customs Cleared",
  "customs_released_at": "Customs Released",
  "shipment_not_existed": "Shipment no doesn't exist",
  "containe_invalidate_char": "There are invalid characters",
  "shipment_no": "Shipment No",
  "fd_no": "FD No",
  "apple_fdno": "Apple-FDNo",
  "fd_manager": "FD Manager",
  "purchase_order_no_po_smp_slash_po": "Purchase Order NO,PO, SMP/PO",
  "or_no": "OR No",
  "s_slash_o_no": "S/O No",
  "s_slash_o_no_plus_ship_s_slash_o": "S/O number \n + Ship(S/O)",
  "di_no": "DI No",
  "in_no": "IN No",
  "sr_no": "SR No",
  "shipmentref_number": "ShipRef Number",
  "pl_no": "PL NO",
  "pn_no": "PN No",
  "permit": "Permit",
  "it_no": "IT No",
  "umc": "UMC",
  "s_slash_n": "S/N",
  "rma": "RMA",
  "material_authorization": "Returned Material\n Authorization",
  "si": "SI",
  "smp_slash_cntct": "SMP/CNTCT",
  "contact_slash_purchaser": "Contact/Purchaser",
  "other1": "Other1",
  "other2": "Other2",
  "other3": "Other3",
  "other4": "Other4",
  "other5": "Other5",
  "other6": "Other6",
  "bulk_update_shipment_references_error_shipment_duplicate": "shipment no is duplicate",
  "reference_bulk_update_upload_memo": "Or Paste Data",
  "reference_bulk_update_upload_memo_description": "(All input data will be overwritten if choosing new file; line breaks will be replaced with comma)\u000b",
  "master_ata": "MAWB - ATA",
  "doc_released_at": "Document Released",
  "ship_to": "Ship To",
  "arrive_forward_gateway": "Arrived at Gateway",
  "depart_forward_gateway": "OFD (Departed from Gateway)",
  "scac": "SCAC",
  "invalid_scac_code": "SCAC Code is incorrect, please check!",
  "invalid_datetime": "format must be YYYY-MM-DD",
  "Invalid_file": "Invalid file. Please upload a .csv file.",
  "Invalid_file_header_name": "Please upload a file with correct header name(s): %{headers}",
  "Invalid_file_lose_main_field": "You need to upload a file with Container No. and HBL# column.",
  "system_source": "system source",
  "receive_booking_req_date": "Rcv. Booking Req. Date",
  "cargo_stuffing_date": "Cargo Stuffing Date",
  "arrived_at_cfs_in_origin": "Arrived at CFS in Origin",
  "full_in_gate_in_origin": "Full in Gate in Origin",
  "document_submitted": "Document Submitted",
  "full_outgate": "Full Outgage",
  "arrive_origin_terminal": "Arrive Origin Terminal",
  "arrival_at_origin_port": "Arrival at Origin Port",
  "depart_from_destination_port": "Depart From Dest Port",
  "cargo_arrived_at_terminal": "Cargo Arrived At Terminal",
  "estimated_pickup": "Estimated Pick-up Date",
  "booking_confirmed": "Booking Confirmation(SO Released)",
  "shipping_doc_received_date": "Shipping Docs Received Date",
  "appointment_made": "Appointment of POD Made",
  "estimated_pod": "Estimated Delivery",
  "appointment_confirmed": "Appointment of POD Confirmed",
  "cause": "Cause",
  "customized_cause": "Customized Cause",
  "ship_to_no_data_warning": "Please update Notify Part Info on Prolink/CW1.",
  "apple_fd_routing_readonly_waring_message": "Please update milestone in Apple Trck!",
  "apple_gar_routing_readonly_waring_message": "Please update milestone in Apple Trck!",
  "error_code_search_not_found": "Resource is not found!",
  "orig_customs_inspection_type": "Origin Customs Inspection Type",
  "dest_customs_inspection_type": "Dest Customs Inspection Type",
  "customs": "Customs",
  "customer_required": "Customer Required",
  "customer_reference": "Customer Reference",
  "report": "Report",
  "report_shipment_status": "Shipment Status",
  "report_forwarder_name": "Forwarder Name",
  "report_lane_id": "Lane ID",
  "report_cargo_type": "Cargo Type",
  "report_good_description": "Good Description",
  "report_gen_size": "Gen Size",
  "report_product_code": "Product Code",
  "report_product_size": "Product Size",
  "report_change_to_low_bed": "Change to Low-Bed",
  "report_air_ride_for_inland": "Air Ride for Inland",
  "report_gw": "gw",
  "report_sheet_qty": "Sheet Qty",
  "report_container_no": "Container No.",
  "report_carrier": "Carrier",
  "report_ex_plant_day": "Ex-Plant Day",
  "report_etd_org_port": "ETD Org Port",
  "report_cargo_delay_days_1": "Cargo Delay Days 1",
  "report_cargo_delay_reason_1": "Cargo Delay Reason 1",
  "report_atd_org_port": "ATD Org Port",
  "report_eta_transit_port": "ETA Transit Port",
  "report_incorrect_docs_received": "Incorrect Docs Received?",
  "report_incorrect_docs_reason": "Incorrect Docs Reason",
  "report_completed_and_correct_custom_clearance_docs_sent_to_broker": "Completed and correct Custom Clearance Docs Sent to Broker",
  "report_cargo_delay_days_2": "Cargo Delay Days 2",
  "report_cargo_delay_reason_2": "Cargo Delay Reason 2",
  "report_ata_transit_port": "ATA Transit Port",
  "report_etd_transit_port": "ETD Transit Port",
  "report_cargo_delay_days_3": "Cargo Delay Days 3",
  "report_cargo_delay_reason_3": "Cargo Delay Reason 3",
  "report_atd_transit_port": "ATD Transit Port",
  "report_eta_gezhouba": "ETA Gezhouba (到达葛洲坝)",
  "report_ata_gezhouba": "ATA Gezhouba (到达葛洲坝)",
  "report_eta_3gorges": "ETA 3gorges (到达三峡)",
  "report_ata_3gorges": "ATA 3gorges (已到达三峡)",
  "report_etd_3gorges": "ETD 3gorges (已过三峡)",
  "report_atd_3gorges": "ATD 3gorges (已过三峡)",
  "report_eta_dest_port": "ETA Dest. Port",
  "report_cargo_delay_days_4": "Cargo Delay Days 4",
  "report_ata_dest_port": "ATA Dest. Port",
  "report_c_c_mode": "C/C Mode",
  "report_customs_declaration_date": "Customs Declaration Date",
  "report_customs_inspection": "Customs Inspection",
  "report_customs_inspection_reason": "Customs Inspection Reason",
  "report_customs_inspection_released": "Customs Inspection Released",
  "report_customs_declaration_sheet_no": "Customs Declaration Sheet No.",
  "report_import_duty": "Import Duty",
  "report_import_vat": "Import VAT",
  "report_vat_or_deposit_release": "VAT or Deposit Release",
  "report_customs_cleared": "Customs Cleared",
  "report_etd_dest_port": "ETD Dest. Port",
  "report_cargo_delay_days_5": "Cargo Delay Days 5",
  "report_atd_dest_port": "ATD Dest. Port",
  "report_eta_final_destination": "ETA Final Destination",
  "report_cargo_delay_days_6": "Cargo Delay Days 6",
  "report_ata_final_destination": "ATA Final Destination",
  "report_empty_equipment_return": "Empty Equipment Return",
  "report_warehouse": "Warehouse",
  "report_demurrage_cost": "Demurrage Cost",
  "report_demurrage_reason": "Demurrage Reason",
  "report_detention_day": "Detention Day",
  "report_detention_cost": "Detention Cost",
  "report_detention_reason": "Detention Reason",
  "report_other_cost": "Other Cost",
  "report_actual_l_t": "Actual L/T",
  "report_required_l_t": "Required L/T",
  "report_plant_tracking": "Plant Tracking#",
  "report_remark": "Remark",
  "report_shipper": "Shipper",
  "report_consignee": "Consignee",
  "report_shipping_mode": "Shipping Mode",
  "report_incoterms": "Incoterms",
  "report_po_number": "PO#",
  "report_commercial_invoice_number": "Inv No.",
  "report_master_number": "MBL#",
  "report_shipment_number": "HBL#",
  "report_loading_port": "Loading Port",
  "report_container_type": "Container Type",
  "report_packing_type": "Packing Type",
  "report_vessel_name": "Vessel name",
  "report_amount": "Amount",
  "report_currency": "Currency",
  "report_completed_and_correct_custom_clearance_docs_received_to_broker": "Completed and Correct Custom Clearance Docs Received by Broker",
  "report_shipment_create_date": "SHIPMENT CREATE DATE",
  "container_not_existed": "Container No. under HBL# can not be found.",
  "duplicate_container": "Container No. under HBL# is duplicate",
  "shipment_not_key_in": "HBL# can not be blank.",
  "container_not_key_in": "Container No can not be blank.",
  "invalidate_datetime": "Format must be YYYY/MM/DD.",
  "report_no_column": "This report no column data",
  "event_routing_actual_pickup_at": "Actual Pick-up Date",
  "report_step1_title": "Step 1: General Upload",
  "report_step2_title": "Step 2: Review Result",
  "report_step2_title_desc": "The listed results including blank field will be uploaded. Please make sure all data is correct.",
  "report_msrnt": "MSRNT",
  "report_via": "VIA",
  "invalidate_string": "Format must be string.",
  "invalidate_integer": "Format must be integer",
  "invalidate_float": "Format must be float",
  "Shipment_Tracking_Report": "Shipment Tracking Report",
  "report_cargo_delay_reason_4": "Cargo Delay Reason 4",
  "report_cargo_delay_reason_5": "Cargo Delay Reason 5",
  "report_cargo_delay_reason_6": "Cargo Delay Reason 6",
  "invalidate_number": "Format must be number.",
  "Invalid_excel_file": "Invalid file. Please upload an Excel file (xlsx, xls).",
  "hbl_not_existed": "HBL# does not exist",
  "report_check_first_memo": "Please rectify all the error in step 1 first then you can click \"Update\".",
  "destination_customs_start_at": "Destination Customs Start At",
  "import_customs_release_at": "Import Customs Release At"
 },
 "forwarder": {
  "actual_appointment_date": "Actual Appt Date",
  "actual_arrive_first_us_ca_dest": "Acutal Arrive First US/CA Dest",
  "actual_arrive_transshipment_port": "Actual Arrive Transshipment Port",
  "actual_delivery_date": "Actual Delivery Date",
  "actual_depart_port_at": "Actual Departed Port of Export Date",
  "actual_depart_transshipment_port": "Actual Depart Transshipment Port",
  "actual_departed_port_of_export": "Actual Departed Port Of Export",
  "actual_pickup_date": "Actual Pickup Date",
  "address": "Address",
  "address_in_local_language": "Address in local language",
  "address_line_one": "Address Line 1",
  "address_line_two": "Address Line 2",
  "address_will_be_updated_which_shipments_not_be_assigned_to_bol": "Address will be updated which shipments not be assigned to BOL",
  "adjust_column": "Adjust Column",
  "agent_id": "Agent ID",
  "agent_role": "H Agent",
  "air_ride": "Air Ride",
  "airport": "Airport",
  "alert": "Alert",
  "amat_edi_alert": "This HAWB has not yet linked to an AMAT ID %{id}. Please verify your AMAT ID and correct it.",
  "amat_id": "AMAT ID",
  "apply_to_all_shipments": "Apply to All Shipments",
  "appointment_confirmed_date": "Appt Confirmed Date",
  "appointment_confirmed_number": "Appt Confirmation No",
  "appointment_made_by": "Appt Made By",
  "appointment_made_date": "Appt Made Date",
  "appointment_made_method": "Appt Made Method",
  "appointment_made_with": "Appt Made With",
  "appointment_number": "Appt No",
  "arrival_city": "Arrival city",
  "arrival_terminal_at": "Arrival at Origin Terminal Date",
  "arrived_at_cfs": "Arrived at CFS",
  "arrived_ata": "Arrived (ATA)",
  "arrived_cfs": "Arrived at Destination CFS",
  "asn_number": "ASN Number",
  "at_risk": " At Risk",
  "ata": "ATA",
  "ata_first_amer": "Actual Arrive First US Destination Date",
  "ata_first_amer_port": "First US Destination",
  "ata_route_1_port": "Transshipment Port",
  "ata_to_port_arrival": "ATA to Port of Arrival",
  "atd": "Departured (ATD)",
  "attachment": "Attachment",
  "aw_gw": "AW / GW",
  "bol_group_rule": "BOL Grouping Rule",
  "bol_no": "BOL No",
  "booked": "Booked",
  "booking_date": "Booking Date",
  "booking_number": "Booking No",
  "brokerage": "Brokerage",
  "cancel": "Cancel",
  "cancelled_at": "Cancelled",
  "cargo_receipt": "Cargo Receipt",
  "cargo_receipt_date": "Cargo Receipt Date",
  "carrier_released": "Carrier Released",
  "cartons": "Cartons",
  "category": "Category",
  "cbm": "CBM",
  "chargeable_weight": "Charge Weight",
  "checked_ata_and_eta": "Updated ATA：%{ata} is after ETA：%{eta}",
  "checked_atd_and_etd": "Updated ATD：%{atd} is after ETD：%{etd}",
  "checked_pod_and_edd": "Updated POD：%{pod} is after EDD：%{edd}",
  "city": "City",
  "client_id": "Client ID",
  "cod_amount": "COD Amount",
  "code": "Code",
  "comment": "Comment",
  "comment_here": "Comment Here",
  "comment_category": "Comment Category",
  "commercial_Inv": "Commercial Inv #",
  "commercial_invoice_number": "Commercial Invoice#",
  "commercial_invoice_numbers": "Commercial Invoice No",
  "commercial_Invoice_value": "Commercial Invoice Value",
  "completed_date": "Completed Date",
  "confirm_destination_documents": "Confirm Destination Documents",
  "confirm_origin_documents": "Confirm Origin Documents",
  "confirmed": "Confirmed",
  "consignee": "Consignee",
  "consignee_address": "Consignee Address",
  "consignee_city": "Consignee City Code",
  "consignee_city_name": "Consignee City",
  "consignee_contact": "Consignee Contact",
  "consignee_country": "Consignee Country/Area",
  "consignee_email": "Consignee Email",
  "consignee_id": "Consignee ID(AMAT)",
  "consignee_info": "Consignee Info",
  "consignee_name": "Consignee Name",
  "consignee_state": "Consignee State",
  "consignee_tax_id": "Consignee's Tax ID",
  "consignee_telephone": "Consignee Telephone",
  "consignee_zipcode": "Consignee Zip",
  "consol_closed_at": "Console Close",
  "contact": "Contact",
  "container_loading_type": "Container Loading Type",
  "container_mode": "Container Mode",
  "container_seal_number": "Container Seal No",
  "container_type": "Container Type",
  "container_type_description": "Container Type Description",
  "copy_edd_from_export": "Copy EDD From Export",
  "country": "Country/Area",
  "country_of_origin": "Country of Origin",
  "create_date_utc": "Create Date (UTC)",
  "created_by": "Created By",
  "cubic_meter": "CBM",
  "currency": "Currency",
  "custom_cleared": "Custom Cleared",
  "customer": "VIP Name",
  "customer_code": "Customer Code",
  "forwarder_code_name": "Forwarder Code/Name",
  "forwarder_name": "FWD Name",
  "customer_code_name": "Customer Code/Name",
  "customer_special_requirement_sla": "Customer Special Requirement / SLA",
  "customs_cleared": "Customs Cleared",
  "customs_released": "Customs Released",
  "customs_value": "Customs Value",
  "cw": "CW",
  "dangerous_good": "Dangerous Goods",
  "delete": "Delete",
  "delivery": "Delivery",
  "delivery_chargeable_weight": "Delivery Charge Weight",
  "delivery_cubic_meter": "Delivery CBM",
  "delivery_gross_weight": "Delivery Gross Weight",
  "delivery_loose_cartons": "Delivery Loose Carton Qty",
  "delivery_method": "Delivery Method",
  "delivery_no": "Delivery No",
  "delivery_package_quantity": "Delivery Package Qty",
  "delivery_port": "Port of Delivery",
  "delivery_port_city": "Port of Delivery City",
  "delivery_port_country": "Port of Delivery Country",
  "delivery_total_cartons": "Delivery Carton Qty",
  "delivery_total_pallets": "Delivery Pallet Qty",
  "delivery_total_pieces": "Delivery Piece Qty",
  "delivery_volumetric_weight": "Delivery Volume Weight",
  "dep": "Dep",
  "department": "Department",
  "department_number": "Department #",
  "departure_city": "Departure city",
  "departure_from_shipper_warehouse": "Departure from Shipper Warehouse",
  "description_of_goods": "Description of Goods",
  "dest_custom_released": "Dest Custom Released",
  "dest_customs_released": "Dest Customs Released",
  "destination": "Destination",
  "destination_city": "Destination city",
  "destination_customs_started_at": "Destination Customs Started Date",
  "destination_port": "Dest",
  "destination_port_city": "Dest Port City",
  "destination_port_country": "Dest Port Country",
  "destination_region": "Dest Region",
  "dim": "DIM",
  "dispatch_no": "Dispatch No",
  "dispatch_number": "Dispatch number",
  "do_you_want_to_raise_an_irregularity_to_alert_the_customer": "Do you want to raise an irregularity to alert the customer ?",
  "doc_received_at": "Doc Received Date",
  "doc_released": "Doc Released",
  "doc_submitted_at": "Doc Submitted Date",
  "doc_to_broker_at": "Doc To Broker Date",
  "document_released": "Document Released",
  "edi_send_date_time_utc": "EDI Sent Date & Time",
  "edi_status_code": "EDI Status Code",
  "estimated_delivery_date": "Estimated Delivery Date",
  "estimated_delivery_date_sla": "Estimated Delivery Date (SLA)",
  "estimated_pickup_at": "Estimated Pickup Date",
  "eta": "Last ETA",
  "etd": "First ETD",
  "event_date_and_time_local": "Event Datetime (Local)",
  "event_description": "Event Description",
  "event_location": "Event Location",
  "event_routing": "Event & Routing",
  "exception": "Exception",
  "exceptions": "exceptions",
  "export": "Export",
  "export_milestones": "Export",
  "factor": "Factor",
  "fcr": "FCR",
  "fish_and_wildlife": "Fish & Wildlife",
  "freight_term": "Freight Term",
  "from": "From",
  "from_tms": "From TMS",
  "gross_weight": "Gross Weight",
  "handed_to_carrier": "Handed to Carrier",
  "handling_pieces": "HAWB Pcs",
  "hawb": "HAWB",
  "hawb_detail": "HAWB Detail",
  "hazardous": "Hazardous",
  "height": "Height",
  "hts_code": "HS Code",
  "id": "ID",
  "id_not_found": "ID not found",
  "import": "Import",
  "import_milestones": "Import",
  "incoterm": "Inco Term",
  "input_more_than_the_next_seven_days_is_not_allowed": "Input more than the next 7 days is not allowed.",
  "instruction": "Instruction",
  "internal_transaction_number": "ITN#",
  "international_duty_taxes": "International Duites & Taxes",
  "is_customs_clearance": "Customs clearance",
  "item": "Item #",
  "item_quantity": "Number of Items",
  "itn_mrn": "ITN # / MRN #",
  "last_consol_closed_at": "Last Consol Closed Date",
  "length": "Length",
  "line_item_class_number": "Class Number",
  "logistic_handling": "Logistic Handling",
  "logistic_number": "LOG No",
  "loose_carton_quantity": "Loose carton quantity",
  "loose_cartons": "Loose Ctn Qty",
  "lot_number": "Lot No",
  "master_agent_id": "Master Agent ID",
  "master_agent_role": "Consol Agent",
  "master_number": "MAWB No",
  "max_length_two": "Max Length Two",
  "milestone": "Milestone",
  "milestone_utc": "Milestone (UTC)",
  "milestone_status": "Milestone Status",
  "morrison_one_customer": "Morrison One Customer",
  "name": "Name",
  "need_resubmission": "Need Resubmission",
  "no": "No",
  "no_select_shipment_list": "No select shipment List !",
  "notify_party_address": "Notify Party Address",
  "notify_party_city": "Notify Party City Code",
  "notify_party_city_name": "Notify Party City",
  "notify_party_country": "Notify Party Country/Area",
  "notify_party_id": "Notify Party ID",
  "notify_party_name": "Notify Party Name",
  "notify_party_state": "Notify Party State",
  "notify_party_zipcode": "Notify Party Zipcode",
  "npi": "NPI",
  "number": "Number",
  "ofd_departed_from_cfs": "OFD (Departed from CFS)",
  "off_load_at": "Offload",
  "off_loaded_at": "Off Loaded Date",
  "office": "Office",
  "onboard_date": "Onboard Date",
  "order": "Order #",
  "order_number": "Order Number",
  "orig_port_of_loading": "Orig (Port of Loading)",
  "origin": "Origin",
  "origin_customs_broker": "Origin customs broker",
  "origin_customs_cleared": "Origin Customs Cleared",
  "origin_pickup_vechicle_registration": "Origin pickup vechicle registration",
  "origin_pickup_vehicle_driver": "Origin pickup vehicle driver",
  "hawb_hbl": "HAWB / HBL",
  "orig": "Orig",
  "dest": "Dest",
  "orig_region": "Orig Region",
  "dest_region": "Dest Region",
  "origin_status": "Origin Status",
  "mawb_mbl": "MAWB / MBL",
  "orig_mawb_mbl": "Orig (MAWB / MBL)",
  "dest_mawb_mbl": "Dest (MAWB / MBL)",
  "dest_status": "Dest Status",
  "dn_sid": "DN / SID",
  "doc_status": "Doc Status",
  "origin_pickup_vehicle_registration_plate": "Origin Pickup Vehicle Plate",
  "origin_port": "Origin",
  "origin_port_city": "Orig Port City",
  "origin_port_country": "Orig Port Country",
  "out_for_delivery_date": "Out for Delivery",
  "out_of_gauge": "Out of Gauge",
  "pack": "Pack #",
  "package": "Package",
  "package_detail": "Package Details",
  "package_quantity": "Package Qty",
  "packing_info": "Packing Info",
  "part_description": "Part Description",
  "part_number": "Part Number",
  "payment_method": "Payment Method",
  "payment_to_carrier_by": "Payment to Carrier By",
  "pick_up_date": "Pick-up Date",
  "pickup_address": "Pickup address",
  "pickup_contactor": "Pickup contactor",
  "pickup_info": "Pickup Info",
  "pickup_telephone": "Pickup telphone",
  "pickup_up": "Picked up",
  "po_item_number": "PO Item No",
  "po_number": "PO No",
  "pod_delivery_date": "POD / Delivery Date",
  "pod_doc_checked": "POD Checked",
  "pod_doc_checked_messages": "You have reviewed the document. Our customer will be able to see the document.",
  "pod_doc_unchecked_messages": "You have unchecked the document. Our customer will not be able to see the document.",
  "pod_name": "POD Name",
  "pod_signed_by": "POD Signed by",
  "port_of_receipt": "Port of Receipt",
  "port_of_receipt_city": "Port of Receipt City",
  "port_of_receipt_country": "Port of Receipt Country",
  "preview": "Preview",
  "product_characteristic": "Product Characteristic",
  "product_type": "Product Type",
  "project_code": "Project Code",
  "prolink_export_created_by": "Export Created By",
  "prolink_import_created_by": "Import Created By",
  "proof_of_delivery_location": "POD Location",
  "quantity": "Quantity",
  "quotation_number": "Quotation No",
  "ready_for_pickup_at": "Ready For Pickup Date",
  "reason": "Reason",
  "received_from_airline": "Received from airline",
  "reference": "Reference",
  "reference_no": "Reference No",
  "reference_number": "AMAT Reference#",
  "reference_type": "Reference Type",
  "reference_value_di": "DI Reference",
  "reference_value_in": "IN Reference",
  "reference_value_po": "PO Reference",
  "reference_value_sr": "SR Reference",
  "reference_value_undefined": "Undefined Reference",
  "release_location": "Release Location",
  "release_to": "Release To",
  "release_type": "Release Type",
  "requested_sailing_date": "Requested Sailing Date",
  "required": "Required",
  "required_arrival_time": "Required arrival time",
  "required_pick_up_time": "Required pick up time",
  "routing_atd": "%{prefix} ATD",
  "routing_console_sequence": "%{prefix} Sequence",
  "routing_eta": "%{prefix} ETA",
  "routing_etd": "%{prefix} ETD",
  "routing_from_port": "%{prefix} From",
  "routing_master_number": "%{prefix} MAWB / MBL",
  "routing_number": "%{prefix} Flight / Vessel No",
  "routing_to_port": "%{prefix} To",
  "routing_vessel_name": "%{prefix} Vessel Name",
  "routing_voyage_name": "%{prefix} Voyage",
  "sea_destination_port": "Dest (Port of Discharge)",
  "security_level": "Security Level",
  "security_level_handling": "Security Level Handling",
  "select_hawbs_to_apply": "Select HAWBs to apply",
  "select_milestones_to_update": "Select Milestones to update",
  "sequence": "Seq",
  "service_level": "Svc Lvl",
  "service_type": "Svc Type",
  "ship_date": "Ship Date",
  "shipment_details": "Shipment Details",
  "shipment_mode": "Ship Mode",
  "shipment_number": "Shipment No",
  "shipment_status": "Shipment Status",
  "shipment_type": "Shipment Type",
  "shipper": "Shipper",
  "shipper_address": "Shipper Address",
  "shipper_city": "Shipper City Code",
  "shipper_city_name": "Shipper City",
  "shipper_contact": "Shipper Contact",
  "shipper_country": "Shipper Country/Area",
  "shipper_email": "Shipper Email",
  "shipper_id": "Shipper ID",
  "shipper_name": "Shipper Name",
  "shipper_state": "Shipper State",
  "shipper_tax_id": "Shipper's Tax ID",
  "shipper_telephone": "Shipper Telephone",
  "shipper_zipcode": "Shipper Zipcode",
  "shipping_instructions": "Shipping Instructions",
  "signed_by": "Signed By",
  "sla": "SLA",
  "something_wrong": "Something Wrong !",
  "special_delivery_instruction": "Special Delivery Instruction",
  "special_instruction": "Special Instruction",
  "split_shipment": "Split HAWB",
  "state": "State",
  "status": "Status",
  "submitted_to_customs": "Submitted to Customs",
  "tea": "TEA #",
  "tea_number": "TEA #",
  "tel": "Tel",
  "this_vendor_doesnt_have_amat_id": "This vendor doesn't have AMAT ID",
  "this_vendor_doesnt_have_it": "This vendor doesn't have it",
  "tianma_edi_alert": "This HAWB has not yet linked to an Tianma ID %{id}. Please verify your Tianma ID and correct it.",
  "tianma_id": "Tianma ID",
  "to": "To",
  "total_carton": "Total carton",
  "total_cartons": "Total Cartons",
  "total_gross_weight_kg": "Total gross weight(KG)",
  "total_net_weight_kg": "Total net weight(KG)",
  "total_packages": "Total Packages",
  "total_pallet": "Total pallet",
  "total_pallets": "Plt Qty",
  "total_pieces": "Total Pieces",
  "total_volume": "Total Volume",
  "transshipment_station": "Transshipment Station",
  "transshipment_type": "Transshipment Type",
  "type": "Type",
  "unit": "Unit",
  "unit_value": "Unit Value",
  "uom": "UOM",
  "update_to_address_book": "Update to Address Book",
  "updated_success_shipment_list": "Updated Success Shipment List",
  "upload_document": "Upload Document",
  "uppercase_all": "ALL",
  "uppercase_canceled": "CANCELED",
  "uppercase_completed": "COMPLETED",
  "uppercase_in_progress": "IN PROGRESS",
  "urgent": "Urgent",
  "urgent_delivery": "Urgent Delivery",
  "vessel_name": "Vessel Name",
  "volume": "Volume",
  "volumetric_factor": "Volume Factor",
  "volumetric_unit": "Unit of Volume",
  "volumetric_weight": "Volume Weight",
  "voyage": "Voyage",
  "vw": "VW",
  "weight_unit": "Weight Unit",
  "width": "Width",
  "yes": "Yes",
  "zipcode": "Zipcode",
  "required_edi": "Required EDI",
  "edi_invoice": "EDI Invoice",
  "edi_milestone": "EDI Milestone",
  "edi_inbound": "Customer EDI Inbound",
  "empty_container_to_shipper": "Empty Out Gate at Origin",
  "full_container_loaded_at_origin_port": "Full in Gate at Origin",
  "container_available_at_discharge_port": "Discharged From POD",
  "container_out_gate_at_discharge_port": "Full Outgate",
  "container_on_rail_at_discharge_port": "On Rail",
  "container_off_rail_at_discharge_port": "Off Rail",
  "container_out_gate_at_dest_rail_station": "Full Outgate Inland",
  "empty_container_to_carrier": "Empty Container Return ",
  "please_check": "Checked!",
  "container_available_notice": "Container Available Notice",
  "no_shipment_container_mode_warning": "The Shipment Container Mode is null, please check!",
  "document_no_or_commerce_invoice_no": "Document No / Commerce INV No",
  "routing_ata": "ATA",
  "should_be_identical_with_port_of_routing_number": "Should be identical with %{port} of %{routing_number}",
  "samsung_document_no": "Document No",
  "samsung_invoice_no": "Invoice No",
  "samsung_document_no_and_invoice_no": "Document No / Invoice No",
  "samsung_document_no_placeholder": "Add multiple “Document No” separated by comma (,)",
  "destination_pickup_vehicle_driver": "Dest Pickup Vehicle Driver",
  "destination_pickup_vehicle_registration_plate": "Dest Pickup Vehicle Registration Plate",
  "arrival_origin_at": "Arrival at Origin Port",
  "departure_from_discharging_port_at": "Departure from Discharging Port",
  "arrived_cfs_at": "Arrived at CFS",
  "departure_from_cfs_at": "OFD (Departure from CFS)",
  "samsung_edi_alert": "This HAWB has not yet linked to an Samsung Request %{id}. Please verify your Samsung Request and correct it.",
  "edi_message_departure_port": "Origin",
  "edi_message_destination_port": "Destination",
  "edi_message_document_date": "Document Date",
  "edi_message_ship_date": "Ship Date",
  "edi_message_transportation_type": "Transportation Type",
  "edi_message_order_type": "Order Type",
  "edi_message_payment_method": "Payment method",
  "edi_message_inco_term": "IncoTerm",
  "edi_message_business_type": "Business Type",
  "edi_message_reference_document_number": "Ref Document No",
  "edi_message_hsi_dentifier": "HSI",
  "edi_message_total_package": "Total Package",
  "edi_message_total_pieces": "Total Pieces",
  "edi_message_total_volume": "Total Volume",
  "edi_message_total_gross_weight": "Total Gross Weight",
  "edi_message_total_net_weight": "Total Net Weight",
  "edi_message_invoice_number": "Invoice No",
  "edi_message_invoice_amount": "Invoice Amount",
  "edi_message_freight_amount": "Freight Amount",
  "edi_message_shipper_or_sender": "Shipper/Sender",
  "edi_message_plant": "Plant",
  "edi_message_shipper_contact": "Shipper Contact",
  "edi_message_shipper_phone_or_email": "Shipper Phone/Email",
  "edi_message_shipper_address": "Shipper Address",
  "edi_message_consignee": "Consignee",
  "edi_message_consignee_contact": "Consignee Contact",
  "edi_message_consignee_phone_or_email": "Consignee Phone/Email",
  "edi_message_consignee_address": "Consignee Address",
  "edi_message_buyer": "Buyer",
  "edi_message_buyer_contact": "Buyer Contact",
  "edi_message_buyer_phone_or_email": "Buyer Phone/Email",
  "edi_message_buyer_address": "Buyer Address",
  "edi_message_packing_detail": "Packing Detail",
  "edi_message_part_number": "Part No.",
  "edi_message_part_description": "Description",
  "edi_message_packing_quantity": "Packing Qty.",
  "edi_message_pieces_quantity": "Pieces Qty.",
  "edi_message_gross_weight": "Gross Weight",
  "edi_message_net_weight": "Net Weight",
  "edi_message_volume": "Volume",
  "edi_message_unit_price_amount": "Unit Price Amount",
  "edi_message_other_charge_amount": "Other Charge Amount",
  "container": "Container",
  "add_container": "Add Container ",
  "booking_no": "Booking No",
  "po_no": "PO No",
  "so_no": "SO No",
  "bulk_update": "Bulk Update",
  "bulk_edit_shipment_reference_no": "Bulk Update Shipment References",
  "apple_fd_no": "FD No",
  "apple_pr_no": "APPLE PR NO",
  "apple_fd_manager": "FD MANAGER",
  "apple_dri": "APPLE DRI",
  "purchase_order_no": "PO No",
  "order_no": "OR No",
  "so_number_plus_ship": "S/O No",
  "do_no": "DO No",
  "delivery_id": "DI No",
  "invoice_no": "Invoice No",
  "shipment_request_number": "SR No",
  "packing_list_no": "Packing List No",
  "part_no": "Part No",
  "permit_reference": "Permit Reference",
  "item_no": "Item No",
  "umc_ref_type": "UMC Ref Type",
  "serial_no": "Serial No",
  "returned_material_authorization": "RMA",
  "sid_no": "SID No",
  "contact_purchase": "SMP/CNTCT",
  "other1_ot": "Other1",
  "other2_ot1": "Other2",
  "other3_ot2": "Other3",
  "other4_ot3": "Other4",
  "other5_ot4": "Other5",
  "other6_ot5": "Other6",
  "created_at_utc": "Created At (UTC)",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "actual_pickup_at": "Picked Up",
  "customs_cleared_at": "Customs Cleared",
  "customs_released_at": "Customs Released",
  "shipment_not_existed": "Shipment no doesn't exist",
  "containe_invalidate_char": "There are invalid characters",
  "shipment_no": "Shipment No",
  "fd_no": "FD No",
  "apple_fdno": "Apple-FDNo",
  "fd_manager": "FD Manager",
  "purchase_order_no_po_smp_slash_po": "Purchase Order NO,PO, SMP/PO",
  "or_no": "OR No",
  "s_slash_o_no": "S/O No",
  "s_slash_o_no_plus_ship_s_slash_o": "S/O number \n + Ship(S/O)",
  "di_no": "DI No",
  "in_no": "IN No",
  "sr_no": "SR No",
  "shipmentref_number": "ShipRef Number",
  "pl_no": "PL NO",
  "pn_no": "PN No",
  "permit": "Permit",
  "it_no": "IT No",
  "umc": "UMC",
  "s_slash_n": "S/N",
  "rma": "RMA",
  "material_authorization": "Returned Material\n Authorization",
  "si": "SI",
  "smp_slash_cntct": "SMP/CNTCT",
  "contact_slash_purchaser": "Contact/Purchaser",
  "other1": "Other1",
  "other2": "Other2",
  "other3": "Other3",
  "other4": "Other4",
  "other5": "Other5",
  "other6": "Other6",
  "bulk_update_shipment_references_error_shipment_duplicate": "shipment no is duplicate",
  "reference_bulk_update_upload_memo": "Or Paste Data",
  "reference_bulk_update_upload_memo_description": "(All input data will be overwritten if choosing new file; line breaks will be replaced with comma)\u000b",
  "master_ata": "MAWB - ATA",
  "error_code_forwarder_id_name_not_found": "",
  "you_cannot_cancel_the_shipment_without_a_comment": "You cannot cancel the shipment without a comment",
  "back": "Back",
  "are_you_sure_you_want_to_cancel_the_shipment": "Confirm delete shipment?",
  "operating_number": "No.",
  "level": "Level",
  "no_data": "No Data",
  "pick_up_address_same_as_shipper_address": "Pick Up Address Same As Shipper Address",
  "ship_to_address_same_as_consignee_address": "Ship To Address Same As Consignee Address",
  "pick_up": "Pick Up ",
  "ship_to": "Ship To ",
  "customer_name": "Customer Name",
  "custom_value": "Custom Value",
  "commercial_invoice": "Commercial Inv#",
  "comments": "Comments",
  "edit_comments": "Edit Comments",
  "is_dangerous": "Dangerous Goods",
  "new_comments": "New Comments"
 },
 "sys": {
  "role_type": "Role Type",
  "role_name": "Role Name",
  "role_id": "Role Id",
  "system": "System",
  "delete": "Delete",
  "role_type_function": "Function",
  "role_type_data": "Data",
  "role_type_privilege": "Privilege",
  "are_you_sure_to_delete_the_role": "Are you sure you want to delete the Role?",
  "customer": "Customer",
  "owner": "Owner",
  "client_id": "Client ID",
  "shipper": "Shipper",
  "consignee": "Consignee",
  "company_id": "User Company ID",
  "shipment_mode": "Shipment Mode",
  "origin_port": "Orig",
  "destination_port": "Dest",
  "station": "Station",
  "carrier": "Carrier",
  "agent": "Agent",
  "export_prolink_db": "Prolink DB - Export",
  "import_prolink_db": "Prolink DB - Import",
  "site": "Site",
  "user_info": "User Information",
  "user_type": "User Type",
  "api_token": "API Token",
  "employee_id": "Employee Id",
  "forwarder_id": "Forwarder ID",
  "region": "Region",
  "division": "Division",
  "department": "Department",
  "supervisor": "Supervisor",
  "job_title": "Job Title",
  "individual": "Individual",
  "update_logs": "Update logs",
  "system_current_value": "System Current Value",
  "shipment_number": "Shipment Number",
  "delivery_number": "Delivery Number",
  "source_system": "Source System",
  "target_system": "Target System",
  "shipment_no": "Shipment No",
  "delivery_no": "Delivery No",
  "milestone_name": "Milestone Name",
  "milestone_value": "Milestone Value",
  "updated_at": "Updated At",
  "dataflow_process_at": "Dataflow Process At",
  "dataflow_scheduler": "Dataflow Scheduler",
  "table": "Table",
  "m1_records": "M1 Records",
  "dataflow_logs_from_ods": "Dataflow Logs (From ODS)",
  "ods_records": "ODS Records",
  "dataflow_logs_from_trck": "Dataflow Logs (From TRCK)",
  "rec_seq_no": "REC_SEQ_NO",
  "prolink": "Prolink",
  "datapool": "Datapool",
  "process_at": "Process At",
  "process_status": "Process Status",
  "error_class": "Error Class",
  "quotation_no": "Quotation No",
  "awb_type": "AWB_TYPE",
  "pkg": "PKG",
  "transshipment_type": "Transshipment Type",
  "wb_status": "WB_STATUS",
  "log_id": "Log Id",
  "trck": "TRCK",
  "updated_table": "Updated Table",
  "module": "Module",
  "epic_ticket_number": "Epic Number",
  "ready_to_publish": "Ready to Publish",
  "create_by": "Create By",
  "last_edit_by": "Last Edit By",
  "title": "Title",
  "content": "Content",
  "release_note_config": "Release Note Config",
  "release_date": "Release Date",
  "language": "Language",
  "create_at": "Create At",
  "last_by": "Last By",
  "there_is_no_more_content": "There is No More Content",
  "scroll_down_to_see_more": "Scroll Down to See More",
  "content_has_been_updated": "%{module} Just Updated, Click to Refresh",
  "release_notes": "Release Notes",
  "role_black_list_alert_message": "Notice, if you choose two policies that black list and white list at same dimension element, the black list won't be effected as you expected",
  "publish": "Publish To PROD",
  "mark_all_as_read_and_close": "Mark All As Read and Don't Show again",
  "system_current_value_mop": "System Current Value - MOP",
  "system_current_value_ods": "System Current Value - Prolink (ODS)",
  "system_current_value_apple_trck": "System Current Value - APPLE TRCK",
  "system_change_logs_mop": "System Change Logs - MOP",
  "system_change_logs_apple_trck": "System Change Logs - APPLE TRCK",
  "dataflow_sync_logs_ods": "Dataflow Sync Logs - Prolink (ODS) To MOP",
  "dataflow_sync_logs_apple_trck": "Dataflow Sync Logs - APPLE TRCK To MOP",
  "milestone_value_local_time": "Milestone Value (Local Time)",
  "updated_at_utc": "Updated At (UTC)",
  "prolink_code": "Prolink Code",
  "source_db": "Source DB",
  "create_flow": "Flow",
  "revised_source": "Source",
  "flow": "Flow",
  "search_milestone_and_log": "Search milestone and log",
  "search_shipment_and_log": "Search shipment and log",
  "system_current_value_mop_description": "Schema: tracking, Table: latest_milestone",
  "system_current_value_ods_description": "Schema: odsprd, Table: BLSTATUS",
  "system_current_value_apple_trck_description": "Schema: trckprd, Table: hawd_records, dn_shipments",
  "system_change_logs_mop_description": "Schema: tracking, Table: milestone_history",
  "system_change_logs_apple_trck_description": "Schema: trckprd, Table: edi_milestone_logs",
  "dataflow_sync_logs_ods_description": "Schema: data_flow, Table: log_blstatus",
  "dataflow_sync_logs_apple_trck_description": "Schema: data_flow, Table: trck_milestone_log",
  "mop_log_at_utc": "MOP Log At (UTC)",
  "process_message": "Process Message",
  "datapool_created_at_utc": "Datapool At (UTC)",
  "updated_by": "Updated By",
  "user_apple_trck": "Apple Trck Information",
  "shipment_dimension_title": "Shipment Dimension",
  "shipment_dimension_description": "Apply on Shipment, Billing, TMS, CFS, Tracking, Comprehensive",
  "master_dimension_title": "Master Dimension",
  "master_dimension_description": "Apply on Master, Routing",
  "user_dimension_title": "User Dimension",
  "user_dimension_description": "Apply on Job weighting, Dashboard, Task",
  "master_origin_port": "MAWB Orig",
  "master_destination_port": "MAWB Dest",
  "bamboo_location": "Bamboo Location",
  "appletrckrole_superuser": "SUPERUSER",
  "appletrckrole_exporter": "EXPORTER",
  "appletrckrole_export_supervisor": "EXPORT_SUPERVISOR",
  "appletrckrole_importer": "IMPORTER",
  "appletrckrole_import_supervisor": "IMPORT_SUPERVISOR",
  "appletrckrole_client": "CLIENT",
  "appletrckrole_finance_and_accounting": "FINANCE_AND_ACCOUNTING",
  "appletrckrole_control_tower": "CONTROL_TOWER",
  "appletrckrole_fd_user": "FD_USER",
  "appletrckrole_fd_importer_user": "FD_IMPORTER_USER",
  "appletrckrole_fd_exporter_user": "FD_EXPORTER_USER",
  "generate_password": "Generate Password",
  "get_token": "Get Token",
  "privilege_role": "Privilege Role",
  "do_you_want_to_send_email_to_user": "Do you want to send email to user?",
  "send_welcome_email_to_user": "Send Welcome Email To User",
  "simulate_login_user": "Simulate Login User",
  "check_privilege": "Check Privilege",
  "privilege_not_found": "Privilege not found",
  "error_role_policy_submit": "Following roles need to set policy",
  "error_role_name_duplicate": "Duplicate role name"
 },
 "task_mgmt": {
  "all__issue_type__count": "Task Count",
  "all_task": "All Task",
  "all_tasks": "All Tasks",
  "assign_at_utc": "Assign At (UTC)",
  "assignee": "Assignee",
  "assignee_reason": "Reason for Assigning",
  "category": "Category",
  "client_id": "Client ID",
  "closed_by": "Closed By",
  "complete_invalid_fd_pr_in_apple_trck": "Complete Invalid FD/PR in Apple TRCK",
  "complete_missing_milestone": "Complete Missing Milestone",
  "complete_missing_milestone_in_apple_trck": "Complete Missing Milestone in Apple TRCK",
  "complete_missing_shipment_data": "Complete Missing Shipment Data",
  "complete_missing_shipment_data_in_prolink": "Complete Missing Shipment Data in Prolink/CW1",
  "complete_missing_tms_data": "Complete Missing TMS Data",
  "complete_missing_invoice": "Complete Missing Invoice",
  "complete_missing_voucher": "Complete Missing Voucher",
  "create_valid_quotation_in_prolink": "Create Valid Quotation in Prolink/CW1",
  "correct_shipment_data_in_prolink": "Correct Shipment Data in Prolink/CW1",
  "created_at": "Created At (UTC)",
  "created_at_utc": "Created At (UTC)",
  "created_by": "Created By",
  "days": "Days",
  "days_elapsed": "%{days} Days Elapsed",
  "department": "Dept",
  "description": "Description",
  "do_you_want_to_change_task_status_to_exemption": "Exempt the selected task(s) from KPI calculation?",
  "escalation_at_utc": "Escalation at (UTC)",
  "escalation_owner": "Escalation Owner",
  "event_date_and_time_local": "Event Local Date Time",
  "event_location": "Event Location",
  "exempted": "KPI Exempted",
  "exemption": "KPI Exemption",
  "expected_due_date": "Expected Due Date",
  "expected_due_date_utc": "Expected Due Date (UTC)",
  "general_guidelines": "General Guideline",
  "urgent": "Urgent",
  "high": "High",
  "hours": "Hours",
  "instruction": "Instruction",
  "is_qa_team_assigned": "QA Team Assigned",
  "issue_type": "Issue Type",
  "issue_type_aged_booking": "Aged Booking",
  "issue_type_ar_overdue": "AR Overdue",
  "issue_type_booking": "Booking",
  "issue_type_consol_data": "Consol Data",
  "issue_type_document": "Document",
  "issue_type_edi_failure": "EDI Failure",
  "issue_type_invoice_data": "Invoice Data",
  "issue_type_late_billing": "Late Billing",
  "issue_type_milestone": "Milestone",
  "issue_type_voucher_data": "Voucher Data",
  "issue_type_quotation": "Quotation",
  "issue_type_shipment_data": "Shipment Data",
  "issue_type_tms_data": "TMS Data",
  "issue_type_unclosed_console": "Close Consol in Prolink",
  "issue_type_tms_milestone": "TMS Milestone",
  "issue_type_tms_document": "TMS Document",
  "issue_type_tms_po": "TMS PO",
  "issue_type_log_milestone": "LOG Milestone",
  "issue_type_log_invoice_data": "LOG Invoice Data",
  "issue_type_log_voucher_data": "LOG Voucher Data",
  "issue_type_log_shipment_data": "LOG Shipment Data",
  "issue_type_log_document": "LOG Document",
  "issue_type_booking_request": "Booking Request",
  "leave_a_comment_in_apple_trck": "Leave a comment for latest update in Apple TRCK",
  "leave_an_exception": "Leave an Exception or Comment",
  "confirm_booking_request": "Confirm Booking Request",
  "leave_a_comment": "Leave a comment",
  "linked_issues": "Linked Issues",
  "linked_task_number_list": "Linked task number list",
  "low": "Low",
  "medium": "Medium",
  "my_task": "My Task",
  "my_tasks": "My Tasks",
  "my_watching": "My Watching",
  "or_specify_who_or_what_is_stopping_you_from_closing_the_task": "Or specify who or what is stopping you from closing the task",
  "or_to_leave_your_reason_and_latest_update_by": "Or to leave your reason and latest update:",
  "overdue": "Overdue (hours)",
  "overdue_tip": "Number",
  "is_overdue": "Is Overdue?",
  "overdue__issue_type__count": "Late Task Count",
  "overdue__overdue__avg": "Average Lateness (Hours)",
  "overdue_percentage": "Overdue (%)",
  "pending_a_feedback_from": "Pending a feedback from",
  "pending": "Pending",
  "pending_reason": "Pending Reason",
  "priority": "Priority",
  "reason_code": "Reason Code",
  "reference_no": "Reference NO.",
  "reference_number": "Reference Number",
  "reference_type": "Reference Type",
  "search_for_issues": "Search for Issues",
  "search_for_tags": "Search for tags",
  "search_issue": "Search Task",
  "shipment_mode": "Shipment mode",
  "shipment_number": "Shipment No",
  "specify_your_reason_for_stopping": "Specify your reason for stopping the Task",
  "specify_your_reason_for_late_billing": "Specify your reason for late billing",
  "task_watched": "Watched",
  "watch_task": "Watch",
  "start_watching": "Start Watching",
  "station": "Station",
  "status": "Status",
  "stop_watching": "Stop Watching",
  "tags": "Tags",
  "target_due_date": "Target Resolution Date",
  "target_due_date_utc": "Target Resolution Date (UTC)",
  "task_closed_at_utc": "Task Closed At (UTC)",
  "task_closed_at": "Task Closed At (UTC)",
  "task_detail_general_quidelines_terms": "General Guidelines:\n1.    Task assignee is responsible for completing the task to close the issue timely.\n2.    Under some situations, assignee will not be able to close task timely. Please leave a comment or exception to provide more detail and the reason. \n3.    Change the target resolution date to the time when you expect it to be solved by yourself.\n4.    Link the task, if your task is dependent with others.",
  "task_id": "Task Number",
  "task_status": "Task Status",
  "task_status_action_taken": "Action Taken",
  "task_status_assigned": "Assigned",
  "task_status_closed": "Closed",
  "task_status_in_progress": "In Progress",
  "task_status_open": "Open",
  "task_status_reopen": "Re-open",
  "task_status_unassigned": "Unassigned",
  "this_will_trigger_edi_to_customer": "This will trigger EDI to customer",
  "title": "Title",
  "to_close_task_by": "To close task (status refreshes every 10 mins)",
  "Type": "Type",
  "type_number_of_overdue": "Type Days of Overdue",
  "typing_title_or_task_number_or_reference_no": "Typing title, task number, reference No.",
  "updated_at_utc": "Updated at (UTC)",
  "updated_by": "Updated By",
  "upload_documents": "Upload Documents",
  "upload_documents_in_apple_trck": "Upload Documents in Apple TRCK",
  "upload_the_invoice_document_and_check_the_rate_in_apple_trck": "Upload the Invoice Document and check the rate in Apple TRCK",
  "vip_name": "Customer",
  "watcher_list": "Watcher List",
  "last_comment": "Last comment",
  "delivery_number": "Delivery No",
  "master_number": "MAWB/MBL No",
  "vessel_name_list": "Flight/Vessel No",
  "voyage_number_list": "Voyage No",
  "exemption_true": "Yes",
  "exemption_false": "No",
  "stakeholder": "Pending A Feedback",
  "stakeholder_true": "Yes",
  "stakeholder_false": "No",
  "region": "Region",
  "division": "Division",
  "issue_type_wms_consignment_data": "WMS Consigment Data",
  "issue_type_wms_dn_data": "WMS DN Data",
  "issue_type_production_support": "Production Support",
  "week_year": "Week",
  "choose_actions": "Choose Actions",
  "exempt_kpi": "KPI Exempt",
  "add_comment": "Add Comment",
  "add_pending_reason": "Add Pending Reason",
  "add_late_billing_reason": "Add Late Billing Reason",
  "close_task": "Close Task",
  "reassign": "Re-assign",
  "info_to_update_target_due_date": "Please update your target resolution date if this task cannot be completed by the expected due date.",
  "warn_to_update_target_due_date": "Please update your target resolution date because this task has been overdue.",
  "assignee_reason_prolink_created_by_hawb": "HAWB/HBL Creator",
  "assignee_reason_prolink_created_by_booking": "Booking Creator",
  "assignee_reason_consol_created_by": "Consol Creator",
  "assignee_reason_op_manager": "OP Manager",
  "assignee_reason_station_head": "Station Head",
  "assignee_reason_regional_qa": "Regional QA",
  "assignee_reason_assignee_config": "Designated Assignee",
  "assignee_reason_agent_compensate_prolink_created_by": "Agent Station",
  "assignee_reason_trck_created_by": "TRCK HAWB/HBL Creator",
  "assignee_reason_hard_assign": "Reassigned",
  "assignee_reason_tip_prolink_created_by_hawb": "HAWB/HBL Creator",
  "assignee_reason_tip_prolink_created_by_booking": "Booking Creator",
  "assignee_reason_tip_consol_created_by": "Consol Creator",
  "assignee_reason_tip_op_manager": "No HAWB/HBL Creator in Prolink/CW1, thus the Task is assigned to you as the Department Manager.",
  "assignee_reason_tip_station_head": "No HAWB/HBL creator and Department Manager, thus the Task is assigned to you as the Station Head.",
  "assignee_reason_tip_regional_qa": "No HAWB/HBL creator, Department Manager and Station Head, thus the Task is assigned to you as the Regioanal QA.",
  "assignee_reason_tip_assignee_config": "Designated person of this Task category by default",
  "assignee_reason_tip_agent_compensate_prolink_created_by": "To take care of agent Station for VIP customer shipments, and you are the one who processed the HAWB/HBL at your Station in Prolink/CW1.",
  "assignee_reason_tip_trck_created_by": "You are the one who created the HAWB in TRCK",
  "assignee_reason_tip_hard_assign": "Manually Re-assigned",
  "upload_documents_bol": "Upload Documents to BOL",
  "confirm_documents": "Check POD Document of Shipment",
  "task_created_at (in nth week)": "Created At (UTC)",
  "add_comments": "Add comments",
  "add_watchers": "Add Watchers",
  "advised_action": "Advised Action",
  "aging": "Aging",
  "choose_category": "Choose Category",
  "choose_subcategory": "Choose Subcategory",
  "late_reason_category": "Late Reason Category",
  "late_reason_code": "Late Reason Code",
  "late_reason_subcategory": "Late Reason Subcategory",
  "late_reason_subcode": "Late Reason Subcode",
  "can_not_perform_action": "No corresponding advised action. Cannot proceed the actions on %{taskNumbers}",
  "selected_task_count": "Total Records: %{count}",
  "you_cannot_close_the_task_without_a_comment": "You cannot close the task without a comment",
  "comment": "Comment",
  "comment_here": "Comment here",
  "option_milstone_task_closed_at": "Task Closed At (UTC)",
  "option_milstone_task_created_at": "Created At (UTC)",
  "option_milstone_task_expected_due_date": "Expected Due Date (UTC)",
  "task_expected_due_date": "Expected Due Date (UTC)",
  "system_source": "Export System Source",
  "import_system_source": "Import System Source",
  "prolink_export_created_by": "Prolink Export Created By (HAWB/HBL)",
  "prolink_import_created_by": "Prolink Import Created By (HAWB/HBL)",
  "quotation_number": "Quotation Number",
  "incoterm": "Incoterm",
  "freight_term": "Freight Term",
  "origin_port": "Origin Station",
  "destination_port": "Dest Port",
  "gross_weight": "Gross Weight",
  "package_quantity": "Package Qty",
  "etd": "ETD",
  "eta": "ETA",
  "atd": "ATD",
  "ata": "ATA",
  "service_type": "Service Type",
  "service_level": "Service Level",
  "estimated_pickup_at": "Estimated Pickup",
  "actual_pickup_at": "Picked Up",
  "doc_released_at": "Document Released",
  "customs_released_at": "Customs Released",
  "arrived_cfs_at": "Arrived at Dest CFS",
  "cargo_receipt_at": "Cargo Receipt",
  "shipper_id": "Shipper Code",
  "shipper_name_1": "Shipper Name",
  "payer": "Payer",
  "payer_name": "Payer Name",
  "master_number_2": "2nd MAWB/MBL",
  "lot_number_1": "1st Lot No ",
  "lot_number_2": "2nd Lot No",
  "voyage_name_1": "1st Voyage  Name",
  "voyage_name_2": "2nd Voyage  Name",
  "vessel_name_1": "1st Vessel Name",
  "vessel_name_2": "2nd Vessel Name",
  "out_for_delivery_date": "Out For Delivery",
  "actual_delivery_date": "Actual Delivery Date",
  "consignee_id": "Consignee Code",
  "consignee_name_1": "Consignee Name",
  "notify_id": "Notify Party Code",
  "notify_name_1": "Notify Party Name",
  "inv_vou_number": "Invoice/Voucher No",
  "inv_vou_trade_type": "Invoice/Voucher Trade Type",
  "inv_vou_bill_to_credit_to": "Debit To/ Vendor",
  "inv_vou_print_at": "Invoice/Voucher Print Date",
  "inv_vou_print_by": "Invoice/Voucher Print By",
  "can_not_perform_comment_action": "No corresponding advised action. Cannot proceed the actions on",
  "consignee_state": "Consignee State",
  "consignee_city": "Consignee City",
  "origin_port_list": "Origin Port",
  "origin_country": "Origin Country",
  "origin_region": "Origin Region",
  "destination_country": "Dest Country",
  "destination_region": "Dest Region",
  "config_priority": "Config Priority",
  "first_assignee_type": "1st Assignee Type",
  "first_assignee": "1st Assignee",
  "second_assignee_type": "2nd Assignee Type",
  "second_assignee": "2nd Assignee",
  "handling_station": "Handling Station",
  "lock": "Lock",
  "unlock": "Unlock",
  "first_assignee_status": "1st Assignee Status",
  "second_assignee_status": "2nd Assignee Status",
  "inactive_assignee_status": "inactive",
  "watcher": "Watcher",
  "cancel": "Cancel",
  "clone": "Clone",
  "name": "Name",
  "error_assignee_config_is_duplicated": "Assignee Config Setting Already Exist.",
  "error_assignee_is_not_exists": "Assignee Is Not Exists.",
  "error_can_not_find_reference_type": "Can't find the reference type.",
  "error_assignee_config_is_not_exists": "Assignee Config Is Not Exists.",
  "error_task_asignee_create": "Create assignee config errror.",
  "error_task_asignee_update": "Update assignee config errror.",
  "error_task_asignee_delete": "Delete assignee config errror.",
  "error_task_asignee_lock": "Change lock status error.",
  "error_issue_type_must_have_value": "issue type must have input value.",
  "error_category_must_have_value": "category must have input value.",
  "error_mode_list_must_have_value": "mode must have input value.",
  "error_handling_station_list_must_have_value": "handling station must have input value.",
  "error_client_id_list_must_have_value": "client_id must have input value.",
  "error_vip_name_list_must_have_value": "vip_name must have input value.",
  "error_consignee_state_list_must_have_value": "consignee state must have input value.",
  "error_consignee_city_list_must_have_value": "consignee city must have input value.",
  "error_origin_port_list_must_have_value": "origin port must have input value.",
  "error_origin_country_list_must_have_value": "origin country must have input value.",
  "error_origin_region_list_must_have_value": "origin region must have input value.",
  "error_dest_port_list_must_have_value": "destination port must have input value.",
  "error_dest_country_list_must_have_value": "destination country  must have input value.",
  "error_dest_region_list_must_have_value": "destination region must have input value.",
  "error_first_assignee_type_must_have_value": "first assignee type must have input value.",
  "error_first_assignee_must_have_value": "first assignee must have input value.",
  "error_second_assignee_type_must_have_value": "second assignee type  must have input value.",
  "error_second_assignee_must_have_value": "second assignee must have input value.",
  "error_config_priority_must_have_value": "config priority must have input value.",
  "input_is_limited_to_max_selections": "input is limited to %{max} selections",
  "lock_assignee_config_cant_be_delete": "Lock assignee config can't be delete",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "Unsaved changes will be lost, are you sure you want to leave?",
  "customer_code_name": "Customer Code/Name",
  "delete_comment_failed": "Delete comment failed ",
  "assignee_reason_dispatch_created_by": "Dispatch Creator",
  "assignee_reason_invoice_printed_by": "Invoice Printed By",
  "assignee_reason_invoice_created_by": "Invoice Created By",
  "assignee_reason_loading_plan_generator": "Loading Plan Generator",
  "assignee_reason_assignee_supervisor": "Assignee's Supervisor",
  "assignee_reason_shipment_created_by": "Shipment Creator",
  "assignee_reason_tip_dispatch_created_by": "Dispatch Creator",
  "assignee_reason_tip_invoice_printed_by": "Invoice Printed By",
  "assignee_reason_tip_invoice_created_by": "Invoice Created By",
  "assignee_reason_tip_loading_plan_generator": "Loading Plan Generator",
  "assignee_reason_tip_assignee_supervisor": "Assignee's Supervisor",
  "assignee_reason_tip_shipment_created_by": "Shipment Creator",
  "complete_missing_invoice_in_prolink": "Complete Missing Invoice In Prolink",
  "complete_missing_charge_in_prolink": "Complete Missing Charge In Prolink",
  "complete_missing_voucher_in_prolink": "Complete Missing Voucher In Prolink",
  "complete_unconfirm_cost_in_prolink": "Complete Unconfirm Cost In Prolink",
  "complete_unprint_invoice_in_prolink": "Complete Unprint Invoice In Prolink",
  "issue_type_sentry": "Sentry",
  "issue_type_non_trade_voucher_data": "Non-trade voucher",
  "issue_type_non_trade_invoice_data": "Non-trade invoice",
  "origin_iata_region": "Origin IATA Region",
  "destination_iata_region": "Dest IATA Region",
  "customer_name_code": "Customer/Code",
  "more_fields": "More Fields",
  "comments": "Comments",
  "due_date": "Due Date",
  "complete_job_duty": "Complete tasks in job duty",
  "more_fields_header_children": "KPI Exemption, add tags, Reference No…",
  "upload_photo_on_cfs": "to upload crate photo on CFS",
  "issue_type_jobduty_need_action": "Job Duty Need Action",
  "config_id": "Config ID",
  "task_assignee_config_priority_p1": "1st Priority",
  "task_assignee_config_priority_p2": "2nd Priority ",
  "task_assignee_config_priority_p3": "3nd Priority",
  "task_assignee_config_priority_p4": "4nd Priority",
  "task_assignee_config_priority_p5": "5nd Priority",
  "task_assignee_config_priority_p6": "6nd Priority",
  "task_assignee_config_priority_low": "Low",
  "task_assignee_config_priority_medium": "Medium",
  "task_assignee_config_priority_high": "High",
  "task_assignee_type_job_group": "JOB_GROUP",
  "task_assignee_type_email": "EMAIL",
  "task_assignee_group_prolink_import_created_by": "PROLINK_IMPORT_CREATED_BY",
  "task_assignee_group_prolink_export_created_by": "PROLINK_EXPORT_CREATED_BY",
  "task_assignee_group_invoice_created_by": "INVOICE_CREATED_BY",
  "task_assignee_group_console_created_by": "CONSOLE_CREATED_BY",
  "task_assignee_group_consol_created_by": "CONSOL_CREATED_BY",
  "task_assignee_group_invoice_printed_by": "INVOICE_PRINTED_BY",
  "task_assignee_group_booking_created_by": "BOOKING_CREATED_BY",
  "task_assignee_group_dispatch_created_by": "DISPATCH_CREATED_BY",
  "task_assignee_group_loading_plan_generator": "LOADING_PLAN_GENERATOR",
  "task_assignee_group_shipment_created_by": "SHIPMENT_CREATED_BY",
  "task_assignee_group_op_manager": "OP_MANAGER",
  "task_assignee_group_transshipment_source_created_by": "TRANSSHIPMENT_SOURCE_CREATED_BY",
  "is_overdue_true": "Yes",
  "is_overdue_false": "No",
  "region_apac": "APAC",
  "region_emea": "EMEA",
  "region_amer": "AMER",
  "delete_this_config": "Delete this config : %{id}?",
  "sec_sales": "Secure Sales",
  "chargeable_weight": "Chargeable Weight",
  "delivery_teu": "Delivery TEU",
  "shipment_teu": "Shipment TEU",
  "reassign_by": "Reassigned by",
  "reassign_at_utc": "Reassigne date",
  "any_inactive": "Any Inactive",
  "both_active": "Both Active",
  "assignee_status": "Assignee Status",
  "assignee_status_active": "Active",
  "assignee_status_inactive": "Inactive",
  "error_late_billing_not_allowed": "This Task is not related to Late Billing, this category is not allowed",
  "search_issue_tooltip_text": "Fuzzy search for Shipment Number, Task Number, or Reference Number",
  "complete_missing_milestone_ofd_dispatch": "Input OFD Time to Dispatch",
  "complete_missing_milestone_pod_bol": "Input POD Time to BOL",
  "complete_missing_tms_data_assign_to_carrier": "Assign Shipment to Carrier",
  "complete_missing_tms_data_create_dispatch": "Create Dispatch",
  "complete_missing_tms_data_create_po": "Create PO Request of Dispatch",
  "leave_shipment_exception": "Leave an Shipment Exception",
  "undefined": ""
 },
 "user_registration_mgmt": {
  "active_description": "Actived User",
  "all": "All",
  "carrier_code": "Carrier Code",
  "client_id": "Client ID",
  "clients_account_appliction_records": "Client Account Application Records",
  "company_name": "Company Name",
  "destination_port": "Destination Port",
  "disabled_desciption": "User have created, but  have not invited",
  "invitation": "Invitation",
  "origin_port": "Origin Port",
  "password_rest_description": "Password reset",
  "request_date": "Request Date",
  "search_key": "Search Key",
  "shipment_mode": "Shipment Mode",
  "site": "Site",
  "station": "Station",
  "submitted_description": "Send Application form",
  "system": "System",
  "tracking_slash_shipment": "Tracking / Shipment",
  "tracking_slash_spend_report": "Tracking / Spend Report",
  "vip_name": "VIP Name",
  "wait_verified_description": "Send Invitation email",
  "your_data_authority": "Your data authority"
 },
 "wms": {
  "alert_check_customer_pickup_shipments": "This selection including %{shipmentCounts} shipments with “Self pick-up” service type.(WHSP) \nIncluding Consignment: %{shipmentNumbers}.\nPlease change service type to “To Door” type (WHTD) before entering dispatch creation.",
  "alert_check_customer_pickup_before_check_in_counter": "This selection including %{shipmentCounts} shipment(s) with “To port”  service type ( ATA or DTA or PTP or DTP or ATP or PTA) .\nIncluding HAWB: %{shipmentNumbers}.\nPlease change service type to “To Door” type (ATD or DTD or PTD) before Assign Carrier.",
  "warning_has_dispatch_shipments": "Warning:\n\nThis selection including %{shipmentCounts} shipments have already had OFD/POD.\nSystem will update OFD & POD timestamps and POD files on these shipments based on TMS .\nIncluding Consignment: %{shipmentNumbers}.\n\nDo you want to proceed DN & Dispatch creation?",
  "bol_details": "DN Details",
  "bol_grouping_rule": "DN Grouping Rule",
  "cancel_quotation_and_continue_to_cancel_this_bill_of_lading": "Accepted quotation will be canceled if delete DN %{bol_number} . Do you still want to delete BOL/ DN ? ",
  "cancel_quotation_and_delete_dispatch_dispatchnumber": "Accepted quotation will be canceled if delete Dispatch %{dispatchnumber} . Do you want to delete Dispatch ? ",
  "dispatch_station": "Station",
  "tracking_device": "Tracking Device No",
  "actual_driver_arrival_time": "Actual Driver Arrival Time",
  "actual_out_for_delivery_date": "OFD Date",
  "actual_out_for_delivery_time": "OFD Time",
  "actual_ship_method": "Actual Shipping Method",
  "advanced_search": "Advanced Search",
  "arrival_date": "Arrival Date",
  "arrival_time": "Arrival Time",
  "arrival_type": "Arrival Type",
  "asn": "ASN#",
  "asn_number": "ASN Number",
  "asn_qty": "ASN QTY",
  "assistant_driver_1_name": "Assistant Driver 1",
  "assistant_driver_2_name": "Assistant Driver 2",
  "available": "Available",
  "available_qty": "Available QTY",
  "bid_required": "Bid Required",
  "billing_period": "Billing Period",
  "bol_dn_no": "DN No",
  "bol_dn_type": "Shipment Leg",
  "bol_no": "DN No",
  "bol_status": "DN Status",
  "bol_status_update": "DN Status Update",
  "booking_status": "Quotation Status",
  "cancelled": "Cancelled",
  "carrier": "Carrier",
  "carrier_service_level": "Carrier Svc Lvl",
  "close_date": "Close Date",
  "commodity": "Commodity",
  "completed_date": "Completed Date",
  "complete_time": "Complete Time",
  "confirm_complete": "Confirm Complete",
  "confirm_report": "Confirmation Report",
  "consignment": "Consignment",
  "consignment_detail": "Consignment Detail",
  "consignment_no": "Consignment No",
  "consignment_pcs": "Consignment Pcs",
  "consignment_progress": "Consignment Progress",
  "country": "Country/Area",
  "creation_date": "Creation Date",
  "custom_ref": "Cust Ref#",
  "customer": "Customer",
  "customer_name": "Customer Name",
  "customer_ref": "Customer Reference",
  "damage_qty": "Damage QTY",
  "date_from": "Date From",
  "date_to": "Date To",
  "day_0_30": "< 30 Days",
  "day_121_180": "121 - 180 Days",
  "day_181_270": "181 - 270 Days",
  "day_271_360": "271 - 360 Days",
  "day_31_60": "31 - 60 Days",
  "day_360_plus": "> 360 Days",
  "day_61_90": "61 - 90 Days",
  "day_91_120": "91 - 120 Days",
  "delivery_count": "#SOs",
  "delivery_date": "Delivery Date",
  "delivery_from": "Target Delivery From",
  "delivery_method": "Delivery Method",
  "delivery_no": "SO",
  "delivery_to": "To",
  "detail": "Detail",
  "dimensions": "Dimensions",
  "dispatch_creation_date": "Dispatch Creation Date",
  "dispatch_delivery_method": "Dispatch Delivery Method",
  "dispatch_no": "Dispatch No",
  "dispatch_planned_ofd_date": "Dispatch Planned OFD Date",
  "dispatch_status": "Dispatch Status",
  "dispatch_type": "Dispatch Type",
  "dispatchDeliveryMethod": "Delivery Method",
  "dispatchShipmentMode": "Shipment Mode",
  "dn_carrier": "DN Carrier",
  "dn_carrier_service_level": "DN Carrier Svc Level",
  "dn_details": "DN Details",
  "dn_group_rule": "DN Grouping Rule",
  "dn_no": "DN No",
  "dn_status": "DN Status",
  "document_date": "Document Date",
  "edi_date": "EDI Date",
  "edi_ship_method": "EDI Shipping Method",
  "edi_time": "EDI Time",
  "error_client_not_provided": "The WMS client is not provided",
  "europe_destination_region": "EU Dest",
  "event": "Event",
  "exception_message": "Exception Message",
  "fast_delete_booking_status_tips_accepted": "The quotation will be canceled if you remove the shipment from the BOL/ DN. Are you sure to continue?",
  "fast_delete_booking_status_tips_submitted": "The carrier's quotation is submitted, and the removal of the shipment from the BOL/ DN will cancel the quotation at the sam time. Are you sure you want to remove the shipment from the BOL/ DN?",
  "fragile": "Fragile",
  "gross_weight": "Gross Weight",
  "gw": "GW",
  "hawb_bl": "HAWB / BL",
  "hawb_count": "# Consignments",
  "hawb_no": "Consignment No",
  "hazardous": "Hazardous",
  "height": "Height",
  "inbound_detail": "Inbound Detail",
  "inbound_header": "Inbound",
  "inbound_ref": "Inbound Ref No",
  "inbound_scheduled_date": "Inbound Scheduled Date",
  "inbound_scheduled_time": "Inbound Scheduled Time",
  "inbound_start_date": "Inbound Start Date",
  "inbound_start_time": "Inbound Start Time",
  "instruction": "Special Instruction",
  "inv_damage": "Damage",
  "inv_expired": "Expired",
  "inv_good": "Good",
  "inv_hold": "On-Hold",
  "inv_quarantine": "Quarantine",
  "inv_rma": "RMA",
  "inv_special": "Special",
  "length": "Length",
  "line_number": "Line No",
  "location": "Location",
  "loose_ctn_qty": "Loose Ctn Qty",
  "notification": "Notification",
  "number": "No",
  "ofd_departed_from_warehouse": "OFD(Departed from Warehouse)",
  "ofd_time": "OFD Time",
  "onhold_qty": "On-Hold QTY",
  "order": "Order#",
  "order_date": "Order Date",
  "order_info": "Order Info",
  "order_number": "Order Number",
  "order_picked_date": "Order Picked Date",
  "order_picked_time": "Order Picked Time",
  "order_qty": "Order QTY",
  "order_start_date": "Order Start Date",
  "order_start_time": "Order Start Time",
  "order_time": "Order Time",
  "order_type": "Order Type",
  "ordered_qty": "Ordered QTY",
  "orig_line_number": "Original Line Number",
  "outbound_attachment": "Outbound Attachment",
  "outbound_detail": "Outbound Detail",
  "outbound_header": "Outbound",
  "outbound_package": "Outbound Package",
  "outbound_sn": "Outbound SN",
  "package_id": "Package ID",
  "package_info": "Package Info",
  "package_unit": "Package Unit",
  "packed_date": "Packed Date",
  "packed_qty": "Packed QTY",
  "packed_time": "Packed Time",
  "payer_account": "Payer Account",
  "payment_method": "Payment Method",
  "payment_to_carrier_by": "Payment to Carrier by",
  "pick_qty": "Pick QTY",
  "picked_date": "Picked Date",
  "picked_qty": "Picked QTY",
  "picked_time": "Picked Time",
  "pid": "PID",
  "planned_driver_arrival_date": "Planned Driver Arrival Date",
  "planned_driver_arrival_time": "Planned Driver Arrival Time",
  "planned_out_for_delivery_time": "Planned OFD Time",
  "plt_qty": "Plt Qty",
  "po_received_date": "EDI Date",
  "po_received_time": "PO Received TIme",
  "pod": "POD Time",
  "pod_date": "POD Date",
  "pod_file": "POD File",
  "preassigned_carrier": "Pre-assigned Carrier",
  "preassigned_carrier_service_level": "Pre-assigned Carrier Svc Level",
  "primary_driver_license_no": "Primary Driver License No",
  "primary_driver_name": "Primary Driver Name",
  "primary_driver_phone_no": "Primary Driver Phone No",
  "print": "Print",
  "print_label": "Print Label",
  "priority": "Priority",
  "product_desc": "Product Desc",
  "product_sku": "SKU",
  "product_sn": "Serial No",
  "quantity": "Quantity",
  "query": "Query",
  "query_date": "Query Date",
  "query_ref": "Reference",
  "rcv_date": "Received Date",
  "rcv_qty": "RCV QTY",
  "received_date": "Received Date",
  "received_qty": "Received QTY",
  "received_time": "Received Time",
  "reference": "Reference",
  "tms": "Reference Files",
  "reference_files": "Reference Files",
  "reference_no": "Reference No",
  "remark_general": "Warehouse Instruction",
  "remark_label": "Note To Customer",
  "remark_receive": "Receiving Note",
  "remark_shipping": "Shipping Instruction",
  "remark_topic": "Remark",
  "require_sn": "Require S/N",
  "result": "Result",
  "rma_qty": "RMA QTY",
  "sequence": "Sequence",
  "sharp_bols": "#DNs",
  "sharp_consignments": "#Consignments",
  "sharp_deliveries": "#SOs",
  "sharp_dns": "#DNs",
  "sharp_gross_weight": "#GW",
  "sharp_gw": "#GW",
  "sharp_packages": "#PKGs",
  "sharp_pkgs": "#PKGs",
  "sharp_shipments": "#Consignments",
  "ship_from": "From",
  "ship_mode": "Ship Mode",
  "ship_to": "Ship To",
  "ship_to_city": "To City",
  "ship_to_country": "Ship To Country",
  "sn": "Serial No",
  "to_name": "To Name",
  "ship_to_state": "To State",
  "ship_type": "Ship Type",
  "shipment_customer": "Customer",
  "shipment_mode": "Shipment Mode",
  "shipment_no": "Consignment No",
  "shipped": "Shipped",
  "shipped_date": "Shipped Date",
  "shipped_qty": "Shipped QTY",
  "shipped_time": "Shipped Time",
  "shipper_account": "Shipper Account",
  "site": "Site",
  "sku": "SKU",
  "so": "SO",
  "so_and_package": "SO & Package",
  "so_no": "SO #",
  "sorting_completed_date": "Sorting Completed Date",
  "sorting_completed_time": "Sorting Completed Time",
  "special_delivery_instruction": "Special Delivery Instruction",
  "special_instruction": "Special Instruction",
  "station": "Station",
  "status": "Status",
  "summary": "Summary",
  "svc_type": "Svc Type",
  "tms_shipment_leg": "TMS Shipment Leg",
  "to_address": "To Address",
  "to_city": "To City",
  "to_country": "To Country/Area",
  "to_pack_id": "Pack ID",
  "to_state": "To State",
  "total_gross_weight": "# GW",
  "total_gw": "Total GW",
  "total_lines": "Total Lines",
  "total_packages": "# PKGs",
  "total_qty": "Total QTY",
  "tracking_device_number": "Tracking Device No",
  "tracking_no": "Tracking No",
  "trailer_no": "Trailer No",
  "truck_no": "Truck No",
  "truck_type": "Truck Type",
  "type": "Type",
  "uom": "UOM",
  "urgent_delivery": "Urgent Delivery",
  "valuable": "Valuable",
  "vip_name": "Customer",
  "weight": "Weight",
  "weight_unit": "Weight Unit",
  "width": "Width",
  "will_also_save_fields_confirm": "Will also save fields below: %{change_fields}",
  "wms": "Dual Use",
  "wms_clients": "WMS Clients",
  "pick_area": "Pick Area",
  "sku_description": "SKU Description",
  "new": "New",
  "verification_code": "Verification Code",
  "lot_no": "Lot No",
  "line": "Line #",
  "ship_to_name": "Ship-To Name",
  "post_code": "Postcode",
  "customer_line": "Customer Line #",
  "packed": "Packed",
  "aged_stock": "Aged Stock",
  "bonded": "Bonded",
  "damage": "Damage",
  "expired": "Expired",
  "hold": "Hold",
  "inspection": "Inspection",
  "quarantine": "Quarantine",
  "returns": "Returns",
  "write_off": "Write Off",
  "demo_stock": "Demo Stock",
  "good": "Good",
  "manufacture_date": "Manufacture Date",
  "po_no": "Po No",
  "inbound_sn": "Inbound SN",
  "special_remark": "Special Remark",
  "special": "Special",
  "ship_info": "Ship Info",
  "tracking_number": "Tracking Number",
  "custom_po_number": "Customer PO Number",
  "po_number": "PO Number",
  "reference_number": "Reference Number",
  "remarks": "Remarks",
  "edi_acked": "EDI Acked",
  "ibound_tally_sheet": "Ibound Tally Sheet",
  "picking_list": "Picking List",
  "packing_list": "Packing List",
  "customer_code": "Customer Code",
  "client": "Client",
  "portal_query": "Query",
  "portal_result": "Result",
  "portal_type": "Type...",
  "portal_multiple_keyword": "Multiple Keyword",
  "portal_customer": "Customer",
  "portal_wms_client": "WMS Client",
  "portal_status": "Status",
  "portal_available": "Available",
  "portal_in_progress": "In Progress",
  "portal_completed": "Completed",
  "portal_cancelled": "Cancelled",
  "portal_date": "Date",
  "option_milstone_edi_date": "EDI Date",
  "option_milstone_received_date": "Received Date",
  "portal_keyword_search": "Keyword Search",
  "portal_print_its": "Print ITS",
  "portal_please_tick_asn_with_status_in_progress": "Please tick ASN with Status \"In Progress\"",
  "portal_please_tick_order_with_status_in_progress": "Please tick Order with Status \"In Progress\"",
  "portal_edit_remark": "Edit Remark",
  "portal_please_check_asn_to_edit": "Please tick ASN to edit",
  "portal_confirmation_report": "Confirmation Report",
  "portal_please_specify_received_date_in_date_query_fields": "Please specify Received Date in Date Query Fields",
  "portal_cust_ref": "Cust Ref#",
  "portal_arrival_type": "Arrival Type",
  "portal_hawb_bl": "HAWB / BL",
  "portal_edi_date": "EDI Date",
  "portal_arrival_date": "Arrival Date",
  "portal_received_date": "Received Date",
  "portal_asn": "ASN#",
  "portal_remark": "Remark",
  "portal_so": "SO#",
  "portal_customer_po": "Customer PO#",
  "portal_order_type": "Order Type",
  "portal_ship_to_name": "Ship To Name",
  "portal_country": "Country",
  "portal_total_qty": "Total QTY",
  "portal_order_start_date": "Order Start Date",
  "portal_completed_date": "Completed Date",
  "portal_shipped_date": "Shipped Date",
  "portal_shipped": "Shipped",
  "portal_edi_acked": "EDI Acked",
  "portal_order_number": "Order Number",
  "portal_customer_ref": "Customer Reference",
  "portal_sku": "SKU",
  "portal_serial_number": "Serial Number",
  "portal_tracking_number": "Tracking Number",
  "portal_print_manifest": "Print Manifest",
  "portal_please_tick_order_to_edit": "Please tick order to edit",
  "portal_please_specify_shipped_date_in_date_query_fields": "Please specify shipped date in date query fields",
  "portal_please_input_load_number": "Please Enter Load Number",
  "portal_do_you_want_to_print_this": "Do you want to print this ?",
  "portal_ok": "ok",
  "portal_cancel": "Cancel",
  "portal_print_picking_list": "Print Picking List",
  "portal_no_data": "No Data",
  "portal_invalid_load_number": "Invalid Load Number",
  "portal_miss_options": "Miss Options",
  "portal_fetch_error": "Fetch Error",
  "portal_something_wrong": "Something Wrong",
  "portal_download_error": "Download Error",
  "portal_inbound_detail": "Inbound Detail",
  "portal_site": "Site",
  "portal_reference_no": "Reference No",
  "portal_customer_reference": "Customer Reference",
  "portal_required": "Required",
  "portal_save": "Save",
  "portal_success": "Success",
  "portal_print_success": "Print Success",
  "portal_print_error": "Print Error",
  "portal_inbound_sn": "Inbound SN",
  "portal_po_no": "PO No",
  "portal_receiving_note": "Receiving Note",
  "portal_shipping_instruction": "Shipping Instruction",
  "portal_warehouse_instruction": "Warehouse Instruction",
  "portal_note_to_customer": "Note To Customer",
  "portal_line": "Line #",
  "portal_lot_no": "Lot No",
  "portal_asn_qty": "ASN Qty",
  "portal_received_qty": "Received Qty",
  "portal_manufacture_date": "Manufacture Date",
  "portal_require_sn": "Require S/N",
  "portal_pack_id": "Pack ID",
  "portal_serial_no": "Serial No",
  "portal_sku_desc": "SKU Desc",
  "portal_inbound": "Inbound",
  "portal_remark_too_long_and_limit_to_variable_characters": "Remark too long and limit to %{max} characters",
  "portal_task": "Task",
  "portal_pick_order": "Pick Order",
  "portal_total_lines": "Total Lines",
  "portal_please_enter_picking_list_number": "Please Enter Picking List Number",
  "portal_print_orders": "Print Orders",
  "portal_available_qty": "Available QTY",
  "portal_picked_qty": "Picked QTY",
  "portal_packed_qty": "Packed QTY",
  "portal_marketing_qty": "Marketing QTY",
  "portal_damage_qty": "Damage QTY",
  "portal_order_details": "Order Details",
  "portal_order_info": "Order Info",
  "portal_morrison_asn": "Morrison ASN #",
  "portal_morrison_ord": "Morrison ORD #",
  "portal_morrison_customer_code": "Morrison Customer Code",
  "portal_open_date": "Open Date",
  "portal_ship_date": "Ship Date",
  "portal_ship_to": "Ship To",
  "portal_ship_to_code": "Ship To Code",
  "portal_address": "Address",
  "portal_city": "City",
  "portal_zip": "Zip",
  "portal_tel": "Tel",
  "portal_shipping_info": "Shipping Info",
  "portal_expect_ship_method": "Expect Ship Method",
  "portal_actual_ship_method": "Actual Ship Method",
  "portal_courier_tracking_number": "Courier Tracking Number",
  "portal_shipping_cost": "Shipping Cost",
  "portal_package_count": "Package Count",
  "portal_sold_to": "Sold To",
  "portal_sold_to_code": "Sold To Code",
  "portal_sold_to_name": "Sold To Name",
  "portal_incoterm_one": "Incoterm1",
  "portal_incoterm_two": "Incoterm2",
  "portal_special_instructions": "Special Instructions",
  "portal_priority": "Priority",
  "portal_special_note_message_of_consignee": "Special Note (Message of Consignee)",
  "portal_processing_instructions_message_to_warehouse_operators": "Processing Instructions (Message to Warehouse Operators)",
  "portal_documents": "Documents",
  "portal_order_items": "Order Items",
  "portal_packing_details": "Packing Details",
  "portal_customer_line": "Customer Line #",
  "portal_customer_sku": "Customer SKU",
  "portal_description": "Description",
  "portal_plant_delta": "Plant (Delta)",
  "portal_location_delta": "Location (Delta)",
  "portal_order_qty": "Order Qty",
  "portal_shipped_qty": "Shipped Qty",
  "portal_pack_id_box": "Pack ID (Box #)",
  "portal_courier": "Courier",
  "portal_length_cm": "Length (cm)",
  "portal_width_cm": "Width (cm)",
  "portal_hight_cm": "Hight (cm)",
  "portal_weight_kg": "Weight (kg)",
  "portal_desc": "Desc",
  "portal_qty": "Qty",
  "portal_OFD": "OFD",
  "portal_po": "Po",
  "portal_order_line": "Order Line",
  "portal_order_quantity": "Order Quantity",
  "portal_edi_ship_method": "EDI Ship Method",
  "portal_edi_time": "EDI Time",
  "portal_order_date": "Order Date",
  "portal_order_time": "Order Time",
  "portal_picked_date": "Picked Date",
  "portal_picked_time": "Picked Time",
  "portal_packed_date": "Packed Date",
  "portal_packed_time": "Packed Time",
  "portal_customer_name": "Customer Name",
  "portal_customer_order_no": "Customer Order No",
  "portal_wms_order_no": "WMS Order No",
  "portal_order_comparison": "Order Comparison",
  "portal_update_comparison": "Update Comparison",
  "portal_difference_export": "Difference Export",
  "portal_cust_line": "Cust Line",
  "portal_product": "Product",
  "portal_cust_qty": "Cust QTY",
  "portal_wms_qty": "WMS QTY",
  "portal_category": "Category",
  "portal_wms_line": "WMS Line",
  "portal_please_select_upload_template": "Please select upload template",
  "portal_wrong_template": "Wrong template",
  "portal_no_difference": "No difference",
  "portal_comparing_result": "Comparing Result",
  "portal_upload": "Upload",
  "portal_client": "Client",
  "portal_state": "State",
  "portal_post_code": "Postcode",
  "portal_phone": "Phone",
  "portal_outbound_detail": "Outbound Detail",
  "portal_outbound_package": "Outbound Package",
  "portal_outbound_sn": "Outbound SN",
  "portal_product_sku": "Product SKU",
  "portal_commodity": "Commodity",
  "portal_product_desc": "Product Desc",
  "portal_ordered_qty": "Ordered Qty",
  "portal_package_info": "Package Info",
  "portal_package_id": "Package ID",
  "portal_dimensions": "Dimensions",
  "portal_weight": "Weight",
  "portal_quantity": "Quantity",
  "portal_outbound_attachment": "Outbound Attachment",
  "portal_print_put_back_list": "Print Put Back List",
  "portal_please_enter_customer_order_number": "Please Enter Customer Order Number",
  "portal_wrong_customer_order_number": "Wrong customer order number",
  "portal_wms": "WMS",
  "portal_photo_mgmt_direct_upload": "Direct Upload",
  "portal_photo_mgmt_save": "Save",
  "portal_photo_mgmt_check": "Check",
  "portal_photo_mgmt_confirm": "Confirm",
  "portal_photo_mgmt_order_no": "Order No",
  "portal_photo_mgmt_main_page": "Main Page",
  "portal_photo_mgmt_photo_upload": "Take Photos",
  "portal_photo_mgmt_photo_management": "Photo management",
  "portal_photo_mgmt_order": "Order",
  "portal_photo_mgmt_select_site": "Select Site",
  "portal_photo_mgmt_scan_qrcode_or_enter_order_no": "Scan QR Code or enter Order No.",
  "portal_photo_mgmt_select_process": "Select Process",
  "portal_photo_mgmt_please_select_at_least_one_photo": "You must select at least one photo.",
  "portal_photo_mgmt_select_the_photos_you_surely_want_to_upload": "Select the photos you surely want to upload",
  "portal_photo_mgmt_select_all": "Select All",
  "portal_photo_mgmt_cancel": "Cancel",
  "portal_photo_mgmt_cancel_all": "Cancel All",
  "portal_photo_mgmt_please_input_valid_order_number": "Please Input Valid Order No",
  "portal_photo_mgmt_data_uploaded_failed": "Note! There are errors found in the file. Please correct the data and upload again.",
  "portal_photo_mgmt_take_photo_now": "Take photo now",
  "portal_photo_mgmt_select_source_of_photo": "Choose how to get the photos",
  "portal_photo_mgmt_compress_img_before_upload": "Compress image before uploading",
  "portal_photo_mgmt_photo_source_camera": "Take photos",
  "portal_photo_mgmt_photo_source_device": "From device",
  "portal_photo_mgmt_photo_selection_info": "Photos: %{number} / %{total}",
  "portal_photo_mgmt_please_grant_the_permission_for_accessing_webcam": "Please Grant the Permission for accessing webcam",
  "portal_photo_mgmt_check_all_photo": "Check all photos",
  "portal_photo_mgmt_enter_order_no": "Enter Order No.",
  "portal_photo_mgmt_please_enter_valid_order_no": "Please Enter Valid Order No",
  "portal_photo_mgmt_select_the_photos_to_upload": "Select the photos to upload",
  "portal_photo_mgmt_please_take_a_photo": "You must take a photo.",
  "portal_photo_mgmt_enter": "Enter",
  "portal_photo_mgmt_take_photo": "Take photo",
  "portal_photo_mgmt_check_photos": "Check photos",
  "portal_photo_mgmt_the_maximum_of_photos_taken": "The maximum of photos taken is %{number}",
  "portal_photos": "Photos",
  "portal_seq": "Seq",
  "portal_original_received_date": "Original Received Date",
  "portal_inbound_ref_hashtag": "Inbound Ref #",
  "portal_area": "Area",
  "portal_location": "Location",
  "portal_sku_description": "SKU Description",
  "portal_onhold_qty": "Onhold QTY",
  "portal_outbound": "Outbound",
  "portal_remark_receive": "Receiving Note",
  "portal_photo": "Photo",
  "portal_download_all": "Download All",
  "portal_upload_max_%{max_length}_ohotos": "Upload (max %{max_length} photos)",
  "portal_ship_type": "Ship Type",
  "portal_asn_received_date": "ASN Received Date",
  "portal_inbound_scheduled_date": "Inbound Scheduled Date",
  "portal_unloading_in_progress_date": "Unloading in Progress Date",
  "portal_sorting_completed_date": "Sorting Completed Date",
  "portal_close_date": "Close Date",
  "portal_reference": "Reference",
  "portal_order_not_exist": "Order not exist",
  "portal_customer_order_number_is_different": "Customer order number is different",
  "portal_order_upload_failed": "Order upload failed",
  "portal_does_not_support_order_comparison": "Does not support order comparison",
  "portal_no_export_data": "No export data",
  "portal_tsmc_po": "TSMC PO",
  "portal_tsmc_asn": "TSMC ASN",
  "portal_tsmc_crate": "TSMC Crate",
  "portal_asn_hashtag": "ASN #",
  "portal_po_hashtag": "PO #",
  "portal_crate_hashtag": "Crate #",
  "portal_wms_po_hashtag": "WMS PO #",
  "portal_receiver_date": "Receiver Date",
  "portal_pid": "PID",
  "portal_remarks": "Remarks",
  "portal_so_hashtag": "SO #",
  "portal_inbound_ref": "Inbound Ref",
  "portal_print_batch_picking_list": "Print Batch Picking List",
  "portal_please_enter_batch_order_number": "Please Enter Batch Order Number",
  "portal_print": "Print",
  "transport_control_invalid_date": "Invalid Date",
  "transport_control_iless_than_other_date": "Less than other date",
  "transport_control_carrier_code": "Carrier Code",
  "transport_control_client": "Client",
  "transport_control_description": "Description",
  "transport_control_printer": "Printer",
  "transport_control_service_level": "Service Level",
  "transport_control_rules": "Transport Control Rules",
  "transport_control_printer_mapping": "Printer Mapping",
  "transport_control_printer_setup": "Printer Setup",
  "transport_control_mail_list": "Mail List",
  "transport_control_query": "Query",
  "transport_control_create_success": "Create Success",
  "transport_control_add": "Add",
  "transport_control_destroy_success": "Destroy Success",
  "transport_control_delete": "Delete",
  "transport_control_add_notification": "Add Notification",
  "transport_control_mail": "Mail",
  "transport_control_add_printer_mapping": "Add Printer Mapping",
  "transport_control_add_printer_setup": "Add Printer Setup",
  "transport_control_printer_name": "Printer Name",
  "transport_control_agent_host_key": "Agent Host Key",
  "transport_control_ip_address": "IP Adress",
  "transport_control_port": "Port",
  "transport_control_add_rule": "Add Rule",
  "transport_control_customer_account": "Customer Account",
  "transport_control_configuration": "Configuration",
  "transport_control_less_than_pickup_datetime": "Less than Pickup Datetime",
  "transport_control_less_than_booking_datetime": "Less than Booking Datetime",
  "transport_control_less_than_now": "Less than Now",
  "transport_control_ofd_datetime": "OFD",
  "transport_control_print_order": "Print Order",
  "transport_control_clients": "Clients",
  "transport_control_transport_types": "Transport Types",
  "transport_control_categories": "Categories",
  "transport_control_statuses": "Statuses",
  "transport_control_order_numbers": "Order Numbers",
  "transport_control_ofd_was_filled": "OFD was filled",
  "transport_control_pickup_datetime": "Pickup Datetime",
  "transport_control_booking_datetime": "Booking Datetime",
  "transport_control_no_selected_orders": "No selected orders",
  "transport_control_send_to_booking": "Send to booking",
  "transport_control_send_to_klairy": "Send to Klairy",
  "transport_control_close": "Close",
  "transport_control_update_success": "Update success",
  "transport_control_order_number": "Order Number",
  "transport_control_rollback": "Rollback",
  "transport_control_transport_type": "Transport Type",
  "transport_control_save": "Save",
  "transport_control_trucking_company": "Trucking Company",
  "transport_control_contact_name": "Contact Name",
  "transport_control_address": "Address",
  "transport_control_post_code": "Post Code",
  "transport_control_country": "Country",
  "transport_control_change_to_self_pickup": "Change to Self-pickup",
  "transport_control_courier": "Courier",
  "transport_control_service_type": "Service Type",
  "transport_control_attachment": "Attchment",
  "transport_control_commercial_invoice": "Commercial Invoice",
  "transport_control_export_document": "Export Document",
  "transport_control_total_parcels": "Total Parcels",
  "transport_control_pk_value": "PK Value",
  "transport_control_incoterms": "Incoterms",
  "transport_control_coo": "COO",
  "transport_control_change_to_parcel": "Change to Parcel",
  "transport_control_change_to_pallet": "Change to Pallet",
  "transport_control_host_key": "Host Key",
  "transport_control_status": "Status",
  "transport_control_remark": "Remark",
  "transport_control_pkg_qty": "PKG Qty",
  "transport_control_pack_type": "Pack Type",
  "transport_control_total_gross_weight": "Total Gross Weight",
  "transport_control_has_attachments": "Has Attachments",
  "transport_control_total_pk_count": "Total PK Count",
  "transport_control_total_pk_value": "Total PK Value",
  "transport_control_category": "Category",
  "transport_control_delete_success": "Delete Success",
  "transport_control_printer_document_type": "Printer Document Type",
  "transport_control_printer_location": "Printer Location",
  "transport_control_city": "City",
  "transport_control_tracking_no": "Tracking No.",
  "transport_control_tracking_label": "Tracking Label",
  "undefined": ""
 },
 "kpi": {
  "kpi_owner": "KPI Owner",
  "kpi_owner_department": "KPI Owner Department",
  "kpi_owner_division": "KPI Owner Division",
  "kpi_owner_station": "KPI Owner Station",
  "kpi_owner_region": "KPI Owner Region",
  "kpi_owner_reason": "KPI Owner Reason",
  "kpi_name": "KPI Name",
  "kpi_detail": "KPI Detail",
  "kpi_result": "KPI Result",
  "kpi_review_period": "KPI review Period",
  "kpi_date": "KPI Date",
  "kpi_ref_number": "KPI Ref Number",
  "vip_name": "VIP Name",
  "client_id": "Customer ID",
  "kpi_ref_department": "KPI Ref Department",
  "kpi_ref_station": "KPI Ref Station",
  "kpi_ref_region": "KPI Ref Region",
  "kpi_date_review_period": "Review Period",
  "kpi_date_datetime": "Date Time",
  "kpi_group": "KPI Group",
  "kpi_exemption_flag": "Exemption Flag",
  "kpi_exemption_reason": "Exemption Reason",
  "kpi_number": "KPI Amount USD",
  "kpi_reference_type": "KPI Reference Type",
  "kpi_reference_number": "KPI Reference Number",
  "kpi_reference_station": "KPI Reference Station",
  "kpi_reference_department": "KPI Reference Department",
  "kpi_reference_region": "KPI Reference Region",
  "kpi_reference_vip_name": "KPI Reference VIP Name",
  "kpi_reference_client_id": "KPI Reference Client ID",
  "LATE_INVOICE": "Late Invoice",
  "LATE_INVOICE_OVER_60D": "Late Invoice Over 60 Days",
  "LATE_VOUCHER": "Late Voucher",
  "LATE_VOUCHER_OVER_60D": "Late Voucher Over 60 Days",
  "LATE_MILESTONE": "Late Milestone",
  "MISSING_MILESTONE": "MIssing Milestone",
  "TASK_OVERDUE": "Task Overdue",
  "INCOMPLETE_TASK": "Incomplete Task",
  "invalid_format": "Invalid Format",
  "undefined": "",
  "region": "Region",
  "department": "Department",
  "division": "Division",
  "owner": "Owner",
  "station": "Station",
  "year_week": "Year Week",
  "pass_count": "Pass Count",
  "failed_count": "Failed Count",
  "total_count": "Total Count",
  "failed_percentage": "Failed (%)",
  "target_percentage": "Target (%)",
  "amount": "Amount",
  "overdue_percentage": "Overdue (%)",
  "overdue_count": "Overdue Count",
  "assignee": "Asignee (email)",
  "kpi_date_review_period_2022h1": "2022 H1",
  "kpi_date_review_period_2022h2": "2022 H2",
  "kpi_date_review_period_2021h1": "2021 H1",
  "kpi_date_review_period_2021h2": "2021 H2",
  "kpi_date_review_period_2020h1": "2020 H1",
  "kpi_date_review_period_2020h2": "2020 H2",
  "pass_percentage": "Pass (%)",
  "customer_code": "Customer Code",
  "bill_to_id": "Bill-To Code",
  "origin_port": "Orig",
  "origin_port_country": "Orig Country",
  "destination_port": "Dest",
  "destination_port_country": "Dest Country",
  "created_by": "Created By",
  "requested_by": "Requested By",
  "kpi_exception_type": "KPI Exception Type",
  "effective_date": "Effective Date",
  "exception_service_type": "Exception Service Type",
  "shipment_number": "HAWB / HBL",
  "extension_days": "Day(s)",
  "extension_days_required": "Days*",
  "effective_start": "Effective From (Local Time)",
  "effective_end": "Effective To (Local Time)",
  "kpi_exception_rule_exemption": "Exemption",
  "kpi_exception_rule_extension": "Extension",
  "deactivate": "Deactivate",
  "activate": "Activate",
  "error_data_is_duplicated": "",
  "error_input_value_in_enum_attribute": "",
  "exception_type": "Exception Type",
  "mass_upload": "Mass Upload",
  "exception_service_type_required": "Exception Service Type*",
  "exemption_type": "Exemption Type*",
  "master_number": "MAWB / MBL",
  "quotation_number": "Quotation No",
  "charge_code": "Charge Code",
  "exception_invoice_number": "Exception Invoice Number",
  "remark": "Remark*",
  "requester": "Requester*",
  "invalid_service_type": "Invalid Service Type",
  "invalid_exception_service_type": "Invalid Exception Service Type",
  "invalid_exemption_type": "Invalid Exemption Type",
  "invalid_department": "Invalid Department",
  "invalid_extension_type": "Invalid Extension Type",
  "invalid_incoterm": "Invalid Incoterm",
  "invalid_requester": "Invalid Requester",
  "extension_type": "Extension Type*",
  "extension_type_first_bill": "FIrst Bill",
  "extension_type_second_bill": "Second Bill",
  "extension_type_all_bill": "All Billing",
  "extension_type_holiday": "Holiday"
 },
 "hrs": {
  "undefined": "",
  "division": "Division",
  "need_action": "New Hired / Job Changed",
  "name": "Name",
  "station": "Station",
  "status": "Status",
  "region": "Region",
  "download_template": "Download Template",
  "check": "check",
  "employee_id": "Employee ID",
  "update_detail_description": "You have to input description in \"Other main task > detail\" after you filled in % value in other main task. Please check detail cells on ID %{id}",
  "update_detail_total_percentage": "Save failed, Total Job duty of %{id} must be 100%",
  "update_error_no_employee_id": "Missing employee ID, please check your excel",
  "update_error_no_review_start_date": "Missing review period, please check your excel",
  "update_error_employee_id_and_review_start_date_not_created_yet": "The employee ID or review period is incorrect",
  "update_error_illegal_percentage": "Illegal percentage of job duty on ID %{id}",
  "unknown_error_on_row": "Error, please contact MOP help desk",
  "is_confirmed": "Confirmed",
  "review_period": "Review Period",
  "bulk_confirm": "Bulk Confirm",
  "please_select_at_least_one_profile": "Please select at least one profile",
  "import": "Import",
  "import_failed": "Error found in the file, please correct the error and upload again",
  "import_sheet_with_illegal_header": "Sheet header doesn't match: cell %{cellname} should be \"%{expect}\" but got \"%{actual}\"        ",
  "update_data_completed_message": "Save Completed. you have updated %{number} employee's data.  ID: ${update_id_list}",
  "bulk_confirm_count": "%{number} records are selected",
  "before_bulk_confirm_title": "Confirm to modify confirm into Y",
  "before_bulk_confirm_message": "%{number} items have been selected, do you want to change the Confirm status to Y ?",
  "NEW_HIRED": "New Hired employee ",
  "JOB_CHANGE": "Employee has promoted or transfered ",
  "before_save_confirm_title": "Save edited data",
  "before_save_confirm_message": "You edited the job weighting of %{number} staff, do you want to save the data?",
  "before_save_confirm_sync_bulk_confirm_field": "Sync Change Confirm Status to \" Y \"",
  "after_save_confirm_title": "Saved Successfully",
  "after_save_confirm_message": "Data saved successfully",
  "after_save_confirm_and_sync_confirm_field_message": "Save data successfully and update Confirm status",
  "nothing_changed": "Nothing Changed",
  "you_have_select_number_of_data": "%{number} data was selected",
  "error_no_data_selected": "No data selected",
  "error_data_no_review_period": "No employee in this review period for ID: %{id}",
  "are_you_sure_to_confirm_these_employee_data": "Are You Sure to Confirm These Employee Data %{employee_list}",
  "location": "Location",
  "is_abnormal": "Abnormal",
  "detail_total_percentage_warning": "Biggest Job Weighting is in \"%{topDuty}\", but this employee is in \"%{division}\"",
  "all_record_will_not_save": "All record(s) won't be saved. %{br} Please revise error record(s) above and save again.",
  "review_period_hint": "\"Review Period\" is updated, please Click \"Search\" Again.",
  "handover_submit_error_ofd_earlier_than_adat": "OFD Time is earlier than Actual Driver Arrival Time, please double check and input",
  "handover_submit_error_ofd_later_than_current": "OFD Time exceed the current time, please double check and input",
  "handover_submit_error_ofd_later_than_pod": "POD Time is earlier than the OFD time, please double check and input",
  "handover_submit_error_pod_later_than_current": "POD Time exceed the current time, please double check and input"
 },
 "sop_mgmt": {
  "sop_category": "SOP Category",
  "vip_name": "VIP Name",
  "sop_name": "SOP Name",
  "ship_mode": "Ship Mode",
  "sop_station": "Station",
  "sop_station_type_billing_station": "Billing Station",
  "sop_station_type_handling_station": "Handling Station",
  "sop_station_type_export_station": "Export Station",
  "sop_station_type_import_station": "Import Station",
  "sop_station_type_control_tower": "Control Tower​",
  "sop_station_type_trans_ship_station": "Trans-ship Station​",
  "step_one": "Step 1",
  "step_two": "Step 2",
  "step_three": "Step 3",
  "process_category": "Process Category",
  "version": "Version",
  "valid_from": "Valid From",
  "valid_to": "Valid To",
  "customer_type": "Customer",
  "instruction": "Instruction",
  "url": "URL",
  "version_tooltip": "Can not  be smaller than current version​",
  "sop_step_one_header_description": "Set up SOP category​",
  "sop_step_two_header_description": "Select applied station​",
  "sop_step_three_header_description": "Upload one or multiple files​",
  "sop_publish": "Publish",
  "sop_number": "SOP No",
  "sop_status": "Status",
  "validity": "Validity",
  "validity_valid": "Valid",
  "validity_invalid": "Invalid",
  "sop_status_draft": "Draft",
  "sop_status_approved": "Approved",
  "sop_status_canceled": "Canceled",
  "attachment_list": "File",
  "invalid_version": "Invalid Version",
  "fuzzy_search": "Fuzzy Search",
  "export_station_list": "Export",
  "import_station_list": "Import",
  "handling_station_list": "Handling",
  "billing_station_list": "Billing",
  "trans_ship_station_list": "Trans-Ship",
  "control_tower_list": "Control Tower",
  "file_duplicate": "File duplicate",
  "are_you_sure_want_to_delete": "Are you sure you want to delete this SOP?",
  "sop_category_placeholder": "Select one ",
  "sop_name_placeholder": "Enter SOP name",
  "process_category_placeholder": "Select options",
  "version_placeholder": "ex. 1.0",
  "instruction_placeholder": "Any instruction for carrying out the SOP",
  "url_placeholder": "Please paste the video URL here ",
  "sop_number_placeholder": "ex. SP0001",
  "sop_category_customer": "CUSTOMER SOP",
  "sop_category_standard": "STANDARD SOP",
  "sop_category_policy": "POLICY",
  "sop_category_guideline": "GUIDELINE",
  "ship_mode_placeholder": "Select options",
  "stations_placeholder": "Search...",
  "vip_name_placeholder": "Search...",
  "customer_type_placeholder": "Search...",
  "edit_attachment_list": "File (up to 3)",
  "standard_process_category_standard_quotation_mgmt": "Standard Quotation Mgmt",
  "standard_process_category_export_operation_mgmt": "Export Operation Mgmt",
  "standard_process_category_export_booking_handling": "Export Booking Handling",
  "standard_process_category_export_consol_processing": "Export Consol Processing",
  "standard_process_category_export_awb_or_bl_processing": "Export AWB/ BL Processing",
  "standard_process_category_export_others": "Export Others",
  "standard_process_category_import_operation_mgmt": "Import Operation Mgmt",
  "standard_process_category_import_consol_handling": "Import Consol Handling",
  "standard_process_category_import_awb_or_bl_processing": "Import AWB/ BL Processing",
  "standard_process_category_import_dispatch_and_pod": "Import Dispatch & POD",
  "standard_process_category_import_other_main_task": "Import Others",
  "standard_process_category_logistics_operation_mgmt": "Logistics Operation Mgmt",
  "standard_process_category_logistics_customer_service_mgmt": "Logistics Customer Service Mgmt",
  "standard_process_category_logistics_warehouse_mgmt": "Logistics Warehouse Mgmt",
  "standard_process_category_logistics_distribution_mgmt": "Logistics Distribution Mgmt",
  "standard_process_category_logistics_billing": "Logistics Billing",
  "standard_process_category_logistics_others": "Logistics Others",
  "customer_process_category_customer_quotation_mgmt": "Quotation Mgmt",
  "customer_process_category_shipment_data_and_milestone": "Shipment Data & Milestone",
  "customer_process_category_warehouse_operation": "Warehouse Operation",
  "customer_process_category_billing": "Billing",
  "customer_process_category_control_tower_and_kpi": "Control Tower & KPI",
  "customer_process_category_agent_mgmt": "Agent Mgmt",
  "invalid_url": "Invalid Url",
  "invalid_date_range": "Valid to date can not be earlier than valid from date",
  "error_resource_not_found": "%{resource} is not found",
  "error_version_not_match": "Vesion no. does not match",
  "error_version_lower": "Can not  be smaller than current version",
  "error_attachment_duplicate": "You are uploading duplicated files. Please check your file name.",
  "error_valid_interval_not_match": "Valid to date can not be earlier than valid from date",
  "error_sop_name_duplicate": "Duplicated SOP name. Please try a new one. ",
  "error_item_list_count": "Please add at least 1 data",
  "create_successfully": "Publish Successfully",
  "update_successfully": "Save Successfully",
  "please_add_least_min_stations": "Please add at least 1 station",
  "published_at": "Publish Time (UTC)",
  "updated_at_utc": "Update Time (UTC)",
  "published_by": "Publish By ",
  "error_invalid_station": "Below station(s) does not exist, please modify or delete: ",
  "invalid_level_value": "Invalid Level: %{level}",
  "error_invalid_station_value": "Station Row %{row_index} - %{station}",
  "error_process_category_not_match": "",
  "is_reviewed": "Reviewed by HQ",
  "undefined": ""
 },
 "3rd_party": "3rd Party",
 "abbr_no": "No",
 "accept_all_cookies": "Accept all cookies",
 "accept_all_cookies_v20201223": "If you continue to browse without changing your settings, or if you click “Accept all cookies”, we'll assume that you agree to receive all cookies on the MEC website. However, you can change your cookie settings at any time.",
 "accept_terms_and_conditions_is_required": "Accept Terms & Conditions is required",
 "account": "Account",
 "action": "Action",
 "actions": "Actions",
 "actual_arrive_transshipment_port": "Actual Arrive Transshipment Port",
 "actual_delivery_date": "Actual Delivery Date",
 "actual_delivery_date_should_be_greater_actual_pickup_date": "Actual Delivery date should be greater than Actual Pickup Date",
 "actual_delivery_dt_utc": "Actual Delivery DT (UTC)",
 "actual_delivery_time": "Actual Delivery Time",
 "actual_depart_transshipment_port": "Actual Depart Transshipment Port",
 "actual_driver_arrival_time": "Actual Driver Arrival Time",
 "actual_ofd_time": "OFD Time",
 "actual_out_for_delivery_date": "tinyurl_service_error",
 "actual_out_for_delivery_time": "OFD Time",
 "actual_pickup_at": "Picked Up",
 "actual_pickup_time": "Actual Pickup Time",
 "acutal_arrive_final_usca_destination": "Actual Arrive Final US/CA Destination",
 "acutal_arrive_first_usca_destination": "Actual Arrive First US/CA Destination",
 "acutal_pickup_time": "Actual Pickup Time",
 "add": "Add",
 "add_at_least_one_shipment_to_bol": "Add at least one shipment to BOL.",
 "add_bol": "Add BOL",
 "add_comment": "Add Comment",
 "add_dimension": "Add Dimension",
 "add_field_select": "Add Field Select",
 "add_hawb": "Add HAWB",
 "add_minus_n": "± Num",
 "add_part": "Add Part",
 "add_type": "Add Type",
 "address": "Address",
 "adjust_column": "Adjust Column",
 "advanced_search": "Advanced Search",
 "ae": "AE",
 "agent": "Agent",
 "agree": "Agree",
 "air": "Air",
 "alert": "Alert",
 "alert_station": "Alert Station",
 "all": "ALL",
 "all_docs_received": "All Docs Received",
 "all_station": "All Station",
 "all_the_unsaved_work_will_be_lost": "All the unsaved work will be lost.",
 "allow_to_view_function": "Allow To View Function",
 "amat": "AMAT",
 "amount": "Amount",
 "ap_ar": "AP & AR",
 "ap_processing": "AP Processing",
 "api": "API",
 "application_data_will_process_to_review": "Application data will process to review",
 "appointment_no": "Appointment No",
 "appointment_number": "Appt/C#",
 "approved_amount": "Approved Amount",
 "apptc_no": "APPT/C No",
 "are_you_sure_to_delete_the_mawb": "Are you sure you want to delete the MAWB?",
 "are_you_sure_to_delete_this_view": "Are you sure to delete this view?",
 "are_you_sure_you_want_to_cancel_this_invoice_number": "Are you sure you want to cancel this invoice - %{number} ?",
 "are_you_sure_you_want_to_confirm_the_dispatch": "Are you sure you want to confirm the Dispatch?",
 "are_you_sure_you_want_to_delete_the_subscription": "Are you sure you want to delete the subscription?",
 "are_you_sure_you_want_to_edit_it": "Are you sure you want to edit it?",
 "are_you_sure_you_want_to_edit_the_dispatch": "Are you sure you want to edit the Dispatch?",
 "are_you_sure_you_want_to_exit": "Are you sure you want to exit?",
 "are_you_sure_you_want_to_save_the_dispatch": "Are you sure you want to save the Dispatch?",
 "arrival_notice_sent_date": "Arrival Notice Sent Date",
 "arrive_cfs_date": "Arrive CFS Date",
 "arrived": "Arrived",
 "arrived_at_dest_cfs": "Arrived at Dest CFS",
 "arrived_ata": "Arrived (ATA)",
 "arrived_cfs": "Arrived CFS",
 "arrived_dest_cfs": "Arrived Dest CFS",
 "asn_details": "ASN Details",
 "asn_info": "ASN Info",
 "asn_items": "ASN Items",
 "asn_qty": "ASN QTY",
 "asn_received_date": "ASN Received Date",
 "assign_carrier": "Assign Carrier",
 "assign_to": "Assign to",
 "assigned": "Assigned",
 "assigned_carrier": "Assigned Carrier",
 "assigned_to_bol": "Assigned to BOL",
 "assigned_to_carrier": "Assigned to Carrier",
 "assigned_to_dispatch": "Assigned to Dispatch",
 "assigned_to_driver": "Assigned to Driver",
 "assistant_driver_1_name": "Assist Driver 1",
 "assistant_driver_2_name": "Assist Driver 2",
 "at_risk": "At Risk",
 "ata": "ATA",
 "ata_date": "ATA Date",
 "atd": "ATD",
 "attachment_list": "Attachment",
 "attributes": "Attributes",
 "audit_actions": "Audit Actions",
 "aw": "AW",
 "awb_doc": "AWB Doc",
 "awgw": "AW/GW",
 "back": "Back",
 "bid_required": "Bid Required",
 "bigger_than_end_number": "Bigger than end number",
 "bigger_than_end_on": "Bigger than Endon",
 "bigger_then_endon": "Bigger then End",
 "bill_to_code": "Bill-To code",
 "billto_party": "Bill-to Party",
 "bol_details": "BOL Details",
 "bol_dispatch_creation_successfully": "Create Dispatch successfully.",
 "bol_grouping_rule": "BOL Grouping Rule",
 "bol_no": "BOL No",
 "bol_pod": "POD Location",
 "bol_shipment_mode": "Shipment Mode",
 "bol_shipto_city": "To City",
 "bol_shipto_country": "To Country/Area",
 "bol_shipto_name": "To Name",
 "bol_special_instruction": "BOL Special Instruction",
 "bol_station": "BOL Station",
 "bol_status": "BOL Status",
 "bol_status_update": "BOL Status Update",
 "bol_type": "BOL Type",
 "bol_warning": "BOL Warning",
 "bols": "BOLs",
 "bols_added": "BOLs Added",
 "booked_on": "Booked On",
 "booking": "Booking：",
 "booking_id": "Booking ID",
 "booking_mgmt": "Booking Mgmt",
 "booking_no": "Booking No",
 "box": "Box",
 "broker_date": "Broker Date",
 "by": "By",
 "by_clicking_alert_your_unsaved_data_will_also_be_saved": "By clicking alert, your unsaved data will also be saved",
 "by_selecting_this_you_confirm_the_value_and_detail_of_your_uploaded_invoice_and_linked_reference_numbers_are_correct": "By selecting this, you confirm the value and detail of your uploaded invoice and linked reference numbers are correct.",
 "c": "C",
 "call_printer": "Call Printer",
 "can_not_be_0": "Can not be 0",
 "can_not_contain_special_characters": "Can not contain Special Characters",
 "cancel": "Cancel",
 "cancel_bol": "Cancel BOL",
 "cancel_booking": "Cancel Booking",
 "cancel_edit": "Cancel Edit",
 "canceled": "Canceled",
 "cancelled": "Cancelled",
 "cannot_be_empty": "Cannot be empty",
 "cannot_be_empty_or_zero": "Cannot be empty or zero",
 "cannot_update_to_null": "Cannot update to null",
 "cant_get_notreceived_count_error": "Can\"t get NotReceived count %{error}",
 "cargo_receipt": "Cargo Receipt",
 "cargosolution": "CARGOSOLUTION",
 "carrier": "Carrier",
 "carrier_released": "Carrier Released",
 "carrier_service_level": "Carrier Service Level",
 "carton": "Carton",
 "cartons_in_pallet": "Cartons in Pallet",
 "cartons_in_ref": "Cartons in Ref#",
 "cash_on_delivery": "COD Amount",
 "cash_on_delivery_amount": "COD Amount",
 "category": "Category",
 "category_section": "Category Section",
 "cbm": "CBM",
 "cbf": "CBF",
 "changed_by_name_on_time": "Changed by %{name} on %{time}",
 "charge_code": "Charge Code",
 "charge_item": "Charge Item",
 "charge_weight": "Charge Weight",
 "check_first_memo": "Please click \"Check\" first.",
 "check_result": "Check Result",
 "checked_in_counter": "Assigned Carrier",
 "checked_to_confirmed": "Check to confirm",
 "children_node_will_have_the_same_privilege_as_parent": "Children node will have the same privilege as parent",
 "choose_one": "Choose One",
 "choose_policy": "Choose Policy",
 "choose_the_put_away_location": "Choose the put away location：",
 "city": "City",
 "clear_options": "Clear All",
 "cleared_customs": "Cleared Customs",
 "click_here_or_drop_a_file_to_upload": "Click here or drop a file to upload",
 "client_code": "Client Code",
 "close": "Close",
 "close_date": "Close Date",
 "closed": "Closed",
 "cod_amount_fee_terms": "COD Amount Fee Terms",
 "column_with_error": "Column with Error",
 "comment": "Comment",
 "comment_category": "Comment Category",
 "comment_category_close_task": "Close Task",
 "cm": "CM",
 "cm_and_inch_are_available": "cm' and 'inch' are available",
 "cod": "COD",
 "collect": "Collect",
 "column_name": "Column Name",
 "comment_category_exception": "Exception",
 "comment_category_external_normal": "External Normal",
 "comment_category_internal_normal": "Internal Normal",
 "comment_category_late_billing_reason": "Late Billing Reason",
 "comment_category_message_code": "Message Code",
 "comment_category_normal_update": "Normal Update",
 "comment_category_pending_reason": "Pending Reason",
 "comment_category_action_required": "Action Required",
 "comment_category_tms_exception": "TMS Delivery Exception",
 "comment_category_tms_exception_message_code": "Exception Code",
 "comment_category_tms_exception_message_code_A2": "A2-Incorrect Address",
 "comment_category_tms_exception_message_code_AG": "AG-Consignee Related",
 "comment_category_tms_exception_message_code_AJ": "AJ-Other Carrier Related",
 "comment_category_tms_exception_message_code_AN": "AN-Holiday Closed",
 "comment_category_tms_exception_message_code_B1": "B1-Consignee Closed",
 "comment_category_tms_exception_message_code_BG": "BG-Other",
 "comment_category_tms_exception_message_code_S1": "S1-Delivery Shortage",
 "comment_here": "Comment here",
 "comment_category_void_shipment": "Cancel Shipment",
 "commercial_inv": "Commercial Inv",
 "commercial_invoice": "Commercial Invoice",
 "commercial_invoice_doc": "Commercial Invoice Doc.",
 "company_name": "Company Name",
 "company_phone_number": "Company Phone Number",
 "complete": "Complete",
 "completed": "Completed",
 "completed_date": "Completed Date",
 "comprehensive_routing_service_type_cross_border_truck": "Cross Border Truck",
 "comprehensive_routing_service_type_customs": "Customs",
 "comprehensive_routing_service_type_domestic_flight": "Domestic Flight",
 "comprehensive_routing_service_type_domestic_truck": "Domestic Truck",
 "comprehensive_routing_service_type_integrated": "Integrated",
 "comprehensive_routing_service_type_international_flight": "International Flight",
 "comprehensive_routing_service_type_loading_and_unloading": "Loading & UnLoading",
 "comprehensive_routing_service_type_packing": "Packing",
 "comprehensive_routing_service_type_warehousing": "Warehousing",
 "comprehensive_routing_truck_type_air_ride_truck": "Air Ride Truck",
 "comprehensive_routing_truck_type_platform_truck": "Platform Truck",
 "comprehensive_routing_truck_type_refrigerated_van": "Refrigerated Van",
 "comprehensive_routing_truck_type_refrigerated_van_for_dangerous_goods": "Refrigerated Van For Dangerous Goods",
 "comprehensive_routing_truck_type_temperature_controlled_air_ride_truck": "Temperature Controlled Air Ride Truck",
 "comprehensive_routing_truck_type_van": "Van",
 "comprehensive_routing_truck_type_vehicle_for_dangerous_goods": "Vehicle For Dangerous good",
 "comprehensive_routing_truck_type_sprinter": "Sprinter",
 "confirm": "Confirm",
 "confirm_bol": "Confirm BOL",
 "confirm_password": "Confirm Password",
 "confirmed": "Confirmed",
 "consignee": "Consignee",
 "consignee_address": "Consignee Address",
 "consignee_address_local_language": "Consignee Address (Local Language)",
 "consignee_address_localized": "Consignee Address Localized",
 "consignee_address1": "Consignee address1",
 "consignee_address2": "Consignee address2",
 "consignee_city": "Consignee City",
 "consignee_code": "Consignee Code",
 "consignee_country": "Consignee Country/Area",
 "consignee_ctry": "Consignee Ctry/Area",
 "consignee_idamat": "Consignee ID(AMAT)",
 "consignee_name": "Consignee Name",
 "consignee_name1": "Consignee Name1",
 "consignee_name2": "Consignee Name2",
 "consignee_state": "Consignee State",
 "consignee_zip": "Consignee Zip",
 "consol_closed": "Consol Close",
 "contact": "Contact",
 "contact_phone": "Contact Phone",
 "contact_email": "Contact Email",
 "container_no": "Container No",
 "content": "Content",
 "continue": "Continue",
 "continue_to_cancel_this_bill_of_lading": "Continue to cancel this bill of lading ?",
 "continue_to_save": "Continue to save ?",
 "continue_uploading": "Continue uploading",
 "contractual_ctn_qty": "Contractual Ctn Qty",
 "contractual_loose_ctn_qty": "Contractual Loose Ctn Qty",
 "contractual_pallet_qty": "Contractual Pallet Qty",
 "contractual_pcs_qty": "Contractual Pcs Qty",
 "contractual_wgt": "Contractual Wgt",
 "contry_region": "Country Region",
 "conversation_id": "Conversation Id",
 "convert_to_ap": "Convert to AP",
 "copy_from_export": "Copy from export",
 "cost": "Cost",
 "country": "Country/Area",
 "country_of_origin": "Country of Origin",
 "create": "Create",
 "create_bol_failed": "Create BOL failed.",
 "create_by": "Create By",
 "create_csv_failed": "Create CSV failed ",
 "create_date": "Creation Date",
 "create_date_end": "Create Date End",
 "create_date_start": "Create Date Start",
 "create_date_utc": "Create Date (UTC)",
 "create_master": "Create Master",
 "create_password": "Create Password",
 "create_subscription": "Create Subscription",
 "create_success": "Create Success",
 "created_at": "Created At",
 "created_at_utc": "Created At (UTC)",
 "created_by": "Created By",
 "created_date": "Created Date",
 "created_utc_time": "Created Utc Time",
 "creation_date": "Creation Date",
 "creator": "Creator",
 "cron_no_data_input": "Cron No Data Input",
 "cron_schedule": "Schedule",
 "csv_upload": "CSV Upload",
 "ctn_qty": "Ctn Qty",
 "currency": "Currency",
 "custom_clearance_date": "Custom Clearance Date",
 "custom_value": "Custom Value",
 "customer": "Customer",
 "customer_check_acceptable": "Customer check Acceptable",
 "customer_code": "Customer Code",
 "customer_id": "Customer Id",
 "customer_name": "Customer Name",
 "customer_reference_number": "Customer Reference Number",
 "customer_selfpickup": "Customer Self-pickup",
 "customer_tracking": "Customer Tracking",
 "customers": "Customers",
 "customized_setting": "Customized Setting",
 "customized_table_header": "Customized Table Header",
 "customs_cleared": "Customs Cleared",
 "customs_released": "Customs Released",
 "cw": "CW",
 "dangerous_good": "Dangerous Goods",
 "data": "DATA",
 "data_export": "Export",
 "data_uploaded_failed": "Error found in the file, please correct the error and upload again",
 "data_uploaded_successfully": "Data uploaded successfully",
 "data_validator_error_0001": "You are NOT authorized to deal with this shipment.",
 "data_validator_error_0002": "Loading on MAWB is NOT confirmed yet.",
 "data_validator_error_0003": "Have not received EDI945",
 "data_validator_error_0004": "The HAWB does NOT exist. Please check if there is any typo.",
 "data_validator_error_0005": "Booking is almost done in Prolink, except for the Customer Code.",
 "data_validator_error_0006": "Due to other factors. Please check with IT staff.",
 "data_validator_error_0007": "Data is present. Please check if the search condition is proper.",
 "data_validator_error_0008": "Label has been generated via Urgent Input. (Scenario: Others)",
 "data_validator_error_0009": "Label has been generated via Urgent Input. (Scenario: Missing EDI)",
 "data_validator_error_0010": "Loading plan (shipmet_to_pmaster) is not available.",
 "data_validator_error_0011": "No Privilege",
 "data_validator_error_no_authorization": "You are NOT authorized to check the data.",
 "data_validator_error_empty_vip_name": "Customer code is blank.",
 "data_validator_error_empty_handling_pieces": "HAWB pcs is not set up.",
 "data_validator_error_no_cfs_delivery_package_data": "Package ID (delivery_package) is not generated.",
 "data_validator_error_no_shipment_data": "HAWB information is lost in MorrisonOne.",
 "data_validator_error_no_master_data": "Consol (MAWB) information does NOT exist.",
 "data_validator_error_console_info_is_not_complete": "Consol information (including loading plan) is not complete.",
 "data_validator_error_not_assign_master_yet": "Loading plan (shipmet_to_master) is not available.",
 "data_validator_error_empty_customer_code_in_source_db": "Customer code is blank.",
 "data_validator_error_not_sync_from_source_db_yet": "Wait for data sync from Prolink/CW1",
 "data_validator_error_not_received_edi945_yet": "Have not received EDI945",
 "data_validator_error_not_assign_master_yet_in_source_db": "Need to check if loading plan is complete.",
 "data_validator_error_cannot_find_in_both_source_db_and_mop": "The HAWB does NOT exist. Please check if booking is made.",
 "data_validator_error_cannot_find_master_in_mop": "This MAWB does NOT exist. Please check if booking is made.",
 "data_validator_error_shipment_created_time_out_of_range": "It was beyond default time range (created 1 month ago).",
 "data_validator_error_empty_etd": "ETD is blank.",
 "data_validator_error_wrong_port_in_search_conditions": "Data is present. Please check if the search condition is proper.",
 "wrong_sscc_qty_in_edi945": "EDI945 content error (SSCC Qty)",
 "data_validator_error_wrong_sscc_qty_in_edi945": "EDI945 content error (SSCC Qty)",
 "data_validator_error_failed_to_invoking_validation_api": "Failed to invoke validation API",
 "data_validator_error_other": "Due to other factors. Please check with IT staff.",
 "date_range": "Date Range",
 "date_range_between": "Between",
 "date_range_between_relative_days": "Between Relative Days",
 "date_range_gte": "After",
 "date_range_is_empty": "Is Empty",
 "date_range_last_month": "Last Month",
 "date_range_last_week": "Last Week",
 "date_range_last_year": "Last Year",
 "date_range_lte": "Before",
 "date_range_month_today": "Month-To-Date",
 "date_range_next_month": "Next Month",
 "date_range_next_week": "Next Week",
 "date_range_not_empty": "Not Empty",
 "date_range_relative_date_gte": "Since",
 "date_range_relative_date_lte": "Before",
 "date_range_this_month": "This Month",
 "date_range_this_week": "This Week",
 "date_range_this_year": "This Year",
 "date_range_today": "Today",
 "date_range_tomorrow": "Tomorrow",
 "date_range_week_number_range": "Week Num",
 "date_range_week_today": "Week-To-Date",
 "date_range_within_days": "Within Days",
 "date_range_year_today": "Year-To-Date",
 "date_range_yesterday": "Yesterday",
 "string_eq": "Equal",
 "string_ne": "Not Equal",
 "string_in": "Contain",
 "string_not_in": "Not Contain",
 "string_like": "Like",
 "string_like_start_with": "Start With",
 "string_like_end_with": "End With",
 "string_is_empty": "Is Empty",
 "string_not_empty": "Not Empty",
 "days": "Days",
 "days_after": "Days After",
 "days_ago": "Days Ago",
 "default": "Default",
 "delay": "Delay",
 "delayed": "Delayed",
 "delete": "Delete",
 "delete_dispatch": "Delete Dispatch?",
 "delete_dispatch_dispatchnumber": "Delete dispatch : \"%{dispatchNumber}\"",
 "delete_query_save_view_success": "Delete View %{name} success!",
 "delete_view": "Delete View",
 "delivered": "Delivered",
 "delivered_time": "Delivered Time",
 "delivery_container": "Delivery No/ Container No",
 "delivery_count": "#Deliveries",
 "delivery_date": "Target Delivery From",
 "delivery_from": "Target Delivery From",
 "delivery_in_progress": "Delivery in Progress",
 "delivery_method": "Delivery Method",
 "delivery_method_customer_self_pickup": "Customer Self-Pickup",
 "delivery_method_express": "Express",
 "delivery_method_ftl": "FTL",
 "delivery_method_ltl": "LTL",
 "delivery_method_ltl_ftl": "LTL/FTL",
 "delivery_no": "Delivery No",
 "delivery_on_progress": "Delivery in Progress",
 "delivery_status_update": "Delivery Status Update",
 "delivery_time": "Delivery Time",
 "delivery_to": "Target Delivery To",
 "delivery_to_customer": "Delivery to Customer",
 "deny": "Deny",
 "dep": "Dep",
 "departed_to_terminal": "Departed to Terminal",
 "department": "Department",
 "departure": "Departure",
 "departured_atd": "Departured (ATD)",
 "desc": "Desc.",
 "description": "Description",
 "dest": "Dest",
 "dest_country": "Dest Country",
 "dest_custom_cleared": "Dest Custom Cleared",
 "dest_custom_released": "Dest Custom Released",
 "dest_hawb_hbl": "Dest",
 "dest_mawb_mbl": "Dest (MAWB / MBL)",
 "dest_port_city": "Dest Port City",
 "dest_port_country": "Dest Port Country/Area",
 "dest_port_ctry": "Dest Port Ctry/Area",
 "dest_port_of_discharge": "Dest (Port of Discharge)",
 "dest_region": "Dest Region",
 "dest_status": "Dest Status",
 "destination": "Destination",
 "destination_custom_released": "Destination Custom Released",
 "destination_doc_status": "Dest Doc Status",
 "destroy_success": "Destroy Success.",
 "detail": "DETAIL",
 "details": "Details",
 "dimension": "Dimension",
 "dimension_unit": "Dimension Unit",
 "dimension_uom": "Dimension UOM",
 "disabled_input_select": "Select...",
 "disabled_input_text": "Type...",
 "dismiss_cookies_statements": "Cancel",
 "dispatch": "Dispatch",
 "dispatch_actual_ofd_date": "OFD Date",
 "dispatch_carrier": "Dispatch Carrier",
 "dispatch_creation_date": "Dispatch Creation Date",
 "dispatch_customer_selfpu": "Customer Self-Pickup",
 "dispatch_delivery_method": "Shipment Delivery Method",
 "dispatch_details": "Dispatch Details",
 "dispatch_edit": "Dispatch Edit",
 "dispatch_form": "Dispatch Form",
 "dispatch_id": "Dispatch ID",
 "dispatch_indicator": "Dispatch Indicator",
 "dispatch_mgmt": "Dispatch Mgmt",
 "dispatch_no_or_verification_code_is_wrong": "Dispatch No or Verification Code is wrong",
 "dispatch_planned_ofd_date": "Planned OFD Date",
 "dispatch_shipment_mode": "Shipment Mode",
 "dispatch_special_instruction": "Special Instruction",
 "dispatch_status": "Dispatch Status",
 "dispatch_status_update": "Dispatch Status Update",
 "dispatch_summary": "Dispatch Summary",
 "dispatch_truck_pickup_date": "Driver Arrival Date",
 "dispatch_truck_pu_date": "Dispatch Truck PU Date",
 "dispatch_type": "Dispatch Type",
 "dn_sid": "DN / SID",
 "dn_sid_id": "DN / SID Id",
 "dn_timeline": "DN Timeline",
 "undefined": "",
 "do_you_want_to_delete_this_shipment": "Do you want to delete this consignment?",
 "do_you_want_to_print_bol_manifest": "Do you want to print the documents below?",
 "do_you_want_to_reprocess_it": "Do you want to re-process it?",
 "do_you_want_to_resubmit_it": "Do you want to re-submit it?",
 "do_you_want_to_send_email_to_user": "Do you want to send email to user?",
 "doc_avail_date": "Doc Avail Date",
 "doc_release": "Doc Release",
 "doc_released": "Doc Released",
 "doc_status": "Doc Status",
 "doc_to_broker": "Doc to Broker",
 "document": "Document",
 "document_check": "Document Check",
 "document_id": "Document Id",
 "document_ready": "Document Ready",
 "document_section": "Document Section",
 "documents": "Documents",
 "documents_attached": "Documents Attached",
 "download": "Download",
 "download_auth_failed": "Download Auth Failed",
 "download_file": "Download File",
 "download_file_expires": "Download File Expires",
 "download_request_data_empty": "Download Data Empty",
 "download_template": "Download Template",
 "draft": "Draft",
 "driver": "Driver",
 "driver_info_entered": "Driver Info Entered",
 "driver_name": "Driver Name",
 "driver_phone_no": "Driver Phone No",
 "drop_the_files_here": "Drop the files here",
 "each": "Each",
 "eccn": "ECCN",
 "edi_event": "EDI Event",
 "edi_log_id": "EDI Log ID",
 "edi_method": "EDI Method",
 "edi_monitor": "EDI Monitor",
 "edi_monitor_edit": "EDI Monitor Edit",
 "edi_platform_id": "EDI Platform Id",
 "edi_platform_sent_to_customer": "EDI Platform Sent To Customer",
 "edi_req_date": "EDI Req. Date",
 "edi_req_date_utc": "EDI Req. Date (UTC)",
 "edi_sent_date": "EDI Sent Date",
 "edi_sent_date_timeutc0": "EDI Sent Date & Time(UTC+0)",
 "edi_sent_date_utc": "EDI Sent Date (UTC)",
 "edi_sent_datetimeutc0": "EDI Sent Date&Time(UTC+0)",
 "edi_sent_utc_time": "EDI Sent Utc Time",
 "edit": "Edit",
 "edit_subscription": "Edit Subscription",
 "editcancel": "Edit/Cancel",
 "effective_end": "Effective End",
 "effective_start": "Effective Start",
 "eic_code": "EIC Code",
 "email": "Email",
 "email_id": "Email ID",
 "email_sent": "Email Sent",
 "email_status": "Email Status",
 "email_subject": "Email Subject",
 "email_to": "Email to",
 "email_type": "Email Type",
 "employeeid": "employee_id",
 "end_on": "End On",
 "english": "English",
 "env_notice": "DEVELOP Environment",
 "env_notice_demo": "Demo Environment",
 "env_notice_develop": "DEVELOP Environment",
 "env_notice_epic": "EPIC Environment",
 "env_notice_feature": "FEATURE Environment",
 "env_notice_hint": "",
 "env_notice_hint_demo": "All The Data Is Not Real",
 "env_notice_local": "LOCAL Environment",
 "env_notice_prod": "PROD Environment",
 "env_notice_uat": "UAT Environment",
 "error": "Error",
 "error_code": "Error code",
 "error_code_400": "",
 "error_code_401": "Your login session has expired",
 "error_code_403": "Please refresh and try again. If you still cannot access this page - Please contact mec_onehelp@morrisonexpress.com",
 "error_code_404": "Please refresh and try again. If you still cannot access this page - Please contact mec_onehelp@morrisonexpress.com",
 "error_code_406": "Request content-type is not correct",
 "error_code_409": "Reqeust Conflict",
 "error_code_500": "Server Error, Pls copy Tracking ID and mail to mec_onehelp@morrisonexpress.com.",
 "error_code_504": "Timeout Error",
 "error_code_address_book_already_exist": "Address Book Already Exist",
 "error_code_asn_number_correspond_replicated_400": "asn_number has already linked to other shipment:\n%{error_string}",
 "error_code_booking_accept_bol_not_found": "Unable to find BOL/ DN by car Quotation id: %{car_booking_id}.",
 "error_code_booking_accept_bol_status_not_allowed": "This BOL/ DN %{bol_id} is %{bol_status}. Can not execute submit when BOL/ DN status is %{bol_status}.",
 "error_code_booking_accept_booking_status_not_allowed": "Unable to execute accept request when Quotation status is %{booking_status}.",
 "error_code_booking_accept_car_booking_not_found": "Unable to find Quotation ID: %{car_booking_id}.",
 "error_code_booking_accept_carrier_name_not_defined": "Carrier is no API. Pls check.",
 "error_code_booking_accept_exception_bol_or_dispatch_not_found": "Can not mapping %{quotation_level} by Quotation ID:%{car_booking_id} in CarBookingMapping table.",
 "error_code_booking_accept_exception_booking_status_not_allowed": "Unable to execute accept request when Quotation Status is %{booking_status}.",
 "error_code_booking_accept_exception_car_booking_not_found": "Unable to find quotation by Quotation ID: %{car_booking_id}.",
 "error_code_booking_accept_exception_exist_another_accepted_booking": "Unable to execute accept request. There is already a quotation of  %{quotation_level} id: %{relation_id} is accepted.",
 "error_code_booking_accept_exist_another_accepted_booking": "Unable to execute accept request. There is already a quotation of BOL ID: %{bol_id} is accepted. ",
 "error_code_booking_accept_no_submit_response_s3_key": "Unable to get shipment_digest from quotation %{car_booking_id} due to no quotation_res_s3_key.",
 "error_code_booking_accept_offline_exist_another_accept_booking": "An offline accepted quotation is already existing.",
 "error_code_booking_accept_partner_info_not_found": "Unable to find carrier's API %{partner_name} connection infomation.",
 "error_code_booking_accept_response": "Response error message: %{error_message} Carrier booking log id: %{carrier_booking_log_id}, Car booking id: %{car_booking_id}, Bol id: %{bol_id}",
 "error_code_booking_accept_ups_api_validate_error": "UPS API detect accept parameter error. %{result}. Car booking id: %{car_booking_id}",
 "error_code_booking_cancel_bol_not_found": "Unable to find BOL/ DN by quotation id: %{car_booking_id}.",
 "error_code_booking_cancel_bol_status_not_allowed": "This BOL/ DN %{bol_id} is %{bol_status}. Can not execute cancel when BOL/ DN status is %{bol_status}.",
 "error_code_booking_cancel_booking_status_not_allowed": "Unable to execute cancel request when Quotation status is %{booking_status}.",
 "error_code_booking_cancel_car_booking_not_found": "Unable to find Quotation ID: %{car_booking_id}.",
 "error_code_booking_cancel_carrier_name_not_defined": "Carrier %{carrier_code} %{carrier_name} is not existing.",
 "error_code_booking_cancel_partner_info_not_found": "Unable to find carrier's API %{partner_name} connection infomation.",
 "error_code_booking_cancel_response": "Response error message: %{error_message}",
 "error_code_booking_cancel_ups_api_validate_error": "UPS API detect cancel parameter error. %{result}. Car booking id: %{car_booking_id}",
 "error_code_booking_mop_shipping_key_not_found": "MOP shipping key is not found. Please check carrier : %{carrier_code} , and shipper account: %{shipper_account} .",
 "error_code_booking_submit_bol_not_found": "Unable to find BOL/ DN %{bol_id}.",
 "error_code_booking_submit_bol_status_not_allowed": "This BOL/ DN(id: %{bol_id}) is %{bol_status}.. Unable to execute submit when BOL/ DN status is %{bol_status}.",
 "error_code_booking_submit_carrier_name_not_defined": "Carrier %{carrier_code}  %{carrier_name} is not existing.",
 "error_code_booking_submit_partner_info_not_found": "Unable to find carrier's API %{partner_name} connection infomation.",
 "error_code_booking_submit_redis_carrier_mapping_error": "Mapping carrier service config by carrier code: %{carrier_code} failed. Please check define of TMS_CARRIER in Redis.",
 "error_code_booking_submit_response": "Response error message: %{error_message}",
 "error_code_booking_submit_ups_api_validate_error": "UPS API detect parameter error. %{result}. ",
 "error_code_can_not_find_bol": "Unable to find BOL: %{bol_number}.",
 "error_code_can_not_find_item": "Can not find %{item_name}: %{item_key}: ",
 "error_code_can_not_find_shipment": "Unable to find Shipment: %{shipment_number}.",
 "error_code_cant_save_with_processed_voucher": "There are processed vouchers, please void them first. %{error_detail}",
 "error_code_create_shipment_invalid_pr": "This is an invalid PR, which cannot be used to create shipment anymore",
 "error_code_delivery_status_update_error_PDF_file_broken": "The PDF file is broken, pls replace and try again.",
 "error_code_failed_to_create_invoice_request": "Create Invoice Request is Failed",
 "error_code_failed_to_create_voucher_request": "Create PO Request is Failed",
 "error_code_failed_to_delete_voucher_request": "Delete PO Request is Failed",
 "error_code_failed_to_post_all_to_prolink": "Post All to Prolink Failed",
 "error_code_failed_to_retrieve_voucher_request": "Retrieve PO Request is Failed",
 "error_code_failed_to_update_voucher_request": "Update PO Request is Failed",
 "error_code_failed_to_void": "Void request is in progressing. Please do not multi void.",
 "error_code_failed_to_void_invoice": "Void Invoice is Failed",
 "error_code_failed_to_void_voucher": "Void PO is Failed",
 "error_code_forbidden": "You don't have the privilege id %{privilege_id}.",
 "error_code_label_data_not_ready": "%{shipment} is required to print consignee pallet label before shipping out. Unable to print due to “CNEE Pallet Label Data” is “Not Ready”, please contact with Account Mgmr %{account_manager}.",
 "error_code_permission_401": "You have no permission.",
 "error_code_policy_not_set": "Policy scope is not correct. reason: %{reason}",
 "error_code_print_pallet_label": "Missing CNEE Pallet Label required data %{missing_required_data}. Please click “Confirm“ if you have already confirmed with Account Mgmr %{account_manager}, or “Cancel“ ?",
 "error_code_s3_client": "File Operation Error",
 "error_code_tms_bol_grouping_different_bol_delivery_method": "Shipments can't be added into Dispatch due to different BOL/ DN's Delivery Method. Pls select shipments which BOL/DN's Delivery Method is same.",
 "error_code_unable_to_find_the_invoice_id": "Can't find the invoice ID",
 "error_code_unable_to_find_the_shipment": "Can't find the shipment",
 "error_code_unable_to_find_the_voucher_id": "Can't find the voucher ID",
 "error_code_unauthorized": "Your login session has expired",
 "error_code_xxx": "Server connection failed. Please refresh and try again. If you still cannot access this page - Please contact mec_onehelp@morrisonexpress.com",
 "error_confirm_not_checked": "Checkbox should be checked",
 "error_confirmed": "I got it",
 "error_detail": "Detail",
 "error_invalid_cron": "Invalid Schedule",
 "error_row": "Error Row",
 "error_tracking_id": "Tracking ID",
 "escort": "Escort",
 "estimated_delivery": "Estimated Delivery",
 "estimated_delivery_date": "Estimated Delivery Date",
 "estimated_pickup_at": "Estimated Pickup",
 "eta": "ETA",
 "etd": "ETD",
 "etd_utc": "ETD (UTC)",
 "event": "Event",
 "event_category": "Event Category",
 "event_cateogry": "Event Cateogry",
 "event_date_local_time": "Event Date (Local Time)",
 "event_date_time_local": "Event Datetime (Local)",
 "event_datetime_local": "Event Datetime (Local)",
 "event_datetimelocal": "Event Date&Time(Local)",
 "event_location": "Event Location",
 "exception": "Exception",
 "exception_count": "Exception Count",
 "exception_history": "Exception History",
 "exception_id": "Exception Id",
 "exception_invoice_number": "Exception Invoice Number",
 "exception_message": "Exception Message",
 "exemption_type": "Exemption Type",
 "expensive": "Expensive",
 "export": "Export",
 "export_cfs_to_terminal": "Export CFS to Terminal",
 "export_depart_to_terminal_time_utc": "Export Depart to Terminal Time (UTC)",
 "export_errors": "Export Errors!",
 "export_picking": "Export Picking",
 "export_put_away": "Export Put Away",
 "export_receive": "Export Receive",
 "export_receive_edit": "Export Receive Edit",
 "export_received_time_utc": "Export Received Time (UTC)",
 "export_status": "Export Status",
 "express": "Express",
 "ext": "Ext",
 "extension_type": "Extension Type",
 "fail": "Fail",
 "fast_dispatch_create": "Fast Dispatch Create",
 "feet": "Feet",
 "field_select": "Field Select",
 "file": "File",
 "file_name": "File Name",
 "file_over_quantity": "File over quantity.",
 "files_submitted": "File(s) Submitted",
 "filter": "Filter",
 "final_ata": "Last ATA",
 "finish": "Finish",
 "first_atd": "First ATD",
 "first_bill": "First Bill",
 "first_etd": "First ETD",
 "fish_wildlife": "Fish & Wildlife",
 "flight": "Flight",
 "flight_no": "Flight No",
 "forget_password": "Forget Password",
 "form": "Form",
 "freight_charge_terms": "Freight Charge Terms",
 "freight_charges_collect": "Collect",
 "freight_charges_prepaid": "Prepaid",
 "freight_charges_third_party": "3rd Party",
 "freight_counted_by": "Freight Counted By",
 "freight_counted_driver_pallets": "Driver/pallets said to contain",
 "freight_counted_driver_pieces": "Driver/Pieces",
 "freight_counted_shipper": "Shipper",
 "freight_term": "Freight Term",
 "from": "From",
 "ftl": "FTL",
 "function": "Function",
 "general": "General",
 "general_menu": "General Menu",
 "get_bolnumber_data_failed": "Get \"%{bolNumber}\" Data Failed!",
 "get_edi": "Get EDI",
 "get_picking_failed": "Get picking failed!",
 "get_presigned_url_failed": "Get presigned URL failed",
 "get_putaway_failed": "Get putaway failed",
 "get_putaway_location_error": "Get putaway location error",
 "get_summary_data_failed": "Get summary data failed",
 "goods_description": "Goods Description",
 "gross_weight": "Gross Weight",
 "gross_weight_must_be_greater_than_tare_weight": "Gross weight must be greater than tare weight",
 "gross_wgt": "Gross Wgt",
 "group_id": "Group ID",
 "had_the_different_auto_print": "have the different auto print.",
 "had_the_different_carrier": "have the different carrier.",
 "had_the_different_consignee_address": "have the different consignee address.",
 "had_the_different_consignee_id": "have the different consignee id.",
 "had_the_different_delivery_method": "have the different delivery method.",
 "had_the_different_document_ready": "have the different document ready.",
 "had_the_different_payer": "have the different payer.",
 "had_the_different_payer_account": "have the different payer account.",
 "had_the_different_payer_name": "have the different payer name.",
 "had_the_different_quotation_level": "have the different Quotation Level.",
 "handed_to_air_carrier": "Handed to Air Carrier",
 "handed_to_mec": "Handed to MEC",
 "handling_agent": "Handling Agent",
 "handover": "Handover",
 "handover_to_driver": "Handover to Driver",
 "has_duplicate_query_save_view_name": "Warning! This view name is used.",
 "hawb": "HAWB#",
 "hawb_cartons": "HAWB Cartons",
 "hawb_count": "#Shipments",
 "hawb_dest": "HAWB Dest",
 "hawb_doc": "HAWB Doc.",
 "hawb_edit": "HAWB Edit",
 "hawb_first_atd_local_time": "HAWB First ATD (Local Time)",
 "hawb_hbl": "HAWB / HBL",
 "hawb_id": "HAWB ID",
 "hawb_label_print": "HAWB Label Print",
 "hawb_no": "HAWB / HBL",
 "hawb_orig": "HAWB Orig",
 "hawb_pallets": "HAWB Pallets",
 "hawb_pcs": "HAWB Pcs",
 "hawb_pkg_detail": "HAWB Pkg Detail",
 "hawb_total_cartons": "HAWB Total Cartons",
 "hawb_upload": "HAWB Upload",
 "hawbbl": "HAWB / HBL",
 "hawbhbl": "HAWB / HBL",
 "height": "Height",
 "hello_name": "Hello, %{name}!",
 "hide": "Hide",
 "high": "High",
 "history": "History...",
 "hkg_milestone": "HKG Milestone",
 "hts": "HTS",
 "i_accept": "I accept",
 "id": "ID",
 "import": "Import",
 "in_process": "IN PROCESS",
 "in_progress": "In Progress",
 "inbound_file_path": "Inbound File Path",
 "incomplete": "Incomplete",
 "incoterm": "Incoterm",
 "index_properties": "Index Properties",
 "indicator": "Indicator",
 "inland_trucking": "Inland Trucking",
 "inner_cartons": "Inner Cartons",
 "input_more_than_the_next_n_days_is_not_allowed": "Input more than the next %{n} days is not allowed.",
 "instruction": "Instruction",
 "internal_id": "Internal Id",
 "inv_amount": "Inv Amount",
 "inv_canceled": "Inv Canceled",
 "inv_currency": "Inv Currency",
 "inv_id": "Inv Id",
 "inv_no": "Inv No",
 "inv_rejected": "Inv Rejected",
 "inv_submitted": "Inv Submitted",
 "invalid_date": "Invalid Date",
 "invalid_date_time_please_enter_yyyy_mm_dd_hh24_mm": "Invalid Date time, please enter yyyy/MM/dd HH:mm",
 "invalid_datetime": "Invalid Datetime",
 "invalid_email": "Invalid Email",
 "invalid_future_time": "Invalid Future Time",
 "invalid_input": "Invalid Input",
 "invalid_password": "Invalid Password",
 "invalid_phone_number": "Invalid Phone Number",
 "invoice": "Invoice",
 "invoice_amount": "Invoice amount",
 "invoice_date": "Invoice Date",
 "invoice_edit": "Invoice Edit",
 "invoice_file": "Invoice File",
 "invoice_no": "Invoice No",
 "invoice_no_number": "Invoice No: %{number}",
 "invoice_number": "Invoice Number",
 "invoice_query": "Invoice Query",
 "invoice_status": "Invoice Status:",
 "invoice_upload": "Invoice Upload",
 "isa_no": "ISA No",
 "issue_date": "Issue Date",
 "itn": "ITN #",
 "key": "Key",
 "kg": "KG",
 "last_4_digits_of_your_phone_number": "Last 4 digits of your phone no",
 "last_eta": "Last ETA",
 "lb": "LB",
 "lefted_atd": "Lefted (ATD)",
 "length": "Length",
 "level": "Level",
 "license_code": "License Code",
 "lifted_atd": "Lifted (ATD)",
 "lifted_departed_atd": "Lifted / Departed (ATD)",
 "line": "Line",
 "link": "Link",
 "link_reference_no_confirm": "Link Reference No & Confirm",
 "linked_po_no_ref_no": "Linked PO No / Ref No",
 "list": "List",
 "load_dispatch": "Load Dispatch",
 "loading": "Loading...",
 "loading_meter": "#LDM",
 "loading_type": "Loading Type",
 "logistic_handling": "Logistic Handling",
 "logout": "Logout",
 "loose_cartons": "Loose Cartons",
 "loose_ctn_qty": "Loose Ctn Qty",
 "ltl": "LTL / FTL",
 "m": "M",
 "mawb": "MAWB",
 "mawb_awb": "MAWB AWB",
 "mawb_dest": "MAWB Dest",
 "mawb_doc": "MAWB Doc.",
 "mawb_mbl": "MAWB / MBL",
 "mawb_no": "MAWB / MBL",
 "mawb_orig": "MAWB Orig",
 "mawb_pcs": "MAWB Pcs",
 "mawb_upload": "MAWB Upload",
 "max_length_colon": "Max Length: %{max}",
 "max_length_must_be_variable_characters_or_less": "Must be %{max} characters or less",
 "mec_booking_no": "MEC Booking No",
 "medium": "Medium",
 "menu": "Menu",
 "message": "Message",
 "message_id": "Message Id",
 "milestone": "Milestone",
 "milestone_options_air_delivery_actual_pod": "Actual Delivery Date",
 "milestone_options_air_delivery_estimated_pod": "Estimated Delivery Date",
 "milestone_options_air_delivery_estimated_pod_sla": "SLA",
 "milestone_options_air_delivery_ofd": "OFD",
 "milestone_options_air_shipment_actual_pickup": "Actual Pickup",
 "milestone_options_air_shipment_arrive_cfs": "Arrive CFS",
 "milestone_options_air_shipment_ata": "ATA",
 "milestone_options_air_shipment_atd": "ATD",
 "milestone_options_air_shipment_booking_date": "Booking Date",
 "milestone_options_air_shipment_customs_clear": "Origin Customs Cleared",
 "milestone_options_air_shipment_dest_customs_start": "Dest Customs Started",
 "milestone_options_air_shipment_doc_release": "Doc Released",
 "milestone_options_air_shipment_estimated_pickup": "Estimated Pickup Date",
 "milestone_options_air_shipment_eta": "ETA",
 "milestone_options_air_shipment_etd": "ETD",
 "milestone_options_air_shipment_packing": "Packing",
 "milestone_options_cargo_returned": "Cargo Returned",
 "milestone_options_destination_customs_start": "Import Station Customs Started",
 "milestone_options_import_customs_release": "Import Station Customs Released",
 "milestone_status": "Milestone Status",
 "milestone_status_arrived": "Arrived",
 "milestone_status_arrived_cfs": "Arrived at Dest CFS",
 "milestone_status_booked": "Booked",
 "milestone_status_cancelled": "Cancelled",
 "milestone_status_customs_cleared": "Origin Customs Cleared",
 "milestone_status_customs_released": "Dest Customs Released",
 "milestone_status_delivered": "Delivered",
 "milestone_status_departured": "Departured",
 "milestone_status_document_released": "Doc Released",
 "milestone_status_estimated_arrival": "Estimated Arrival",
 "milestone_status_estimated_departure": "Estimated Departure",
 "milestone_status_out_for_delivery": "Out For Delivery",
 "milestone_status_packing": "Packing",
 "milestone_status_picked_up": "Picked Up",
 "milestones": "Milestones",
 "milestones_with_blue_font_will_be_sent_via_edi_214_to_customer": "Milestones with blue font will be sent via EDI 214 to customer",
 "misc_docs": "Misc. Docs",
 "mobile_number": "",
 "mode": "Mode",
 "mop_application_form": "MOP Application Form",
 "mop_status_code": "MOP Status Code",
 "more": "More...",
 "morrison": "Morrison",
 "morrison_asn": "Morrison ASN#",
 "morrison_customer_id": "Morrison Customer ID",
 "morrison_rcv": "Morrison RCV#",
 "multiple_key": "Multiple Key",
 "must_a_number": "Must a number",
 "must_be_only_y_n": "Must be only Y / N",
 "must_in_options": "does not match, accept only %{options}",
 "must_integer": "Must Integer",
 "my_colleague": "My Colleague",
 "my_station": "My Station",
 "my_subscription": "My Subscription",
 "my_team": "My Team",
 "only_allow_decimal": "只允許輸入小數%{num}位",
 "n": "N",
 "name": "Name",
 "nav_more": "More",
 "necessary_to_input_only_if_scenario_is_urgent_use": "Necessary to input only if scenario is Missing EDI",
 "need_resubmission": "Need Resubmission",
 "net_wgt": "Net Wgt",
 "new": "New",
 "new_content": "New content",
 "new_dispatch": "New Dispatch",
 "new_exception": "New Exception",
 "new_hawb": "New HAWB",
 "new_shipment": "New Shipment",
 "new_update": "New",
 "next": "Next",
 "no": "No",
 "no_data": "No Data",
 "no_dispatch": "No Dispatch!",
 "no_dispath_bols": "No Dispath BOLs",
 "no_dnsid_selected": "No DN/SID selected!",
 "no_document": "No document!",
 "no_documents": "No documents!",
 "no_downloadurl": "No DownloadUrl !",
 "no_future_datetime_is_allowed": "No future date/time is allowed",
 "no_invoice": "No invoice!",
 "no_invoices": "No invoices!",
 "no_milestones": "No Milestones !",
 "no_pre_signed_list": "No Pre-signed List",
 "no_records": "no Records!",
 "no_references": "No references!",
 "no_reports": "No Reports!",
 "no_roles": "No Roles!",
 "no_shipment": "No Shipment !",
 "no_shipment_tracking_data": "No Shipment Tracking Data !",
 "no_shipments": "No shipments!",
 "no_users": "No users!",
 "noDocument": "No Document!",
 "noDownloadUrl": "No Download Url!",
 "none": "None",
 "nontrade": "Non-Trade",
 "normal": "Normal",
 "not_assigned": "Not Assigned",
 "not_ready": "Not Ready",
 "not_received": "Not Received",
 "not_special_characters": "Can not contain Special Characters",
 "note": "Note",
 "notice_status": "Notice Status",
 "notification": "Notification",
 "notify_party": "Notify Party",
 "npi": "NPI",
 "num": "Num",
 "number": "Number",
 "number_of_line_items": "Number of line items",
 "numbering_assigned_to_carrier": "10-Assigned to Carrier",
 "numbering_assigned_to_driver": "15-Assigned to Driver",
 "numbering_canceled": "90-Cancelled",
 "numbering_cancelled": "90-Cancelled",
 "numbering_delivered": "30-Delivered",
 "numbering_delivery_in_progress": "25-Delivery in Progress",
 "numbering_not_assigned": "00-Initial",
 "numbering_out_for_delivery": "20-Out for Delivery",
 "numbering_planning": "05-Planning",
 "numbering_shipment_canceled": "99-Canceled",
 "ocean": "Ocean",
 "ocr": "OCR",
 "ofd": "OFD",
 "ofd_time": "OFD Time",
 "ofddeparted_from_cfs": "OFD(Departed from CFS)",
 "off_for_delivery_date": "Off For Delivery Date",
 "off_load": "Offload",
 "ok": "OK",
 "onboard": "Onboard",
 "onground": "On-Ground",
 "open": "Open",
 "operator": "Operator",
 "option_contactrole_agent": "Agent",
 "option_contactrole_airliner_carrier": "",
 "option_contactrole_branch": "Branch",
 "option_contactrole_broker": "",
 "option_contactrole_co_loader": "",
 "option_contactrole_general_cargo": "",
 "option_contactrole_ocean_liner": "",
 "option_contactrole_other_vendor": "",
 "option_contactrole_trucker": "",
 "option_contactrole_warehouse": "",
 "option_milestone_arrive_at_cfs": "",
 "option_milestone_arrived_at_cfs": "Arrived at CFS",
 "option_milestone_custom_cleared": "Origin Customs Cleared",
 "option_milestone_customs_cleared_at": "Customs Cleared",
 "option_milestone_customs_released_at": "Customs Released",
 "option_milestone_dest_custom_released": "Dest Customs Released",
 "option_milestone_ofd": "OFD",
 "option_milestone_packing": "Packing Date",
 "option_milestone_pickup_at": "Actual Pickup",
 "option_milestone_pod": "POD",
 "option_milstone_actual_delivery_date": "Actual Delivery Date",
 "option_milstone_actual_pickup_at": "Picked Up",
 "option_milstone_arrived_at_cfs": "Arrived at Destination CFS",
 "option_milstone_arrived_cfs": "Arrived at Destination CFS",
 "option_milstone_arrived_cfs_at": "Arrived at Destination CFS",
 "option_milstone_ata": "Last ATA",
 "option_milstone_atd": "First ATD",
 "option_milstone_billing_issued_at": "Invoice / Voucher Date",
 "option_milstone_booking_date": "Booked",
 "option_milstone_created_at_utc": "Created At",
 "option_milstone_customs_cleared": "Customs Cleared",
 "option_milstone_customs_cleared_at": "Origin Customs Cleared",
 "option_milstone_customs_released": "Customs Released",
 "option_milstone_customs_released_at": "Dest Customs Released",
 "option_milstone_doc_released": "Document Released",
 "option_milstone_doc_released_at": "Document Released",
 "option_milstone_estimated_delivery_date": "Estimated Delivery Date",
 "option_milstone_estimated_delivery_date_sla": "SLA Due Date",
 "option_milstone_estimated_pickup_at": "Estimated Pickup",
 "option_milstone_eta": "Last ETA",
 "option_milstone_etd": "First ETD",
 "option_milstone_first_etd": "First ETD",
 "option_milstone_out_for_delivery_date": "Out for Delivery",
 "option_milstone_print_at": "Print Date",
 "option_milstone_void_at": "Void Date",
 "option_milstone_actual_pickup": "Actual Pickup Date",
 "option_milstone_estimated_pickup": "Estimated Pickup Date",
 "order": "Order",
 "order_class": "Order Class",
 "order_id": "Order ID",
 "orig": "Orig",
 "orig_country": "Orig Country",
 "orig_hawb_hbl": "Orig",
 "orig_mawb_mbl": "Orig (MAWB / MBL)",
 "orig_port_city": "Orig Port City",
 "orig_port_country": "Orig Port Country/Area",
 "orig_port_ctry": "Orig Port Ctry/Area",
 "orig_port_of_loading": "Orig (Port of Loading)",
 "orig_status": "Orig Status",
 "origin": "Origin",
 "origin_custom_cleared": "Origin Custom Cleared",
 "osd_status": "OSD Status",
 "other_docs": "Other Docs.",
 "other_instruction": "Other Instruction",
 "others": "Others",
 "out_for_delivery": "Out for Delivery",
 "out_of_allow_date_range": "Out of allow date range",
 "out_of_delivery": "Out Of Delivery",
 "out_of_gauge": "Out of Gauge",
 "output": "Output",
 "pack_unit": "Pack Unit",
 "package_details": "Package Details",
 "package_id": "Package ID",
 "package_qty": "Package Qty",
 "package_quantity": "Package Qty",
 "package_type": "Package Type",
 "package_unit": "Package Unit",
 "packing_list": "Packing List",
 "packs": "Packs",
 "pallet": "Pallet",
 "pallet_id": "Pallet Id",
 "pallet_wgt": "Pallet Wgt",
 "parser": "Parser",
 "part": "Part",
 "part_desc": "Part Desc*",
 "part_no": "Part No",
 "partial_received": "Partial Received",
 "partilly_receive": "Partilly Receive",
 "password": "Password",
 "password_must_match": "Passwords must Match",
 "payer": "Payer",
 "payer_name": "Payer Name",
 "payment_completed": "Payment Completed",
 "payment_to_carrier_by": "Payment to Carrier By",
 "pcs": "Pcs",
 "pcs_in_location": "Pcs In Location",
 "pcs_is_not_a_positive_number": "Pcs is not a positive number",
 "pcs_is_over_received": "Pcs is over received",
 "pcs_qty": "Pcs Qty",
 "period_end": "Period End",
 "period_start": "Period Start",
 "permission_denied_warning": "Please refresh and try again. If you still cannot access this page - Please contact mec_onehelp@morrisonexpress.com",
 "photo_list": "Photo List",
 "photo_management_milestone_export_handover": "Export - Handover",
 "photo_management_milestone_export_receiving": "Export - Receiving",
 "photo_management_milestone_import_after_sorting": "Import - After Sorting",
 "photo_management_milestone_import_reweigh": "Import - Reweigh",
 "photo_management_milestone_import_delivery": "Import - Delivery",
 "photo_management_milestone_import_pickup": "Import - Pickup",
 "photo_management_milestone_import_receiving": "Import - Receiving",
 "photo_management_milestone_export_received": "Export - Received",
 "photo_management_milestone_import_received": "Import - Received",
 "inventory_transfer_pickup": "Inventory - Pickup",
 "inventory_transfer_receiving": "Inventory - Receiving",
 "photos": "Photos",
 "picked": "Picked",
 "picked_pcs": "Picked Pcs",
 "picked_up": "Picked Up",
 "picking_pcs": "Picking Pcs",
 "picking_status": "Picking Status",
 "pickup_complete": "Pickup Complete",
 "pickup_date": "Pickup Date",
 "pickup_from": "Pickup From",
 "pickup_from_shipper": "Pickup from Shipper",
 "pickup_to": "Pickup To",
 "planned_delivery_date": "Planned Delivery Date",
 "planned_delivery_dt_utc": "Planned Delivery DT (UTC)",
 "planned_delivery_time": "Planned Delivery Time",
 "planned_driver_arrival_date": "Planned Driver Arrival Date",
 "planned_driver_arrival_time": "Planned Driver Arrival Time",
 "planned_driver_arrival_time_and_ofd_time": "Planned Driver Arrival Time and OFD Time",
 "planned_ofd_time": "Planned OFD Time",
 "planned_out_for_delivery_date": "Planned OFD Date",
 "planned_out_for_delivery_time": "Planned OFD Time",
 "planned_pickup_time": "Planned Driver Arrival Time",
 "planning": "Planning",
 "please_click_link_below_to_download_report_if_automatic_download_do_not_initialize": "Please Click Link Below To download Report If Automatic Download Do Not Initialize.",
 "please_enter_a_valid_datetime": "Please enter a valid datetime",
 "please_enter_a_valid_date_and_time": "Please enter a valid date and time",
 "please_enter_a_valid_number": "Please enter a valid number",
 "please_enter_invoice_no": "Please enter Invoice No",
 "please_select_at_least_one_record": "Please select at least one record.",
 "please_select_delivery_nubmer_for_document_upload": "Please select delivery nubmer for document upload",
 "please_select_station": "Please select station",
 "please_type_in_your_printed_invoice_value": "Please type in your printed invoice value",
 "please_upload_the_supporting_doc_eg_proof_of_delivery": "Please upload the supporting doc (E.g. Proof of delivery)",
 "please_upload_your_invoice": "Please upload your invoice",
 "please_use_google_chrome_to_access": "Please use Google Chrome to access Morrison One Platform.",
 "plt_qty": "Plt Qty",
 "plts": "PLTS",
 "po": "PO#",
 "po_created_date": "PO Created Date",
 "po_issue_date": "PO Issue Date",
 "po_request_status": "PO Request Status",
 "pod": "POD File",
 "pod_date": "POD Date",
 "pod_file": "POD File",
 "pod_location": "POD Location",
 "pod_name": "Signed by",
 "pod_reference_file": "POD Reference File",
 "pod_signed_by": "Signed by",
 "pod_time": "POD Time",
 "poddelivery_date": "POD/Delivery Date",
 "policy_attribute": "Policy Attribute",
 "policy_form": "Policy Form",
 "policy_name": "Policy Name",
 "policy_setting": "Policy Setting",
 "do_you_want_to_delete_policy?": "Do you want to delete policy?",
 "port_of_delivery": "Port of Delivery",
 "port_of_delivery_city": "Port of Delivery City",
 "port_of_delivery_country": "Port of Delivery Country/Area",
 "port_of_receipt": "Port of Receipt",
 "port_of_receipt_city": "Port of Receipt City",
 "port_of_receipt_country": "Port of Receipt Country/Area",
 "potential_delay": "Potential Delay",
 "preassigned_carrier": "Carrier",
 "preassigned_carrier_different_bol_carrier": "preassigned carrier different bol carrier.",
 "prepaid": "Prepaid",
 "preview": "Preview",
 "preview_file": "Preview File",
 "previous": "Previous",
 "primary_driver_name": "Primary Driver Name",
 "print": "Print",
 "print_bol": "Print BOL",
 "print_manifest": "Print Manifest",
 "print_status": "Print Status",
 "privacy_policy": "Privacy Policy",
 "private": "Private",
 "privilege": "PRIVILEGE",
 "process": "Process",
 "process_message": "Process Message",
 "product_type": "Product Type",
 "progress": "Progress",
 "project_code": "Project Code",
 "proof_of_delivery": "Proof of Delivery",
 "protocol": "Protocol",
 "public": "Public",
 "put_away": "Put Away",
 "put_away_location": "Put Away Location",
 "put_away_pieces_is_less_than_hawb_pieces_continue_to_save": "Put away pieces is less than hawb pieces, continue to save ?",
 "putaway_location": "Putaway Location",
 "qty": "Qty*",
 "query": "Query",
 "query_documents_success": "Query documents success",
 "query_fail_no_bill_of_lading_data": "Query Fail ! No Bill of Lading Data.",
 "query_failed": "Query Failed",
 "query_failed_due_to_error": "Query failed due to %{error}",
 "query_invoices_success": "Query invoices success",
 "query_report_download_success": "Query Report Download Success.",
 "query_save_view_info": "You can use this function to save the filter and result columns for next time.",
 "query_save_view_title": "Create a New View",
 "query_user_success": "Query user success",
 "quit": "Quit",
 "quotation_level": "Quotation Level",
 "quotation_number": "Quotation No",
 "quotation_price": "Quotation Price",
 "ready": "Ready",
 "reason": "Reason",
 "receipt_no": "Receipt no",
 "received": "Received",
 "received_serial_numbers": "Received Serial Numbers",
 "receiver": "Receiver",
 "receiving": "Receiving",
 "receiving_no": "Receiving No.",
 "recipient_email": "Recipient Email",
 "recipient_email_content": "Message(Optional)",
 "recipient_email_list": "Recipient Email List",
 "records_not_found": "%{type} not found (no record or limited authorization)",
 "ref": "Reference No",
 "ref_no": "Reference No",
 "reference": "Reference",
 "reference_type": "Reference Type",
 "reference_value": "Reference Value",
 "reference_files": "Reference Files",
 "reference2": "Reference#2",
 "refresh_every_15_seconds": "Refresh Every 15 seconds",
 "refresh_every_5_minutes": "Refresh Every 5 minutes",
 "register": "Register",
 "register_email": "Register Email",
 "reject": "Reject",
 "rejected": "Rejected",
 "related": "Related",
 "remark": "Remark",
 "remove_all_error": "Remove All Error",
 "report": "Report",
 "report_download": "Report Download",
 "report_name": "Report Name",
 "reprocess": "Reprocess",
 "requester": "requester",
 "required": "Required",
 "resend_sms": "Resend SMS",
 "reset": "Reset",
 "resubmit": "Resubmit",
 "result": "Result",
 "revise": "Revise",
 "risk": "Risk",
 "role": "Role",
 "role_id": "Role Id",
 "role_name": "Role Name",
 "role_type": "Role Type",
 "roll": "Roll",
 "row_no": "Row No",
 "s3_key": "S3 Key",
 "sales_mgmt": "Sales Mgmt",
 "save": "Save",
 "save_view_is_this_view_default": "Default View",
 "save_view_is_this_view_default_when_no_default": "Default When No Default",
 "scac": "SCAC",
 "scan_qr_code": "Scan QR Code",
 "sea": "Sea",
 "seal_no": "Seal No",
 "search": "Search",
 "search_fail": "Search Failed",
 "search_key": "Search key",
 "second_bill": "Second Bill",
 "security_level": "Security Level",
 "select_at_least_one_bol": "Select at least one BOL",
 "select_or_type_in": "Select or type in",
 "selected_bols": "Selected BOLs",
 "selected_shipments": "Selected Shipments",
 "selling_rate": "Selling Rate",
 "semi": "Semi",
 "send_email": "Send Email",
 "sender": "Sender",
 "sent_to_edi_platform": "Sent To EDI Platform",
 "seq": "Seq",
 "sequence": "Sequence",
 "service_level": "Service Level",
 "service_type": "Service Type",
 "set_default_query_view_success": "Set Default View Success!",
 "sharp_bols": "#BOLs",
 "sharp_deliveries": "#Deliveries",
 "sharp_gross_weight": "#Gross Weight",
 "sharp_gw": "#GW",
 "sharp_packages": "#Packages",
 "sharp_pkgs": "#PKGs",
 "sharp_shipments": "#Shipments",
 "sharp_shpts": "#SHPTs",
 "ship_dateutc0": "Ship Date(UTC+0)",
 "ship_from": "From",
 "ship_from_address1": "From Address 1",
 "ship_from_address2": "From Address 2",
 "ship_from_city": "From City",
 "ship_from_contact2": "From Contact",
 "ship_from_ctry": "From Ctry/Region",
 "ship_from_name": "Ship from Name",
 "ship_from_state": "From State",
 "ship_from_zip": "From Zip",
 "ship_mode": "Ship Mode",
 "ship_to": "Ship To",
 "ship_to_address": "To Address",
 "ship_to_address_localized": "Ship to Address Localized",
 "ship_to_address_or_city": "To Address / City",
 "ship_to_city": "To City",
 "ship_to_city_localized": "Ship to City Localized",
 "ship_to_country": "To Country/Area",
 "ship_to_name": "To Name",
 "ship_to_state": "To State",
 "shipment": "Shipment",
 "shipment_check": "Assign Carrier",
 "shipment_complete": "Shipment Complete?",
 "shipment_creation_date": "Creation Date",
 "shipment_customer": "Customer",
 "shipment_details": "Shipment Details",
 "shipment_dispatch_status": "Truck Delivery Status",
 "shipment_docs_ready": "Shipment Docs Ready",
 "shipment_has_bol_please_go_to_tms_mgmt": "This shipment has BOL %{bolNumbers}. Please go to %{url} update OFD or POD",
 "shipment_leg": "Shipment Leg",
 "shipment_mgmt": "Shipment Mgmt",
 "shipment_mode": "Shipment Mode",
 "shipment_mode_all": "All",
 "shipment_mode_air": "Air",
 "shipment_mode_comprehensive": "Comprehensive",
 "shipment_mode_logistics": "Logistics",
 "shipment_mode_rail": "Rail",
 "shipment_mode_sea": "Sea",
 "shipment_mode_truck": "Truck",
 "shipment_mode_warehouse_truck": "Warehouse Truck",
 "shipment_no": "HAWB / HBL",
 "shipment_number_is_not_unique": "Shipment number is not unique",
 "shipment_number_not_unique": "Shipment Number is not unique",
 "shipment_progress": "Shipment Progress",
 "shipment_progress_cancel": "Cancel",
 "shipment_reference_list_option_apple_dri": "APPLE DRI",
 "shipment_reference_list_option_apple_fdno": "APPLE FD",
 "shipment_reference_list_option_apple_fd": "APPLE FD",
 "shipment_reference_list_option_apple_prno": "APPLE PR",
 "shipment_reference_list_option_box_number": "BOX",
 "shipment_reference_list_option_box": "BOX",
 "shipment_reference_list_option_cost_cnt": "COST CENTER",
 "shipment_reference_list_option_cost_center": "COST CENTER",
 "shipment_reference_list_option_cra": "CRA",
 "shipment_reference_list_option_dc": "DC",
 "shipment_reference_list_option_di": "DI",
 "shipment_reference_list_option_gl_accunt": "GL ACCOUNT",
 "shipment_reference_list_option_gl_account": "GL ACCOUNT",
 "shipment_reference_list_option_in": "IN",
 "shipment_reference_list_option_it": "IT",
 "shipment_reference_list_option_lu": "LU",
 "shipment_reference_list_option_or": "OR",
 "shipment_reference_list_option_ot": "OTHER",
 "shipment_reference_list_option_other": "OTHER",
 "shipment_reference_list_option_pj": "PROJECT",
 "shipment_reference_list_option_pl": "PL",
 "shipment_reference_list_option_pn": "PN",
 "shipment_reference_list_option_po": "PO",
 "shipment_reference_list_option_rma": "RMA",
 "shipment_reference_list_option_sd": "SD",
 "shipment_reference_list_option_si": "SI",
 "shipment_reference_list_option_sn": "SN",
 "shipment_reference_list_option_s_n": "SN",
 "shipment_reference_list_option_so": "SO",
 "shipment_reference_list_option_s_o": "SO",
 "shipment_reference_list_option_sr": "SR",
 "shipment_reference_list_option_st": "ST",
 "shipment_reference_list_option_umc": "UMC",
 "shipment_reference_list_option_undefined": "Undefined",
 "shipment_reference_list_option_whse": "Warehouse",
 "shipment_reference_list_option_cargo_loc": "Cargo Location",
 "shipment_reference_list_option_carrier": "Carrier",
 "shipment_reference_list_option_mawb": "MAWB / MBL",
 "shipment_reference_list_option_hawb": "HAWB / HBL",
 "shipment_reference_list_option_asn": "ASN",
 "shipment_reference_list_option_bol_ref": "BOL ref",
 "shipment_reference_list_option_vsl_name": "Vessel name",
 "shipment_reference_list_option_cntr_no": "Container No",
 "shipment_reference_list_option_cntr_size": "Container Size",
 "shipment_reference_list_tooltip_apple_dri": "APPLE DRI",
 "shipment_reference_list_tooltip_apple_fdno": "Apple FD No.",
 "shipment_reference_list_tooltip_apple_prno": "Apple PR No.",
 "shipment_reference_list_tooltip_box_number": "BOX No.",
 "shipment_reference_list_tooltip_cost_cnt": "Cost Center No.",
 "shipment_reference_list_tooltip_cra": "Cannister Number",
 "shipment_reference_list_tooltip_dc": "Declaration Number for AI and AE",
 "shipment_reference_list_tooltip_di": "Delivery ID",
 "shipment_reference_list_tooltip_from_prolink": "From Prolink/CW1",
 "shipment_reference_list_tooltip_gl_accunt": "GL Account No.",
 "shipment_reference_list_tooltip_in": "Commercial Invoice No.",
 "shipment_reference_list_tooltip_it": "Item No.",
 "shipment_reference_list_tooltip_lu": "LU No.",
 "shipment_reference_list_tooltip_or": "Order No.",
 "shipment_reference_list_tooltip_ot": "Other",
 "shipment_reference_list_tooltip_pj": "Project Name",
 "shipment_reference_list_tooltip_pl": "Packing List No.",
 "shipment_reference_list_tooltip_pn": "Part No.",
 "shipment_reference_list_tooltip_po": "Purchase Order No.",
 "shipment_reference_list_tooltip_rma": "Returned Material Authorization",
 "shipment_reference_list_tooltip_sd": "SDAF No.",
 "shipment_reference_list_tooltip_si": "SID No.",
 "shipment_reference_list_tooltip_sn": "Serial No.",
 "shipment_reference_list_tooltip_so": "S/O No.",
 "shipment_reference_list_tooltip_sr": "Shipment Reference No. / Shipment Request No.",
 "shipment_reference_list_tooltip_st": "Style No.",
 "shipment_reference_list_tooltip_umc": "UMC Ref list",
 "shipment_truck_status": "Shipment Truck Status",
 "shipment_type": "Shipment Type",
 "shipments": "Shipments",
 "shipments_added": "Shipments Added",
 "shipments_already_had_preassigned_carrier_make_sure_to_change": "%{shipments} had pre-assigned carrier, Are you sure to change it?",
 "shipper": "Shipper",
 "shipper_code": "Shipper Code",
 "shipper_name": "Shipper Name",
 "shipping_address": "Shipping Address",
 "shipping_key": "Shipping Key",
 "shipping_qty": "Shipping Qty",
 "shp_mode": "Ship Mode",
 "shpr": "SHPR",
 "sign_by": "Sign by：",
 "sign_by_name": "Sign By Name",
 "signed_by": "Signed By",
 "sku": "SKU",
 "sla": "SLA",
 "smaller_than_start_number": "Smaller than start number",
 "smaller_than_start_on": "Smaller than StartOn",
 "smaller_then_starton": "Smaller then Start",
 "sn": "SN",
 "something_went_wrong": "Something went wrong, pls re-try or contact with mec_onehelp@morrisonexpress.com",
 "something_wrong": "Something went wrong, pls re-try or contact with mec_onehelp@morrisonexpress.com",
 "something_wrong_error": "Something Wrong! %{error}",
 "special_delivery_instruction": "Special Delivery Instruction",
 "special_instruction": "Special Instruction",
 "split": "Split",
 "split_hawb": "Split HAWB",
 "split_package_quantity_is_invalid": "The split package quantity is invalid.",
 "split_package_unit_is_invalid": "The split package unit is invalid.",
 "sprinter": "Sprinter",
 "standard": "Standard",
 "standard_carrier_alpha_code": "Standard Carrier Alpha Code",
 "start_on": "Start On",
 "state": "State",
 "station": "Station",
 "station_approval": "Station Approval",
 "status": "Status",
 "step": "Step",
 "step_step": "Step %{step}",
 "step1_edit_data": "Step 1: Edit Data",
 "step2_check_result": "Step 2: Check Result",
 "submit": "Submit",
 "submitted_to_customs": "Submitted to Customs",
 "subscription": "Subscription",
 "subscription_name": "Subscription Name",
 "success": "Success",
 "summary": "Summary",
 "support_doc": "Supporting Documents",
 "svc_lv": "Svc Lvl",
 "svc_lvl": "Svc Lvl",
 "svc_type": "Svc Type",
 "system": "System",
 "target_delivery": "Target Delivery",
 "target_delivery_date": "Target Delivery Date",
 "target_delivery_date_utc": "Target Delivery Time (UTC)",
 "target_delivery_from": "Target Delivery From",
 "target_delivery_to": "Target Delivery To",
 "tea": "TEA #",
 "tel": "Tel",
 "tel_no": "Tel No",
 "terminal_to_import_cfs": "Terminal to Import CFS",
 "terms_and_conditions": "Terms and conditions",
 "thank_you": "Thank you!",
 "the_account_already_exists": "The account already exists",
 "the_file_is_being_uploaded": "Uploading...",
 "the_next_number_of_days": "The Next Number of Days",
 "the_past_number_of_days": "The Past Number of Days",
 "the_text_will_be_shown_on_all_of_the_labels": "The text will be shown on all of the labels",
 "third_party_bill": "Third Party Bill",
 "this_hawb_has_not_yet_linked_to_an_amat_id_please_click_get_edi_to_link_one": "This HAWB has not yet linked to an AMAT ID. Please click \"Get EDI\" to link one.",
 "this_is_a_comment": "This is a comment...",
 "timezone": "Time Zone",
 "title": "Title",
 "tms_fast_dispatch_create_step_1": "Create BOL",
 "tms_fast_dispatch_create_step_2": "Create Dispatch",
 "tms_mgmt": "TMS Mgmt",
 "to": "To",
 "to_address": "To Address",
 "to_address_localized": "To Address Localized",
 "to_be_completed": "To be completed",
 "to_city": "To City",
 "to_city_localized": "To City Localized",
 "to_country": "To Country/Area",
 "to_country_localized": "To Country/Area Localized",
 "to_name": "To Name",
 "to_state": "To State",
 "to_zipcode": "To Zipcode",
 "total_amount_selected": "Total Amount Selected:",
 "total_bols": "#BOLs",
 "total_cartons": "Total Cartons",
 "total_charge_weight": "Total Charge Weight",
 "total_cw": "Total CW",
 "total_deliveries": "Total Deliveries",
 "total_deliverys": "#Deliveries",
 "total_gross_weight": "#Gross Weight",
 "total_gw": "Total GW",
 "total_hawbs": "#HAWBs",
 "total_loose_cartons": "Total Loose Cartons",
 "total_packages": "#Packages",
 "total_pallets": "Total Pallets",
 "total_pieces": "Total Pieces",
 "total_records": "Total Records",
 "total_shipments": "Total Shipments",
 "total_volume": "Total Volume",
 "total_weight": "Total Weight",
 "tracking": "TRACKING",
 "tracking_daily_report": "Tracking Daily Report",
 "tracking_device_number": "Tracking Device No",
 "tracking_no": "Tracking No",
 "tracking_result": "TRACKING RESULT",
 "trade": "Trade",
 "trade_type": "Trade Type",
 "transfer_direction": "Transfer Direction",
 "truck_no": "Truck No",
 "truck_size_10_tons": "10 Tons",
 "truck_size_point_five_tons": "0.5 Tons",
 "truck_size_1_tons": "1 Tons",
 "truck_size_2_tons": "2 Tons",
 "truck_size_3_tons": "3 Tons",
 "truck_size_40_hq": "40 HQ",
 "truck_size_45_hq": "45 HQ",
 "truck_size_5_tons": "5 Tons",
 "truck_size_53_hq": "53 HQ",
 "truck_size_8_tons": "8 Tons",
 "truck_size_24_foot": "24 Foot",
 "truck_size_26_foot": "26 Foot",
 "truck_size_53_foot": "53 Foot",
 "truck_type": "Truck Type",
 "truck_type_semi": "Semi",
 "truck_type_sprinter": "Sprinter",
 "truck_type_van": "Van",
 "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "Unsaved changes will be lost, are you sure you want to leave?",
 "un_number": "UN Number",
 "unit": "Unit*",
 "unit_value": "Unit Value",
 "unknown_message_code": "Unknown Message Code!",
 "unloading_in_progress_date": "Unloading in Progress Date",
 "unreceived": "Unreceived",
 "uom": "UOM",
 "update": "Update",
 "update_hawb_pkg_data": "Update HAWB Pkg Data",
 "update_mawb": "Update MAWB",
 "update_pod": "Update POD",
 "update_success": "Update Success.",
 "updated_by": "Updated By",
 "updated_date": "Updated Date",
 "updated_date_utc": "Updated Date (UTC)",
 "updated_utc_time": "Updated UTC Time",
 "upload": "Upload",
 "upload_by": "Upload By",
 "upload_date_time": "Upload Date / Time",
 "upload_file": "Upload File",
 "upload_file_section": "Upload File Section",
 "upload_file_to_s3_failure": "Upload File to S3 Failure",
 "upload_invoice": "Upload Invoice",
 "upload_license": "License",
 "upload_license_failed": "Upload Driving license failed",
 "upload_photo": "Upload Photo",
 "uploadS3Failure": "Failed to upload S3 file",
 "uppercase_privacy_policy": "PRIVACY POLICY",
 "uppercase_temrs_and_condition": "TERMS AND CONDITIONS",
 "uppercase_terms_and_conditions": "TERMS AND CONDITIONS",
 "urgent": "Urgent",
 "urgent_delivery": "Urgent Delivery",
 "urgent_use": "Missing EDI",
 "user": "User",
 "user_account_request_form": "User Account Request Form",
 "user_information": "User Information",
 "user_mgmt": "User Mgmt",
 "user_not_found": "User Not Found: %{email}",
 "user_registration_form": "User Registration Form",
 "user_registration_query": "User Registration Query",
 "user_type": "User Type",
 "value": "Value",
 "van": "Van",
 "variance": "Variance",
 "variance_approved_by": "Variance Approved by",
 "vendor": "Vendor",
 "vendor_billing_portal": "Vendor Billing Portal",
 "vessel_name": "Vessel Name",
 "view_name": "View Name",
 "view_no_data": "View No Data",
 "view_pod": "View POD",
 "view_type": "View Type",
 "vip": "VIP",
 "vip_name": "Customer",
 "vol": "Volume",
 "volume": "Volume",
 "volume_uom": "Volume UOM",
 "volume_weight": "Volume Weight",
 "voyage": "Voyage",
 "vw": "VW",
 "wait_for_approval": "Waiting for Approval",
 "waiting_for_approval": "Waiting for Approval",
 "warehouse_truck": "Warehouse Truck",
 "warehouse_trucking": "Warehouse Trucking",
 "warning": "Warning",
 "warning_row": "Warning Row",
 "was_existed_in_the_other_dispatch": "was existed in the other dispatch.",
 "was_not_planning_or_assigned_to_carrier": "was not Planning or Assigned to Carrier.",
 "we_cannot_access_your_camera": "Unable to access your camera",
 "weight_unit": "Weight Unit",
 "weight_uom": "Weight UOM",
 "welcome_to_morrisonone_platform": "Welcome to Morrison One Platform. Please choose a function on the menu bar above.",
 "what_is_cookies": "\"Cookies\" are small files that enable us to store information related to your device and you, the user, specifically, while you visit one of our websites. Cookies help us to determine how frequently our internet pages are accessed as well as the number of users. And they help us configure our offers so that they are as convenient and efficient as possible for you. This includes cookies from third party websites if you visit a page which contains embedded content from such websites. Such third party cookies may track your use of the MEC website.",
 "width": "Width",
 "wms_fast_dispatch_create_step_1": "Create DN",
 "wms_fast_dispatch_create_step_2": "Create Dispatch",
 "y": "Y",
 "yard": "Yard",
 "yes": "Yes",
 "you_donot_have_query_permission_below": "You do not have below perssion.",
 "you_have_finished_editing_invoice_no_number": "You have finished editing invoice No: %{number}",
 "you_have_finished_uploading_invoice_no_number": "You have finished uploading invoice No: %{number}",
 "you_have_unsaved_change_that_will_be_lost": "You have unsaved change that will be lost.",
 "you_have_unsaved_changes_are_you_want_to_leave": "You have unsaved changes, are you sure to leave?",
 "your_linked_ref_no": "Your Linked Ref No",
 "your_presearch_donot_have_query_permission_below": "You haven't below query previlege:",
 "your_ticket_number_number": "Your Ticket Number = %{number}",
 "yyyy": "YYYY",
 "zip_code": "Zipcode",
 "zipcode": "Zipcode",
 "error_code_update_delivery_get_country_info": "Country Name does not exist, please check.",
 "filters": "Filters",
 "export_exceeds_rows_limit": "The number of exported data exceeds the limit, please set more search criteria.",
 "export_no_matched_data": "The exported is zero, please check!",
 "unhandled_export_action": "Unhandled export action",
 "info": "Info",
 "empty": "Empty",
 "are_you_sure_you_want_to_delete_this_file": "Are you sure you want to delete this file?",
 "forwarder_shipment_mgmt": "Forwarder Shipment Mgmt",
 "upload_file_number_more": "%{fileNumber} More",
 "save_view_delete_with_subscribers": "This view has been subscribed, should not be deleted!",
 "issue": "Issue",
 "query_more_options": "More Options",
 "are_you_sure_you_want_to_delete_the_address_book": "Are you sure you want to delete the address ? ID: %{id}",
 "select_address_book": "Select address",
 "create_address_book": "Create address",
 "edit_address_book": "Edit address",
 "addressbook": "Address book",
 "addressbook_name": "Name",
 "address_and_city": "Address & City",
 "floor": "Floor/Suit/Unit",
 "contact_name": "Contact Name",
 "phone_number": "Phone No",
 "quick_guide": "Quick Guide",
 "quick_guide_empty": "No Quick Guide",
 "action_by": "Action By",
 "booking_request": "Booking Request",
 "is_truck_info_correct": "Are you sure the truck information is correct?",
 "combine": "Combine",
 "updated_at_utc": "Updated At (UTC)",
 "sheet_status_not_established": "NOT ESTABLISHED",
 "sheet_status_established": "ESTABLISHED",
 "sheet_status_departed": "DEPARTED",
 "sheet_status_arrived": "ARRIVED",
 "save_and_next": "Save & Next",
 "user_type_customer": "Customer",
 "user_type_morrison": "Morrison",
 "user_type_agent": "Agent",
 "user_type_vendor": "Vendor",
 "user_type_api": "Api",
 "user_type_demo": "Demo",
 "user_type_forwarder": "Forwarder",
 "total": "Total",
 "actual_truck_pickup_at": "Actual Truck Pickup Date",
 "estimated_delivery_at": "Estimated Delivery Date",
 "clear": "Clear",
 "revert": "Revert",
 "you_cannot_cancel_the_shipment_without_a_comment": "You cannot cancel the shipment without a comment",
 "are_you_sure_you_want_to_cancel_the_shipment": "Confirm delete shipment?",
 "please_cancel_this_shipment_on_prolink_cw1": "Please Cancel this shipment on Prolink/CW1",
 "refresh": "Refresh",
 "last_update": "Last Update: %{time}",
 "type": "TYPE",
 "token_expired": "Login Expired!",
 "error_code_token_expired": "Login Expired!",
 "release_date": "Release Date",
 "security_level_standard": "STANDARD",
 "security_level_medium": "MEDIUM",
 "security_level_high": "HIGH",
 "view": "View",
 "dropzone_placeholder_default": "Click here or drop a file to upload!",
 "dropzone_placeholder_on_drag": "Drop the files here ...",
 "dropzone_error_quantity_limit": "File over quantity.",
 "dropzone_error_upload_failed": "Failed to upload",
 "error_only_english_and_numbers_allowed": "Only English and Numbers Allowed",
 "english_and_numbers_input_placeholder": "Please input in English or numbers",
 "department_ae": "AE",
 "department_ai": "AI",
 "department_se": "SE",
 "department_si": "SI",
 "department_lg": "LG",
 "department_dt": "DT",
 "service_type_dtd": "DTD",
 "service_type_ata": "ATA",
 "service_type_dta": "DTA",
 "service_type_atd": "ATD",
 "service_type_ptp": "PTP",
 "service_type_dtp": "DTP",
 "service_type_ptd": "PTD",
 "service_type_atp": "ATP",
 "service_type_pta": "PTA",
 "service_type_whtd": "WHTD",
 "service_type_whsp": "WHSP",
 "incoterm_exw": "EXW",
 "incoterm_fca": "FCA",
 "incoterm_fas": "FAS",
 "incoterm_fob": "FOB",
 "incoterm_cpt": "CPT",
 "incoterm_cfr": "CFR",
 "incoterm_cif": "CIF",
 "incoterm_cip": "CIP",
 "incoterm_dap": "DAP",
 "incoterm_ddp": "DDP",
 "incoterm_dat": "DAT",
 "incoterm_dpu": "DPU",
 "incoterm_daf": "DAF",
 "incoterm_des": "DES",
 "incoterm_deq": "DEQ",
 "incoterm_ddu": "DDU",
 "tao_test": " ",
 "shipment_created_at_utc": "Shipment Create (UTC)",
 "validation_wrong_format": "Wrong Format",
 "validation_max_date": "Maximum date %{date}",
 "validation_min_date": "Minimum date %{date}",
 "copy": "Copy",
 "paste": "Paste",
 "delete_comment_null_id": "This Comment does not exist",
 "too_many_files": "Too many files (max %{max})",
 "pagination_first": "First",
 "pagination_last": "Last",
 "login": "Login",
 "export_exceed_rows_limit": "Over %{limit} Record, please contact with morrison one help",
 "sql_field_error": "The query condition contains error, please correct it and query again",
 "forget_password_success": "Reset  Password  email  has  sent  to  %{email}"
}

export default enDictionary