
export const esES = {
  application: {
    action: '',
    all: 'All',
    dashboard: 'Dashboard',
    data: 'Data',
    default: 'Default',
    function: 'Function',
    general: 'General',
    hello: 'Hello, %{name}!',
    id: 'ID',
    language: 'Español',
    short_lang: 'Español',
    list: 'List',
    logout: 'Logout',
    name: 'Name',
    no: 'No.',
    query: 'Query',
    result: 'Result',
    shipment_label: 'HAWB/HBL',
    type: 'Type',
    general_menu: 'General Menu',
    something_went_wrong: 'Something went wrong',
    please_select_at_least_one_record: 'Please select at least one record.',
    we_cannot_access_your_camera: 'We cannnot access your camera.',
    new: 'New',
    create_date: 'Create Date (UTC)',
    created_by: 'Created By',
    client_id: 'Client ID',
    refresh_every_15_seconds: 'Refresh Every 15 seconds',
    refresh_every_5_minutes: 'Refresh Every 5 minutes',
    customized_setting: 'Customized Setting',
    customized_table_header: 'Customized Table Header',
    timeLine: 'Timeline',
    response_is_empty: 'Response is empty',
    we_will_send_file_by_email_after_few_minutes: 'We will send file by email after few minutes.',
    the_file_is_too_large_to_be_generated: 'The file is too large to be genereated.',
    the_file_is_being_uploaded: 'The file is being uploaded.',
    customerTracking: {
      query: 'QUERY',
      result: 'TRACKING RESULT',
    },
    new_kpi_exception: 'Create a KPI Exception',
    exemption: 'Exemption',
    extension: 'Extension',
    breadcrumbs: {
      system: 'System',
      menu: 'Menu',
      user: 'User',
      userEdit: 'Edit',
      userCreate: 'Create',
      role: 'Role',
      bookingManagement: 'Booking Mgmt',
      booking: 'Booking',
      ocr: 'OCR',
      edit: 'Edit',
      create: 'Create',
      shipmentManagement: 'Shipment Mgmt',
      shipment: 'Shipment',
      hawbEdit: 'HAWB Edit',
      newShipment: 'New Shipment',
      document: 'Document Mgmt',
      hawbUpload: 'HAWB Upload',
      mawbUpload: 'MAWB Upload',
      report: 'Report',
      reportDownload: 'Report Download',
      ediStatus: 'EDI Status',
      invoice: 'Invoice',
      milestone: 'Milestone',
      ediMonitor: 'EDI Monitor',
      ediMonitorEdit: 'EDI Monitor Edit',
      vendorBillingPortal: 'Vendor Billing Portal',
      vendorBillingInvoiceQuery: 'Invoice Query',
      vendorBillingInvoiceUpload: 'Invoice Upload',
      VendorBillingInvoiceEdit: 'Invoice Edit',
      salesManagement: 'Sales Mgmt',
      sellingRate: 'Selling Rate',
      cfs: 'CFS',
      exportReceiveQuery: 'Export Receive',
      exportReceiveEdit: 'Export Receive Edit',
      exportPutAway: 'Export Put Away',
      exportPicking: 'Export Picking',
      tms: 'TMS Mgmt',
      billOfLadingQuery: 'BOL Mgmt',
      billOfLadingEdit: 'BOL Edit',
      billOfLadingNew: 'BOL New',
      dispatch: 'Dispatch',
      dispatchQuery: 'Dispatch Mgmt',
      dispatchEdit: 'Dispatch Edit',
      newDispatch: 'New Dispatch',
      tmsShipment: 'Shipment Mgmt',
      fastDispatchCreate: 'Fast Dispatch Create',
      customerTracking: 'Customer Tracking',
      shipmentSearch: 'Shipment',
      hawbDetail: 'HAWB Detail',
      handover: 'Handover',
      handoverToDriver: 'Handover to Driver',
      deliveryStatusUpdate: 'Delivery Status Update',
      kpiManagement: 'KPI Management',
      invoiceKPISearch: 'Invoice KPI Search',
      newInvoiceKPI: 'New Invoice KPI',
    },
    buttons: {
      add: 'Add',
      back: 'Back',
      cancel: 'Cancel',
      close: 'Close',
      complete: 'Complete',
      confirm: 'Confirm',
      continue: 'Continue',
      create: 'Create',
      delete: 'Delete',
      download: 'Download',
      draft: 'Save as draft',
      edit: 'Edit',
      export: 'Export',
      getEDI: 'Get EDI',
      next: 'Next',
      ok: 'OK',
      parser: 'Parser',
      previous: 'Previous',
      print: 'Print',
      quit: 'Quit',
      reprocess: 'Reprocess',
      reset: 'Reset',
      resubmit: 'Resubmit',
      search_fail: 'Search Fail',
      save: 'Save',
      search: 'Search',
      update: 'Update',
      hawbLabelPrint: 'HAWB Label Print',
      uploading: 'Uploading',
      cancelBol: 'Cancel BOL',
      confirmBol: 'Confirm BOL',
      booking: 'Booking',
      cancelBooking: 'Cancel Booking',
      printLabel: 'Print Label',
      choosePolicy: 'Choose Policy',
      scanQrCode: 'Scan QR Code',
      checkInCounter: 'Check In Counter',
      fastDispatchCreate: 'Fast Dispatch Create',
      uploadLicense: 'Upload License',
      outForDelivery: 'Out For Delivery',
      uploadPhoto: 'Upload Photo',
      resendSMS: 'Resend SMS',
      cancelEdit: 'Cancel Edit',
      departure: 'Departure',
      upload: 'Upload',
      spinSave: 'Save',
      activate: 'Activate',
      deactivate: 'Deactivate',
    },
  },
}
