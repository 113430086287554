import deepExtend from 'deep-extend'

import { en } from './en'
import { zhHant } from './zhHant'
import { zhHans } from './zhHans'
import { esES } from './esES'

import enLang from './languageDictionary/en'
import zhHantLang from './languageDictionary/zhHant'
import zhHansLang from './languageDictionary/zhHans'
import esESLang from './languageDictionary/spanish'

import enTerms from './ePODTermsAndConditions/en'
import zhHantTerms from './ePODTermsAndConditions/zhHant'
import zhHansTerms from './ePODTermsAndConditions/zhHans'
import esESTerms from './ePODTermsAndConditions/esES'

const enHantObject = {}
deepExtend(enHantObject, en)
deepExtend(enHantObject, enLang)
deepExtend(enHantObject, enTerms)

const zhHantObject = {}
deepExtend(zhHantObject, en)
deepExtend(zhHantObject, zhHant)
deepExtend(zhHantObject, zhHantLang)
deepExtend(zhHantObject, zhHantTerms)

const zhHansObject = {}
deepExtend(zhHansObject, en)
deepExtend(zhHansObject, zhHans)
deepExtend(zhHansObject, zhHansLang)
deepExtend(zhHansObject, zhHansTerms)

const esESObject = {}
deepExtend(esESObject, en)
deepExtend(esESObject, esES)
deepExtend(esESObject, esESLang)
deepExtend(esESObject, esESTerms)

const locales = {
  en: enHantObject,
  zhHant: zhHantObject,
  zhHans: zhHansObject,
  esES: esESObject,
}

export default locales
